import React, { useMemo } from 'react';
import MaterialDataTable from '../../../../components/MaterialDataTable';
import axios from 'axios';
import { useHistory } from "react-router-dom";
import { useSnackbar } from 'notistack';

const PayrollTaxSetupAddOnsList = () => {

  const {enqueueSnackbar} = useSnackbar();
  const history = useHistory();
  const tableColumns = useMemo(() => ([
    {
      title: 'Name',
      field: 'name',
      searchable: true,
      sorting: true
    },
    {
      title: 'Employee tax rate',
      field: 'employee_tax_rate',
      searchable: true,
      sorting: true
    },
    {
      title: 'Employer tax rate',
      field: 'employer_tax_rate',
      searchable: true,
      sorting: true
    },
    {
      title: 'State',
      field: 'state',
      searchable: true,
      sorting: true
    }
  ]), []);

  const onRemove = (payrollTaxSetupAddOn) => {
    return axios({
      method: 'DELETE',
      url: Routes.api_web_v1_payroll_tax_setup_add_on_path(payrollTaxSetupAddOn.id),
    })
      .then((response) => {
        enqueueSnackbar('Payroll tax setup was removed.', { variant: 'success' });
        return response;
      });
  };

  const onAdd = () => history.push(`/payroll_tax_setup_add_ons/new`);

  const onEdit = (payrollTaxSetupAddOn) => (
    history.push(`/payroll_tax_setup_add_ons/${payrollTaxSetupAddOn.id}/edit`)
  );

  return (
    <div className="pt-4">
      <MaterialDataTable
        title="Payroll tax setup add on settings"
        tableColumns={tableColumns}
        path={Routes.api_web_v1_payroll_tax_setup_add_ons_path()}
        onRemove={onRemove}
        onAdd={onAdd}
        onEdit={onEdit}
      />
    </div>
  );
};

export default PayrollTaxSetupAddOnsList;
