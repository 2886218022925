import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { get, map } from 'lodash';
import Table from 'react-bootstrap/Table';
import Bootstrap4Paginator from '../../../components/Bootstrap4Paginator';
import { change } from 'redux-form';
import moment from 'moment';

const ReportTable = () => {
  const dispatch = useDispatch();

  const commissionReportsStore = useSelector(state => state.commissionReportsStore);
  const payrollSalaryChanges = get(commissionReportsStore, 'payrollSalaryChanges', []);
  const totalRecords = get(commissionReportsStore, 'responseMetaData.total_count', 0);
  const additionalSum = get(commissionReportsStore, 'responseMetaData.additional_sum.cents', 0);
  const deductionSum = get(commissionReportsStore, 'responseMetaData.deduction_sum.cents', 0);

  const [currentPage, setCurrentPage] = useState(get(commissionReportsStore, 'responseMetaData.page', 1));

  useEffect(() => {
    dispatch(change('commissionReportsFilters', 'page', currentPage));
  }, [currentPage]);

  const defineAdditional = (salaryChange) => {
    if (salaryChange.math_type === 'additional') {
      return salaryChange.paid_amount.cents / 100;
    } else {
      return (0);
    }
  };

  const defineDeduction = (salaryChange) => {
    if (salaryChange.math_type === 'deduction') {
      return salaryChange.paid_amount.cents / 100;
    } else {
      return (0);
    }
  };

  const handlePageClick = ({selected}) => {
    setCurrentPage(selected + 1)
  };

  return (
    <>
      <Table striped bordered hover size="sm">
        <thead>
        <tr>
          <th>Employee Name</th>
          <th>Paid Date</th>
          <th>Reason</th>
          <th>Note</th>
          <th>Additional</th>
          <th>Deduction</th>
        </tr>
        </thead>
        <tbody>
        {payrollSalaryChanges.length > 0 &&
         map(payrollSalaryChanges, (salaryChange, index) => {
            return (
              <tr key={index}>
                <td>{salaryChange.employee_full_name}</td>
                <td>{moment(salaryChange.paid_date).calendar()}</td>
                <td>{salaryChange.reason_name}</td>
                <td>{salaryChange.note}</td>
                <td>{defineAdditional(salaryChange)}</td>
                <td>{defineDeduction(salaryChange)}</td>
              </tr>
            );
          })
        }

        {payrollSalaryChanges.length === 0 &&
        <tr>
          <td colSpan='6'>No data</td>
        </tr>
        }

        </tbody>
        <tfoot>
          <tr>
            <th className='text-right w-100' colSpan='4'>Totals:</th>
            <th>{additionalSum / 100}</th>
            <th>{deductionSum / 100}</th>
          </tr>
        </tfoot>
      </Table>

      <Bootstrap4Paginator handlePageClick={handlePageClick} currentPage={currentPage} totalRecords={totalRecords}/>

    </>
  );

};

export default ReportTable;

