import React, { useState, useEffect } from 'react';
import DropdownButton from 'react-bootstrap/DropdownButton';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import { connect } from 'react-redux';
import { map } from 'lodash';
import Button from 'react-bootstrap/Button';

const DocumentCopyToSection = ({ document: { values } }) => {

  const [copyToOptions, setCopyToOptions] = useState(null);
  const [pdfLink, setPdfLink] = useState(null);

  useEffect(() => defineCopyToOptions(),
    [
      values.id,
      values.class_name
    ]
  );

  const defineCopyToOptions = () => {
    if (!values.id) return;

    switch (values.class_name) {
      case 'SalesQuotation':
        setCopyToOptions(
          [
            {
              name: 'Sales Quotation',
              link: Routes.copy_to_sales_quotation_sales_quotation_path(values.id)
            },
            {
              name: 'Sales Order',
              link: Routes.copy_to_sales_order_sales_quotation_path(values.id)
            }
          ]
        );
        return setPdfLink(Routes.sales_quotation_path(values.id, { format: 'pdf' }));
      case 'SalesOrder':
        return setCopyToOptions(
          [
            {
              name: 'Tech Sales Order',
              link: Routes.copy_to_sales_order_sales_order_path(values.id)
            },
            {
              name: 'A/R Down Payment Invoice',
              link: Routes.copy_to_ar_dp_invoice_sales_order_path(values.id)
            },
            {
              name: 'A/R Invoice',
              link: Routes.copy_to_ar_invoice_sales_order_path(values.id)
            }
          ]
        );
      default:
        return [];
    }
  };

  return (
    <div className="mt-3">
      {copyToOptions &&
      <DropdownButton
        as={ButtonGroup}
        title="Copy to"
        drop="right"
        variant="info"
      >
        {
          map(copyToOptions, (option, key) => {
            return (
              <Dropdown.Item
                key={`copy-to-option-${key}`}
                href={option.link}
                target="_blank"
              >{option.name}</Dropdown.Item>
            );
          })
        }
      </DropdownButton>
      }
      {pdfLink &&
      <Button
        variant="warning"
        href={pdfLink}
        className="ml-1"
        target="_blank"
      >Open PDF</Button>
      }
    </div>
  );

};

const mapStateToProps = (state, ownProps) => {
  return {
    document: state.form.commonDocumentForm,
  };
};

export default connect(mapStateToProps)(DocumentCopyToSection);
