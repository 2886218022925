import Application from 'application-namespace';
import $ from 'jquery';
import _each from 'lodash/each';
import _get from 'lodash/get';
import moment from 'moment';
import Tabulator from 'tabulator-tables';

Application.Classes.AttendanceCalculationBuilder = class AttendanceCalculationBuilder {
  constructor($scope) {
    this.$scope = $scope;
    this.initializeRangeDatePicker(this.$scope);
    this.$table = this.$scope.find('#attendance-calculation-table');
    this.$dateRangeFilter = this.$scope.find('.range-date-picker');
    this.$employeesFilter = this.$scope.find('#employees_filter');
    this.$violationsFilter = this.$scope.find('#filter_violations');
    this.$recordsPerPage = this.$scope.find('#records_per_page_size');
    this.sourcePath = this.$scope.data('sourcePath');
    this.$currentPageInput = this.$scope.find('.current-page-value');
    this.filters = [this.$dateRangeFilter, this.$employeesFilter, this.$violationsFilter];
    this.filtersResult = {};

    _each(this.filters, element  => element.on('change', () => this.reloadTable()));

    this.$recordsPerPage.on('change', (event) => {
      const value = $(event.currentTarget).val();
      this.filtersResult.page_size = value;
      this.$table.setPageSize(value);
      this.replaceStateParams();
    });

    this.setDateRange();
  }

  reloadTable() {
    this.$table.clearData();
    this.setDateRange();
  }

  setDateRange() {
    const currentDateRanges = this.$dateRangeFilter.length ? this.$dateRangeFilter.val()
      .split('-') : this.$scope.$dateRangeFilter.val()
      .split('-');
    this.range = [];
    this.rangeStartAt = moment(moment(currentDateRanges[0])
      .format('llll'))
      .format('MM/DD/YYYY');
    this.rangeEndAt = moment(moment(currentDateRanges[1])
      .format('llll'))
      .format('MM/DD/YYYY');

    let start = new Date(this.rangeStartAt);
    const end = new Date(this.rangeEndAt);

    while (start <= end) {
      this.range.push(moment(start)
        .format('MM/DD/YYYY'));
      const newDate = start.setDate(start.getDate() + 1);
      start = new Date(newDate);
    }

    this.parseData();
  }

  parseData() {
    _each(this.filters, (element) => {
      let key;
      let val;
      if ($(element)
        .attr('type') === 'checkbox') {
        key = $(element)
          .attr('name');
        val = $(element)
          .prop('checked');
        this.filtersResult[key] = val;
      } else {
        key = $(element)
          .attr('name');
        val = $(element)
          .val();
        this.filtersResult[key] = val;
      }
    });
    this.replaceStateParams();
    this.newDateParams();

    if (this.sourcePath) {
      $.ajax({
        method: 'GET',
        url: this.sourcePath,
        dataType: 'JSON',
        data: this.filtersResult,
      })
        .then((response) => {
          this.prepareData(response.data, response.employees, response.not_assigned_punches);
        })
        .catch((error) => {
          console.error('Error: ', error);
        });
    } else {
      console.warn(`Application.Classes.AttendanceCalculationBuilder#parseData this.sourcePath - ${this.source}`);
    }
  }

  replaceStateParams() {
    let url = '';
    this.newDateParams();
    _each(Object.keys(this.filtersResult), (value) => {
      url += `${encodeURIComponent(value)}=${encodeURIComponent(this.filtersResult[value])}&`;
    });
    window.history.replaceState('', '', [window.location.href.split('?')[0], url].join('?'));
  }

  prepareData(employeeWorkDays, employeesCollection, notAssignedPunches, finish) {
    const finishResult = finish == null ? [] : finish;
    const employees = this.getEmployees(employeesCollection);

    employees.forEach((employee) => {
      const schedule = employee.work_days.configuration.raw.hours;

      this.range.forEach((date) => {
        const originWorkDay = this.findWorkDay(employeeWorkDays, employee.id, date);
        if (!originWorkDay && this.$violationsFilter.prop('checked')) {
          return;
        }
        let startTime;
        let endTime;
        const dayName = moment(date)
          .format('ddd')
          .toLowerCase();
        const currentSchedule = schedule[dayName];
        if (currentSchedule) {
          const originStartTime = Object.keys(currentSchedule)[0];
          const originEndTime = Object.values(currentSchedule)[0];
          if (originStartTime) {
            startTime = moment(originStartTime, 'HH:mm')
              .format('h:mm A');
          }
          if (originEndTime) {
            endTime = moment(originEndTime, 'HH:mm')
              .format('h:mm A');
          }
        }

        const data = {
          employee_full_name: employee.full_name,
          work_day_at: (originWorkDay && _get(originWorkDay, 'payroll_report.status') !== 'archived') ? `<a data-lazy-modal='true' target='_blank' href='${Routes.edit_employee_employee_work_day_path(employee.id, originWorkDay.id)}'>${date}</a>` : date,
          on_duty: startTime,
          off_duty: endTime,
          check_in: this.checkIn(originWorkDay, employee, date, startTime, currentSchedule),
          check_out: this.checkOut(originWorkDay, employee, date, endTime, currentSchedule),
          total_hours: _get(originWorkDay, 'formatted_total_hours'),
        };

        finishResult.push(data);
      });
    });

    const unknownEmployees = [];
    notAssignedPunches.forEach((punch) => {
      if (!unknownEmployees.includes(punch.origin_data != null ? punch.origin_data.employee_pass_id : undefined)) {
        unknownEmployees.push(punch.origin_data != null ? punch.origin_data.employee_pass_id : undefined);
      }
    });

    if (!this.$violationsFilter.prop('checked')) {
      $.unique(unknownEmployees)
        .forEach((employeeId) => {
          this.range.forEach((date) => {
            let startTime;
            let endTime;
            notAssignedPunches.forEach((punch) => {
              if ((_get(punch, 'origin_data.employee_pass_id') === employeeId) && (moment(punch.punch_time_at)
                .format('MM/DD/YYYY') === date)) {
                if (startTime && (moment(startTime) > moment(punch.punch_time_at))) {
                  endTime = startTime;
                  startTime = punch.punch_time_at;
                } else {
                  startTime = punch.punch_time_at;
                }

                if (endTime && (moment(endTime) < moment(punch.punch_time_at))) {
                  startTime = endTime;
                  endTime = punch.punch_time_at;
                } else {
                  endTime = punch.punch_time_at;
                }
              }
            });

            const data = {
              employee_full_name: ['Unknown User', employeeId].join(' #'),
              work_day_at: date,
              on_duty: 'Unknown',
              off_duty: 'Unknown',
              check_in: startTime ? moment(startTime)
                .format('LT') : undefined,
              check_out: endTime ? moment(endTime)
                .format('LT') : undefined,
              total_hours: 'Unknown',
            };
            finishResult.push(data);
          });
        });
    }

    this.buildTable(finishResult);
  }

  getEmployees(employees, result) {
    const finalResult = result == null ? [] : result;
    _each(employees, (activeEmployee) => {
      const fullName = [activeEmployee.first_name, activeEmployee.last_name].join(' ');
      finalResult.push({
        id: activeEmployee.id,
        full_name: fullName,
        work_days: activeEmployee.work_days,
        max_work_time_offset_minutes: activeEmployee.max_work_time_offset_minutes,
      });
    });
    return finalResult;
  }

  findWorkDay(employeeWorkDays, employeeId, date, result) {
    let finalResult = result == null ? undefined : result;
    _each(employeeWorkDays, (workDay) => {
      if ((workDay.employee.id === employeeId) && (moment(workDay.work_day_at)
        .format('MM/DD/YYYY') === date)) {
        finalResult = workDay;
      }
    });
    return finalResult;
  }

  checkIn(originWorkDay, employee, date, startTime, currentSchedule, additionalClass) {
    let path;
    let addClass = additionalClass == null ? '' : additionalClass;
    if (originWorkDay && originWorkDay.first_punch) {
      path = Routes.edit_employee_employee_punch_path(employee.id, originWorkDay.first_punch.id);
      addClass = addClass && (moment([date, Object.keys(currentSchedule)[0]].join(' '))
        .add(employee.max_work_time_offset_minutes, 'minutes') < moment([date, originWorkDay.first_punch.formatted_punch_time_at].join(' '))) ? 'mark-background' : '';
      if (_get(originWorkDay, 'payroll_report.status') !== 'archived') {
        return this.modalLink(path, originWorkDay.first_punch.formatted_punch_time_at, null, addClass);
      }
      return `<p class='text-danger'>${originWorkDay.first_punch.formatted_punch_time_at}</p>`;
    }

    if (_get(originWorkDay, 'payroll_report.status') !== 'archived') {
      const recommendedDate = moment([date, startTime].join(' '))
        .format('L LT');
      path = Routes.new_employee_employee_punch_path(employee.id, { recommended_date: recommendedDate });
      return this.modalLink(path, 'Add manual', currentSchedule);
    }
    return '<p class="text-muted">No data</p>';
  }

  checkOut(originWorkDay, employee, date, endTime, currentSchedule, additionalClass) {
    let path;
    let addClass = additionalClass == null ? '' : additionalClass;

    if (originWorkDay && originWorkDay.last_punch) {
      path = Routes.edit_employee_employee_punch_path(employee.id, originWorkDay.last_punch.id);
      addClass = addClass && (moment([date, Object.values(currentSchedule)[0]].join(' '))
        .subtract(employee.max_work_time_offset_minutes, 'minutes') > moment([date, originWorkDay.last_punch.formatted_punch_time_at].join(' '))) ? 'mark-background' : '';
      if (_get(originWorkDay, 'payroll_report.status') !== 'archived') {
        return this.modalLink(path, originWorkDay.last_punch.formatted_punch_time_at, null, addClass);
      }
      return `<p class='text-danger'>${originWorkDay.last_punch.formatted_punch_time_at}</p>`;
    }

    if (_get(originWorkDay, 'payroll_report.status') !== 'archived') {
      const recommendedDate = moment([date, endTime].join(' '))
        .format('L LT');
      path = Routes.new_employee_employee_punch_path(employee.id, { recommended_date: recommendedDate });
      return this.modalLink(path, 'Add manual', currentSchedule);
    }
    return '<p class=\'text-muted\'>No data</p>';
  }

  modalLink(path, label, currentSchedule, additionalClass) {
    const addClass = additionalClass == null ? '' : additionalClass;
    const className = currentSchedule && Object.values(currentSchedule).length ? 'text-warning' : undefined;
    return `<a class='${className} ${addClass}' data-lazy-modal='true' target='_blank' href='${path}'>${label}</a>`;
  }

  buildTable(finishResult) {
    let previousEmployeeName;
    this.$table = new Tabulator('#attendance-calculation-table', {
      layout: 'fitColumns',
      placeholder: 'No Data Set',
      pagination: 'local',
      paginationSize: this.$recordsPerPage.val(),
      columns: [
        {
          title: 'Employee',
          field: 'employee_full_name',
          sorter: 'string',
        },
        {
          title: 'Date',
          field: 'work_day_at',
          sorter: 'string',
          formatter: 'html',
        },
        {
          title: 'On duty',
          field: 'on_duty',
        },
        {
          title: 'Off duty',
          field: 'off_duty',
        },
        {
          title: 'Check In',
          field: 'check_in',
          formatter: 'html',
        },
        {
          title: 'Check Out',
          field: 'check_out',
          formatter: 'html',
        },
        {
          title: 'Total Time',
          field: 'total_hours',
          sorter: 'string',
          sortable: false,
        },
      ],
      data: finishResult,
      pageLoaded: (pageno) => {
        this.$currentPageInput.val(pageno);
        Application.app.initLazyModals(this.$scope);
      },
      renderComplete: (pageno) => {
        this.$currentPageInput.val(pageno);
        Application.app.initLazyModals(this.$scope);
      },
      rowFormatter: (row) => {
        const employeeName = row.getData().employee_full_name;
        if (row.getData().total_hours === 'Unknown') {
          $(row.getElement())
            .addClass('bg-warning');
        }
        if (previousEmployeeName && (employeeName !== previousEmployeeName)) {
          $(row.getElement())
            .css('border-top', '1px solid');
        }
        previousEmployeeName = employeeName;
      },
    });

    Application.app.initLazyModals(this.$scope);
    this.markCells();
  }

  markCells() {
    this.$scope.find('.mark-background')
      .each((index, element) => $(element)
        .closest('.tabulator-cell')
        .addClass('bg-warning'));
  }

  newDateParams() {
    const currentDateRanges = this.$dateRangeFilter.length ? this.$dateRangeFilter.val()
      .split('-') : this.$scope.$dateRangeFilter.val()
      .split('-');
    const rangeStart = moment(moment(currentDateRanges[0])
      .format('llll'))
      .format('MM/DD/YYYY');
    const rangeEnd = moment(moment(currentDateRanges[1])
      .format('llll'))
      .format('MM/DD/YYYY');
    this.filtersResult.start_date = rangeStart;
    this.filtersResult.end_date = rangeEnd;
    delete this.filtersResult.date_range;
  }

  initializeRangeDatePicker($el) {
    const $scope = $el == null ? $('body') : $el;
    const payrollRuns = {
      weekly: 6,
      biweekly: 14,
      monthly: 31,
    };
    const $input = $scope.find('.range-date-picker');
    const res = {};
    if ($input.data('lastPeriods')) {
      $input.data('lastPeriods')
        .forEach((element) => {
          const dates = [];
          const keys = [];
          Object.values(element.dates)
            .forEach((date, index) => {
              dates.push(index > 0 ? moment.utc(date) : moment.utc(date));
              keys.push(index > 0 ? moment.utc(date)
                .format('l') : moment.utc(date)
                .format('l'));
            });
          res[`<span class='pull-right label ${element.label_class}'>${element.status}</span>${keys.join('-')}`] = dates;
        });
    }
    $input.daterangepicker({
      locale: {
        format: 'MM/DD/YYYY',
        separator: '-',
      },
      maxSpan: { days: payrollRuns[$input.data('payrollRun')] || 61 },
      ranges: res,
    });
  }
};
