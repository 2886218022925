import React from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';

let AddressBillingNav = ({
  partnerBillingAddresses,
  fields
}) => {

  const newNestedForm = (event) => {
    event.preventDefault();
    fields.push({});
  };

  return (
    <Row>
      <Col sm={12}>
        <hr/>
        <h5 className={'text-center'}>Billing Addresses</h5>
        <Nav variant="pills" className="flex-column">
          {
            fields.map((partnerBillingAddress, index) =>
              <Nav.Link
                eventKey={`billing-${index}`}
                key={`partnerBillingAddress-${index}`}
              >{partnerBillingAddresses[index].address_name || 'Define new'}</Nav.Link>
            )
          }
          <Nav.Item>
            <Nav.Link
              eventKey="first-billing"
              onClick={newNestedForm}
            >Add</Nav.Link>
          </Nav.Item>
        </Nav>
      </Col>
    </Row>
  );
};

AddressBillingNav = reduxForm({
  form: 'businessPartnerForm',
  enableReinitialize: true
})(AddressBillingNav);

AddressBillingNav = connect(state => {
  const { values: { partner_billing_addresses_attributes: partnerBillingAddresses } } = state.form.businessPartnerForm;
  return {
    partnerBillingAddresses,
  };
})(AddressBillingNav);

export default AddressBillingNav;
