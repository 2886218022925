import React, { useEffect } from 'react';
import { Field, FieldArray } from 'redux-form';
import { connect } from 'react-redux';
import Button from 'react-bootstrap/Button';
import Tab from 'react-bootstrap/Tab';
import Table from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import map from 'lodash/map';
import ContactPeoplePhone from './ContactPeoplePartnerPhone/ContactPeoplePhone';
import ContactPeopleEmail from './ContactPeoplePartnerEmails/ContactPeopleEmail';
import RenderField from '../../../../components/ReduxForm/RenderField';
import MultiSelectWrapper from '../../../../components/ReduxForm/MultiSelectWrapper';
import { fetchBusinessPartnerData } from '../../../../redux/businessPartnerStoreReducer/actions';

let ContactFields = ({
  partnerContactPerson,
  index,
  fields,
  personPositions,
  changeBusinessPartnerCollection
}) => {


  useEffect(() => {
    // if (personPositions.length === 0) {
    //   changeBusinessPartnerCollection();
    // }
  });

  const personPositionsCollection = map(personPositions, (value, _key) => ({
    value: value.code,
    label: value.name
  }));

  return (
    <Tab.Pane key={index} eventKey={index}>
      <Row>
        <Col xs={12}>

          <Field
            name={`${partnerContactPerson}.contact_id`}
            component={RenderField}
            label="Contact"
          />

          <Field
            name={`${partnerContactPerson}.first_name`}
            component={RenderField}
            label='First name'
          />

          <Field
            name={`${partnerContactPerson}.middle_name`}
            component={RenderField}
            label={'Middle name'}
          />

          <Field
            name={`${partnerContactPerson}.last_name`}
            component={RenderField}
            label={'Last name'}
          />

          <Field
            label={'Person positions'}
            name={`${partnerContactPerson}.person_position_ids`}
            component={MultiSelectWrapper}
            collection={personPositionsCollection}
            formName="businessPartnerForm"
          />

          <Field
            name={`${partnerContactPerson}.title`}
            component={RenderField}
            label={'Title'}
          />

          <Field
            name={`${partnerContactPerson}.company_name`}
            component={RenderField}
            label={'Company name'}
          />

          <div>
            <Table bordered responsive size="sm">
              <thead>
              <tr>
                <th>Phone</th>
                <th>Phone description</th>
                <th style={{ width: '78px' }}>Actions</th>
              </tr>
              </thead>
              <FieldArray
                name={`${partnerContactPerson}.contact_person_phones_attributes`}
                component={ContactPeoplePhone}
              />
            </Table>
          </div>

          <div>
            <Table bordered responsive size="sm">
              <thead>
              <tr>
                <th>Email</th>
                <th style={{ width: '78px' }}>Actions</th>
              </tr>
              </thead>
              <FieldArray
                name={`${partnerContactPerson}.contact_person_emails_attributes`}
                component={ContactPeopleEmail}
              />
            </Table>
          </div>
          <Field
            name={`${partnerContactPerson}.fax`}
            component={RenderField}
            label={'Fax'}
          />
          <Field
            name={`${partnerContactPerson}.remarks_1`}
            component={RenderField}
            label={'Remarks 1'}
          />
          <Field
            name={`${partnerContactPerson}.remarks_2`}
            component={RenderField}
            label={'Remarks 2'}
          />
          <Field
            name={`${partnerContactPerson}.remarks_3`}
            component={RenderField}
            label={'Remarks 3'}
          />
          <hr/>
          <Button variant="danger" size="sm" onClick={() => fields.remove(index)}>
            Remove
          </Button>
        </Col>
      </Row>
    </Tab.Pane>
  );
};


const mapDispatchToProps = dispatch => ({
  changeBusinessPartnerCollection: () => {
    dispatch(fetchBusinessPartnerData());
  },
});


const mapStateToProps = (state) => {
  const {
    form: { businessPartnerForm: businessPartner }, businessPartnerStore: {
      personPositionsCollection,
    }
  } = state;

  return {
    businessPartner,
    personPositions: personPositionsCollection
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactFields);


