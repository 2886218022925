import React, { Fragment } from 'react';
import { Switch, Route } from 'react-router-dom';
import { Redirect} from 'react-router-dom';
import { useSelector } from 'react-redux';
import find from 'lodash/find'
import GlobalRetirementSettingsList from './GlobalRetirementSettingsList';
import FormGlobalRetirementSettingWrapper from './FormGlobalRetirementSetting';

const GlobalRetirementSettings = () => {

  const currentSetupStepData = useSelector(state => state.projectPayrollSetupStore.notConfiguredSettings);

  return (
    <Fragment>
      {
        currentSetupStepData && find(currentSetupStepData, { path: 'global_retirement_settings' }) &&
        <Redirect from="/global_retirement_settings" to="/global_retirement_settings/new" />
      }
      <h1>GLOBAL RETIREMENT SETTING</h1>
      <Switch>
        <Route path="/global_retirement_settings/new" component={FormGlobalRetirementSettingWrapper}/>
        <Route path="/global_retirement_settings/:globalRetirementSettingId/edit" component={FormGlobalRetirementSettingWrapper}/>
        <Route path="/global_retirement_settings" component={GlobalRetirementSettingsList}/>
      </Switch>
    </Fragment>
  );
};

export default GlobalRetirementSettings;
