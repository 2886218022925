import Application from 'application-namespace';
import $ from 'jquery';
import moment from 'moment';

Application.Classes.EmployeePayrollSalaryChangeForm = class EmployeePayrollSalaryChangeForm {
  constructor($scope) {
    this.$scope = $scope;
    this.$startDateInput = this.$scope.find('[name="payroll_salary_change[start_date]"]');
    this.$endDateInput = this.$scope.find('[name="payroll_salary_change[end_date]"]');
    this.$mathTypeInput = this.$scope.find('[name="payroll_salary_change[math_type]"]');
    this.$taxTypeOfInput = this.$scope.find('[name="payroll_salary_change[type_of]"]');
    this.$ifMissDeductionTryNextPeriodInput = this.$scope.find('[name="payroll_salary_change[if_miss_deduction_try_next_period]"]');
    this.$stateTypeInput = this.$scope.find('[data-state-type-input]');
    this.$stateInput = this.$scope.find('[data-state-input]');
    this.$frequency = this.$scope.find('[name="payroll_salary_change[frequency]"]');
    this.empoyeeId = this.$scope.data('employeeId');
    this.amountChangeType = this.$scope.find('[name="payroll_salary_change[amount_change_type]"]');
    this.amountInput = this.$scope.find('[name="payroll_salary_change[amount]"]').closest('.input');
    this.amountPercentInput = this.$scope.find('[name="payroll_salary_change[amount_percent]"]').closest('.input');

    this.$scope.find('[data-math-type-input]').on('change', () => this.getTaxType());
    this.$scope.find('[data-payroll-salary-change-reason-input]').on('change', (event) => {
      this.getTaxType();
      this.chooseAmountInput(event);
    });

    this.$startDateInput.on('dp.change', (event) => {
      this.getPayrollReport(event);
      this.constructor.limitTime($(event.currentTarget), this.$endDateInput);
    });

    this.$endDateInput.on('dp.change', (event) => {
      this.getPayrollReport(event);
      this.constructor.limitTime($(event.currentTarget), this.$startDateInput, 'max');
    });

    this.$stateTypeInput.each((i, el) => {
      this.renderForStates($(el));
    });

    this.$frequency.on('change', event => this.getPayrollReport(event));
    this.$stateTypeInput.on('change', event => this.behaviorForStates(event));

    this.$mathTypeInput.on('change', (event) => {
      if ($(event.currentTarget).val() === 'additional') {
        this.$taxTypeOfInput.val('before_tax').trigger('change');
        this.$ifMissDeductionTryNextPeriodInput.prop('checked', false).trigger('change');
        this.$ifMissDeductionTryNextPeriodInput.prop('disabled', true).trigger('change');
      } else {
        this.$taxTypeOfInput.val('after_tax').trigger('change');
        this.$ifMissDeductionTryNextPeriodInput.prop('disabled', false);
      }
    });

    this.$scope.find('[data-payroll-salary-change-reason-input]').trigger('change');
  }

  chooseAmountInput(event) {
    const currentTypeOf = $(event.currentTarget).find(':selected').data('typeOf');

    if (currentTypeOf === 'retirement') {
      this.amountInput.addClass('d-none').val('');
      this.amountPercentInput.removeClass('d-none');
      this.amountChangeType.val('percent').trigger('change');
    } else {
      this.amountInput.removeClass('d-none');
      this.amountPercentInput.addClass('d-none').val('');
      this.amountChangeType.val('fixed').trigger('change');
    }
  }

  getTaxType() {
    const formData = this.serializedForm();
    $.ajax({
      url: Routes.determine_tax_type_employee_payroll_salary_changes_path({ employee_id: this.empoyeeId }),
      dataType: 'JSON',
      type: 'POST',
      data: formData,
    }).then((response) => {
      this.$scope.find('[data-tax-type-text]').val(response.tax_type_text);
      this.$scope.find('[data-tax-type-off-input]').val(response.tax_type).trigger('change');
    }).catch((error) => {
      console.log(error);
    });
  }

  renderForStates(el) {
    if ($(el).val() === 'custom') {
      this.$stateInput.prop('disabled', false);
    } else {
      this.$stateInput.prop('disabled', true).val('').trigger('change');
    }
  }

  behaviorForStates(event) {
    if ($(event.currentTarget).val() === 'custom') {
      this.$stateInput.prop('disabled', false);
    } else {
      this.$stateInput.prop('disabled', true).val('').trigger('change');
    }
  }

  getPayrollReport(event, defaultFormData) {
    const formData = defaultFormData == null ? {} : defaultFormData;
    const startDate = this.$startDateInput.val();
    const endDate = this.$endDateInput.val();
    this.$scope.find('.panel-info').remove();

    this.$scope.serializeArray().forEach((data) => {
      if (data.name !== '_method') {
        formData[data.name] = data.value;
      }
    });

    if (startDate && endDate) {
      $.ajax({
        url: Routes.affecting_payroll_reports_employee_payroll_salary_changes_path(this.empoyeeId),
        method: 'POST',
        dataType: 'JSON',
        data: formData,
      })
        .then((response) => {
          if (response.affecting_payroll_reports.length) {
            const trData = [];
            response.affecting_payroll_reports.forEach((v) => {
              const dateRange = [moment(v.report_start_date).format('L'), moment(v.report_end_date).format('L')].join('-');
              trData.push(`<tr><td>${dateRange}</td><td><a class='btn-min btn-primary' href=${Routes.employee_payroll_path({ date_range: dateRange, mark_report_id: v.id })}>Payroll report</a></td></tr>`);
            });
            const reportsTemplate = `<div class='panel panel-info'><div class='panel-heading'><b>Will affect on next payroll reports</b></div><table class='table'><thead><tr><th>Date range</th><th>Link</th></tr></thead><tbody>${trData.join(' ')}</tbody></table></div>`;
            this.$scope.prepend(reportsTemplate);
          }
        })
        .catch((error) => { console.warn('error', error); });
    } else {
      console.warn(`Application.Classes.EmployeePayrollSalaryChangeForm#_getPayrollReport start_date- ${startDate} && end_date - ${endDate}`);
    }
  }

  static limitTime($from, $to, defaultType) {
    const type = defaultType == null ? 'min' : defaultType;
    if ($from.val()) {
      const selectedTime = moment($from.val(), 'h:mm A');
      if (type === 'min') {
        $to.data('DateTimePicker').minDate(moment({ h: moment(selectedTime).hour(), m: moment(selectedTime).minute() }));
      } else {
        $to.data('DateTimePicker').maxDate(moment({ h: moment(selectedTime).hour(), m: moment(selectedTime).minute() }));
      }
    }
  }

  serializedForm(defaultFormData) {
    const formData = defaultFormData == null ? {} : defaultFormData;
    this.$scope.serializeArray().forEach((data) => {
      if (data.name !== '_method') {
        formData[data.name] = data.value;
      }
    });
    return formData;
  }
};
