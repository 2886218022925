import Application from 'application-namespace';
import $ from 'jquery';

Application.Classes.TechSalesOrderForInvoice = class TechSalesOrderForInvoice extends Application.Classes.CoreBase {
  constructor($scope) {
    super();
    this.$scope = $scope;
    this.selected_tso_ids = [];
    this.currentId = this.$scope.data('resourceId');
    this.$tsoCheckboxes = this.$scope.find('.tso_checkbox');
    this.$sendButton = this.$scope.find('.send-button');

    this.$tsoCheckboxes.on('change', () => this.assembleIds());
    this.$sendButton.on('click', () => this.sendData());
    this.assembleIds();
  }

  assembleIds() {
    this.selected_tso_ids = [];
    this.$tsoCheckboxes.each((key, element) => { if ($(element).is(':checked')) { this.selected_tso_ids.push($(element).data('tsoId')); } });
    this.$sendButton.prop('disabled', !this.selected_tso_ids.length);
  }

  sendData() {
    $.ajax({
      url: Routes.copy_to_ar_invoice_tech_sales_order_path(this.currentId),
      dataType: 'SCRIPT',
      type: 'POST',
      data: { selected_tso_ids: this.selected_tso_ids },
    });
  }
};
