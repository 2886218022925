import React from 'react';
import Button from 'react-bootstrap/Button';
import GeneralPartnerEmailFields from './AttachmentFileFields';

const defaultProps = {
  partnerEmailScheme: {
    id: null,
  }
};

const AttachmentFile = ({ fields, partnerEmailScheme }) => {
  return (
    <>
      <tbody>
      {fields.map((attachments_attributes, index) =>
        <GeneralPartnerEmailFields
          key={`attachments_attribute-${index}`}
          attachments_attributes={attachments_attributes}
          index={index}
          fields={fields}
        />
      )}
      <tr>
        <td colSpan="1" className="text-right">

        </td>
        <td className="text-right">
          <Button block variant="success" size="sm" onClick={() => fields.push(partnerEmailScheme)}>
            Add
          </Button>
        </td>
      </tr>
      </tbody>
    </>
  );
};

AttachmentFile.defaultProps = defaultProps;

export default AttachmentFile;
