import Application from 'application-namespace';
import $ from 'jquery';

Application.Classes.JournalEntryForm = class JournalEntryForm extends Application.Classes.CoreBase {
  constructor($scope) {
    super();
    this.$totalDebit = $scope.find('[data-total-debit]');
    this.$totalCredit = $scope.find('[data-total-credit]');
    this.$formSubmite = $scope.find("[type='submit']");

    $scope.find('table').on('cocoon:after-remove', (e, insertedItem) => {
      insertedItem.empty();
      this.sumByDebitInputs($scope);
      this.sumByCreditInputs($scope);
      this.buttonDisable($scope);
    });

    $scope.find('table').on('cocoon:after-insert', (e, insertedItem) => {
      this.initializePluginsAfterInsert(insertedItem);
      this.initializeInputsWithEvent($scope);
    });

    this.initializeInputsWithEvent($scope);
    this.sumByDebitInputs($scope);
    this.sumByCreditInputs($scope);
    this.buttonDisable($scope);
  }

  initializeInputsWithEvent($scope) {
    $scope.find('[data-debit-input]').on('change', (el) => {
      this.sumByDebitInputs($scope, el);
      this.buttonDisable($scope);
    });

    $scope.find('[data-credit-input]').on('change', (el) => {
      this.sumByCreditInputs($scope, el);
      this.buttonDisable($scope);
    });
  }

  sumByDebitInputs($scope, element) {
    if (element && !$(element.currentTarget).val()) {
      $(element.currentTarget).val(0);
    }
    let debitTotal = 0;
    const debitInput = $scope.find('[data-debit-input]');
    debitInput.each((i, el) => { debitTotal += parseFloat($(el).val()); });
    this.$totalDebit.html(`<b style='float:right'>$${debitTotal.toFixed(2)}</b>`);
  }

  sumByCreditInputs($scope, element) {
    if (element) {
      if (!$(element.currentTarget).val()) { $(element.currentTarget).val(0); }
    }
    let creditTotal = 0;
    const creditInput = $scope.find('[data-credit-input]');
    creditInput.each((i, el) => {
      creditTotal += parseFloat($(el).val());
    });
    this.$totalCredit.html(`<b style='float:right'>$${creditTotal.toFixed(2)}</b>`);
  }

  buttonDisable() {
    if (this.$totalDebit.html() !== this.$totalCredit.html()) {
      this.$formSubmite.is('disabled', true);
      this.$totalDebit.removeClass('success').addClass('danger');
      return this.$totalCredit.removeClass('success').addClass('danger');
    }
    this.$formSubmite.is('disabled', false);
    this.$totalDebit.removeClass('danger').addClass('success');
    return this.$totalCredit.removeClass('danger').addClass('success');
  }
};
