import React, { Fragment } from 'react';
import { Switch, Route } from 'react-router-dom';
import { Redirect} from 'react-router-dom';
import { useSelector } from 'react-redux';
import find from 'lodash/find'
import EmergencyPhoneSettingsList from './EmergencyPhoneSettingsList';
import FormEmergencyPhoneSettingWrapper from './FormEmergencyPhoneSetting';

const EmergencyPhoneSettings = () => {

  const currentSetupStepData = useSelector(state => state.projectPayrollSetupStore.notConfiguredSettings);

  return (
    <Fragment>
      {
        currentSetupStepData && find(currentSetupStepData, { path: 'emergency_phone_settings' }) &&
        <Redirect from="/emergency_phone_settings" to="/emergency_phone_settings/new" />
      }
      <h1>EMERGENCY PHONE SETTING</h1>
      <Switch>
        <Route path="/emergency_phone_settings/new" component={FormEmergencyPhoneSettingWrapper}/>
        <Route path="/emergency_phone_settings/:emergencyPhoneSettingId/edit" component={FormEmergencyPhoneSettingWrapper}/>
        <Route path="/emergency_phone_settings" component={EmergencyPhoneSettingsList}/>
      </Switch>
    </Fragment>
  );
};

export default EmergencyPhoneSettings;
