import React from 'react';
import {Provider} from "react-redux";
import MissedDaysFilters from "./MissedDaysFilters";
import MissedDaysTable from "./MissedDaysTable";
import store from '../../../../redux/store';

const MissedDaysReportContainer = ({}) => {

    const initialValues = { employee_id: null, year: new Date().getFullYear(), day_types: [2, 3, 4, 5] };

    return (
        <>
            <Provider store={store}>
                <MissedDaysFilters initialValues={initialValues}/>
                <MissedDaysTable/>
            </Provider>
        </>
    )

};

export default MissedDaysReportContainer
