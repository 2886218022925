import { produce } from 'immer';
import forEach from 'lodash/forEach';
import reduce from 'lodash/reduce';
import round from 'lodash/round';
import sum from 'lodash/sum';
import get from 'lodash/get';
import last from 'lodash/last';
import isNaN from 'lodash/isNaN';
import filter from 'lodash/filter';

// TODO: check https://redux-toolkit.js.org/introduction/quick-start

import {
  RECALCULATE_DOCUMENT,
  CHANGE_POSITION_ITEM_TYPE,
  CHANGE_POSITION_ITEM_TAX_RATE,
  REMOVE_POSITION_ITEM,
  DEFINE_BUSINESS_PARTNER_DATA
} from './actionTypes'

const setPositionTaxes = (draft, action) => {
  const currentStatePosition = draft.values.positions[action.payload.positionIndex];
  currentStatePosition.tax_rate = action.payload.taxRate;
};

const recalculateDocument = (draft, action) => {
  const documentDiscountPercent = draft.values.discount_percent;
  let totalBeforeDiscount = 0;

  forEach(filterRemovedNestedAttributes(draft.values.positions), (position, index) => {
    position.total_cents = (position.quantity * position.item_price_cents) || 0;
    totalBeforeDiscount += position.total_cents;
    position.tax_price_cents = position.total_cents * position.tax_rate / 100;

    let discountedTaxPriceCents = position.tax_price_cents;
    if (documentDiscountPercent > 0) {
      discountedTaxPriceCents = discountedTaxPriceCents - (discountedTaxPriceCents * documentDiscountPercent / 100);
    }
    position.discounted_tax_price_cents = discountedTaxPriceCents;
  });

  draft.values.total_before_discount_cents = totalBeforeDiscount;
  calculateTotalAfterDiscount(draft, action);
};

const calculateTotalAfterDiscount = (draft, action) => {
  const totalBeforeDiscountCents = draft.values.total_before_discount_cents;
  const discountPercent = draft.values.discount_percent;

  draft.values.discountBasedOn = action.payload.discountBasedOn;

  if (action.payload && action.payload.discountBasedOn && action.payload.discountBasedOn === 'discount_price_cents') {
    if (draft.values.discount_price_cents > totalBeforeDiscountCents) {
      draft.values.discount_price_cents = totalBeforeDiscountCents;
      draft.values.total_after_discount_cents = 0;
      draft.values.discount_percent = 100;
    } else {
      const totalAfterDiscountCents = round(totalBeforeDiscountCents - draft.values.discount_price_cents, 2);
      if (totalAfterDiscountCents >= 0) {
        draft.values.total_after_discount_cents = totalAfterDiscountCents;
        draft.values.discount_percent = round(draft.values.discount_price_cents * 100 / totalBeforeDiscountCents, 2) || 0;
      } else {
        draft.values.total_after_discount_cents = 0;
        draft.values.discount_percent = 100;
      }
    }
  } else {
    if (discountPercent) {
      const discountPriceCents = round(discountPercent * totalBeforeDiscountCents / 100);
      if (discountPercent <= 100) {
        draft.values.discount_price_cents = discountPriceCents;
        draft.values.total_after_discount_cents = round(totalBeforeDiscountCents - discountPriceCents);
      } else {
        draft.values.total_after_discount_cents = 0;
        draft.values.discount_percent = 100;
      }
    } else {
      draft.values.discount_percent = 0;
      draft.values.total_after_discount_cents = totalBeforeDiscountCents;
    }
  }
  calculateDocumentTaxes(draft, action);
};

const calculateDocumentTaxes = (draft, action) => {
  const currentPositions = filterRemovedNestedAttributes(draft.values.positions);

  const discountedTotalTaxPrice = reduce(currentPositions, (sum, position) => {
    sum += position.discounted_tax_price_cents || 0;
    return sum;
  }, 0);

  const taxRates = reduce(currentPositions, (array, position, index) => {
    if (position.tax_rate && position.tax_rate > 0) {
      array.push(position.tax_rate);
    }
    return array;
  }, []);

  draft.values.total_tax_percent = round(sum(taxRates) / taxRates.length, 3) || 0;

  const priceForFreight = draft.values.freight_price_cents * draft.values.total_tax_percent / 100;
  draft.values.total_tax_price_cents = round(sum([discountedTotalTaxPrice, priceForFreight]));
  calculateDocumentTotal(draft);
};

const calculateDocumentTotal = (draft) => {
  draft.values.total_cents = round(sum([draft.values.total_after_discount_cents, draft.values.total_tax_price_cents, draft.values.freight_price_cents]));
};

const filterRemovedNestedAttributes = (nestedAttributes) => {
  return filter(nestedAttributes, (nestedAttribute) => {
    return !nestedAttribute._destroy
  });
};

const positionItemTypeBehavior = (draft, action) => {
  const currentStatePosition = draft.values.positions[action.payload.positionIndex];

  currentStatePosition.item_id = null;
  currentStatePosition.general_ledger_account_id = null;
  currentStatePosition.quantity = 0;
  currentStatePosition.item_description = null;
  currentStatePosition.item_details = null;
  currentStatePosition.item_price_cents = 0;
  currentStatePosition.total_cents = 0;
  if (action.payload.position.position_type === 2) {
    currentStatePosition.warehouse_id = null;
  }
};

const markAsRemovedPositionItem = (draft, action) => {
  const currentStatePosition = draft.values.positions[action.payload.positionIndex];
  currentStatePosition._destroy = true;
};

const defineBusinessPartnerData = (draft, action) => {
  const getValue = (collection, valueKey) => {
    if (isNaN(get(draft.initial, valueKey, '')) &&
      get(draft.initial, valueKey, '') === get(draft.values, valueKey, '')) {
      return get(draft.initial, valueKey, '');
    } else {
      return collection.length > 1 && !get(draft.initial, valueKey, '') ? get(draft.values, valueKey, '') : get(last(collection), 'id');
    }
  };

  draft.values.contact_person_id = getValue(action.payload.contactPeople, 'contact_person_id');
  draft.values.partner_billing_address_id = getValue(action.payload.partnerBillingAddresses, 'partner_billing_address_id');
  draft.values.partner_shipping_address_id = getValue(action.payload.partnerShippingAddresses, 'partner_shipping_address_id');
};

const commonDocumentReducer = produce((draft = [], action, baseState) => {
  switch (action.type) {
    case RECALCULATE_DOCUMENT:
      return recalculateDocument(draft, action);
    case CHANGE_POSITION_ITEM_TAX_RATE:
      return setPositionTaxes(draft, action);
    case CHANGE_POSITION_ITEM_TYPE:
      return positionItemTypeBehavior(draft, action);
    case REMOVE_POSITION_ITEM:
      return markAsRemovedPositionItem(draft, action);
    case DEFINE_BUSINESS_PARTNER_DATA:
      return defineBusinessPartnerData(draft, action);
    default:
      return draft;
  }
});

export default commonDocumentReducer;
