import React from 'react';
import { reduxForm } from 'redux-form';
import { compose } from 'redux';
import DocumentHeader from './DocumentHeader';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import DocumentPositionsContainer from './ContentsTab/DocumentPositionsContainer';
import DocumentTotals from './DocumentTotals';
import Form from 'react-bootstrap/Form';
import DocumentCopyToSection from './Sections/DocumentCopyToSection';
import DocumentLogisticForm from './LogisticsTab/DocumentLogisticForm';
import DocumentAttachmentsContainer from './AttachmentsTab/DocumentAttachmentsContainer';
import ValidateAlertSection from './Sections/ValidateAlertSection';
import SubmitFormSection from './Sections/SubmitFormSection';
import { connect } from 'react-redux';
import LinkedTSOContainer from './LinkedTechSalesOrders/LinkedTSOContainer';
import { parseTechSalesOrders } from '../../../redux/documentStoreReducer/actions';

const CommonDocumentForm = ({ handleSubmit, initialValues, fetchTechSalesOrders }) => {

  const updateTechSalesOrders = () => { fetchTechSalesOrders(initialValues.id) };

  return (
    <>
      <ValidateAlertSection/>
      <Form onSubmit={handleSubmit}>
        <DocumentHeader/>
        <Tabs id="document-tabs">
          <Tab eventKey="contents" title="Contents">
            <DocumentPositionsContainer/>
          </Tab>
          <Tab eventKey="logistics" title="Logistics">
            <DocumentLogisticForm/>
          </Tab>
          {initialValues.class_name === 'SalesOrder' &&
          <Tab onEntered={updateTechSalesOrders} eventKey="linkedTechSalesOrders" title="Linked Tech Sales Orders">
            <LinkedTSOContainer onRemove={updateTechSalesOrders}/>
          </Tab>
          }
          <Tab eventKey="attachments" title="Attachments">
            <DocumentAttachmentsContainer/>
          </Tab>
        </Tabs>
        <hr/>
        <DocumentTotals/>
        <DocumentCopyToSection/>
        <SubmitFormSection/>
      </Form>
    </>
  );
};

const mapDispatchToProps = dispatch => ({
  fetchTechSalesOrders: (salesOrderId) => {
    dispatch(parseTechSalesOrders(salesOrderId));
  }
});

export default compose(
  connect(null, mapDispatchToProps),
  reduxForm({ form: 'commonDocumentForm' })
)(CommonDocumentForm);
