import {
  RECALCULATE_DOCUMENT,
  CHANGE_POSITION_ITEM_TYPE,
  CHANGE_POSITION_ITEM_TAX_RATE,
  REMOVE_POSITION_ITEM,
  DEFINE_BUSINESS_PARTNER_DATA
} from './actionTypes'

export const recalculateDocument = (payload = {}) => ({
  type: RECALCULATE_DOCUMENT,
  payload
});

export const changePositionItemType = (position, positionIndex) => ({
  type: CHANGE_POSITION_ITEM_TYPE,
  payload: {
    position,
    positionIndex
  }
});

export const changePositionItemTaxRate = (positionIndex, taxRate) => ({
  type: CHANGE_POSITION_ITEM_TAX_RATE,
  payload: {
    positionIndex,
    taxRate
  }
});

export const removePositionItem = (positionIndex) => ({
  type: REMOVE_POSITION_ITEM,
  payload: {
    positionIndex
  }
});

export const defineBusinessPartnerData = (contactPeople, partnerBillingAddresses, partnerShippingAddresses) => ({
  type: DEFINE_BUSINESS_PARTNER_DATA,
  payload: {
    contactPeople,
    partnerBillingAddresses,
    partnerShippingAddresses
  }
});
