import { produce } from 'immer';
import { get } from 'lodash';

import { LOAD_PAYROLL_SALARY_CHANGES, LOAD_SALARY_CHANGE_REASONS, LOAD_ACTIVE_EMPLOYEES } from './actionTypes'

const loadSalaryChangeReasons = (draft, action) => {
  draft.payrollSalaryChangeReasons = get(action, 'payload.response.data.payroll_salary_change_reasons', []);
};

const loadPayrollSalaryChanges = (draft, action) => {
  draft.payrollSalaryChanges = get(action, 'payload.response.data.payroll_salary_changes', []);
  draft.responseMetaData = get(action, 'payload.response.data.meta', {});
};

const loadActiveEmployees = (draft, action) => {
  draft.activeEmployees = get(action, 'payload.response.data.employees', []);
};

const commissionReportsStoreReducer = produce((draft = {}, action, baseState) => {
  switch (action.type) {
    case LOAD_SALARY_CHANGE_REASONS:
      return loadSalaryChangeReasons(draft, action);
    case LOAD_PAYROLL_SALARY_CHANGES:
      return loadPayrollSalaryChanges(draft, action);
    case LOAD_ACTIVE_EMPLOYEES:
      return loadActiveEmployees(draft, action);
    default:
      return draft;
  }
});

export default commissionReportsStoreReducer;
