import React, { Fragment } from 'react';
import { Switch, Route } from 'react-router-dom';
import { Redirect} from 'react-router-dom';
import { useSelector } from 'react-redux';
import find from 'lodash/find'
import SickSettingsList from './SickSettingsList';
import FormSickSettingWrapper from './FormSickSetting';

const SickSettings = () => {

  const currentSetupStepData = useSelector(state => state.projectPayrollSetupStore.notConfiguredSettings);

  return (
    <Fragment>
      {
        currentSetupStepData && find(currentSetupStepData, { path: 'sick_settings' }) &&
        <Redirect from="/sick_settings" to="/sick_settings/new" />
      }
      <h1>SICK SETTING</h1>
      <Switch>
        <Route path="/sick_settings/new" component={FormSickSettingWrapper}/>
        <Route path="/sick_settings/:sickSettingId/edit" component={FormSickSettingWrapper}/>
        <Route path="/sick_settings" component={SickSettingsList}/>
      </Switch>
    </Fragment>
  );
};

export default SickSettings;
