import Application from 'application-namespace';

Application.Classes.TaxJurisdictionsForm = class TaxJurisdictionsForm extends Application.Classes.CoreBase {
  constructor($scope) {
    super();
    $scope.find('table').on('cocoon:after-insert', (e, insertedItem) => {
      this.initializePluginsAfterInsert(insertedItem);
    });
  }
};
