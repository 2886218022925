import React, { useState, useEffect } from 'react';
import FormCompanyRetirementSettingLine from './form';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import delay from 'lodash/delay';
import ReduxFormSubmissionError from '../../../../components/ReduxFormSubmissionError';
import { useSnackbar } from 'notistack';

const FormCompanyRetirementSettingLineWrapper = ({
  stepButtons = [], goNextStep = () => {
  }
}) => {

  let params = useParams();
  const {enqueueSnackbar} = useSnackbar();
  const [loadPath, setLoadPath] = useState(null);
  const [companyRetirementSettingLine, setCompanyRetirementSettingLine] = useState({});

  useEffect(() => {
    if (params.companyRetirementSettingId) {
      if (params.companyRetirementSettingLineId) {
        setLoadPath(
          Routes.edit_api_web_v1_company_retirement_setting_company_retirement_setting_line_path(params.companyRetirementSettingId, params.companyRetirementSettingLineId)
        );
      } else {
        setLoadPath(
          Routes.new_api_web_v1_company_retirement_setting_company_retirement_setting_line_path(params.companyRetirementSettingId)
        );
      }
    }
  }, [params]);

  useEffect(() => {
    if (loadPath) {
      loadCompanyRetirementSettingLine()
        .then(response => {
          setCompanyRetirementSettingLine(response.data.company_retirement_setting_line);
        })
        .catch(error => {
          console.log('FormCompanyRetirementSettingLineWrapper.loadCompanyRetirementSettingLine', error.response);
        });
    }
  }, [loadPath]);

  const loadCompanyRetirementSettingLine = () => {
    return axios({ url: loadPath });
  };

  const handleSubmit = (values) => {
    const submitPath = params.companyRetirementSettingLineId ?
      Routes.api_web_v1_company_retirement_setting_company_retirement_setting_line_path(
        params.companyRetirementSettingId,
        params.companyRetirementSettingLineId
      ) :
      Routes.api_web_v1_company_retirement_setting_company_retirement_setting_lines_path(
        params.companyRetirementSettingId
      );
    const submitMethod = params.companyRetirementSettingLineId ? 'PUT' : 'POST';

    return axios({
      method: submitMethod,
      url: submitPath,
      data: { company_retirement_setting_line: values }
    })
      .then(response => {
        delay(() => {
          goNextStep();
        }, 1000);
        enqueueSnackbar('Company retirement setting line was saved.', { variant: 'success' })
      })
      .catch(error => {
        ReduxFormSubmissionError(error.response.data.errors, values);
      });
  };

  return (
    <>
      <h4>{`${params.companyRetirementSettingLineId ? 'Edit' : 'Crate'} retirement setting line`}</h4>
      {companyRetirementSettingLine &&
      <FormCompanyRetirementSettingLine
        stepButtons={stepButtons}
        onSubmit={handleSubmit}
        initialValues={companyRetirementSettingLine}
      />
      }
    </>
  );
};

export default FormCompanyRetirementSettingLineWrapper;

