import Application from 'application-namespace';
import $ from 'jquery';


Application.Classes.PaymentWizardFirst = class PaymentWizardFirst {
  constructor($scope) {
    this.$table = $scope.find('#business_partners_payments');

    this.$table.on('draw.dt', () => $('input').on('change', (event) => {
      const $currentInput = $(event.currentTarget);
      $currentInput.closest('form').submit();
    }));
  }
};
