import React from 'react';
import Jumbotron from 'react-bootstrap/Jumbotron';

const SummaryPage = () => {
  return (
    <>
      <Jumbotron>
        <h1 className="display-5">Super smart project setup starts here</h1>
        <p className="lead">
          Before you run your first payroll, please finish setting up your company.
          If you’d like a sneak peek, check out this overview.
        </p>
        <hr className="my-4"/>
        <p>Click to continue to step.</p>
      </Jumbotron>
    </>
  );
};

export default SummaryPage;
