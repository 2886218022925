/*
File generated by js-routes 1.4.9
Based on Rails 6.0.2.2 routes of NewErpSystem::Application
 */

(function() {
  var DeprecatedGlobbingBehavior, NodeTypes, ParameterMissing, ReservedOptions, SpecialOptionsKey, UriEncoderSegmentRegex, Utils, result, root,
    hasProp = {}.hasOwnProperty,
    slice = [].slice;

  root = typeof exports !== "undefined" && exports !== null ? exports : this;

  ParameterMissing = function(message, fileName, lineNumber) {
    var instance;
    instance = new Error(message, fileName, lineNumber);
    if (Object.setPrototypeOf) {
      Object.setPrototypeOf(instance, Object.getPrototypeOf(this));
    } else {
      instance.__proto__ = this.__proto__;
    }
    if (Error.captureStackTrace) {
      Error.captureStackTrace(instance, ParameterMissing);
    }
    return instance;
  };

  ParameterMissing.prototype = Object.create(Error.prototype, {
    constructor: {
      value: Error,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });

  if (Object.setPrototypeOf) {
    Object.setPrototypeOf(ParameterMissing, Error);
  } else {
    ParameterMissing.__proto__ = Error;
  }

  NodeTypes = {"GROUP":1,"CAT":2,"SYMBOL":3,"OR":4,"STAR":5,"LITERAL":6,"SLASH":7,"DOT":8};

  DeprecatedGlobbingBehavior = false;

  SpecialOptionsKey = "_options";

  UriEncoderSegmentRegex = /[^a-zA-Z0-9\-\._~!\$&'\(\)\*\+,;=:@]/g;

  ReservedOptions = ['anchor', 'trailing_slash', 'subdomain', 'host', 'port', 'protocol'];

  Utils = {
    configuration: {
      prefix: "",
      default_url_options: {},
      special_options_key: "_options",
      serializer: null
    },
    default_serializer: function(object, prefix) {
      var element, i, j, key, len, prop, s;
      if (prefix == null) {
        prefix = null;
      }
      if (object == null) {
        return "";
      }
      if (!prefix && !(this.get_object_type(object) === "object")) {
        throw new Error("Url parameters should be a javascript hash");
      }
      s = [];
      switch (this.get_object_type(object)) {
        case "array":
          for (i = j = 0, len = object.length; j < len; i = ++j) {
            element = object[i];
            s.push(this.default_serializer(element, prefix + "[]"));
          }
          break;
        case "object":
          for (key in object) {
            if (!hasProp.call(object, key)) continue;
            prop = object[key];
            if ((prop == null) && (prefix != null)) {
              prop = "";
            }
            if (prop != null) {
              if (prefix != null) {
                key = prefix + "[" + key + "]";
              }
              s.push(this.default_serializer(prop, key));
            }
          }
          break;
        default:
          if (object != null) {
            s.push((encodeURIComponent(prefix.toString())) + "=" + (encodeURIComponent(object.toString())));
          }
      }
      if (!s.length) {
        return "";
      }
      return s.join("&");
    },
    serialize: function(object) {
      var custom_serializer;
      custom_serializer = this.configuration.serializer;
      if ((custom_serializer != null) && this.get_object_type(custom_serializer) === "function") {
        return custom_serializer(object);
      } else {
        return this.default_serializer(object);
      }
    },
    clean_path: function(path) {
      var last_index;
      path = path.split("://");
      last_index = path.length - 1;
      path[last_index] = path[last_index].replace(/\/+/g, "/");
      return path.join("://");
    },
    extract_options: function(number_of_params, args) {
      var last_el, options;
      last_el = args[args.length - 1];
      if ((args.length > number_of_params && last_el === void 0) || ((last_el != null) && "object" === this.get_object_type(last_el) && !this.looks_like_serialized_model(last_el))) {
        options = args.pop() || {};
        delete options[this.configuration.special_options_key];
        return options;
      } else {
        return {};
      }
    },
    looks_like_serialized_model: function(object) {
      return !object[this.configuration.special_options_key] && ("id" in object || "to_param" in object);
    },
    path_identifier: function(object) {
      var property;
      if (object === 0) {
        return "0";
      }
      if (!object) {
        return "";
      }
      property = object;
      if (this.get_object_type(object) === "object") {
        if ("to_param" in object) {
          if (object.to_param == null) {
            throw new ParameterMissing("Route parameter missing: to_param");
          }
          property = object.to_param;
        } else if ("id" in object) {
          if (object.id == null) {
            throw new ParameterMissing("Route parameter missing: id");
          }
          property = object.id;
        } else {
          property = object;
        }
        if (this.get_object_type(property) === "function") {
          property = property.call(object);
        }
      }
      return property.toString();
    },
    clone: function(obj) {
      var attr, copy, key;
      if ((obj == null) || "object" !== this.get_object_type(obj)) {
        return obj;
      }
      copy = obj.constructor();
      for (key in obj) {
        if (!hasProp.call(obj, key)) continue;
        attr = obj[key];
        copy[key] = attr;
      }
      return copy;
    },
    merge: function() {
      var tap, xs;
      xs = 1 <= arguments.length ? slice.call(arguments, 0) : [];
      tap = function(o, fn) {
        fn(o);
        return o;
      };
      if ((xs != null ? xs.length : void 0) > 0) {
        return tap({}, function(m) {
          var j, k, len, results, v, x;
          results = [];
          for (j = 0, len = xs.length; j < len; j++) {
            x = xs[j];
            results.push((function() {
              var results1;
              results1 = [];
              for (k in x) {
                v = x[k];
                results1.push(m[k] = v);
              }
              return results1;
            })());
          }
          return results;
        });
      }
    },
    normalize_options: function(parts, required_parts, default_options, actual_parameters) {
      var i, j, key, len, options, part, parts_options, result, route_parts, url_parameters, use_all_parts, value;
      options = this.extract_options(parts.length, actual_parameters);
      if (actual_parameters.length > parts.length) {
        throw new Error("Too many parameters provided for path");
      }
      use_all_parts = actual_parameters.length > required_parts.length;
      parts_options = {};
      for (key in options) {
        if (!hasProp.call(options, key)) continue;
        use_all_parts = true;
        if (this.indexOf(parts, key) >= 0) {
          parts_options[key] = value;
        }
      }
      options = this.merge(this.configuration.default_url_options, default_options, options);
      result = {};
      url_parameters = {};
      result['url_parameters'] = url_parameters;
      for (key in options) {
        if (!hasProp.call(options, key)) continue;
        value = options[key];
        if (this.indexOf(ReservedOptions, key) >= 0) {
          result[key] = value;
        } else {
          url_parameters[key] = value;
        }
      }
      route_parts = use_all_parts ? parts : required_parts;
      i = 0;
      for (j = 0, len = route_parts.length; j < len; j++) {
        part = route_parts[j];
        if (i < actual_parameters.length) {
          if (!parts_options.hasOwnProperty(part)) {
            url_parameters[part] = actual_parameters[i];
            ++i;
          }
        }
      }
      return result;
    },
    build_route: function(parts, required_parts, default_options, route, full_url, args) {
      var options, parameters, result, url, url_params;
      args = Array.prototype.slice.call(args);
      options = this.normalize_options(parts, required_parts, default_options, args);
      parameters = options['url_parameters'];
      result = "" + (this.get_prefix()) + (this.visit(route, parameters));
      url = Utils.clean_path(result);
      if (options['trailing_slash'] === true) {
        url = url.replace(/(.*?)[\/]?$/, "$1/");
      }
      if ((url_params = this.serialize(parameters)).length) {
        url += "?" + url_params;
      }
      url += options.anchor ? "#" + options.anchor : "";
      if (full_url) {
        url = this.route_url(options) + url;
      }
      return url;
    },
    visit: function(route, parameters, optional) {
      var left, left_part, right, right_part, type, value;
      if (optional == null) {
        optional = false;
      }
      type = route[0], left = route[1], right = route[2];
      switch (type) {
        case NodeTypes.GROUP:
          return this.visit(left, parameters, true);
        case NodeTypes.STAR:
          return this.visit_globbing(left, parameters, true);
        case NodeTypes.LITERAL:
        case NodeTypes.SLASH:
        case NodeTypes.DOT:
          return left;
        case NodeTypes.CAT:
          left_part = this.visit(left, parameters, optional);
          right_part = this.visit(right, parameters, optional);
          if (optional && ((this.is_optional_node(left[0]) && !left_part) || ((this.is_optional_node(right[0])) && !right_part))) {
            return "";
          }
          return "" + left_part + right_part;
        case NodeTypes.SYMBOL:
          value = parameters[left];
          delete parameters[left];
          if (value != null) {
            return this.encode_segment(this.path_identifier(value));
          }
          if (optional) {
            return "";
          } else {
            throw new ParameterMissing("Route parameter missing: " + left);
          }
          break;
        default:
          throw new Error("Unknown Rails node type");
      }
    },
    encode_segment: function(segment) {
      return segment.replace(UriEncoderSegmentRegex, function(str) {
        return encodeURIComponent(str);
      });
    },
    is_optional_node: function(node) {
      return this.indexOf([NodeTypes.STAR, NodeTypes.SYMBOL, NodeTypes.CAT], node) >= 0;
    },
    build_path_spec: function(route, wildcard) {
      var left, right, type;
      if (wildcard == null) {
        wildcard = false;
      }
      type = route[0], left = route[1], right = route[2];
      switch (type) {
        case NodeTypes.GROUP:
          return "(" + (this.build_path_spec(left)) + ")";
        case NodeTypes.CAT:
          return "" + (this.build_path_spec(left)) + (this.build_path_spec(right));
        case NodeTypes.STAR:
          return this.build_path_spec(left, true);
        case NodeTypes.SYMBOL:
          if (wildcard === true) {
            return "" + (left[0] === '*' ? '' : '*') + left;
          } else {
            return ":" + left;
          }
          break;
        case NodeTypes.SLASH:
        case NodeTypes.DOT:
        case NodeTypes.LITERAL:
          return left;
        default:
          throw new Error("Unknown Rails node type");
      }
    },
    visit_globbing: function(route, parameters, optional) {
      var left, right, type, value;
      type = route[0], left = route[1], right = route[2];
      value = parameters[left];
      delete parameters[left];
      if (value == null) {
        return this.visit(route, parameters, optional);
      }
      value = (function() {
        switch (this.get_object_type(value)) {
          case "array":
            return value.join("/");
          default:
            return value;
        }
      }).call(this);
      if (DeprecatedGlobbingBehavior) {
        return this.path_identifier(value);
      } else {
        return encodeURI(this.path_identifier(value));
      }
    },
    get_prefix: function() {
      var prefix;
      prefix = this.configuration.prefix;
      if (prefix !== "") {
        prefix = (prefix.match("/$") ? prefix : prefix + "/");
      }
      return prefix;
    },
    route: function(parts_table, default_options, route_spec, full_url) {
      var j, len, part, parts, path_fn, ref, required, required_parts;
      required_parts = [];
      parts = [];
      for (j = 0, len = parts_table.length; j < len; j++) {
        ref = parts_table[j], part = ref[0], required = ref[1];
        parts.push(part);
        if (required) {
          required_parts.push(part);
        }
      }
      path_fn = function() {
        return Utils.build_route(parts, required_parts, default_options, route_spec, full_url, arguments);
      };
      path_fn.required_params = required_parts;
      path_fn.toString = function() {
        return Utils.build_path_spec(route_spec);
      };
      return path_fn;
    },
    route_url: function(route_defaults) {
      var hostname, port, protocol, subdomain;
      if (typeof route_defaults === 'string') {
        return route_defaults;
      }
      hostname = route_defaults.host || Utils.current_host();
      if (!hostname) {
        return '';
      }
      subdomain = route_defaults.subdomain ? route_defaults.subdomain + '.' : '';
      protocol = route_defaults.protocol || Utils.current_protocol();
      port = route_defaults.port || (!route_defaults.host ? Utils.current_port() : void 0);
      port = port ? ":" + port : '';
      return protocol + "://" + subdomain + hostname + port;
    },
    has_location: function() {
      return (typeof window !== "undefined" && window !== null ? window.location : void 0) != null;
    },
    current_host: function() {
      if (this.has_location()) {
        return window.location.hostname;
      } else {
        return null;
      }
    },
    current_protocol: function() {
      if (this.has_location() && window.location.protocol !== '') {
        return window.location.protocol.replace(/:$/, '');
      } else {
        return 'http';
      }
    },
    current_port: function() {
      if (this.has_location() && window.location.port !== '') {
        return window.location.port;
      } else {
        return '';
      }
    },
    _classToTypeCache: null,
    _classToType: function() {
      var j, len, name, ref;
      if (this._classToTypeCache != null) {
        return this._classToTypeCache;
      }
      this._classToTypeCache = {};
      ref = "Boolean Number String Function Array Date RegExp Object Error".split(" ");
      for (j = 0, len = ref.length; j < len; j++) {
        name = ref[j];
        this._classToTypeCache["[object " + name + "]"] = name.toLowerCase();
      }
      return this._classToTypeCache;
    },
    get_object_type: function(obj) {
      if (root.jQuery && (root.jQuery.type != null)) {
        return root.jQuery.type(obj);
      }
      if (obj == null) {
        return "" + obj;
      }
      if (typeof obj === "object" || typeof obj === "function") {
        return this._classToType()[Object.prototype.toString.call(obj)] || "object";
      } else {
        return typeof obj;
      }
    },
    indexOf: function(array, element) {
      if (Array.prototype.indexOf) {
        return array.indexOf(element);
      } else {
        return this.indexOfImplementation(array, element);
      }
    },
    indexOfImplementation: function(array, element) {
      var el, i, j, len, result;
      result = -1;
      for (i = j = 0, len = array.length; j < len; i = ++j) {
        el = array[i];
        if (el === element) {
          result = i;
        }
      }
      return result;
    },
    namespace: function(root, namespace, routes) {
      var index, j, len, part, parts;
      parts = namespace ? namespace.split(".") : [];
      if (parts.length === 0) {
        return routes;
      }
      for (index = j = 0, len = parts.length; j < len; index = ++j) {
        part = parts[index];
        if (index < parts.length - 1) {
          root = (root[part] || (root[part] = {}));
        } else {
          return root[part] = routes;
        }
      }
    },
    configure: function(new_config) {
      return this.configuration = this.merge(this.configuration, new_config);
    },
    config: function() {
      return this.clone(this.configuration);
    },
    make: function() {
      var routes;
      routes = {
// accounting_item_group_api_web_v1_settings => /api/web/v1/settings/accounting_item_group(.:format)
  // function(options)
  accounting_item_group_api_web_v1_settings_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"web",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"accounting_item_group",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// accrual_documents_by_gl_account_general_ledger_account => /general_ledger_accounts/:id/accrual_documents_by_gl_account(.:format)
  // function(id, options)
  accrual_documents_by_gl_account_general_ledger_account_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"general_ledger_accounts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"accrual_documents_by_gl_account",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// actual_api_web_v1_companies => /api/web/v1/companies/actual(.:format)
  // function(options)
  actual_api_web_v1_companies_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"web",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"companies",false],[2,[7,"/",false],[2,[6,"actual",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// actual_api_web_v1_global_retirement_settings => /api/web/v1/global_retirement_settings/actual(.:format)
  // function(options)
  actual_api_web_v1_global_retirement_settings_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"web",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"global_retirement_settings",false],[2,[7,"/",false],[2,[6,"actual",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// add_to_list_payment => /payments/:id/add_to_list(.:format)
  // function(id, options)
  add_to_list_payment_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"payments",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"add_to_list",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// affecting_payroll_reports_employee_payroll_salary_changes => /employees/:employee_id/payroll_salary_changes/affecting_payroll_reports(.:format)
  // function(employee_id, options)
  affecting_payroll_reports_employee_payroll_salary_changes_path: Utils.route([["employee_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[3,"employee_id",false],[2,[7,"/",false],[2,[6,"payroll_salary_changes",false],[2,[7,"/",false],[2,[6,"affecting_payroll_reports",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// annual_service => /annual_services/:id(.:format)
  // function(id, options)
  annual_service_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"annual_services",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// annual_service_annual_service_date => /annual_services/:annual_service_id/annual_service_dates/:id(.:format)
  // function(annual_service_id, id, options)
  annual_service_annual_service_date_path: Utils.route([["annual_service_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"annual_services",false],[2,[7,"/",false],[2,[3,"annual_service_id",false],[2,[7,"/",false],[2,[6,"annual_service_dates",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// annual_service_annual_service_dates => /annual_services/:annual_service_id/annual_service_dates(.:format)
  // function(annual_service_id, options)
  annual_service_annual_service_dates_path: Utils.route([["annual_service_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"annual_services",false],[2,[7,"/",false],[2,[3,"annual_service_id",false],[2,[7,"/",false],[2,[6,"annual_service_dates",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// annual_services => /annual_services(.:format)
  // function(options)
  annual_services_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"annual_services",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// ap_credit_memo => /ap_credit_memos/:id(.:format)
  // function(id, options)
  ap_credit_memo_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"ap_credit_memos",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// ap_credit_memos => /ap_credit_memos(.:format)
  // function(options)
  ap_credit_memos_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"ap_credit_memos",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// ap_down_payment_invoice => /ap_down_payment_invoices/:id(.:format)
  // function(id, options)
  ap_down_payment_invoice_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"ap_down_payment_invoices",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// ap_down_payment_invoices => /ap_down_payment_invoices(.:format)
  // function(options)
  ap_down_payment_invoices_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"ap_down_payment_invoices",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// ap_invoice => /ap_invoices/:id(.:format)
  // function(id, options)
  ap_invoice_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"ap_invoices",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// ap_invoices => /ap_invoices(.:format)
  // function(options)
  ap_invoices_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"ap_invoices",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// api_v1_annual_services => /api/v1/annual_services(.:format)
  // function(options)
  api_v1_annual_services_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"annual_services",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_v1_auth_sign_in => /api/v1/auth/sign_in(.:format)
  // function(options)
  api_v1_auth_sign_in_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"auth",false],[2,[7,"/",false],[2,[6,"sign_in",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_v1_auth_sign_out => /api/v1/auth/sign_out(.:format)
  // function(options)
  api_v1_auth_sign_out_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"auth",false],[2,[7,"/",false],[2,[6,"sign_out",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_v1_business_partners => /api/v1/business_partners(.:format)
  // function(options)
  api_v1_business_partners_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"business_partners",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_v1_customer_equipment_card => /api/v1/customer_equipment_cards/:id(.:format)
  // function(id, options)
  api_v1_customer_equipment_card_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"customer_equipment_cards",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_v1_customer_equipment_cards => /api/v1/customer_equipment_cards(.:format)
  // function(options)
  api_v1_customer_equipment_cards_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"customer_equipment_cards",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_v1_dictionaries => /api/v1/dictionaries/:name(.:format)
  // function(name, options)
  api_v1_dictionaries_path: Utils.route([["name",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"dictionaries",false],[2,[7,"/",false],[2,[3,"name",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_v1_employees => /api/v1/employees(.:format)
  // function(options)
  api_v1_employees_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"employees",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_v1_items => /api/v1/items(.:format)
  // function(options)
  api_v1_items_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"items",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_v1_me => /api/v1/me(.:format)
  // function(options)
  api_v1_me_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"me",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_v1_service_calls => /api/v1/service_calls(.:format)
  // function(options)
  api_v1_service_calls_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"service_calls",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_v1_tech_sales_order => /api/v1/tech_sales_orders/:id(.:format)
  // function(id, options)
  api_v1_tech_sales_order_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"tech_sales_orders",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_v1_tech_sales_orders => /api/v1/tech_sales_orders(.:format)
  // function(options)
  api_v1_tech_sales_orders_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"tech_sales_orders",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_web_v1_attendance_calculation => /api/web/v1/attendance_calculation(.:format)
  // function(options)
  api_web_v1_attendance_calculation_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"web",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"attendance_calculation",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_web_v1_business_partner => /api/web/v1/business_partners/:id(.:format)
  // function(id, options)
  api_web_v1_business_partner_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"web",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"business_partners",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_web_v1_business_partner_contact_people => /api/web/v1/business_partners/:business_partner_id/contact_people(.:format)
  // function(business_partner_id, options)
  api_web_v1_business_partner_contact_people_path: Utils.route([["business_partner_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"web",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"business_partners",false],[2,[7,"/",false],[2,[3,"business_partner_id",false],[2,[7,"/",false],[2,[6,"contact_people",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// api_web_v1_business_partner_contact_person => /api/web/v1/business_partners/:business_partner_id/contact_people/:id(.:format)
  // function(business_partner_id, id, options)
  api_web_v1_business_partner_contact_person_path: Utils.route([["business_partner_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"web",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"business_partners",false],[2,[7,"/",false],[2,[3,"business_partner_id",false],[2,[7,"/",false],[2,[6,"contact_people",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// api_web_v1_business_partner_customer_equipment_cards => /api/web/v1/business_partners/:business_partner_id/customer_equipment_cards(.:format)
  // function(business_partner_id, options)
  api_web_v1_business_partner_customer_equipment_cards_path: Utils.route([["business_partner_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"web",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"business_partners",false],[2,[7,"/",false],[2,[3,"business_partner_id",false],[2,[7,"/",false],[2,[6,"customer_equipment_cards",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// api_web_v1_business_partner_partner_billing_addresses => /api/web/v1/business_partners/:business_partner_id/partner_billing_addresses(.:format)
  // function(business_partner_id, options)
  api_web_v1_business_partner_partner_billing_addresses_path: Utils.route([["business_partner_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"web",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"business_partners",false],[2,[7,"/",false],[2,[3,"business_partner_id",false],[2,[7,"/",false],[2,[6,"partner_billing_addresses",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// api_web_v1_business_partner_partner_shipping_addresses => /api/web/v1/business_partners/:business_partner_id/partner_shipping_addresses(.:format)
  // function(business_partner_id, options)
  api_web_v1_business_partner_partner_shipping_addresses_path: Utils.route([["business_partner_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"web",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"business_partners",false],[2,[7,"/",false],[2,[3,"business_partner_id",false],[2,[7,"/",false],[2,[6,"partner_shipping_addresses",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// api_web_v1_business_partners => /api/web/v1/business_partners(.:format)
  // function(options)
  api_web_v1_business_partners_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"web",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"business_partners",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_web_v1_common_document => /api/web/v1/common_documents/:id(.:format)
  // function(id, options)
  api_web_v1_common_document_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"web",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"common_documents",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_web_v1_common_documents => /api/web/v1/common_documents(.:format)
  // function(options)
  api_web_v1_common_documents_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"web",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"common_documents",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_web_v1_companies => /api/web/v1/companies(.:format)
  // function(options)
  api_web_v1_companies_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"web",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"companies",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_web_v1_company => /api/web/v1/companies/:id(.:format)
  // function(id, options)
  api_web_v1_company_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"web",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"companies",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_web_v1_company_retirement_setting => /api/web/v1/company_retirement_settings/:id(.:format)
  // function(id, options)
  api_web_v1_company_retirement_setting_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"web",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"company_retirement_settings",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_web_v1_company_retirement_setting_company_retirement_setting_line => /api/web/v1/company_retirement_settings/:company_retirement_setting_id/company_retirement_setting_lines/:id(.:format)
  // function(company_retirement_setting_id, id, options)
  api_web_v1_company_retirement_setting_company_retirement_setting_line_path: Utils.route([["company_retirement_setting_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"web",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"company_retirement_settings",false],[2,[7,"/",false],[2,[3,"company_retirement_setting_id",false],[2,[7,"/",false],[2,[6,"company_retirement_setting_lines",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// api_web_v1_company_retirement_setting_company_retirement_setting_lines => /api/web/v1/company_retirement_settings/:company_retirement_setting_id/company_retirement_setting_lines(.:format)
  // function(company_retirement_setting_id, options)
  api_web_v1_company_retirement_setting_company_retirement_setting_lines_path: Utils.route([["company_retirement_setting_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"web",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"company_retirement_settings",false],[2,[7,"/",false],[2,[3,"company_retirement_setting_id",false],[2,[7,"/",false],[2,[6,"company_retirement_setting_lines",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// api_web_v1_company_retirement_settings => /api/web/v1/company_retirement_settings(.:format)
  // function(options)
  api_web_v1_company_retirement_settings_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"web",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"company_retirement_settings",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_web_v1_dictionaries => /api/web/v1/dictionaries/:name(.:format)
  // function(name, options)
  api_web_v1_dictionaries_path: Utils.route([["name",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"web",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"dictionaries",false],[2,[7,"/",false],[2,[3,"name",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_web_v1_emergency_phone_setting => /api/web/v1/emergency_phone_settings/:id(.:format)
  // function(id, options)
  api_web_v1_emergency_phone_setting_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"web",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"emergency_phone_settings",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_web_v1_emergency_phone_settings => /api/web/v1/emergency_phone_settings(.:format)
  // function(options)
  api_web_v1_emergency_phone_settings_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"web",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"emergency_phone_settings",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_web_v1_employee => /api/web/v1/employees/:id(.:format)
  // function(id, options)
  api_web_v1_employee_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"web",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_web_v1_employee_work_day => /api/web/v1/employee_work_days/:id(.:format)
  // function(id, options)
  api_web_v1_employee_work_day_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"web",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"employee_work_days",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_web_v1_employee_work_days => /api/web/v1/employee_work_days(.:format)
  // function(options)
  api_web_v1_employee_work_days_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"web",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"employee_work_days",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_web_v1_employees => /api/web/v1/employees(.:format)
  // function(options)
  api_web_v1_employees_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"web",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"employees",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_web_v1_general_ledger_accounts => /api/web/v1/general_ledger_accounts(.:format)
  // function(options)
  api_web_v1_general_ledger_accounts_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"web",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"general_ledger_accounts",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_web_v1_global_retirement_setting => /api/web/v1/global_retirement_settings/:id(.:format)
  // function(id, options)
  api_web_v1_global_retirement_setting_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"web",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"global_retirement_settings",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_web_v1_global_retirement_settings => /api/web/v1/global_retirement_settings(.:format)
  // function(options)
  api_web_v1_global_retirement_settings_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"web",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"global_retirement_settings",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_web_v1_groups => /api/web/v1/groups(.:format)
  // function(options)
  api_web_v1_groups_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"web",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"groups",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_web_v1_health_benefit_setting => /api/web/v1/health_benefit_settings/:id(.:format)
  // function(id, options)
  api_web_v1_health_benefit_setting_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"web",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"health_benefit_settings",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_web_v1_health_benefit_setting_health_benefit_setting_line => /api/web/v1/health_benefit_settings/:health_benefit_setting_id/health_benefit_setting_lines/:id(.:format)
  // function(health_benefit_setting_id, id, options)
  api_web_v1_health_benefit_setting_health_benefit_setting_line_path: Utils.route([["health_benefit_setting_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"web",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"health_benefit_settings",false],[2,[7,"/",false],[2,[3,"health_benefit_setting_id",false],[2,[7,"/",false],[2,[6,"health_benefit_setting_lines",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// api_web_v1_health_benefit_setting_health_benefit_setting_lines => /api/web/v1/health_benefit_settings/:health_benefit_setting_id/health_benefit_setting_lines(.:format)
  // function(health_benefit_setting_id, options)
  api_web_v1_health_benefit_setting_health_benefit_setting_lines_path: Utils.route([["health_benefit_setting_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"web",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"health_benefit_settings",false],[2,[7,"/",false],[2,[3,"health_benefit_setting_id",false],[2,[7,"/",false],[2,[6,"health_benefit_setting_lines",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// api_web_v1_health_benefit_settings => /api/web/v1/health_benefit_settings(.:format)
  // function(options)
  api_web_v1_health_benefit_settings_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"web",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"health_benefit_settings",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_web_v1_item => /api/web/v1/items/:id(.:format)
  // function(id, options)
  api_web_v1_item_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"web",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"items",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_web_v1_items => /api/web/v1/items(.:format)
  // function(options)
  api_web_v1_items_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"web",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"items",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_web_v1_payroll_salary_change_reasons => /api/web/v1/payroll_salary_change_reasons(.:format)
  // function(options)
  api_web_v1_payroll_salary_change_reasons_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"web",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"payroll_salary_change_reasons",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_web_v1_payroll_salary_changes => /api/web/v1/payroll_salary_changes(.:format)
  // function(options)
  api_web_v1_payroll_salary_changes_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"web",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"payroll_salary_changes",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_web_v1_payroll_setting => /api/web/v1/payroll_settings/:id(.:format)
  // function(id, options)
  api_web_v1_payroll_setting_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"web",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"payroll_settings",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_web_v1_payroll_settings => /api/web/v1/payroll_settings(.:format)
  // function(options)
  api_web_v1_payroll_settings_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"web",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"payroll_settings",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_web_v1_payroll_tax_setup_add_on => /api/web/v1/payroll_tax_setup_add_ons/:id(.:format)
  // function(id, options)
  api_web_v1_payroll_tax_setup_add_on_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"web",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"payroll_tax_setup_add_ons",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_web_v1_payroll_tax_setup_add_ons => /api/web/v1/payroll_tax_setup_add_ons(.:format)
  // function(options)
  api_web_v1_payroll_tax_setup_add_ons_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"web",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"payroll_tax_setup_add_ons",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_web_v1_person_positions => /api/web/v1/person_positions(.:format)
  // function(options)
  api_web_v1_person_positions_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"web",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"person_positions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_web_v1_project_payroll_setup => /api/web/v1/project-payroll-setup(.:format)
  // function(options)
  api_web_v1_project_payroll_setup_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"web",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"project-payroll-setup",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_web_v1_sales_order => /api/web/v1/sales_orders/:id(.:format)
  // function(id, options)
  api_web_v1_sales_order_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"web",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"sales_orders",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_web_v1_sales_order_tech_sales_orders => /api/web/v1/sales_orders/:sales_order_id/tech_sales_orders(.:format)
  // function(sales_order_id, options)
  api_web_v1_sales_order_tech_sales_orders_path: Utils.route([["sales_order_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"web",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"sales_orders",false],[2,[7,"/",false],[2,[3,"sales_order_id",false],[2,[7,"/",false],[2,[6,"tech_sales_orders",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// api_web_v1_sales_tax_codes => /api/web/v1/sales_tax_codes(.:format)
  // function(options)
  api_web_v1_sales_tax_codes_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"web",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"sales_tax_codes",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_web_v1_service_partner_types => /api/web/v1/service_partner_types(.:format)
  // function(options)
  api_web_v1_service_partner_types_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"web",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"service_partner_types",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_web_v1_setting => /api/web/v1/settings/:id(.:format)
  // function(id, options)
  api_web_v1_setting_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"web",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_web_v1_settings => /api/web/v1/settings(.:format)
  // function(options)
  api_web_v1_settings_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"web",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"settings",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_web_v1_sick_setting => /api/web/v1/sick_settings/:id(.:format)
  // function(id, options)
  api_web_v1_sick_setting_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"web",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"sick_settings",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_web_v1_sick_settings => /api/web/v1/sick_settings(.:format)
  // function(options)
  api_web_v1_sick_settings_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"web",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"sick_settings",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_web_v1_states => /api/web/v1/states(.:format)
  // function(options)
  api_web_v1_states_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"web",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"states",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_web_v1_tax_statuses => /api/web/v1/tax_statuses(.:format)
  // function(options)
  api_web_v1_tax_statuses_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"web",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"tax_statuses",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_web_v1_tech_sales_order => /api/web/v1/tech_sales_orders/:id(.:format)
  // function(id, options)
  api_web_v1_tech_sales_order_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"web",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"tech_sales_orders",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_web_v1_vacation_setting => /api/web/v1/vacation_settings/:id(.:format)
  // function(id, options)
  api_web_v1_vacation_setting_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"web",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"vacation_settings",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_web_v1_vacation_settings => /api/web/v1/vacation_settings(.:format)
  // function(options)
  api_web_v1_vacation_settings_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"web",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"vacation_settings",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_web_v1_warehouses => /api/web/v1/warehouses(.:format)
  // function(options)
  api_web_v1_warehouses_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"web",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"warehouses",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// ar_credit_memo => /ar_credit_memos/:id(.:format)
  // function(id, options)
  ar_credit_memo_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"ar_credit_memos",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// ar_credit_memos => /ar_credit_memos(.:format)
  // function(options)
  ar_credit_memos_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"ar_credit_memos",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// ar_down_payment_invoice => /ar_down_payment_invoices/:id(.:format)
  // function(id, options)
  ar_down_payment_invoice_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"ar_down_payment_invoices",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// ar_down_payment_invoices => /ar_down_payment_invoices(.:format)
  // function(options)
  ar_down_payment_invoices_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"ar_down_payment_invoices",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// ar_invoice => /ar_invoices/:id(.:format)
  // function(id, options)
  ar_invoice_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"ar_invoices",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// ar_invoices => /ar_invoices(.:format)
  // function(options)
  ar_invoices_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"ar_invoices",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// attachment => /attachments/:id(.:format)
  // function(id, options)
  attachment_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"attachments",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// attachments => /attachments(.:format)
  // function(options)
  attachments_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"attachments",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// attendance_calculation => /attendance_calculation(.:format)
  // function(options)
  attendance_calculation_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"attendance_calculation",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// authenticated_root => /
  // function(options)
  authenticated_root_path: Utils.route([], {}, [7,"/",false]),
// autocomplete_api_v1_business_partners => /api/v1/business_partners/autocomplete(.:format)
  // function(options)
  autocomplete_api_v1_business_partners_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"business_partners",false],[2,[7,"/",false],[2,[6,"autocomplete",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// autocomplete_general_ledger_accounts => /general_ledger_accounts/autocomplete(.:format)
  // function(options)
  autocomplete_general_ledger_accounts_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"general_ledger_accounts",false],[2,[7,"/",false],[2,[6,"autocomplete",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// autocomplete_items => /items/autocomplete(.:format)
  // function(options)
  autocomplete_items_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"items",false],[2,[7,"/",false],[2,[6,"autocomplete",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// autocomplete_sales_tax_codes => /sales_tax_codes/autocomplete(.:format)
  // function(options)
  autocomplete_sales_tax_codes_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"sales_tax_codes",false],[2,[7,"/",false],[2,[6,"autocomplete",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// award => /awards/:id(.:format)
  // function(id, options)
  award_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"awards",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// awards => /awards(.:format)
  // function(options)
  awards_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"awards",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// balance_general_ledger_account => /general_ledger_accounts/:id/balance(.:format)
  // function(id, options)
  balance_general_ledger_account_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"general_ledger_accounts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"balance",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// balance_sheet => /balance_sheet(.:format)
  // function(options)
  balance_sheet_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"balance_sheet",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// bill => /bills/:id(.:format)
  // function(id, options)
  bill_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"bills",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// bills => /bills(.:format)
  // function(options)
  bills_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"bills",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// bonuse => /bonuses/:id(.:format)
  // function(id, options)
  bonuse_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"bonuses",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// bonuses => /bonuses(.:format)
  // function(options)
  bonuses_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"bonuses",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// business_partner => /business_partners/:id(.:format)
  // function(id, options)
  business_partner_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"business_partners",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// business_partner_ap_down_payment_invoice => /business_partners/:business_partner_id/ap_down_payment_invoices/:id(.:format)
  // function(business_partner_id, id, options)
  business_partner_ap_down_payment_invoice_path: Utils.route([["business_partner_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"business_partners",false],[2,[7,"/",false],[2,[3,"business_partner_id",false],[2,[7,"/",false],[2,[6,"ap_down_payment_invoices",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// business_partner_ap_down_payment_invoices => /business_partners/:business_partner_id/ap_down_payment_invoices(.:format)
  // function(business_partner_id, options)
  business_partner_ap_down_payment_invoices_path: Utils.route([["business_partner_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"business_partners",false],[2,[7,"/",false],[2,[3,"business_partner_id",false],[2,[7,"/",false],[2,[6,"ap_down_payment_invoices",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// business_partner_ap_invoice => /business_partners/:business_partner_id/ap_invoices/:id(.:format)
  // function(business_partner_id, id, options)
  business_partner_ap_invoice_path: Utils.route([["business_partner_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"business_partners",false],[2,[7,"/",false],[2,[3,"business_partner_id",false],[2,[7,"/",false],[2,[6,"ap_invoices",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// business_partner_ap_invoices => /business_partners/:business_partner_id/ap_invoices(.:format)
  // function(business_partner_id, options)
  business_partner_ap_invoices_path: Utils.route([["business_partner_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"business_partners",false],[2,[7,"/",false],[2,[3,"business_partner_id",false],[2,[7,"/",false],[2,[6,"ap_invoices",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// business_partner_ar_down_payment_invoice => /business_partners/:business_partner_id/ar_down_payment_invoices/:id(.:format)
  // function(business_partner_id, id, options)
  business_partner_ar_down_payment_invoice_path: Utils.route([["business_partner_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"business_partners",false],[2,[7,"/",false],[2,[3,"business_partner_id",false],[2,[7,"/",false],[2,[6,"ar_down_payment_invoices",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// business_partner_ar_down_payment_invoices => /business_partners/:business_partner_id/ar_down_payment_invoices(.:format)
  // function(business_partner_id, options)
  business_partner_ar_down_payment_invoices_path: Utils.route([["business_partner_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"business_partners",false],[2,[7,"/",false],[2,[3,"business_partner_id",false],[2,[7,"/",false],[2,[6,"ar_down_payment_invoices",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// business_partner_ar_invoice => /business_partners/:business_partner_id/ar_invoices/:id(.:format)
  // function(business_partner_id, id, options)
  business_partner_ar_invoice_path: Utils.route([["business_partner_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"business_partners",false],[2,[7,"/",false],[2,[3,"business_partner_id",false],[2,[7,"/",false],[2,[6,"ar_invoices",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// business_partner_ar_invoices => /business_partners/:business_partner_id/ar_invoices(.:format)
  // function(business_partner_id, options)
  business_partner_ar_invoices_path: Utils.route([["business_partner_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"business_partners",false],[2,[7,"/",false],[2,[3,"business_partner_id",false],[2,[7,"/",false],[2,[6,"ar_invoices",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// business_partner_attachment => /business_partners/:business_partner_id/attachments/:id(.:format)
  // function(business_partner_id, id, options)
  business_partner_attachment_path: Utils.route([["business_partner_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"business_partners",false],[2,[7,"/",false],[2,[3,"business_partner_id",false],[2,[7,"/",false],[2,[6,"attachments",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// business_partner_attachments => /business_partners/:business_partner_id/attachments(.:format)
  // function(business_partner_id, options)
  business_partner_attachments_path: Utils.route([["business_partner_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"business_partners",false],[2,[7,"/",false],[2,[3,"business_partner_id",false],[2,[7,"/",false],[2,[6,"attachments",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// business_partner_contact_people => /business_partners/:business_partner_id/contact_people(.:format)
  // function(business_partner_id, options)
  business_partner_contact_people_path: Utils.route([["business_partner_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"business_partners",false],[2,[7,"/",false],[2,[3,"business_partner_id",false],[2,[7,"/",false],[2,[6,"contact_people",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// business_partner_contact_person => /business_partners/:business_partner_id/contact_people/:id(.:format)
  // function(business_partner_id, id, options)
  business_partner_contact_person_path: Utils.route([["business_partner_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"business_partners",false],[2,[7,"/",false],[2,[3,"business_partner_id",false],[2,[7,"/",false],[2,[6,"contact_people",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// business_partner_customer_equipment_card => /business_partners/:business_partner_id/customer_equipment_cards/:id(.:format)
  // function(business_partner_id, id, options)
  business_partner_customer_equipment_card_path: Utils.route([["business_partner_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"business_partners",false],[2,[7,"/",false],[2,[3,"business_partner_id",false],[2,[7,"/",false],[2,[6,"customer_equipment_cards",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// business_partner_customer_equipment_cards => /business_partners/:business_partner_id/customer_equipment_cards(.:format)
  // function(business_partner_id, options)
  business_partner_customer_equipment_cards_path: Utils.route([["business_partner_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"business_partners",false],[2,[7,"/",false],[2,[3,"business_partner_id",false],[2,[7,"/",false],[2,[6,"customer_equipment_cards",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// business_partner_partner_billing_address => /business_partners/:business_partner_id/partner_billing_addresses/:id(.:format)
  // function(business_partner_id, id, options)
  business_partner_partner_billing_address_path: Utils.route([["business_partner_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"business_partners",false],[2,[7,"/",false],[2,[3,"business_partner_id",false],[2,[7,"/",false],[2,[6,"partner_billing_addresses",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// business_partner_partner_billing_addresses => /business_partners/:business_partner_id/partner_billing_addresses(.:format)
  // function(business_partner_id, options)
  business_partner_partner_billing_addresses_path: Utils.route([["business_partner_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"business_partners",false],[2,[7,"/",false],[2,[3,"business_partner_id",false],[2,[7,"/",false],[2,[6,"partner_billing_addresses",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// business_partner_partner_shipping_address => /business_partners/:business_partner_id/partner_shipping_addresses/:id(.:format)
  // function(business_partner_id, id, options)
  business_partner_partner_shipping_address_path: Utils.route([["business_partner_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"business_partners",false],[2,[7,"/",false],[2,[3,"business_partner_id",false],[2,[7,"/",false],[2,[6,"partner_shipping_addresses",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// business_partner_partner_shipping_addresses => /business_partners/:business_partner_id/partner_shipping_addresses(.:format)
  // function(business_partner_id, options)
  business_partner_partner_shipping_addresses_path: Utils.route([["business_partner_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"business_partners",false],[2,[7,"/",false],[2,[3,"business_partner_id",false],[2,[7,"/",false],[2,[6,"partner_shipping_addresses",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// business_partner_purchase_order => /business_partners/:business_partner_id/purchase_orders/:id(.:format)
  // function(business_partner_id, id, options)
  business_partner_purchase_order_path: Utils.route([["business_partner_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"business_partners",false],[2,[7,"/",false],[2,[3,"business_partner_id",false],[2,[7,"/",false],[2,[6,"purchase_orders",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// business_partner_purchase_orders => /business_partners/:business_partner_id/purchase_orders(.:format)
  // function(business_partner_id, options)
  business_partner_purchase_orders_path: Utils.route([["business_partner_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"business_partners",false],[2,[7,"/",false],[2,[3,"business_partner_id",false],[2,[7,"/",false],[2,[6,"purchase_orders",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// business_partner_sales_order => /business_partners/:business_partner_id/sales_orders/:id(.:format)
  // function(business_partner_id, id, options)
  business_partner_sales_order_path: Utils.route([["business_partner_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"business_partners",false],[2,[7,"/",false],[2,[3,"business_partner_id",false],[2,[7,"/",false],[2,[6,"sales_orders",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// business_partner_sales_orders => /business_partners/:business_partner_id/sales_orders(.:format)
  // function(business_partner_id, options)
  business_partner_sales_orders_path: Utils.route([["business_partner_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"business_partners",false],[2,[7,"/",false],[2,[3,"business_partner_id",false],[2,[7,"/",false],[2,[6,"sales_orders",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// business_partner_types_api_web_v1_business_partners => /api/web/v1/business_partners/business_partner_types(.:format)
  // function(options)
  business_partner_types_api_web_v1_business_partners_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"web",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"business_partners",false],[2,[7,"/",false],[2,[6,"business_partner_types",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// business_partners => /business_partners(.:format)
  // function(options)
  business_partners_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"business_partners",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// business_partners_payment => /business_partners_payments/:id(.:format)
  // function(id, options)
  business_partners_payment_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"business_partners_payments",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// business_partners_payments => /business_partners_payments(.:format)
  // function(options)
  business_partners_payments_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"business_partners_payments",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// calendar_api_web_v1_employee_work_days => /api/web/v1/employee_work_days/calendar(.:format)
  // function(options)
  calendar_api_web_v1_employee_work_days_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"web",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"employee_work_days",false],[2,[7,"/",false],[2,[6,"calendar",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// calendar_employee_work_days => /employee_work_days/calendar(.:format)
  // function(options)
  calendar_employee_work_days_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"employee_work_days",false],[2,[7,"/",false],[2,[6,"calendar",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// cancellation_ap_invoice => /ap_invoices/:id/cancellation(.:format)
  // function(id, options)
  cancellation_ap_invoice_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"ap_invoices",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"cancellation",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// cancellation_ar_invoice => /ar_invoices/:id/cancellation(.:format)
  // function(id, options)
  cancellation_ar_invoice_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"ar_invoices",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"cancellation",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// cancellation_inventory_count => /inventory_counts/:id/cancellation(.:format)
  // function(id, options)
  cancellation_inventory_count_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"inventory_counts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"cancellation",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// cancellation_inventory_post => /inventory_posts/:id/cancellation(.:format)
  // function(id, options)
  cancellation_inventory_post_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"inventory_posts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"cancellation",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// cash_basis_journal_entries => /cash_basis_journal_entries(.:format)
  // function(options)
  cash_basis_journal_entries_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"cash_basis_journal_entries",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// cash_basis_journal_entry => /cash_basis_journal_entries/:id(.:format)
  // function(id, options)
  cash_basis_journal_entry_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"cash_basis_journal_entries",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// cash_documents_by_gl_account_general_ledger_account => /general_ledger_accounts/:id/cash_documents_by_gl_account(.:format)
  // function(id, options)
  cash_documents_by_gl_account_general_ledger_account_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"general_ledger_accounts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"cash_documents_by_gl_account",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// change_status_to_archived_payroll_report => /payroll_reports/:id/change_status_to_archived(.:format)
  // function(id, options)
  change_status_to_archived_payroll_report_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"payroll_reports",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"change_status_to_archived",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// commission_report_employee_payroll_salary_changes => /employees/:employee_id/payroll_salary_changes/commission_report(.:format)
  // function(employee_id, options)
  commission_report_employee_payroll_salary_changes_path: Utils.route([["employee_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[3,"employee_id",false],[2,[7,"/",false],[2,[6,"payroll_salary_changes",false],[2,[7,"/",false],[2,[6,"commission_report",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// commissions_report_api_web_v1_payroll_salary_changes => /api/web/v1/payroll_salary_changes/commissions_report(.:format)
  // function(options)
  commissions_report_api_web_v1_payroll_salary_changes_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"web",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"payroll_salary_changes",false],[2,[7,"/",false],[2,[6,"commissions_report",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// commissions_report_payroll_salary_changes => /payroll_salary_changes/commissions_report(.:format)
  // function(options)
  commissions_report_payroll_salary_changes_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"payroll_salary_changes",false],[2,[7,"/",false],[2,[6,"commissions_report",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// communication_manufacturer => /communication_manufacturers/:id(.:format)
  // function(id, options)
  communication_manufacturer_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"communication_manufacturers",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// communication_manufacturer_communication_type => /communication_manufacturers/:communication_manufacturer_id/communication_types/:id(.:format)
  // function(communication_manufacturer_id, id, options)
  communication_manufacturer_communication_type_path: Utils.route([["communication_manufacturer_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"communication_manufacturers",false],[2,[7,"/",false],[2,[3,"communication_manufacturer_id",false],[2,[7,"/",false],[2,[6,"communication_types",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// communication_manufacturer_communication_types => /communication_manufacturers/:communication_manufacturer_id/communication_types(.:format)
  // function(communication_manufacturer_id, options)
  communication_manufacturer_communication_types_path: Utils.route([["communication_manufacturer_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"communication_manufacturers",false],[2,[7,"/",false],[2,[3,"communication_manufacturer_id",false],[2,[7,"/",false],[2,[6,"communication_types",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// communication_manufacturers => /communication_manufacturers(.:format)
  // function(options)
  communication_manufacturers_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"communication_manufacturers",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// companies => /companies(.:format)
  // function(options)
  companies_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"companies",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// company => /companies/:id(.:format)
  // function(id, options)
  company_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"companies",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// company_clock_device => /companies/:company_id/clock_devices/:id(.:format)
  // function(company_id, id, options)
  company_clock_device_path: Utils.route([["company_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"companies",false],[2,[7,"/",false],[2,[3,"company_id",false],[2,[7,"/",false],[2,[6,"clock_devices",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// company_clock_devices => /companies/:company_id/clock_devices(.:format)
  // function(company_id, options)
  company_clock_devices_path: Utils.route([["company_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"companies",false],[2,[7,"/",false],[2,[3,"company_id",false],[2,[7,"/",false],[2,[6,"clock_devices",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// company_retirement_setting => /company_retirement_settings/:id(.:format)
  // function(id, options)
  company_retirement_setting_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"company_retirement_settings",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// company_retirement_settings => /company_retirement_settings(.:format)
  // function(options)
  company_retirement_settings_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"company_retirement_settings",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// contact_people => /contact_people(.:format)
  // function(options)
  contact_people_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"contact_people",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// contact_person => /contact_people/:id(.:format)
  // function(id, options)
  contact_person_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"contact_people",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// copy_from_inventory_count_inventory_post => /inventory_posts/:id/copy_from_inventory_count(.:format)
  // function(id, options)
  copy_from_inventory_count_inventory_post_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"inventory_posts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"copy_from_inventory_count",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// copy_from_inventory_post => /inventory_posts/:id/copy_from(.:format)
  // function(id, options)
  copy_from_inventory_post_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"inventory_posts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"copy_from",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// copy_to_ap_cm_ap_invoice => /ap_invoices/:id/copy_to_ap_cm(.:format)
  // function(id, options)
  copy_to_ap_cm_ap_invoice_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"ap_invoices",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"copy_to_ap_cm",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// copy_to_ap_down_payment_invoice_purchase_order => /purchase_orders/:id/copy_to_ap_down_payment_invoice(.:format)
  // function(id, options)
  copy_to_ap_down_payment_invoice_purchase_order_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"purchase_orders",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"copy_to_ap_down_payment_invoice",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// copy_to_ap_dp_invoice_ap_down_payment_invoice => /ap_down_payment_invoices/:id/copy_to_ap_dp_invoice(.:format)
  // function(id, options)
  copy_to_ap_dp_invoice_ap_down_payment_invoice_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"ap_down_payment_invoices",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"copy_to_ap_dp_invoice",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// copy_to_ap_invoice_ap_down_payment_invoice => /ap_down_payment_invoices/:id/copy_to_ap_invoice(.:format)
  // function(id, options)
  copy_to_ap_invoice_ap_down_payment_invoice_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"ap_down_payment_invoices",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"copy_to_ap_invoice",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// copy_to_ap_invoice_ap_invoice => /ap_invoices/:id/copy_to_ap_invoice(.:format)
  // function(id, options)
  copy_to_ap_invoice_ap_invoice_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"ap_invoices",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"copy_to_ap_invoice",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// copy_to_ap_invoice_purchase_order => /purchase_orders/:id/copy_to_ap_invoice(.:format)
  // function(id, options)
  copy_to_ap_invoice_purchase_order_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"purchase_orders",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"copy_to_ap_invoice",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// copy_to_ar_credit_memo_ar_invoice => /ar_invoices/:id/copy_to_ar_credit_memo(.:format)
  // function(id, options)
  copy_to_ar_credit_memo_ar_invoice_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"ar_invoices",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"copy_to_ar_credit_memo",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// copy_to_ar_dp_invoice_ar_down_payment_invoice => /ar_down_payment_invoices/:id/copy_to_ar_dp_invoice(.:format)
  // function(id, options)
  copy_to_ar_dp_invoice_ar_down_payment_invoice_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"ar_down_payment_invoices",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"copy_to_ar_dp_invoice",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// copy_to_ar_dp_invoice_sales_order => /sales_orders/:id/copy_to_ar_dp_invoice(.:format)
  // function(id, options)
  copy_to_ar_dp_invoice_sales_order_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"sales_orders",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"copy_to_ar_dp_invoice",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// copy_to_ar_invoice_ar_down_payment_invoice => /ar_down_payment_invoices/:id/copy_to_ar_invoice(.:format)
  // function(id, options)
  copy_to_ar_invoice_ar_down_payment_invoice_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"ar_down_payment_invoices",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"copy_to_ar_invoice",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// copy_to_ar_invoice_sales_order => /sales_orders/:id/copy_to_ar_invoice(.:format)
  // function(id, options)
  copy_to_ar_invoice_sales_order_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"sales_orders",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"copy_to_ar_invoice",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// copy_to_ar_invoice_tech_sales_order => /tech_sales_orders/:id/copy_to_ar_invoice(.:format)
  // function(id, options)
  copy_to_ar_invoice_tech_sales_order_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"tech_sales_orders",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"copy_to_ar_invoice",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// copy_to_inventory_post_inventory_count => /inventory_counts/:id/copy_to_inventory_post(.:format)
  // function(id, options)
  copy_to_inventory_post_inventory_count_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"inventory_counts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"copy_to_inventory_post",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// copy_to_purchase_order_purchase_order => /purchase_orders/:id/copy_to_purchase_order(.:format)
  // function(id, options)
  copy_to_purchase_order_purchase_order_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"purchase_orders",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"copy_to_purchase_order",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// copy_to_purchase_order_purchase_quotation => /purchase_quotations/:id/copy_to_purchase_order(.:format)
  // function(id, options)
  copy_to_purchase_order_purchase_quotation_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"purchase_quotations",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"copy_to_purchase_order",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// copy_to_purchase_quotation_purchase_quotation => /purchase_quotations/:id/copy_to_purchase_quotation(.:format)
  // function(id, options)
  copy_to_purchase_quotation_purchase_quotation_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"purchase_quotations",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"copy_to_purchase_quotation",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// copy_to_sales_order_sales_order => /sales_orders/:id/copy_to_sales_order(.:format)
  // function(id, options)
  copy_to_sales_order_sales_order_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"sales_orders",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"copy_to_sales_order",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// copy_to_sales_order_sales_quotation => /sales_quotations/:id/copy_to_sales_order(.:format)
  // function(id, options)
  copy_to_sales_order_sales_quotation_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"sales_quotations",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"copy_to_sales_order",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// copy_to_sales_quotation_sales_quotation => /sales_quotations/:id/copy_to_sales_quotation(.:format)
  // function(id, options)
  copy_to_sales_quotation_sales_quotation_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"sales_quotations",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"copy_to_sales_quotation",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// copy_to_shipping_partner_billing_address => /partner_billing_addresses/:id/copy_to_shipping(.:format)
  // function(id, options)
  copy_to_shipping_partner_billing_address_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"partner_billing_addresses",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"copy_to_shipping",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// create_invoice_tech_sales_order => /tech_sales_orders/:id/create_invoice(.:format)
  // function(id, options)
  create_invoice_tech_sales_order_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"tech_sales_orders",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"create_invoice",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// create_or_update_role_permissions => /roles/:role_id/permissions/create_or_update(.:format)
  // function(role_id, options)
  create_or_update_role_permissions_path: Utils.route([["role_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"roles",false],[2,[7,"/",false],[2,[3,"role_id",false],[2,[7,"/",false],[2,[6,"permissions",false],[2,[7,"/",false],[2,[6,"create_or_update",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// create_or_update_user_permissions => /users/:user_id/permissions/create_or_update(.:format)
  // function(user_id, options)
  create_or_update_user_permissions_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"permissions",false],[2,[7,"/",false],[2,[6,"create_or_update",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// currencies => /currencies(.:format)
  // function(options)
  currencies_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"currencies",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// currency => /currencies/:id(.:format)
  // function(id, options)
  currency_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"currencies",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// customer_equipment_card => /customer_equipment_cards/:id(.:format)
  // function(id, options)
  customer_equipment_card_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"customer_equipment_cards",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// customer_equipment_card_attachment => /customer_equipment_cards/:customer_equipment_card_id/attachments/:id(.:format)
  // function(customer_equipment_card_id, id, options)
  customer_equipment_card_attachment_path: Utils.route([["customer_equipment_card_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"customer_equipment_cards",false],[2,[7,"/",false],[2,[3,"customer_equipment_card_id",false],[2,[7,"/",false],[2,[6,"attachments",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// customer_equipment_card_attachments => /customer_equipment_cards/:customer_equipment_card_id/attachments(.:format)
  // function(customer_equipment_card_id, options)
  customer_equipment_card_attachments_path: Utils.route([["customer_equipment_card_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"customer_equipment_cards",false],[2,[7,"/",false],[2,[3,"customer_equipment_card_id",false],[2,[7,"/",false],[2,[6,"attachments",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// customer_equipment_card_employee => /customer_equipment_cards/:customer_equipment_card_id/employees/:id(.:format)
  // function(customer_equipment_card_id, id, options)
  customer_equipment_card_employee_path: Utils.route([["customer_equipment_card_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"customer_equipment_cards",false],[2,[7,"/",false],[2,[3,"customer_equipment_card_id",false],[2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// customer_equipment_card_employees => /customer_equipment_cards/:customer_equipment_card_id/employees(.:format)
  // function(customer_equipment_card_id, options)
  customer_equipment_card_employees_path: Utils.route([["customer_equipment_card_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"customer_equipment_cards",false],[2,[7,"/",false],[2,[3,"customer_equipment_card_id",false],[2,[7,"/",false],[2,[6,"employees",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// customer_equipment_card_service_call => /customer_equipment_cards/:customer_equipment_card_id/service_calls/:id(.:format)
  // function(customer_equipment_card_id, id, options)
  customer_equipment_card_service_call_path: Utils.route([["customer_equipment_card_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"customer_equipment_cards",false],[2,[7,"/",false],[2,[3,"customer_equipment_card_id",false],[2,[7,"/",false],[2,[6,"service_calls",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// customer_equipment_card_service_calls => /customer_equipment_cards/:customer_equipment_card_id/service_calls(.:format)
  // function(customer_equipment_card_id, options)
  customer_equipment_card_service_calls_path: Utils.route([["customer_equipment_card_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"customer_equipment_cards",false],[2,[7,"/",false],[2,[3,"customer_equipment_card_id",false],[2,[7,"/",false],[2,[6,"service_calls",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// customer_equipment_card_service_contract => /customer_equipment_cards/:customer_equipment_card_id/service_contracts/:id(.:format)
  // function(customer_equipment_card_id, id, options)
  customer_equipment_card_service_contract_path: Utils.route([["customer_equipment_card_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"customer_equipment_cards",false],[2,[7,"/",false],[2,[3,"customer_equipment_card_id",false],[2,[7,"/",false],[2,[6,"service_contracts",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// customer_equipment_card_service_contracts => /customer_equipment_cards/:customer_equipment_card_id/service_contracts(.:format)
  // function(customer_equipment_card_id, options)
  customer_equipment_card_service_contracts_path: Utils.route([["customer_equipment_card_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"customer_equipment_cards",false],[2,[7,"/",false],[2,[3,"customer_equipment_card_id",false],[2,[7,"/",false],[2,[6,"service_contracts",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// customer_equipment_card_service_maintenance => /customer_equipment_cards/:customer_equipment_card_id/service_maintenances/:id(.:format)
  // function(customer_equipment_card_id, id, options)
  customer_equipment_card_service_maintenance_path: Utils.route([["customer_equipment_card_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"customer_equipment_cards",false],[2,[7,"/",false],[2,[3,"customer_equipment_card_id",false],[2,[7,"/",false],[2,[6,"service_maintenances",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// customer_equipment_card_service_maintenances => /customer_equipment_cards/:customer_equipment_card_id/service_maintenances(.:format)
  // function(customer_equipment_card_id, options)
  customer_equipment_card_service_maintenances_path: Utils.route([["customer_equipment_card_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"customer_equipment_cards",false],[2,[7,"/",false],[2,[3,"customer_equipment_card_id",false],[2,[7,"/",false],[2,[6,"service_maintenances",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// customer_equipment_cards => /customer_equipment_cards(.:format)
  // function(options)
  customer_equipment_cards_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"customer_equipment_cards",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// default_billing_address_business_partner => /business_partners/:id/default_billing_address(.:format)
  // function(id, options)
  default_billing_address_business_partner_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"business_partners",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"default_billing_address",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// default_contact_person_business_partner => /business_partners/:id/default_contact_person(.:format)
  // function(id, options)
  default_contact_person_business_partner_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"business_partners",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"default_contact_person",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// default_shipping_address_business_partner => /business_partners/:id/default_shipping_address(.:format)
  // function(id, options)
  default_shipping_address_business_partner_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"business_partners",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"default_shipping_address",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// define_business_partner_api_web_v1_business_partners => /api/web/v1/business_partners/define_business_partner(.:format)
  // function(options)
  define_business_partner_api_web_v1_business_partners_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"web",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"business_partners",false],[2,[7,"/",false],[2,[6,"define_business_partner",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// define_document_api_web_v1_common_documents => /api/web/v1/common_documents/define_document(.:format)
  // function(options)
  define_document_api_web_v1_common_documents_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"web",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"common_documents",false],[2,[7,"/",false],[2,[6,"define_document",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// deposit => /deposits/:id(.:format)
  // function(id, options)
  deposit_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"deposits",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// deposits => /deposits(.:format)
  // function(options)
  deposits_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"deposits",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// destroy_user_session => /users/sign_out(.:format)
  // function(options)
  destroy_user_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"sign_out",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// determination_line => /determination_lines/:id(.:format)
  // function(id, options)
  determination_line_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"determination_lines",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// determination_lines => /determination_lines(.:format)
  // function(options)
  determination_lines_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"determination_lines",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// determine_tax_type_employee_payroll_salary_changes => /employees/:employee_id/payroll_salary_changes/determine_tax_type(.:format)
  // function(employee_id, options)
  determine_tax_type_employee_payroll_salary_changes_path: Utils.route([["employee_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[3,"employee_id",false],[2,[7,"/",false],[2,[6,"payroll_salary_changes",false],[2,[7,"/",false],[2,[6,"determine_tax_type",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// document_numbering => /document_numberings/:id(.:format)
  // function(id, options)
  document_numbering_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"document_numberings",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// document_numberings => /document_numberings(.:format)
  // function(options)
  document_numberings_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"document_numberings",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// download_attachment => /attachments/:id/download(.:format)
  // function(id, options)
  download_attachment_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"attachments",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"download",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// download_employee_attachment => /employees/:employee_id/attachments/:id/download(.:format)
  // function(employee_id, id, options)
  download_employee_attachment_path: Utils.route([["employee_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[3,"employee_id",false],[2,[7,"/",false],[2,[6,"attachments",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"download",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// download_report_employee_work_period => /employee_work_periods/:id/download_report(.:format)
  // function(id, options)
  download_report_employee_work_period_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"employee_work_periods",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"download_report",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// download_report_posting_period => /posting_periods/:id/download_report(.:format)
  // function(id, options)
  download_report_posting_period_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"posting_periods",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"download_report",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_annual_service => /annual_services/:id/edit(.:format)
  // function(id, options)
  edit_annual_service_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"annual_services",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_annual_service_annual_service_date => /annual_services/:annual_service_id/annual_service_dates/:id/edit(.:format)
  // function(annual_service_id, id, options)
  edit_annual_service_annual_service_date_path: Utils.route([["annual_service_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"annual_services",false],[2,[7,"/",false],[2,[3,"annual_service_id",false],[2,[7,"/",false],[2,[6,"annual_service_dates",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_ap_credit_memo => /ap_credit_memos/:id/edit(.:format)
  // function(id, options)
  edit_ap_credit_memo_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"ap_credit_memos",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_ap_down_payment_invoice => /ap_down_payment_invoices/:id/edit(.:format)
  // function(id, options)
  edit_ap_down_payment_invoice_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"ap_down_payment_invoices",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_ap_invoice => /ap_invoices/:id/edit(.:format)
  // function(id, options)
  edit_ap_invoice_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"ap_invoices",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_api_v1_tech_sales_order => /api/v1/tech_sales_orders/:id/edit(.:format)
  // function(id, options)
  edit_api_v1_tech_sales_order_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"tech_sales_orders",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_api_web_v1_common_document => /api/web/v1/common_documents/:id/edit(.:format)
  // function(id, options)
  edit_api_web_v1_common_document_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"web",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"common_documents",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// edit_api_web_v1_company => /api/web/v1/companies/:id/edit(.:format)
  // function(id, options)
  edit_api_web_v1_company_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"web",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"companies",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// edit_api_web_v1_company_retirement_setting => /api/web/v1/company_retirement_settings/:id/edit(.:format)
  // function(id, options)
  edit_api_web_v1_company_retirement_setting_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"web",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"company_retirement_settings",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// edit_api_web_v1_company_retirement_setting_company_retirement_setting_line => /api/web/v1/company_retirement_settings/:company_retirement_setting_id/company_retirement_setting_lines/:id/edit(.:format)
  // function(company_retirement_setting_id, id, options)
  edit_api_web_v1_company_retirement_setting_company_retirement_setting_line_path: Utils.route([["company_retirement_setting_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"web",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"company_retirement_settings",false],[2,[7,"/",false],[2,[3,"company_retirement_setting_id",false],[2,[7,"/",false],[2,[6,"company_retirement_setting_lines",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]),
// edit_api_web_v1_emergency_phone_setting => /api/web/v1/emergency_phone_settings/:id/edit(.:format)
  // function(id, options)
  edit_api_web_v1_emergency_phone_setting_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"web",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"emergency_phone_settings",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// edit_api_web_v1_employee_work_day => /api/web/v1/employee_work_days/:id/edit(.:format)
  // function(id, options)
  edit_api_web_v1_employee_work_day_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"web",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"employee_work_days",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// edit_api_web_v1_global_retirement_setting => /api/web/v1/global_retirement_settings/:id/edit(.:format)
  // function(id, options)
  edit_api_web_v1_global_retirement_setting_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"web",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"global_retirement_settings",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// edit_api_web_v1_health_benefit_setting => /api/web/v1/health_benefit_settings/:id/edit(.:format)
  // function(id, options)
  edit_api_web_v1_health_benefit_setting_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"web",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"health_benefit_settings",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// edit_api_web_v1_health_benefit_setting_health_benefit_setting_line => /api/web/v1/health_benefit_settings/:health_benefit_setting_id/health_benefit_setting_lines/:id/edit(.:format)
  // function(health_benefit_setting_id, id, options)
  edit_api_web_v1_health_benefit_setting_health_benefit_setting_line_path: Utils.route([["health_benefit_setting_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"web",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"health_benefit_settings",false],[2,[7,"/",false],[2,[3,"health_benefit_setting_id",false],[2,[7,"/",false],[2,[6,"health_benefit_setting_lines",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]),
// edit_api_web_v1_payroll_setting => /api/web/v1/payroll_settings/:id/edit(.:format)
  // function(id, options)
  edit_api_web_v1_payroll_setting_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"web",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"payroll_settings",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// edit_api_web_v1_payroll_tax_setup_add_on => /api/web/v1/payroll_tax_setup_add_ons/:id/edit(.:format)
  // function(id, options)
  edit_api_web_v1_payroll_tax_setup_add_on_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"web",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"payroll_tax_setup_add_ons",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// edit_api_web_v1_setting => /api/web/v1/settings/:id/edit(.:format)
  // function(id, options)
  edit_api_web_v1_setting_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"web",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// edit_api_web_v1_sick_setting => /api/web/v1/sick_settings/:id/edit(.:format)
  // function(id, options)
  edit_api_web_v1_sick_setting_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"web",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"sick_settings",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// edit_api_web_v1_vacation_setting => /api/web/v1/vacation_settings/:id/edit(.:format)
  // function(id, options)
  edit_api_web_v1_vacation_setting_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"web",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"vacation_settings",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// edit_ar_credit_memo => /ar_credit_memos/:id/edit(.:format)
  // function(id, options)
  edit_ar_credit_memo_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"ar_credit_memos",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_ar_down_payment_invoice => /ar_down_payment_invoices/:id/edit(.:format)
  // function(id, options)
  edit_ar_down_payment_invoice_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"ar_down_payment_invoices",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_ar_invoice => /ar_invoices/:id/edit(.:format)
  // function(id, options)
  edit_ar_invoice_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"ar_invoices",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_attachment => /attachments/:id/edit(.:format)
  // function(id, options)
  edit_attachment_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"attachments",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_award => /awards/:id/edit(.:format)
  // function(id, options)
  edit_award_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"awards",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_bill => /bills/:id/edit(.:format)
  // function(id, options)
  edit_bill_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"bills",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_bonuse => /bonuses/:id/edit(.:format)
  // function(id, options)
  edit_bonuse_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"bonuses",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_business_partner => /business_partners/:id/edit(.:format)
  // function(id, options)
  edit_business_partner_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"business_partners",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_business_partner_ap_down_payment_invoice => /business_partners/:business_partner_id/ap_down_payment_invoices/:id/edit(.:format)
  // function(business_partner_id, id, options)
  edit_business_partner_ap_down_payment_invoice_path: Utils.route([["business_partner_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"business_partners",false],[2,[7,"/",false],[2,[3,"business_partner_id",false],[2,[7,"/",false],[2,[6,"ap_down_payment_invoices",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_business_partner_ap_invoice => /business_partners/:business_partner_id/ap_invoices/:id/edit(.:format)
  // function(business_partner_id, id, options)
  edit_business_partner_ap_invoice_path: Utils.route([["business_partner_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"business_partners",false],[2,[7,"/",false],[2,[3,"business_partner_id",false],[2,[7,"/",false],[2,[6,"ap_invoices",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_business_partner_ar_down_payment_invoice => /business_partners/:business_partner_id/ar_down_payment_invoices/:id/edit(.:format)
  // function(business_partner_id, id, options)
  edit_business_partner_ar_down_payment_invoice_path: Utils.route([["business_partner_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"business_partners",false],[2,[7,"/",false],[2,[3,"business_partner_id",false],[2,[7,"/",false],[2,[6,"ar_down_payment_invoices",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_business_partner_ar_invoice => /business_partners/:business_partner_id/ar_invoices/:id/edit(.:format)
  // function(business_partner_id, id, options)
  edit_business_partner_ar_invoice_path: Utils.route([["business_partner_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"business_partners",false],[2,[7,"/",false],[2,[3,"business_partner_id",false],[2,[7,"/",false],[2,[6,"ar_invoices",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_business_partner_attachment => /business_partners/:business_partner_id/attachments/:id/edit(.:format)
  // function(business_partner_id, id, options)
  edit_business_partner_attachment_path: Utils.route([["business_partner_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"business_partners",false],[2,[7,"/",false],[2,[3,"business_partner_id",false],[2,[7,"/",false],[2,[6,"attachments",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_business_partner_contact_person => /business_partners/:business_partner_id/contact_people/:id/edit(.:format)
  // function(business_partner_id, id, options)
  edit_business_partner_contact_person_path: Utils.route([["business_partner_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"business_partners",false],[2,[7,"/",false],[2,[3,"business_partner_id",false],[2,[7,"/",false],[2,[6,"contact_people",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_business_partner_customer_equipment_card => /business_partners/:business_partner_id/customer_equipment_cards/:id/edit(.:format)
  // function(business_partner_id, id, options)
  edit_business_partner_customer_equipment_card_path: Utils.route([["business_partner_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"business_partners",false],[2,[7,"/",false],[2,[3,"business_partner_id",false],[2,[7,"/",false],[2,[6,"customer_equipment_cards",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_business_partner_partner_billing_address => /business_partners/:business_partner_id/partner_billing_addresses/:id/edit(.:format)
  // function(business_partner_id, id, options)
  edit_business_partner_partner_billing_address_path: Utils.route([["business_partner_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"business_partners",false],[2,[7,"/",false],[2,[3,"business_partner_id",false],[2,[7,"/",false],[2,[6,"partner_billing_addresses",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_business_partner_partner_shipping_address => /business_partners/:business_partner_id/partner_shipping_addresses/:id/edit(.:format)
  // function(business_partner_id, id, options)
  edit_business_partner_partner_shipping_address_path: Utils.route([["business_partner_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"business_partners",false],[2,[7,"/",false],[2,[3,"business_partner_id",false],[2,[7,"/",false],[2,[6,"partner_shipping_addresses",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_business_partner_purchase_order => /business_partners/:business_partner_id/purchase_orders/:id/edit(.:format)
  // function(business_partner_id, id, options)
  edit_business_partner_purchase_order_path: Utils.route([["business_partner_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"business_partners",false],[2,[7,"/",false],[2,[3,"business_partner_id",false],[2,[7,"/",false],[2,[6,"purchase_orders",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_business_partner_sales_order => /business_partners/:business_partner_id/sales_orders/:id/edit(.:format)
  // function(business_partner_id, id, options)
  edit_business_partner_sales_order_path: Utils.route([["business_partner_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"business_partners",false],[2,[7,"/",false],[2,[3,"business_partner_id",false],[2,[7,"/",false],[2,[6,"sales_orders",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_business_partners_payment => /business_partners_payments/:id/edit(.:format)
  // function(id, options)
  edit_business_partners_payment_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"business_partners_payments",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_cash_basis_journal_entry => /cash_basis_journal_entries/:id/edit(.:format)
  // function(id, options)
  edit_cash_basis_journal_entry_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"cash_basis_journal_entries",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_communication_manufacturer => /communication_manufacturers/:id/edit(.:format)
  // function(id, options)
  edit_communication_manufacturer_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"communication_manufacturers",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_communication_manufacturer_communication_type => /communication_manufacturers/:communication_manufacturer_id/communication_types/:id/edit(.:format)
  // function(communication_manufacturer_id, id, options)
  edit_communication_manufacturer_communication_type_path: Utils.route([["communication_manufacturer_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"communication_manufacturers",false],[2,[7,"/",false],[2,[3,"communication_manufacturer_id",false],[2,[7,"/",false],[2,[6,"communication_types",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_company => /companies/:id/edit(.:format)
  // function(id, options)
  edit_company_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"companies",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_company_clock_device => /companies/:company_id/clock_devices/:id/edit(.:format)
  // function(company_id, id, options)
  edit_company_clock_device_path: Utils.route([["company_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"companies",false],[2,[7,"/",false],[2,[3,"company_id",false],[2,[7,"/",false],[2,[6,"clock_devices",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_company_retirement_setting => /company_retirement_settings/:id/edit(.:format)
  // function(id, options)
  edit_company_retirement_setting_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"company_retirement_settings",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_contact_person => /contact_people/:id/edit(.:format)
  // function(id, options)
  edit_contact_person_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"contact_people",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_currency => /currencies/:id/edit(.:format)
  // function(id, options)
  edit_currency_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"currencies",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_customer_equipment_card => /customer_equipment_cards/:id/edit(.:format)
  // function(id, options)
  edit_customer_equipment_card_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"customer_equipment_cards",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_customer_equipment_card_attachment => /customer_equipment_cards/:customer_equipment_card_id/attachments/:id/edit(.:format)
  // function(customer_equipment_card_id, id, options)
  edit_customer_equipment_card_attachment_path: Utils.route([["customer_equipment_card_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"customer_equipment_cards",false],[2,[7,"/",false],[2,[3,"customer_equipment_card_id",false],[2,[7,"/",false],[2,[6,"attachments",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_customer_equipment_card_employee => /customer_equipment_cards/:customer_equipment_card_id/employees/:id/edit(.:format)
  // function(customer_equipment_card_id, id, options)
  edit_customer_equipment_card_employee_path: Utils.route([["customer_equipment_card_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"customer_equipment_cards",false],[2,[7,"/",false],[2,[3,"customer_equipment_card_id",false],[2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_customer_equipment_card_service_call => /customer_equipment_cards/:customer_equipment_card_id/service_calls/:id/edit(.:format)
  // function(customer_equipment_card_id, id, options)
  edit_customer_equipment_card_service_call_path: Utils.route([["customer_equipment_card_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"customer_equipment_cards",false],[2,[7,"/",false],[2,[3,"customer_equipment_card_id",false],[2,[7,"/",false],[2,[6,"service_calls",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_customer_equipment_card_service_contract => /customer_equipment_cards/:customer_equipment_card_id/service_contracts/:id/edit(.:format)
  // function(customer_equipment_card_id, id, options)
  edit_customer_equipment_card_service_contract_path: Utils.route([["customer_equipment_card_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"customer_equipment_cards",false],[2,[7,"/",false],[2,[3,"customer_equipment_card_id",false],[2,[7,"/",false],[2,[6,"service_contracts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_customer_equipment_card_service_maintenance => /customer_equipment_cards/:customer_equipment_card_id/service_maintenances/:id/edit(.:format)
  // function(customer_equipment_card_id, id, options)
  edit_customer_equipment_card_service_maintenance_path: Utils.route([["customer_equipment_card_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"customer_equipment_cards",false],[2,[7,"/",false],[2,[3,"customer_equipment_card_id",false],[2,[7,"/",false],[2,[6,"service_maintenances",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_deposit => /deposits/:id/edit(.:format)
  // function(id, options)
  edit_deposit_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"deposits",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_determination_line => /determination_lines/:id/edit(.:format)
  // function(id, options)
  edit_determination_line_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"determination_lines",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_document_numbering => /document_numberings/:id/edit(.:format)
  // function(id, options)
  edit_document_numbering_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"document_numberings",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_emergency_phone_setting => /emergency_phone_settings/:id/edit(.:format)
  // function(id, options)
  edit_emergency_phone_setting_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"emergency_phone_settings",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_emergency_service => /emergency_services/:id/edit(.:format)
  // function(id, options)
  edit_emergency_service_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"emergency_services",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_emergency_services_payroll_report => /payroll_reports/:id/edit_emergency_services(.:format)
  // function(id, options)
  edit_emergency_services_payroll_report_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"payroll_reports",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit_emergency_services",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_employee => /employees/:id/edit(.:format)
  // function(id, options)
  edit_employee_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_employee_attachment => /employees/:employee_id/attachments/:id/edit(.:format)
  // function(employee_id, id, options)
  edit_employee_attachment_path: Utils.route([["employee_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[3,"employee_id",false],[2,[7,"/",false],[2,[6,"attachments",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_employee_award => /employees/:employee_id/awards/:id/edit(.:format)
  // function(employee_id, id, options)
  edit_employee_award_path: Utils.route([["employee_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[3,"employee_id",false],[2,[7,"/",false],[2,[6,"awards",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_employee_bonuse => /employees/:employee_id/bonuses/:id/edit(.:format)
  // function(employee_id, id, options)
  edit_employee_bonuse_path: Utils.route([["employee_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[3,"employee_id",false],[2,[7,"/",false],[2,[6,"bonuses",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_employee_education => /employees/:employee_id/educations/:id/edit(.:format)
  // function(employee_id, id, options)
  edit_employee_education_path: Utils.route([["employee_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[3,"employee_id",false],[2,[7,"/",false],[2,[6,"educations",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_employee_employee_health_benefit => /employees/:employee_id/employee_health_benefits/:id/edit(.:format)
  // function(employee_id, id, options)
  edit_employee_employee_health_benefit_path: Utils.route([["employee_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[3,"employee_id",false],[2,[7,"/",false],[2,[6,"employee_health_benefits",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_employee_employee_punch => /employees/:employee_id/employee_punches/:id/edit(.:format)
  // function(employee_id, id, options)
  edit_employee_employee_punch_path: Utils.route([["employee_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[3,"employee_id",false],[2,[7,"/",false],[2,[6,"employee_punches",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_employee_employee_team => /employees/:employee_id/employee_teams/:id/edit(.:format)
  // function(employee_id, id, options)
  edit_employee_employee_team_path: Utils.route([["employee_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[3,"employee_id",false],[2,[7,"/",false],[2,[6,"employee_teams",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_employee_employee_work_day => /employees/:employee_id/employee_work_days/:id/edit(.:format)
  // function(employee_id, id, options)
  edit_employee_employee_work_day_path: Utils.route([["employee_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[3,"employee_id",false],[2,[7,"/",false],[2,[6,"employee_work_days",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_employee_employees_role => /employees/:employee_id/employees_roles/:id/edit(.:format)
  // function(employee_id, id, options)
  edit_employee_employees_role_path: Utils.route([["employee_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[3,"employee_id",false],[2,[7,"/",false],[2,[6,"employees_roles",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_employee_employment_history => /employees/:employee_id/employment_histories/:id/edit(.:format)
  // function(employee_id, id, options)
  edit_employee_employment_history_path: Utils.route([["employee_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[3,"employee_id",false],[2,[7,"/",false],[2,[6,"employment_histories",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_employee_incoming_taxes_connecticut_tax_setup => /employees/:employee_id/incoming_taxes/connecticut_tax_setups/:id/edit(.:format)
  // function(employee_id, id, options)
  edit_employee_incoming_taxes_connecticut_tax_setup_path: Utils.route([["employee_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[3,"employee_id",false],[2,[7,"/",false],[2,[6,"incoming_taxes",false],[2,[7,"/",false],[2,[6,"connecticut_tax_setups",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// edit_employee_incoming_taxes_federal_tax_setup => /employees/:employee_id/incoming_taxes/federal_tax_setups/:id/edit(.:format)
  // function(employee_id, id, options)
  edit_employee_incoming_taxes_federal_tax_setup_path: Utils.route([["employee_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[3,"employee_id",false],[2,[7,"/",false],[2,[6,"incoming_taxes",false],[2,[7,"/",false],[2,[6,"federal_tax_setups",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// edit_employee_incoming_taxes_new_york_tax_setup => /employees/:employee_id/incoming_taxes/new_york_tax_setups/:id/edit(.:format)
  // function(employee_id, id, options)
  edit_employee_incoming_taxes_new_york_tax_setup_path: Utils.route([["employee_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[3,"employee_id",false],[2,[7,"/",false],[2,[6,"incoming_taxes",false],[2,[7,"/",false],[2,[6,"new_york_tax_setups",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// edit_employee_loan => /employees/:employee_id/loans/:id/edit(.:format)
  // function(employee_id, id, options)
  edit_employee_loan_path: Utils.route([["employee_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[3,"employee_id",false],[2,[7,"/",false],[2,[6,"loans",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_employee_paid_absence_period => /employees/:employee_id/paid_absence_periods/:id/edit(.:format)
  // function(employee_id, id, options)
  edit_employee_paid_absence_period_path: Utils.route([["employee_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[3,"employee_id",false],[2,[7,"/",false],[2,[6,"paid_absence_periods",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_employee_payroll_salary_change => /employees/:employee_id/payroll_salary_changes/:id/edit(.:format)
  // function(employee_id, id, options)
  edit_employee_payroll_salary_change_path: Utils.route([["employee_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[3,"employee_id",false],[2,[7,"/",false],[2,[6,"payroll_salary_changes",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_employee_previous_employment => /employees/:employee_id/previous_employments/:id/edit(.:format)
  // function(employee_id, id, options)
  edit_employee_previous_employment_path: Utils.route([["employee_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[3,"employee_id",false],[2,[7,"/",false],[2,[6,"previous_employments",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_employee_punch => /employee_punches/:id/edit(.:format)
  // function(id, options)
  edit_employee_punch_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"employee_punches",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_employee_retirement_benefit => /employees/:employee_id/retirement_benefits/:id/edit(.:format)
  // function(employee_id, id, options)
  edit_employee_retirement_benefit_path: Utils.route([["employee_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[3,"employee_id",false],[2,[7,"/",false],[2,[6,"retirement_benefits",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_employee_review => /employees/:employee_id/reviews/:id/edit(.:format)
  // function(employee_id, id, options)
  edit_employee_review_path: Utils.route([["employee_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[3,"employee_id",false],[2,[7,"/",false],[2,[6,"reviews",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_employee_salary => /employees/:employee_id/salaries/:id/edit(.:format)
  // function(employee_id, id, options)
  edit_employee_salary_path: Utils.route([["employee_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[3,"employee_id",false],[2,[7,"/",false],[2,[6,"salaries",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_employee_work_day => /employee_work_days/:id/edit(.:format)
  // function(id, options)
  edit_employee_work_day_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"employee_work_days",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_employee_work_day_emergency_service => /employee_work_days/:employee_work_day_id/emergency_services/:id/edit(.:format)
  // function(employee_work_day_id, id, options)
  edit_employee_work_day_emergency_service_path: Utils.route([["employee_work_day_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"employee_work_days",false],[2,[7,"/",false],[2,[3,"employee_work_day_id",false],[2,[7,"/",false],[2,[6,"emergency_services",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_employee_work_period => /employee_work_periods/:id/edit(.:format)
  // function(id, options)
  edit_employee_work_period_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"employee_work_periods",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_employee_work_period_states_employee_work_period => /employee_work_periods/:id/edit_employee_work_period_states(.:format)
  // function(id, options)
  edit_employee_work_period_states_employee_work_period_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"employee_work_periods",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit_employee_work_period_states",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_employees_role => /employees_roles/:id/edit(.:format)
  // function(id, options)
  edit_employees_role_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"employees_roles",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_general_ledger_account => /general_ledger_accounts/:id/edit(.:format)
  // function(id, options)
  edit_general_ledger_account_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"general_ledger_accounts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_gl_account_determination => /gl_account_determinations/:id/edit(.:format)
  // function(id, options)
  edit_gl_account_determination_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"gl_account_determinations",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_global_retirement_setting => /global_retirement_settings/:id/edit(.:format)
  // function(id, options)
  edit_global_retirement_setting_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"global_retirement_settings",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_health_benefit_setting => /health_benefit_settings/:id/edit(.:format)
  // function(id, options)
  edit_health_benefit_setting_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"health_benefit_settings",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_health_benefit_setting_line => /health_benefit_setting_lines/:id/edit(.:format)
  // function(id, options)
  edit_health_benefit_setting_line_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"health_benefit_setting_lines",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_holiday => /holidays/:id/edit(.:format)
  // function(id, options)
  edit_holiday_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"holidays",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_incoming_payment => /incoming_payments/:id/edit(.:format)
  // function(id, options)
  edit_incoming_payment_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"incoming_payments",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_inventory_count => /inventory_counts/:id/edit(.:format)
  // function(id, options)
  edit_inventory_count_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"inventory_counts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_inventory_count_attachment => /inventory_counts/:inventory_count_id/attachments/:id/edit(.:format)
  // function(inventory_count_id, id, options)
  edit_inventory_count_attachment_path: Utils.route([["inventory_count_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"inventory_counts",false],[2,[7,"/",false],[2,[3,"inventory_count_id",false],[2,[7,"/",false],[2,[6,"attachments",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_inventory_post => /inventory_posts/:id/edit(.:format)
  // function(id, options)
  edit_inventory_post_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"inventory_posts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_inventory_post_attachment => /inventory_posts/:inventory_post_id/attachments/:id/edit(.:format)
  // function(inventory_post_id, id, options)
  edit_inventory_post_attachment_path: Utils.route([["inventory_post_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"inventory_posts",false],[2,[7,"/",false],[2,[3,"inventory_post_id",false],[2,[7,"/",false],[2,[6,"attachments",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_inventory_transfer => /inventory_transfers/:id/edit(.:format)
  // function(id, options)
  edit_inventory_transfer_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"inventory_transfers",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_item => /items/:id/edit(.:format)
  // function(id, options)
  edit_item_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"items",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_item_attachment => /items/:item_id/attachments/:id/edit(.:format)
  // function(item_id, id, options)
  edit_item_attachment_path: Utils.route([["item_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"items",false],[2,[7,"/",false],[2,[3,"item_id",false],[2,[7,"/",false],[2,[6,"attachments",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_item_group => /item_groups/:id/edit(.:format)
  // function(id, options)
  edit_item_group_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"item_groups",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_item_group_accounting_item_group => /item_groups/:item_group_id/accounting_item_groups/:id/edit(.:format)
  // function(item_group_id, id, options)
  edit_item_group_accounting_item_group_path: Utils.route([["item_group_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"item_groups",false],[2,[7,"/",false],[2,[3,"item_group_id",false],[2,[7,"/",false],[2,[6,"accounting_item_groups",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_item_stock => /items/:item_id/stocks/:id/edit(.:format)
  // function(item_id, id, options)
  edit_item_stock_path: Utils.route([["item_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"items",false],[2,[7,"/",false],[2,[3,"item_id",false],[2,[7,"/",false],[2,[6,"stocks",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_journal_entry => /journal_entries/:id/edit(.:format)
  // function(id, options)
  edit_journal_entry_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"journal_entries",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_loan => /loans/:id/edit(.:format)
  // function(id, options)
  edit_loan_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"loans",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_operator_manufacturer => /operator_manufacturers/:id/edit(.:format)
  // function(id, options)
  edit_operator_manufacturer_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"operator_manufacturers",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_operator_manufacturer_operator_type => /operator_manufacturers/:operator_manufacturer_id/operator_types/:id/edit(.:format)
  // function(operator_manufacturer_id, id, options)
  edit_operator_manufacturer_operator_type_path: Utils.route([["operator_manufacturer_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"operator_manufacturers",false],[2,[7,"/",false],[2,[3,"operator_manufacturer_id",false],[2,[7,"/",false],[2,[6,"operator_types",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_outgoing_payment => /outgoing_payments/:id/edit(.:format)
  // function(id, options)
  edit_outgoing_payment_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"outgoing_payments",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_paid_absence_period => /paid_absence_periods/:id/edit(.:format)
  // function(id, options)
  edit_paid_absence_period_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"paid_absence_periods",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_partner_billing_address => /partner_billing_addresses/:id/edit(.:format)
  // function(id, options)
  edit_partner_billing_address_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"partner_billing_addresses",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_partner_shipping_address => /partner_shipping_addresses/:id/edit(.:format)
  // function(id, options)
  edit_partner_shipping_address_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"partner_shipping_addresses",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_payment => /payments/:id/edit(.:format)
  // function(id, options)
  edit_payment_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"payments",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_payment_business_partners_payment => /payments/:payment_id/business_partners_payments/:id/edit(.:format)
  // function(payment_id, id, options)
  edit_payment_business_partners_payment_path: Utils.route([["payment_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"payments",false],[2,[7,"/",false],[2,[3,"payment_id",false],[2,[7,"/",false],[2,[6,"business_partners_payments",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_payment_method => /payment_methods/:id/edit(.:format)
  // function(id, options)
  edit_payment_method_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"payment_methods",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_payment_payment_method => /payments/:payment_id/payment_methods/:id/edit(.:format)
  // function(payment_id, id, options)
  edit_payment_payment_method_path: Utils.route([["payment_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"payments",false],[2,[7,"/",false],[2,[3,"payment_id",false],[2,[7,"/",false],[2,[6,"payment_methods",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_payment_recommendation_report => /payments/:payment_id/recommendation_reports/:id/edit(.:format)
  // function(payment_id, id, options)
  edit_payment_recommendation_report_path: Utils.route([["payment_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"payments",false],[2,[7,"/",false],[2,[3,"payment_id",false],[2,[7,"/",false],[2,[6,"recommendation_reports",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_payment_wizard => /payments/:payment_id/wizard/:id/edit(.:format)
  // function(payment_id, id, options)
  edit_payment_wizard_path: Utils.route([["payment_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"payments",false],[2,[7,"/",false],[2,[3,"payment_id",false],[2,[7,"/",false],[2,[6,"wizard",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_payroll_report => /payroll_reports/:id/edit(.:format)
  // function(id, options)
  edit_payroll_report_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"payroll_reports",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_payroll_salary_change => /payroll_salary_changes/:id/edit(.:format)
  // function(id, options)
  edit_payroll_salary_change_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"payroll_salary_changes",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_payroll_salary_change_reason => /payroll_salary_change_reasons/:id/edit(.:format)
  // function(id, options)
  edit_payroll_salary_change_reason_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"payroll_salary_change_reasons",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_payroll_salary_changes_payroll_report => /payroll_reports/:id/edit_payroll_salary_changes(.:format)
  // function(id, options)
  edit_payroll_salary_changes_payroll_report_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"payroll_reports",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit_payroll_salary_changes",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_payroll_setting => /payroll_settings/:id/edit(.:format)
  // function(id, options)
  edit_payroll_setting_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"payroll_settings",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_payroll_tax_setup_add_on => /payroll_tax_setup_add_ons/:id/edit(.:format)
  // function(id, options)
  edit_payroll_tax_setup_add_on_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"payroll_tax_setup_add_ons",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_permission => /permissions/:id/edit(.:format)
  // function(id, options)
  edit_permission_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"permissions",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_posting_period => /posting_periods/:id/edit(.:format)
  // function(id, options)
  edit_posting_period_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"posting_periods",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_production_order => /production_orders/:id/edit(.:format)
  // function(id, options)
  edit_production_order_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"production_orders",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_production_order_attachment => /production_orders/:production_order_id/attachments/:id/edit(.:format)
  // function(production_order_id, id, options)
  edit_production_order_attachment_path: Utils.route([["production_order_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"production_orders",false],[2,[7,"/",false],[2,[3,"production_order_id",false],[2,[7,"/",false],[2,[6,"attachments",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_production_order_line => /production_order_lines/:id/edit(.:format)
  // function(id, options)
  edit_production_order_line_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"production_order_lines",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_production_order_production_order_line => /production_orders/:production_order_id/production_order_lines/:id/edit(.:format)
  // function(production_order_id, id, options)
  edit_production_order_production_order_line_path: Utils.route([["production_order_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"production_orders",false],[2,[7,"/",false],[2,[3,"production_order_id",false],[2,[7,"/",false],[2,[6,"production_order_lines",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_property => /properties/:id/edit(.:format)
  // function(id, options)
  edit_property_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"properties",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_purchase_order => /purchase_orders/:id/edit(.:format)
  // function(id, options)
  edit_purchase_order_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"purchase_orders",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_purchase_quotation => /purchase_quotations/:id/edit(.:format)
  // function(id, options)
  edit_purchase_quotation_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"purchase_quotations",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_rails_conductor_inbound_email => /rails/conductor/action_mailbox/inbound_emails/:id/edit(.:format)
  // function(id, options)
  edit_rails_conductor_inbound_email_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// edit_receipt_production => /receipt_productions/:id/edit(.:format)
  // function(id, options)
  edit_receipt_production_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"receipt_productions",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_receipt_production_attachment => /receipt_productions/:receipt_production_id/attachments/:id/edit(.:format)
  // function(receipt_production_id, id, options)
  edit_receipt_production_attachment_path: Utils.route([["receipt_production_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"receipt_productions",false],[2,[7,"/",false],[2,[3,"receipt_production_id",false],[2,[7,"/",false],[2,[6,"attachments",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_recommendation_report => /recommendation_reports/:id/edit(.:format)
  // function(id, options)
  edit_recommendation_report_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"recommendation_reports",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_role => /roles/:id/edit(.:format)
  // function(id, options)
  edit_role_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"roles",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_salary => /salaries/:id/edit(.:format)
  // function(id, options)
  edit_salary_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"salaries",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_sales_order => /sales_orders/:id/edit(.:format)
  // function(id, options)
  edit_sales_order_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"sales_orders",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_sales_order_tech_sales_order => /sales_orders/:sales_order_id/tech_sales_orders/:id/edit(.:format)
  // function(sales_order_id, id, options)
  edit_sales_order_tech_sales_order_path: Utils.route([["sales_order_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"sales_orders",false],[2,[7,"/",false],[2,[3,"sales_order_id",false],[2,[7,"/",false],[2,[6,"tech_sales_orders",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_sales_quotation => /sales_quotations/:id/edit(.:format)
  // function(id, options)
  edit_sales_quotation_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"sales_quotations",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_sales_tax_code => /sales_tax_codes/:id/edit(.:format)
  // function(id, options)
  edit_sales_tax_code_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"sales_tax_codes",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_service_call => /service_calls/:id/edit(.:format)
  // function(id, options)
  edit_service_call_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"service_calls",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_service_call_call_notification => /service_calls/:service_call_id/call_notifications/:id/edit(.:format)
  // function(service_call_id, id, options)
  edit_service_call_call_notification_path: Utils.route([["service_call_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"service_calls",false],[2,[7,"/",false],[2,[3,"service_call_id",false],[2,[7,"/",false],[2,[6,"call_notifications",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_service_call_customer_equipment_card => /service_calls/:service_call_id/customer_equipment_cards/:id/edit(.:format)
  // function(service_call_id, id, options)
  edit_service_call_customer_equipment_card_path: Utils.route([["service_call_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"service_calls",false],[2,[7,"/",false],[2,[3,"service_call_id",false],[2,[7,"/",false],[2,[6,"customer_equipment_cards",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_service_call_tech_sales_order => /service_calls/:service_call_id/tech_sales_orders/:id/edit(.:format)
  // function(service_call_id, id, options)
  edit_service_call_tech_sales_order_path: Utils.route([["service_call_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"service_calls",false],[2,[7,"/",false],[2,[3,"service_call_id",false],[2,[7,"/",false],[2,[6,"tech_sales_orders",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_service_contract => /service_contracts/:id/edit(.:format)
  // function(id, options)
  edit_service_contract_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"service_contracts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_service_contract_attachment => /service_contracts/:service_contract_id/attachments/:id/edit(.:format)
  // function(service_contract_id, id, options)
  edit_service_contract_attachment_path: Utils.route([["service_contract_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"service_contracts",false],[2,[7,"/",false],[2,[3,"service_contract_id",false],[2,[7,"/",false],[2,[6,"attachments",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_service_contract_service_call => /service_contracts/:service_contract_id/service_calls/:id/edit(.:format)
  // function(service_contract_id, id, options)
  edit_service_contract_service_call_path: Utils.route([["service_contract_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"service_contracts",false],[2,[7,"/",false],[2,[3,"service_contract_id",false],[2,[7,"/",false],[2,[6,"service_calls",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_service_contract_service_maintenance => /service_contracts/:service_contract_id/service_maintenances/:id/edit(.:format)
  // function(service_contract_id, id, options)
  edit_service_contract_service_maintenance_path: Utils.route([["service_contract_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"service_contracts",false],[2,[7,"/",false],[2,[3,"service_contract_id",false],[2,[7,"/",false],[2,[6,"service_maintenances",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_service_maintenance => /service_maintenances/:id/edit(.:format)
  // function(id, options)
  edit_service_maintenance_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"service_maintenances",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_service_partner_type => /service_partner_types/:id/edit(.:format)
  // function(id, options)
  edit_service_partner_type_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"service_partner_types",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_service_partner_type_business_partner => /service_partner_types/:service_partner_type_id/business_partners/:id/edit(.:format)
  // function(service_partner_type_id, id, options)
  edit_service_partner_type_business_partner_path: Utils.route([["service_partner_type_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"service_partner_types",false],[2,[7,"/",false],[2,[3,"service_partner_type_id",false],[2,[7,"/",false],[2,[6,"business_partners",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_setting => /settings/:id/edit(.:format)
  // function(id, options)
  edit_setting_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_sick_setting => /sick_settings/:id/edit(.:format)
  // function(id, options)
  edit_sick_setting_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"sick_settings",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_tax_category => /tax_categories/:id/edit(.:format)
  // function(id, options)
  edit_tax_category_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"tax_categories",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_tax_category_tax_jurisdiction => /tax_categories/:tax_category_id/tax_jurisdictions/:id/edit(.:format)
  // function(tax_category_id, id, options)
  edit_tax_category_tax_jurisdiction_path: Utils.route([["tax_category_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"tax_categories",false],[2,[7,"/",false],[2,[3,"tax_category_id",false],[2,[7,"/",false],[2,[6,"tax_jurisdictions",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_tax_code_determination => /tax_code_determinations/:id/edit(.:format)
  // function(id, options)
  edit_tax_code_determination_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"tax_code_determinations",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_tax_jurisdiction => /tax_jurisdictions/:id/edit(.:format)
  // function(id, options)
  edit_tax_jurisdiction_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"tax_jurisdictions",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_tax_jurisdiction_tax_jurisdictions_rate => /tax_jurisdictions/:tax_jurisdiction_id/tax_jurisdictions_rates/:id/edit(.:format)
  // function(tax_jurisdiction_id, id, options)
  edit_tax_jurisdiction_tax_jurisdictions_rate_path: Utils.route([["tax_jurisdiction_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"tax_jurisdictions",false],[2,[7,"/",false],[2,[3,"tax_jurisdiction_id",false],[2,[7,"/",false],[2,[6,"tax_jurisdictions_rates",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_tax_jurisdictions_rate => /tax_jurisdictions_rates/:id/edit(.:format)
  // function(id, options)
  edit_tax_jurisdictions_rate_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"tax_jurisdictions_rates",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_tech_sales_order => /tech_sales_orders/:id/edit(.:format)
  // function(id, options)
  edit_tech_sales_order_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"tech_sales_orders",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_tech_sales_order_attachment => /tech_sales_orders/:tech_sales_order_id/attachments/:id/edit(.:format)
  // function(tech_sales_order_id, id, options)
  edit_tech_sales_order_attachment_path: Utils.route([["tech_sales_order_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"tech_sales_orders",false],[2,[7,"/",false],[2,[3,"tech_sales_order_id",false],[2,[7,"/",false],[2,[6,"attachments",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_template => /templates/:id/edit(.:format)
  // function(id, options)
  edit_template_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"templates",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_user => /users/:id/edit(.:format)
  // function(id, options)
  edit_user_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_user_password => /users/password/edit(.:format)
  // function(options)
  edit_user_password_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"password",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_user_registration => /users/edit(.:format)
  // function(options)
  edit_user_registration_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// edit_vacation_setting => /vacation_settings/:id/edit(.:format)
  // function(id, options)
  edit_vacation_setting_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"vacation_settings",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_warehouse => /warehouses/:id/edit(.:format)
  // function(id, options)
  edit_warehouse_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"warehouses",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_warehouse_item => /warehouses/:warehouse_id/items/:id/edit(.:format)
  // function(warehouse_id, id, options)
  edit_warehouse_item_path: Utils.route([["warehouse_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"warehouses",false],[2,[7,"/",false],[2,[3,"warehouse_id",false],[2,[7,"/",false],[2,[6,"items",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_wizard => /wizard/:id/edit(.:format)
  // function(id, options)
  edit_wizard_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"wizard",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// emergency_phone_setting => /emergency_phone_settings/:id(.:format)
  // function(id, options)
  emergency_phone_setting_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"emergency_phone_settings",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// emergency_phone_settings => /emergency_phone_settings(.:format)
  // function(options)
  emergency_phone_settings_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"emergency_phone_settings",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// emergency_service => /emergency_services/:id(.:format)
  // function(id, options)
  emergency_service_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"emergency_services",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// emergency_services => /emergency_services(.:format)
  // function(options)
  emergency_services_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"emergency_services",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// employee => /employees/:id(.:format)
  // function(id, options)
  employee_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// employee_attachment => /employees/:employee_id/attachments/:id(.:format)
  // function(employee_id, id, options)
  employee_attachment_path: Utils.route([["employee_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[3,"employee_id",false],[2,[7,"/",false],[2,[6,"attachments",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// employee_attachments => /employees/:employee_id/attachments(.:format)
  // function(employee_id, options)
  employee_attachments_path: Utils.route([["employee_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[3,"employee_id",false],[2,[7,"/",false],[2,[6,"attachments",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// employee_award => /employees/:employee_id/awards/:id(.:format)
  // function(employee_id, id, options)
  employee_award_path: Utils.route([["employee_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[3,"employee_id",false],[2,[7,"/",false],[2,[6,"awards",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// employee_awards => /employees/:employee_id/awards(.:format)
  // function(employee_id, options)
  employee_awards_path: Utils.route([["employee_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[3,"employee_id",false],[2,[7,"/",false],[2,[6,"awards",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// employee_bonuse => /employees/:employee_id/bonuses/:id(.:format)
  // function(employee_id, id, options)
  employee_bonuse_path: Utils.route([["employee_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[3,"employee_id",false],[2,[7,"/",false],[2,[6,"bonuses",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// employee_bonuses => /employees/:employee_id/bonuses(.:format)
  // function(employee_id, options)
  employee_bonuses_path: Utils.route([["employee_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[3,"employee_id",false],[2,[7,"/",false],[2,[6,"bonuses",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// employee_education => /employees/:employee_id/educations/:id(.:format)
  // function(employee_id, id, options)
  employee_education_path: Utils.route([["employee_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[3,"employee_id",false],[2,[7,"/",false],[2,[6,"educations",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// employee_educations => /employees/:employee_id/educations(.:format)
  // function(employee_id, options)
  employee_educations_path: Utils.route([["employee_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[3,"employee_id",false],[2,[7,"/",false],[2,[6,"educations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// employee_employee_health_benefit => /employees/:employee_id/employee_health_benefits/:id(.:format)
  // function(employee_id, id, options)
  employee_employee_health_benefit_path: Utils.route([["employee_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[3,"employee_id",false],[2,[7,"/",false],[2,[6,"employee_health_benefits",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// employee_employee_health_benefits => /employees/:employee_id/employee_health_benefits(.:format)
  // function(employee_id, options)
  employee_employee_health_benefits_path: Utils.route([["employee_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[3,"employee_id",false],[2,[7,"/",false],[2,[6,"employee_health_benefits",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// employee_employee_punch => /employees/:employee_id/employee_punches/:id(.:format)
  // function(employee_id, id, options)
  employee_employee_punch_path: Utils.route([["employee_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[3,"employee_id",false],[2,[7,"/",false],[2,[6,"employee_punches",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// employee_employee_punches => /employees/:employee_id/employee_punches(.:format)
  // function(employee_id, options)
  employee_employee_punches_path: Utils.route([["employee_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[3,"employee_id",false],[2,[7,"/",false],[2,[6,"employee_punches",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// employee_employee_team => /employees/:employee_id/employee_teams/:id(.:format)
  // function(employee_id, id, options)
  employee_employee_team_path: Utils.route([["employee_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[3,"employee_id",false],[2,[7,"/",false],[2,[6,"employee_teams",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// employee_employee_teams => /employees/:employee_id/employee_teams(.:format)
  // function(employee_id, options)
  employee_employee_teams_path: Utils.route([["employee_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[3,"employee_id",false],[2,[7,"/",false],[2,[6,"employee_teams",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// employee_employee_work_day => /employees/:employee_id/employee_work_days/:id(.:format)
  // function(employee_id, id, options)
  employee_employee_work_day_path: Utils.route([["employee_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[3,"employee_id",false],[2,[7,"/",false],[2,[6,"employee_work_days",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// employee_employee_work_days => /employees/:employee_id/employee_work_days(.:format)
  // function(employee_id, options)
  employee_employee_work_days_path: Utils.route([["employee_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[3,"employee_id",false],[2,[7,"/",false],[2,[6,"employee_work_days",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// employee_employees_role => /employees/:employee_id/employees_roles/:id(.:format)
  // function(employee_id, id, options)
  employee_employees_role_path: Utils.route([["employee_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[3,"employee_id",false],[2,[7,"/",false],[2,[6,"employees_roles",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// employee_employees_roles => /employees/:employee_id/employees_roles(.:format)
  // function(employee_id, options)
  employee_employees_roles_path: Utils.route([["employee_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[3,"employee_id",false],[2,[7,"/",false],[2,[6,"employees_roles",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// employee_employment_histories => /employees/:employee_id/employment_histories(.:format)
  // function(employee_id, options)
  employee_employment_histories_path: Utils.route([["employee_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[3,"employee_id",false],[2,[7,"/",false],[2,[6,"employment_histories",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// employee_employment_history => /employees/:employee_id/employment_histories/:id(.:format)
  // function(employee_id, id, options)
  employee_employment_history_path: Utils.route([["employee_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[3,"employee_id",false],[2,[7,"/",false],[2,[6,"employment_histories",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// employee_incoming_taxes_connecticut_tax_setup => /employees/:employee_id/incoming_taxes/connecticut_tax_setups/:id(.:format)
  // function(employee_id, id, options)
  employee_incoming_taxes_connecticut_tax_setup_path: Utils.route([["employee_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[3,"employee_id",false],[2,[7,"/",false],[2,[6,"incoming_taxes",false],[2,[7,"/",false],[2,[6,"connecticut_tax_setups",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// employee_incoming_taxes_connecticut_tax_setups => /employees/:employee_id/incoming_taxes/connecticut_tax_setups(.:format)
  // function(employee_id, options)
  employee_incoming_taxes_connecticut_tax_setups_path: Utils.route([["employee_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[3,"employee_id",false],[2,[7,"/",false],[2,[6,"incoming_taxes",false],[2,[7,"/",false],[2,[6,"connecticut_tax_setups",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// employee_incoming_taxes_federal_tax_setup => /employees/:employee_id/incoming_taxes/federal_tax_setups/:id(.:format)
  // function(employee_id, id, options)
  employee_incoming_taxes_federal_tax_setup_path: Utils.route([["employee_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[3,"employee_id",false],[2,[7,"/",false],[2,[6,"incoming_taxes",false],[2,[7,"/",false],[2,[6,"federal_tax_setups",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// employee_incoming_taxes_federal_tax_setups => /employees/:employee_id/incoming_taxes/federal_tax_setups(.:format)
  // function(employee_id, options)
  employee_incoming_taxes_federal_tax_setups_path: Utils.route([["employee_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[3,"employee_id",false],[2,[7,"/",false],[2,[6,"incoming_taxes",false],[2,[7,"/",false],[2,[6,"federal_tax_setups",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// employee_incoming_taxes_new_york_tax_setup => /employees/:employee_id/incoming_taxes/new_york_tax_setups/:id(.:format)
  // function(employee_id, id, options)
  employee_incoming_taxes_new_york_tax_setup_path: Utils.route([["employee_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[3,"employee_id",false],[2,[7,"/",false],[2,[6,"incoming_taxes",false],[2,[7,"/",false],[2,[6,"new_york_tax_setups",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// employee_incoming_taxes_new_york_tax_setups => /employees/:employee_id/incoming_taxes/new_york_tax_setups(.:format)
  // function(employee_id, options)
  employee_incoming_taxes_new_york_tax_setups_path: Utils.route([["employee_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[3,"employee_id",false],[2,[7,"/",false],[2,[6,"incoming_taxes",false],[2,[7,"/",false],[2,[6,"new_york_tax_setups",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// employee_loan => /employees/:employee_id/loans/:id(.:format)
  // function(employee_id, id, options)
  employee_loan_path: Utils.route([["employee_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[3,"employee_id",false],[2,[7,"/",false],[2,[6,"loans",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// employee_loans => /employees/:employee_id/loans(.:format)
  // function(employee_id, options)
  employee_loans_path: Utils.route([["employee_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[3,"employee_id",false],[2,[7,"/",false],[2,[6,"loans",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// employee_paid_absence_period => /employees/:employee_id/paid_absence_periods/:id(.:format)
  // function(employee_id, id, options)
  employee_paid_absence_period_path: Utils.route([["employee_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[3,"employee_id",false],[2,[7,"/",false],[2,[6,"paid_absence_periods",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// employee_paid_absence_periods => /employees/:employee_id/paid_absence_periods(.:format)
  // function(employee_id, options)
  employee_paid_absence_periods_path: Utils.route([["employee_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[3,"employee_id",false],[2,[7,"/",false],[2,[6,"paid_absence_periods",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// employee_payroll => /employee_payroll(.:format)
  // function(options)
  employee_payroll_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"employee_payroll",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// employee_payroll_reports => /employees/:employee_id/payroll_reports(.:format)
  // function(employee_id, options)
  employee_payroll_reports_path: Utils.route([["employee_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[3,"employee_id",false],[2,[7,"/",false],[2,[6,"payroll_reports",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// employee_payroll_salary_change => /employees/:employee_id/payroll_salary_changes/:id(.:format)
  // function(employee_id, id, options)
  employee_payroll_salary_change_path: Utils.route([["employee_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[3,"employee_id",false],[2,[7,"/",false],[2,[6,"payroll_salary_changes",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// employee_payroll_salary_changes => /employees/:employee_id/payroll_salary_changes(.:format)
  // function(employee_id, options)
  employee_payroll_salary_changes_path: Utils.route([["employee_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[3,"employee_id",false],[2,[7,"/",false],[2,[6,"payroll_salary_changes",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// employee_previous_employment => /employees/:employee_id/previous_employments/:id(.:format)
  // function(employee_id, id, options)
  employee_previous_employment_path: Utils.route([["employee_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[3,"employee_id",false],[2,[7,"/",false],[2,[6,"previous_employments",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// employee_previous_employments => /employees/:employee_id/previous_employments(.:format)
  // function(employee_id, options)
  employee_previous_employments_path: Utils.route([["employee_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[3,"employee_id",false],[2,[7,"/",false],[2,[6,"previous_employments",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// employee_punch => /employee_punches/:id(.:format)
  // function(id, options)
  employee_punch_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"employee_punches",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// employee_punches => /employee_punches(.:format)
  // function(options)
  employee_punches_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"employee_punches",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// employee_retirement_benefit => /employees/:employee_id/retirement_benefits/:id(.:format)
  // function(employee_id, id, options)
  employee_retirement_benefit_path: Utils.route([["employee_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[3,"employee_id",false],[2,[7,"/",false],[2,[6,"retirement_benefits",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// employee_retirement_benefits => /employees/:employee_id/retirement_benefits(.:format)
  // function(employee_id, options)
  employee_retirement_benefits_path: Utils.route([["employee_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[3,"employee_id",false],[2,[7,"/",false],[2,[6,"retirement_benefits",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// employee_review => /employees/:employee_id/reviews/:id(.:format)
  // function(employee_id, id, options)
  employee_review_path: Utils.route([["employee_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[3,"employee_id",false],[2,[7,"/",false],[2,[6,"reviews",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// employee_reviews => /employees/:employee_id/reviews(.:format)
  // function(employee_id, options)
  employee_reviews_path: Utils.route([["employee_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[3,"employee_id",false],[2,[7,"/",false],[2,[6,"reviews",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// employee_salaries => /employees/:employee_id/salaries(.:format)
  // function(employee_id, options)
  employee_salaries_path: Utils.route([["employee_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[3,"employee_id",false],[2,[7,"/",false],[2,[6,"salaries",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// employee_salary => /employees/:employee_id/salaries/:id(.:format)
  // function(employee_id, id, options)
  employee_salary_path: Utils.route([["employee_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[3,"employee_id",false],[2,[7,"/",false],[2,[6,"salaries",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// employee_work_day => /employee_work_days/:id(.:format)
  // function(id, options)
  employee_work_day_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"employee_work_days",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// employee_work_day_emergency_service => /employee_work_days/:employee_work_day_id/emergency_services/:id(.:format)
  // function(employee_work_day_id, id, options)
  employee_work_day_emergency_service_path: Utils.route([["employee_work_day_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"employee_work_days",false],[2,[7,"/",false],[2,[3,"employee_work_day_id",false],[2,[7,"/",false],[2,[6,"emergency_services",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// employee_work_day_emergency_services => /employee_work_days/:employee_work_day_id/emergency_services(.:format)
  // function(employee_work_day_id, options)
  employee_work_day_emergency_services_path: Utils.route([["employee_work_day_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"employee_work_days",false],[2,[7,"/",false],[2,[3,"employee_work_day_id",false],[2,[7,"/",false],[2,[6,"emergency_services",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// employee_work_days => /employee_work_days(.:format)
  // function(options)
  employee_work_days_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"employee_work_days",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// employee_work_period => /employee_work_periods/:id(.:format)
  // function(id, options)
  employee_work_period_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"employee_work_periods",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// employee_work_periods => /employee_work_periods(.:format)
  // function(options)
  employee_work_periods_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"employee_work_periods",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// employees => /employees(.:format)
  // function(options)
  employees_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"employees",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// employees_active_api_web_v1_employees => /api/web/v1/employees/employees_active(.:format)
  // function(options)
  employees_active_api_web_v1_employees_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"web",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[6,"employees_active",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// employees_role => /employees_roles/:id(.:format)
  // function(id, options)
  employees_role_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"employees_roles",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// employees_roles => /employees_roles(.:format)
  // function(options)
  employees_roles_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"employees_roles",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// finish_api_v1_tech_sales_order => /api/v1/tech_sales_orders/:id/finish(.:format)
  // function(id, options)
  finish_api_v1_tech_sales_order_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"tech_sales_orders",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"finish",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// general_ledger => /general_ledger(.:format)
  // function(options)
  general_ledger_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"general_ledger",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// general_ledger_account => /general_ledger_accounts/:id(.:format)
  // function(id, options)
  general_ledger_account_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"general_ledger_accounts",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// general_ledger_accounts => /general_ledger_accounts(.:format)
  // function(options)
  general_ledger_accounts_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"general_ledger_accounts",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// get_actuality_code_business_partners => /business_partners/get_actuality_code(.:format)
  // function(options)
  get_actuality_code_business_partners_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"business_partners",false],[2,[7,"/",false],[2,[6,"get_actuality_code",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// gl_account_determination => /gl_account_determinations/:id(.:format)
  // function(id, options)
  gl_account_determination_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"gl_account_determinations",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// gl_account_determinations => /gl_account_determinations(.:format)
  // function(options)
  gl_account_determinations_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"gl_account_determinations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// global_retirement_setting => /global_retirement_settings/:id(.:format)
  // function(id, options)
  global_retirement_setting_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"global_retirement_settings",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// global_retirement_settings => /global_retirement_settings(.:format)
  // function(options)
  global_retirement_settings_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"global_retirement_settings",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// health_benefit_setting => /health_benefit_settings/:id(.:format)
  // function(id, options)
  health_benefit_setting_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"health_benefit_settings",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// health_benefit_setting_line => /health_benefit_setting_lines/:id(.:format)
  // function(id, options)
  health_benefit_setting_line_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"health_benefit_setting_lines",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// health_benefit_setting_lines => /health_benefit_setting_lines(.:format)
  // function(options)
  health_benefit_setting_lines_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"health_benefit_setting_lines",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// health_benefit_settings => /health_benefit_settings(.:format)
  // function(options)
  health_benefit_settings_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"health_benefit_settings",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// history_of_transactions_employee_payroll_salary_change => /employees/:employee_id/payroll_salary_changes/:id/history_of_transactions(.:format)
  // function(employee_id, id, options)
  history_of_transactions_employee_payroll_salary_change_path: Utils.route([["employee_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[3,"employee_id",false],[2,[7,"/",false],[2,[6,"payroll_salary_changes",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"history_of_transactions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// holiday => /holidays/:id(.:format)
  // function(id, options)
  holiday_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"holidays",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// holidays => /holidays(.:format)
  // function(options)
  holidays_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"holidays",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// incoming_payment => /incoming_payments/:id(.:format)
  // function(id, options)
  incoming_payment_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"incoming_payments",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// incoming_payments => /incoming_payments(.:format)
  // function(options)
  incoming_payments_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"incoming_payments",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// inventory_count => /inventory_counts/:id(.:format)
  // function(id, options)
  inventory_count_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"inventory_counts",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// inventory_count_attachment => /inventory_counts/:inventory_count_id/attachments/:id(.:format)
  // function(inventory_count_id, id, options)
  inventory_count_attachment_path: Utils.route([["inventory_count_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"inventory_counts",false],[2,[7,"/",false],[2,[3,"inventory_count_id",false],[2,[7,"/",false],[2,[6,"attachments",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// inventory_count_attachments => /inventory_counts/:inventory_count_id/attachments(.:format)
  // function(inventory_count_id, options)
  inventory_count_attachments_path: Utils.route([["inventory_count_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"inventory_counts",false],[2,[7,"/",false],[2,[3,"inventory_count_id",false],[2,[7,"/",false],[2,[6,"attachments",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// inventory_counts => /inventory_counts(.:format)
  // function(options)
  inventory_counts_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"inventory_counts",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// inventory_post => /inventory_posts/:id(.:format)
  // function(id, options)
  inventory_post_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"inventory_posts",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// inventory_post_attachment => /inventory_posts/:inventory_post_id/attachments/:id(.:format)
  // function(inventory_post_id, id, options)
  inventory_post_attachment_path: Utils.route([["inventory_post_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"inventory_posts",false],[2,[7,"/",false],[2,[3,"inventory_post_id",false],[2,[7,"/",false],[2,[6,"attachments",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// inventory_post_attachments => /inventory_posts/:inventory_post_id/attachments(.:format)
  // function(inventory_post_id, options)
  inventory_post_attachments_path: Utils.route([["inventory_post_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"inventory_posts",false],[2,[7,"/",false],[2,[3,"inventory_post_id",false],[2,[7,"/",false],[2,[6,"attachments",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// inventory_posts => /inventory_posts(.:format)
  // function(options)
  inventory_posts_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"inventory_posts",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// inventory_transfer => /inventory_transfers/:id(.:format)
  // function(id, options)
  inventory_transfer_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"inventory_transfers",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// inventory_transfers => /inventory_transfers(.:format)
  // function(options)
  inventory_transfers_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"inventory_transfers",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// item => /items/:id(.:format)
  // function(id, options)
  item_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"items",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// item_attachment => /items/:item_id/attachments/:id(.:format)
  // function(item_id, id, options)
  item_attachment_path: Utils.route([["item_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"items",false],[2,[7,"/",false],[2,[3,"item_id",false],[2,[7,"/",false],[2,[6,"attachments",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// item_attachments => /items/:item_id/attachments(.:format)
  // function(item_id, options)
  item_attachments_path: Utils.route([["item_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"items",false],[2,[7,"/",false],[2,[3,"item_id",false],[2,[7,"/",false],[2,[6,"attachments",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// item_group => /item_groups/:id(.:format)
  // function(id, options)
  item_group_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"item_groups",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// item_group_accounting_item_group => /item_groups/:item_group_id/accounting_item_groups/:id(.:format)
  // function(item_group_id, id, options)
  item_group_accounting_item_group_path: Utils.route([["item_group_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"item_groups",false],[2,[7,"/",false],[2,[3,"item_group_id",false],[2,[7,"/",false],[2,[6,"accounting_item_groups",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// item_group_accounting_item_groups => /item_groups/:item_group_id/accounting_item_groups(.:format)
  // function(item_group_id, options)
  item_group_accounting_item_groups_path: Utils.route([["item_group_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"item_groups",false],[2,[7,"/",false],[2,[3,"item_group_id",false],[2,[7,"/",false],[2,[6,"accounting_item_groups",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// item_groups => /item_groups(.:format)
  // function(options)
  item_groups_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"item_groups",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// item_groups_setup_api_web_v1_settings => /api/web/v1/settings/item_groups_setup(.:format)
  // function(options)
  item_groups_setup_api_web_v1_settings_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"web",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"item_groups_setup",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// item_groups_setup_settings => /settings/item_groups_setup(.:format)
  // function(options)
  item_groups_setup_settings_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"item_groups_setup",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// item_stock => /items/:item_id/stocks/:id(.:format)
  // function(item_id, id, options)
  item_stock_path: Utils.route([["item_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"items",false],[2,[7,"/",false],[2,[3,"item_id",false],[2,[7,"/",false],[2,[6,"stocks",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// item_stocks => /items/:item_id/stocks(.:format)
  // function(item_id, options)
  item_stocks_path: Utils.route([["item_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"items",false],[2,[7,"/",false],[2,[3,"item_id",false],[2,[7,"/",false],[2,[6,"stocks",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// items => /items(.:format)
  // function(options)
  items_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"items",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// journal_entries => /journal_entries(.:format)
  // function(options)
  journal_entries_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"journal_entries",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// journal_entry => /journal_entries/:id(.:format)
  // function(id, options)
  journal_entry_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"journal_entries",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// loan => /loans/:id(.:format)
  // function(id, options)
  loan_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"loans",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// loans => /loans(.:format)
  // function(options)
  loans_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"loans",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// math_calculation_formula_api_web_v1_health_benefit_setting_health_benefit_setting_lines => /api/web/v1/health_benefit_settings/:health_benefit_setting_id/health_benefit_setting_lines/math_calculation_formula(.:format)
  // function(health_benefit_setting_id, options)
  math_calculation_formula_api_web_v1_health_benefit_setting_health_benefit_setting_lines_path: Utils.route([["health_benefit_setting_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"web",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"health_benefit_settings",false],[2,[7,"/",false],[2,[3,"health_benefit_setting_id",false],[2,[7,"/",false],[2,[6,"health_benefit_setting_lines",false],[2,[7,"/",false],[2,[6,"math_calculation_formula",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// missed_days_api_web_v1_employee_work_days => /api/web/v1/employee_work_days/missed_days(.:format)
  // function(options)
  missed_days_api_web_v1_employee_work_days_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"web",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"employee_work_days",false],[2,[7,"/",false],[2,[6,"missed_days",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// missed_days_employee_work_days => /employee_work_days/missed_days(.:format)
  // function(options)
  missed_days_employee_work_days_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"employee_work_days",false],[2,[7,"/",false],[2,[6,"missed_days",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// mobile_defaults_api_web_v1_settings => /api/web/v1/settings/mobile_defaults(.:format)
  // function(options)
  mobile_defaults_api_web_v1_settings_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"web",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"mobile_defaults",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// mobile_defaults_settings => /settings/mobile_defaults(.:format)
  // function(options)
  mobile_defaults_settings_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"mobile_defaults",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_annual_service => /annual_services/new(.:format)
  // function(options)
  new_annual_service_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"annual_services",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_annual_service_annual_service_date => /annual_services/:annual_service_id/annual_service_dates/new(.:format)
  // function(annual_service_id, options)
  new_annual_service_annual_service_date_path: Utils.route([["annual_service_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"annual_services",false],[2,[7,"/",false],[2,[3,"annual_service_id",false],[2,[7,"/",false],[2,[6,"annual_service_dates",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_ap_credit_memo => /ap_credit_memos/new(.:format)
  // function(options)
  new_ap_credit_memo_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"ap_credit_memos",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_ap_down_payment_invoice => /ap_down_payment_invoices/new(.:format)
  // function(options)
  new_ap_down_payment_invoice_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"ap_down_payment_invoices",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_ap_invoice => /ap_invoices/new(.:format)
  // function(options)
  new_ap_invoice_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"ap_invoices",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_api_v1_tech_sales_order => /api/v1/tech_sales_orders/new(.:format)
  // function(options)
  new_api_v1_tech_sales_order_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"tech_sales_orders",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_api_web_v1_common_document => /api/web/v1/common_documents/new(.:format)
  // function(options)
  new_api_web_v1_common_document_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"web",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"common_documents",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// new_api_web_v1_company => /api/web/v1/companies/new(.:format)
  // function(options)
  new_api_web_v1_company_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"web",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"companies",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// new_api_web_v1_company_retirement_setting => /api/web/v1/company_retirement_settings/new(.:format)
  // function(options)
  new_api_web_v1_company_retirement_setting_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"web",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"company_retirement_settings",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// new_api_web_v1_company_retirement_setting_company_retirement_setting_line => /api/web/v1/company_retirement_settings/:company_retirement_setting_id/company_retirement_setting_lines/new(.:format)
  // function(company_retirement_setting_id, options)
  new_api_web_v1_company_retirement_setting_company_retirement_setting_line_path: Utils.route([["company_retirement_setting_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"web",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"company_retirement_settings",false],[2,[7,"/",false],[2,[3,"company_retirement_setting_id",false],[2,[7,"/",false],[2,[6,"company_retirement_setting_lines",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// new_api_web_v1_emergency_phone_setting => /api/web/v1/emergency_phone_settings/new(.:format)
  // function(options)
  new_api_web_v1_emergency_phone_setting_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"web",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"emergency_phone_settings",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// new_api_web_v1_employee_work_day => /api/web/v1/employee_work_days/new(.:format)
  // function(options)
  new_api_web_v1_employee_work_day_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"web",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"employee_work_days",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// new_api_web_v1_global_retirement_setting => /api/web/v1/global_retirement_settings/new(.:format)
  // function(options)
  new_api_web_v1_global_retirement_setting_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"web",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"global_retirement_settings",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// new_api_web_v1_health_benefit_setting => /api/web/v1/health_benefit_settings/new(.:format)
  // function(options)
  new_api_web_v1_health_benefit_setting_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"web",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"health_benefit_settings",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// new_api_web_v1_health_benefit_setting_health_benefit_setting_line => /api/web/v1/health_benefit_settings/:health_benefit_setting_id/health_benefit_setting_lines/new(.:format)
  // function(health_benefit_setting_id, options)
  new_api_web_v1_health_benefit_setting_health_benefit_setting_line_path: Utils.route([["health_benefit_setting_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"web",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"health_benefit_settings",false],[2,[7,"/",false],[2,[3,"health_benefit_setting_id",false],[2,[7,"/",false],[2,[6,"health_benefit_setting_lines",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// new_api_web_v1_payroll_setting => /api/web/v1/payroll_settings/new(.:format)
  // function(options)
  new_api_web_v1_payroll_setting_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"web",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"payroll_settings",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// new_api_web_v1_payroll_tax_setup_add_on => /api/web/v1/payroll_tax_setup_add_ons/new(.:format)
  // function(options)
  new_api_web_v1_payroll_tax_setup_add_on_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"web",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"payroll_tax_setup_add_ons",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// new_api_web_v1_setting => /api/web/v1/settings/new(.:format)
  // function(options)
  new_api_web_v1_setting_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"web",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// new_api_web_v1_sick_setting => /api/web/v1/sick_settings/new(.:format)
  // function(options)
  new_api_web_v1_sick_setting_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"web",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"sick_settings",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// new_api_web_v1_vacation_setting => /api/web/v1/vacation_settings/new(.:format)
  // function(options)
  new_api_web_v1_vacation_setting_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"web",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"vacation_settings",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// new_ar_credit_memo => /ar_credit_memos/new(.:format)
  // function(options)
  new_ar_credit_memo_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"ar_credit_memos",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_ar_down_payment_invoice => /ar_down_payment_invoices/new(.:format)
  // function(options)
  new_ar_down_payment_invoice_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"ar_down_payment_invoices",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_ar_invoice => /ar_invoices/new(.:format)
  // function(options)
  new_ar_invoice_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"ar_invoices",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_attachment => /attachments/new(.:format)
  // function(options)
  new_attachment_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"attachments",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_award => /awards/new(.:format)
  // function(options)
  new_award_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"awards",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_bill => /bills/new(.:format)
  // function(options)
  new_bill_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"bills",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_bonuse => /bonuses/new(.:format)
  // function(options)
  new_bonuse_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"bonuses",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_business_partner => /business_partners/new(.:format)
  // function(options)
  new_business_partner_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"business_partners",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_business_partner_ap_down_payment_invoice => /business_partners/:business_partner_id/ap_down_payment_invoices/new(.:format)
  // function(business_partner_id, options)
  new_business_partner_ap_down_payment_invoice_path: Utils.route([["business_partner_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"business_partners",false],[2,[7,"/",false],[2,[3,"business_partner_id",false],[2,[7,"/",false],[2,[6,"ap_down_payment_invoices",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_business_partner_ap_invoice => /business_partners/:business_partner_id/ap_invoices/new(.:format)
  // function(business_partner_id, options)
  new_business_partner_ap_invoice_path: Utils.route([["business_partner_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"business_partners",false],[2,[7,"/",false],[2,[3,"business_partner_id",false],[2,[7,"/",false],[2,[6,"ap_invoices",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_business_partner_ar_down_payment_invoice => /business_partners/:business_partner_id/ar_down_payment_invoices/new(.:format)
  // function(business_partner_id, options)
  new_business_partner_ar_down_payment_invoice_path: Utils.route([["business_partner_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"business_partners",false],[2,[7,"/",false],[2,[3,"business_partner_id",false],[2,[7,"/",false],[2,[6,"ar_down_payment_invoices",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_business_partner_ar_invoice => /business_partners/:business_partner_id/ar_invoices/new(.:format)
  // function(business_partner_id, options)
  new_business_partner_ar_invoice_path: Utils.route([["business_partner_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"business_partners",false],[2,[7,"/",false],[2,[3,"business_partner_id",false],[2,[7,"/",false],[2,[6,"ar_invoices",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_business_partner_attachment => /business_partners/:business_partner_id/attachments/new(.:format)
  // function(business_partner_id, options)
  new_business_partner_attachment_path: Utils.route([["business_partner_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"business_partners",false],[2,[7,"/",false],[2,[3,"business_partner_id",false],[2,[7,"/",false],[2,[6,"attachments",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_business_partner_contact_person => /business_partners/:business_partner_id/contact_people/new(.:format)
  // function(business_partner_id, options)
  new_business_partner_contact_person_path: Utils.route([["business_partner_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"business_partners",false],[2,[7,"/",false],[2,[3,"business_partner_id",false],[2,[7,"/",false],[2,[6,"contact_people",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_business_partner_customer_equipment_card => /business_partners/:business_partner_id/customer_equipment_cards/new(.:format)
  // function(business_partner_id, options)
  new_business_partner_customer_equipment_card_path: Utils.route([["business_partner_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"business_partners",false],[2,[7,"/",false],[2,[3,"business_partner_id",false],[2,[7,"/",false],[2,[6,"customer_equipment_cards",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_business_partner_partner_billing_address => /business_partners/:business_partner_id/partner_billing_addresses/new(.:format)
  // function(business_partner_id, options)
  new_business_partner_partner_billing_address_path: Utils.route([["business_partner_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"business_partners",false],[2,[7,"/",false],[2,[3,"business_partner_id",false],[2,[7,"/",false],[2,[6,"partner_billing_addresses",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_business_partner_partner_shipping_address => /business_partners/:business_partner_id/partner_shipping_addresses/new(.:format)
  // function(business_partner_id, options)
  new_business_partner_partner_shipping_address_path: Utils.route([["business_partner_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"business_partners",false],[2,[7,"/",false],[2,[3,"business_partner_id",false],[2,[7,"/",false],[2,[6,"partner_shipping_addresses",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_business_partner_purchase_order => /business_partners/:business_partner_id/purchase_orders/new(.:format)
  // function(business_partner_id, options)
  new_business_partner_purchase_order_path: Utils.route([["business_partner_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"business_partners",false],[2,[7,"/",false],[2,[3,"business_partner_id",false],[2,[7,"/",false],[2,[6,"purchase_orders",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_business_partner_sales_order => /business_partners/:business_partner_id/sales_orders/new(.:format)
  // function(business_partner_id, options)
  new_business_partner_sales_order_path: Utils.route([["business_partner_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"business_partners",false],[2,[7,"/",false],[2,[3,"business_partner_id",false],[2,[7,"/",false],[2,[6,"sales_orders",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_business_partners_payment => /business_partners_payments/new(.:format)
  // function(options)
  new_business_partners_payment_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"business_partners_payments",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_cash_basis_journal_entry => /cash_basis_journal_entries/new(.:format)
  // function(options)
  new_cash_basis_journal_entry_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"cash_basis_journal_entries",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_communication_manufacturer => /communication_manufacturers/new(.:format)
  // function(options)
  new_communication_manufacturer_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"communication_manufacturers",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_communication_manufacturer_communication_type => /communication_manufacturers/:communication_manufacturer_id/communication_types/new(.:format)
  // function(communication_manufacturer_id, options)
  new_communication_manufacturer_communication_type_path: Utils.route([["communication_manufacturer_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"communication_manufacturers",false],[2,[7,"/",false],[2,[3,"communication_manufacturer_id",false],[2,[7,"/",false],[2,[6,"communication_types",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_company => /companies/new(.:format)
  // function(options)
  new_company_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"companies",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_company_clock_device => /companies/:company_id/clock_devices/new(.:format)
  // function(company_id, options)
  new_company_clock_device_path: Utils.route([["company_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"companies",false],[2,[7,"/",false],[2,[3,"company_id",false],[2,[7,"/",false],[2,[6,"clock_devices",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_company_retirement_setting => /company_retirement_settings/new(.:format)
  // function(options)
  new_company_retirement_setting_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"company_retirement_settings",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_contact_person => /contact_people/new(.:format)
  // function(options)
  new_contact_person_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"contact_people",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_currency => /currencies/new(.:format)
  // function(options)
  new_currency_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"currencies",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_customer_equipment_card => /customer_equipment_cards/new(.:format)
  // function(options)
  new_customer_equipment_card_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"customer_equipment_cards",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_customer_equipment_card_attachment => /customer_equipment_cards/:customer_equipment_card_id/attachments/new(.:format)
  // function(customer_equipment_card_id, options)
  new_customer_equipment_card_attachment_path: Utils.route([["customer_equipment_card_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"customer_equipment_cards",false],[2,[7,"/",false],[2,[3,"customer_equipment_card_id",false],[2,[7,"/",false],[2,[6,"attachments",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_customer_equipment_card_employee => /customer_equipment_cards/:customer_equipment_card_id/employees/new(.:format)
  // function(customer_equipment_card_id, options)
  new_customer_equipment_card_employee_path: Utils.route([["customer_equipment_card_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"customer_equipment_cards",false],[2,[7,"/",false],[2,[3,"customer_equipment_card_id",false],[2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_customer_equipment_card_service_call => /customer_equipment_cards/:customer_equipment_card_id/service_calls/new(.:format)
  // function(customer_equipment_card_id, options)
  new_customer_equipment_card_service_call_path: Utils.route([["customer_equipment_card_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"customer_equipment_cards",false],[2,[7,"/",false],[2,[3,"customer_equipment_card_id",false],[2,[7,"/",false],[2,[6,"service_calls",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_customer_equipment_card_service_contract => /customer_equipment_cards/:customer_equipment_card_id/service_contracts/new(.:format)
  // function(customer_equipment_card_id, options)
  new_customer_equipment_card_service_contract_path: Utils.route([["customer_equipment_card_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"customer_equipment_cards",false],[2,[7,"/",false],[2,[3,"customer_equipment_card_id",false],[2,[7,"/",false],[2,[6,"service_contracts",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_customer_equipment_card_service_maintenance => /customer_equipment_cards/:customer_equipment_card_id/service_maintenances/new(.:format)
  // function(customer_equipment_card_id, options)
  new_customer_equipment_card_service_maintenance_path: Utils.route([["customer_equipment_card_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"customer_equipment_cards",false],[2,[7,"/",false],[2,[3,"customer_equipment_card_id",false],[2,[7,"/",false],[2,[6,"service_maintenances",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_deposit => /deposits/new(.:format)
  // function(options)
  new_deposit_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"deposits",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_determination_line => /determination_lines/new(.:format)
  // function(options)
  new_determination_line_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"determination_lines",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_document_numbering => /document_numberings/new(.:format)
  // function(options)
  new_document_numbering_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"document_numberings",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_emergency_phone_setting => /emergency_phone_settings/new(.:format)
  // function(options)
  new_emergency_phone_setting_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"emergency_phone_settings",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_emergency_service => /emergency_services/new(.:format)
  // function(options)
  new_emergency_service_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"emergency_services",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_employee => /employees/new(.:format)
  // function(options)
  new_employee_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_employee_attachment => /employees/:employee_id/attachments/new(.:format)
  // function(employee_id, options)
  new_employee_attachment_path: Utils.route([["employee_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[3,"employee_id",false],[2,[7,"/",false],[2,[6,"attachments",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_employee_award => /employees/:employee_id/awards/new(.:format)
  // function(employee_id, options)
  new_employee_award_path: Utils.route([["employee_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[3,"employee_id",false],[2,[7,"/",false],[2,[6,"awards",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_employee_bonuse => /employees/:employee_id/bonuses/new(.:format)
  // function(employee_id, options)
  new_employee_bonuse_path: Utils.route([["employee_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[3,"employee_id",false],[2,[7,"/",false],[2,[6,"bonuses",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_employee_education => /employees/:employee_id/educations/new(.:format)
  // function(employee_id, options)
  new_employee_education_path: Utils.route([["employee_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[3,"employee_id",false],[2,[7,"/",false],[2,[6,"educations",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_employee_employee_health_benefit => /employees/:employee_id/employee_health_benefits/new(.:format)
  // function(employee_id, options)
  new_employee_employee_health_benefit_path: Utils.route([["employee_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[3,"employee_id",false],[2,[7,"/",false],[2,[6,"employee_health_benefits",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_employee_employee_punch => /employees/:employee_id/employee_punches/new(.:format)
  // function(employee_id, options)
  new_employee_employee_punch_path: Utils.route([["employee_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[3,"employee_id",false],[2,[7,"/",false],[2,[6,"employee_punches",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_employee_employee_team => /employees/:employee_id/employee_teams/new(.:format)
  // function(employee_id, options)
  new_employee_employee_team_path: Utils.route([["employee_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[3,"employee_id",false],[2,[7,"/",false],[2,[6,"employee_teams",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_employee_employee_work_day => /employees/:employee_id/employee_work_days/new(.:format)
  // function(employee_id, options)
  new_employee_employee_work_day_path: Utils.route([["employee_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[3,"employee_id",false],[2,[7,"/",false],[2,[6,"employee_work_days",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_employee_employees_role => /employees/:employee_id/employees_roles/new(.:format)
  // function(employee_id, options)
  new_employee_employees_role_path: Utils.route([["employee_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[3,"employee_id",false],[2,[7,"/",false],[2,[6,"employees_roles",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_employee_employment_history => /employees/:employee_id/employment_histories/new(.:format)
  // function(employee_id, options)
  new_employee_employment_history_path: Utils.route([["employee_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[3,"employee_id",false],[2,[7,"/",false],[2,[6,"employment_histories",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_employee_incoming_taxes_connecticut_tax_setup => /employees/:employee_id/incoming_taxes/connecticut_tax_setups/new(.:format)
  // function(employee_id, options)
  new_employee_incoming_taxes_connecticut_tax_setup_path: Utils.route([["employee_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[3,"employee_id",false],[2,[7,"/",false],[2,[6,"incoming_taxes",false],[2,[7,"/",false],[2,[6,"connecticut_tax_setups",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// new_employee_incoming_taxes_federal_tax_setup => /employees/:employee_id/incoming_taxes/federal_tax_setups/new(.:format)
  // function(employee_id, options)
  new_employee_incoming_taxes_federal_tax_setup_path: Utils.route([["employee_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[3,"employee_id",false],[2,[7,"/",false],[2,[6,"incoming_taxes",false],[2,[7,"/",false],[2,[6,"federal_tax_setups",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// new_employee_incoming_taxes_new_york_tax_setup => /employees/:employee_id/incoming_taxes/new_york_tax_setups/new(.:format)
  // function(employee_id, options)
  new_employee_incoming_taxes_new_york_tax_setup_path: Utils.route([["employee_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[3,"employee_id",false],[2,[7,"/",false],[2,[6,"incoming_taxes",false],[2,[7,"/",false],[2,[6,"new_york_tax_setups",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// new_employee_loan => /employees/:employee_id/loans/new(.:format)
  // function(employee_id, options)
  new_employee_loan_path: Utils.route([["employee_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[3,"employee_id",false],[2,[7,"/",false],[2,[6,"loans",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_employee_paid_absence_period => /employees/:employee_id/paid_absence_periods/new(.:format)
  // function(employee_id, options)
  new_employee_paid_absence_period_path: Utils.route([["employee_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[3,"employee_id",false],[2,[7,"/",false],[2,[6,"paid_absence_periods",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_employee_payroll_salary_change => /employees/:employee_id/payroll_salary_changes/new(.:format)
  // function(employee_id, options)
  new_employee_payroll_salary_change_path: Utils.route([["employee_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[3,"employee_id",false],[2,[7,"/",false],[2,[6,"payroll_salary_changes",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_employee_previous_employment => /employees/:employee_id/previous_employments/new(.:format)
  // function(employee_id, options)
  new_employee_previous_employment_path: Utils.route([["employee_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[3,"employee_id",false],[2,[7,"/",false],[2,[6,"previous_employments",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_employee_punch => /employee_punches/new(.:format)
  // function(options)
  new_employee_punch_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"employee_punches",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_employee_retirement_benefit => /employees/:employee_id/retirement_benefits/new(.:format)
  // function(employee_id, options)
  new_employee_retirement_benefit_path: Utils.route([["employee_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[3,"employee_id",false],[2,[7,"/",false],[2,[6,"retirement_benefits",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_employee_review => /employees/:employee_id/reviews/new(.:format)
  // function(employee_id, options)
  new_employee_review_path: Utils.route([["employee_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[3,"employee_id",false],[2,[7,"/",false],[2,[6,"reviews",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_employee_salary => /employees/:employee_id/salaries/new(.:format)
  // function(employee_id, options)
  new_employee_salary_path: Utils.route([["employee_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[3,"employee_id",false],[2,[7,"/",false],[2,[6,"salaries",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_employee_work_day => /employee_work_days/new(.:format)
  // function(options)
  new_employee_work_day_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"employee_work_days",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_employee_work_day_emergency_service => /employee_work_days/:employee_work_day_id/emergency_services/new(.:format)
  // function(employee_work_day_id, options)
  new_employee_work_day_emergency_service_path: Utils.route([["employee_work_day_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"employee_work_days",false],[2,[7,"/",false],[2,[3,"employee_work_day_id",false],[2,[7,"/",false],[2,[6,"emergency_services",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_employee_work_period => /employee_work_periods/new(.:format)
  // function(options)
  new_employee_work_period_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"employee_work_periods",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_employees_role => /employees_roles/new(.:format)
  // function(options)
  new_employees_role_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"employees_roles",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_general_ledger_account => /general_ledger_accounts/new(.:format)
  // function(options)
  new_general_ledger_account_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"general_ledger_accounts",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_gl_account_determination => /gl_account_determinations/new(.:format)
  // function(options)
  new_gl_account_determination_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"gl_account_determinations",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_global_retirement_setting => /global_retirement_settings/new(.:format)
  // function(options)
  new_global_retirement_setting_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"global_retirement_settings",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_health_benefit_setting => /health_benefit_settings/new(.:format)
  // function(options)
  new_health_benefit_setting_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"health_benefit_settings",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_health_benefit_setting_line => /health_benefit_setting_lines/new(.:format)
  // function(options)
  new_health_benefit_setting_line_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"health_benefit_setting_lines",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_holiday => /holidays/new(.:format)
  // function(options)
  new_holiday_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"holidays",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_incoming_payment => /incoming_payments/new(.:format)
  // function(options)
  new_incoming_payment_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"incoming_payments",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_inventory_count => /inventory_counts/new(.:format)
  // function(options)
  new_inventory_count_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"inventory_counts",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_inventory_count_attachment => /inventory_counts/:inventory_count_id/attachments/new(.:format)
  // function(inventory_count_id, options)
  new_inventory_count_attachment_path: Utils.route([["inventory_count_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"inventory_counts",false],[2,[7,"/",false],[2,[3,"inventory_count_id",false],[2,[7,"/",false],[2,[6,"attachments",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_inventory_post => /inventory_posts/new(.:format)
  // function(options)
  new_inventory_post_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"inventory_posts",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_inventory_post_attachment => /inventory_posts/:inventory_post_id/attachments/new(.:format)
  // function(inventory_post_id, options)
  new_inventory_post_attachment_path: Utils.route([["inventory_post_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"inventory_posts",false],[2,[7,"/",false],[2,[3,"inventory_post_id",false],[2,[7,"/",false],[2,[6,"attachments",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_inventory_transfer => /inventory_transfers/new(.:format)
  // function(options)
  new_inventory_transfer_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"inventory_transfers",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_item => /items/new(.:format)
  // function(options)
  new_item_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"items",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_item_attachment => /items/:item_id/attachments/new(.:format)
  // function(item_id, options)
  new_item_attachment_path: Utils.route([["item_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"items",false],[2,[7,"/",false],[2,[3,"item_id",false],[2,[7,"/",false],[2,[6,"attachments",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_item_group => /item_groups/new(.:format)
  // function(options)
  new_item_group_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"item_groups",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_item_group_accounting_item_group => /item_groups/:item_group_id/accounting_item_groups/new(.:format)
  // function(item_group_id, options)
  new_item_group_accounting_item_group_path: Utils.route([["item_group_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"item_groups",false],[2,[7,"/",false],[2,[3,"item_group_id",false],[2,[7,"/",false],[2,[6,"accounting_item_groups",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_item_stock => /items/:item_id/stocks/new(.:format)
  // function(item_id, options)
  new_item_stock_path: Utils.route([["item_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"items",false],[2,[7,"/",false],[2,[3,"item_id",false],[2,[7,"/",false],[2,[6,"stocks",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_journal_entry => /journal_entries/new(.:format)
  // function(options)
  new_journal_entry_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"journal_entries",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_loan => /loans/new(.:format)
  // function(options)
  new_loan_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"loans",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_operator_manufacturer => /operator_manufacturers/new(.:format)
  // function(options)
  new_operator_manufacturer_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"operator_manufacturers",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_operator_manufacturer_operator_type => /operator_manufacturers/:operator_manufacturer_id/operator_types/new(.:format)
  // function(operator_manufacturer_id, options)
  new_operator_manufacturer_operator_type_path: Utils.route([["operator_manufacturer_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"operator_manufacturers",false],[2,[7,"/",false],[2,[3,"operator_manufacturer_id",false],[2,[7,"/",false],[2,[6,"operator_types",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_outgoing_payment => /outgoing_payments/new(.:format)
  // function(options)
  new_outgoing_payment_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"outgoing_payments",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_paid_absence_period => /paid_absence_periods/new(.:format)
  // function(options)
  new_paid_absence_period_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"paid_absence_periods",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_partner_billing_address => /partner_billing_addresses/new(.:format)
  // function(options)
  new_partner_billing_address_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"partner_billing_addresses",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_partner_shipping_address => /partner_shipping_addresses/new(.:format)
  // function(options)
  new_partner_shipping_address_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"partner_shipping_addresses",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_payment => /payments/new(.:format)
  // function(options)
  new_payment_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"payments",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_payment_business_partners_payment => /payments/:payment_id/business_partners_payments/new(.:format)
  // function(payment_id, options)
  new_payment_business_partners_payment_path: Utils.route([["payment_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"payments",false],[2,[7,"/",false],[2,[3,"payment_id",false],[2,[7,"/",false],[2,[6,"business_partners_payments",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_payment_method => /payment_methods/new(.:format)
  // function(options)
  new_payment_method_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"payment_methods",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_payment_payment_method => /payments/:payment_id/payment_methods/new(.:format)
  // function(payment_id, options)
  new_payment_payment_method_path: Utils.route([["payment_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"payments",false],[2,[7,"/",false],[2,[3,"payment_id",false],[2,[7,"/",false],[2,[6,"payment_methods",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_payment_recommendation_report => /payments/:payment_id/recommendation_reports/new(.:format)
  // function(payment_id, options)
  new_payment_recommendation_report_path: Utils.route([["payment_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"payments",false],[2,[7,"/",false],[2,[3,"payment_id",false],[2,[7,"/",false],[2,[6,"recommendation_reports",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_payment_wizard => /payments/:payment_id/wizard/new(.:format)
  // function(payment_id, options)
  new_payment_wizard_path: Utils.route([["payment_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"payments",false],[2,[7,"/",false],[2,[3,"payment_id",false],[2,[7,"/",false],[2,[6,"wizard",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_payroll_report => /payroll_reports/new(.:format)
  // function(options)
  new_payroll_report_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"payroll_reports",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_payroll_salary_change => /payroll_salary_changes/new(.:format)
  // function(options)
  new_payroll_salary_change_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"payroll_salary_changes",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_payroll_salary_change_reason => /payroll_salary_change_reasons/new(.:format)
  // function(options)
  new_payroll_salary_change_reason_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"payroll_salary_change_reasons",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_payroll_setting => /payroll_settings/new(.:format)
  // function(options)
  new_payroll_setting_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"payroll_settings",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_payroll_tax_setup_add_on => /payroll_tax_setup_add_ons/new(.:format)
  // function(options)
  new_payroll_tax_setup_add_on_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"payroll_tax_setup_add_ons",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_permission => /permissions/new(.:format)
  // function(options)
  new_permission_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"permissions",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_posting_period => /posting_periods/new(.:format)
  // function(options)
  new_posting_period_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"posting_periods",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_production_order => /production_orders/new(.:format)
  // function(options)
  new_production_order_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"production_orders",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_production_order_attachment => /production_orders/:production_order_id/attachments/new(.:format)
  // function(production_order_id, options)
  new_production_order_attachment_path: Utils.route([["production_order_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"production_orders",false],[2,[7,"/",false],[2,[3,"production_order_id",false],[2,[7,"/",false],[2,[6,"attachments",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_production_order_line => /production_order_lines/new(.:format)
  // function(options)
  new_production_order_line_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"production_order_lines",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_production_order_production_order_line => /production_orders/:production_order_id/production_order_lines/new(.:format)
  // function(production_order_id, options)
  new_production_order_production_order_line_path: Utils.route([["production_order_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"production_orders",false],[2,[7,"/",false],[2,[3,"production_order_id",false],[2,[7,"/",false],[2,[6,"production_order_lines",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_property => /properties/new(.:format)
  // function(options)
  new_property_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"properties",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_purchase_order => /purchase_orders/new(.:format)
  // function(options)
  new_purchase_order_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"purchase_orders",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_purchase_quotation => /purchase_quotations/new(.:format)
  // function(options)
  new_purchase_quotation_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"purchase_quotations",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_rails_conductor_inbound_email => /rails/conductor/action_mailbox/inbound_emails/new(.:format)
  // function(options)
  new_rails_conductor_inbound_email_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// new_receipt_production => /receipt_productions/new(.:format)
  // function(options)
  new_receipt_production_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"receipt_productions",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_receipt_production_attachment => /receipt_productions/:receipt_production_id/attachments/new(.:format)
  // function(receipt_production_id, options)
  new_receipt_production_attachment_path: Utils.route([["receipt_production_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"receipt_productions",false],[2,[7,"/",false],[2,[3,"receipt_production_id",false],[2,[7,"/",false],[2,[6,"attachments",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_recommendation_report => /recommendation_reports/new(.:format)
  // function(options)
  new_recommendation_report_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"recommendation_reports",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_role => /roles/new(.:format)
  // function(options)
  new_role_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"roles",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_salary => /salaries/new(.:format)
  // function(options)
  new_salary_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"salaries",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_sales_order => /sales_orders/new(.:format)
  // function(options)
  new_sales_order_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"sales_orders",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_sales_order_tech_sales_order => /sales_orders/:sales_order_id/tech_sales_orders/new(.:format)
  // function(sales_order_id, options)
  new_sales_order_tech_sales_order_path: Utils.route([["sales_order_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"sales_orders",false],[2,[7,"/",false],[2,[3,"sales_order_id",false],[2,[7,"/",false],[2,[6,"tech_sales_orders",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_sales_quotation => /sales_quotations/new(.:format)
  // function(options)
  new_sales_quotation_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"sales_quotations",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_sales_tax_code => /sales_tax_codes/new(.:format)
  // function(options)
  new_sales_tax_code_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"sales_tax_codes",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_service_call => /service_calls/new(.:format)
  // function(options)
  new_service_call_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"service_calls",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_service_call_call_notification => /service_calls/:service_call_id/call_notifications/new(.:format)
  // function(service_call_id, options)
  new_service_call_call_notification_path: Utils.route([["service_call_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"service_calls",false],[2,[7,"/",false],[2,[3,"service_call_id",false],[2,[7,"/",false],[2,[6,"call_notifications",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_service_call_customer_equipment_card => /service_calls/:service_call_id/customer_equipment_cards/new(.:format)
  // function(service_call_id, options)
  new_service_call_customer_equipment_card_path: Utils.route([["service_call_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"service_calls",false],[2,[7,"/",false],[2,[3,"service_call_id",false],[2,[7,"/",false],[2,[6,"customer_equipment_cards",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_service_call_tech_sales_order => /service_calls/:service_call_id/tech_sales_orders/new(.:format)
  // function(service_call_id, options)
  new_service_call_tech_sales_order_path: Utils.route([["service_call_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"service_calls",false],[2,[7,"/",false],[2,[3,"service_call_id",false],[2,[7,"/",false],[2,[6,"tech_sales_orders",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_service_contract => /service_contracts/new(.:format)
  // function(options)
  new_service_contract_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"service_contracts",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_service_contract_attachment => /service_contracts/:service_contract_id/attachments/new(.:format)
  // function(service_contract_id, options)
  new_service_contract_attachment_path: Utils.route([["service_contract_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"service_contracts",false],[2,[7,"/",false],[2,[3,"service_contract_id",false],[2,[7,"/",false],[2,[6,"attachments",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_service_contract_service_call => /service_contracts/:service_contract_id/service_calls/new(.:format)
  // function(service_contract_id, options)
  new_service_contract_service_call_path: Utils.route([["service_contract_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"service_contracts",false],[2,[7,"/",false],[2,[3,"service_contract_id",false],[2,[7,"/",false],[2,[6,"service_calls",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_service_contract_service_maintenance => /service_contracts/:service_contract_id/service_maintenances/new(.:format)
  // function(service_contract_id, options)
  new_service_contract_service_maintenance_path: Utils.route([["service_contract_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"service_contracts",false],[2,[7,"/",false],[2,[3,"service_contract_id",false],[2,[7,"/",false],[2,[6,"service_maintenances",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_service_maintenance => /service_maintenances/new(.:format)
  // function(options)
  new_service_maintenance_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"service_maintenances",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_service_partner_type => /service_partner_types/new(.:format)
  // function(options)
  new_service_partner_type_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"service_partner_types",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_service_partner_type_business_partner => /service_partner_types/:service_partner_type_id/business_partners/new(.:format)
  // function(service_partner_type_id, options)
  new_service_partner_type_business_partner_path: Utils.route([["service_partner_type_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"service_partner_types",false],[2,[7,"/",false],[2,[3,"service_partner_type_id",false],[2,[7,"/",false],[2,[6,"business_partners",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_setting => /settings/new(.:format)
  // function(options)
  new_setting_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_sick_setting => /sick_settings/new(.:format)
  // function(options)
  new_sick_setting_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"sick_settings",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_tax_category => /tax_categories/new(.:format)
  // function(options)
  new_tax_category_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"tax_categories",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_tax_category_tax_jurisdiction => /tax_categories/:tax_category_id/tax_jurisdictions/new(.:format)
  // function(tax_category_id, options)
  new_tax_category_tax_jurisdiction_path: Utils.route([["tax_category_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"tax_categories",false],[2,[7,"/",false],[2,[3,"tax_category_id",false],[2,[7,"/",false],[2,[6,"tax_jurisdictions",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_tax_code_determination => /tax_code_determinations/new(.:format)
  // function(options)
  new_tax_code_determination_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"tax_code_determinations",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_tax_jurisdiction => /tax_jurisdictions/new(.:format)
  // function(options)
  new_tax_jurisdiction_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"tax_jurisdictions",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_tax_jurisdiction_tax_jurisdictions_rate => /tax_jurisdictions/:tax_jurisdiction_id/tax_jurisdictions_rates/new(.:format)
  // function(tax_jurisdiction_id, options)
  new_tax_jurisdiction_tax_jurisdictions_rate_path: Utils.route([["tax_jurisdiction_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"tax_jurisdictions",false],[2,[7,"/",false],[2,[3,"tax_jurisdiction_id",false],[2,[7,"/",false],[2,[6,"tax_jurisdictions_rates",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_tax_jurisdictions_rate => /tax_jurisdictions_rates/new(.:format)
  // function(options)
  new_tax_jurisdictions_rate_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"tax_jurisdictions_rates",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_tech_sales_order => /tech_sales_orders/new(.:format)
  // function(options)
  new_tech_sales_order_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"tech_sales_orders",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_tech_sales_order_attachment => /tech_sales_orders/:tech_sales_order_id/attachments/new(.:format)
  // function(tech_sales_order_id, options)
  new_tech_sales_order_attachment_path: Utils.route([["tech_sales_order_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"tech_sales_orders",false],[2,[7,"/",false],[2,[3,"tech_sales_order_id",false],[2,[7,"/",false],[2,[6,"attachments",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_template => /templates/new(.:format)
  // function(options)
  new_template_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"templates",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_user => /users/new(.:format)
  // function(options)
  new_user_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_user_password => /users/password/new(.:format)
  // function(options)
  new_user_password_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"password",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_user_session => /users/sign_in(.:format)
  // function(options)
  new_user_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"sign_in",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_user_unlock => /users/unlock/new(.:format)
  // function(options)
  new_user_unlock_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"unlock",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_vacation_setting => /vacation_settings/new(.:format)
  // function(options)
  new_vacation_setting_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"vacation_settings",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_warehouse => /warehouses/new(.:format)
  // function(options)
  new_warehouse_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"warehouses",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_warehouse_item => /warehouses/:warehouse_id/items/new(.:format)
  // function(warehouse_id, options)
  new_warehouse_item_path: Utils.route([["warehouse_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"warehouses",false],[2,[7,"/",false],[2,[3,"warehouse_id",false],[2,[7,"/",false],[2,[6,"items",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_wizard => /wizard/new(.:format)
  // function(options)
  new_wizard_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"wizard",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// open_invoices_business_partner => /business_partners/:id/open_invoices(.:format)
  // function(id, options)
  open_invoices_business_partner_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"business_partners",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"open_invoices",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// open_orders_business_partner => /business_partners/:id/open_orders(.:format)
  // function(id, options)
  open_orders_business_partner_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"business_partners",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"open_orders",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// operator_manufacturer => /operator_manufacturers/:id(.:format)
  // function(id, options)
  operator_manufacturer_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"operator_manufacturers",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// operator_manufacturer_operator_type => /operator_manufacturers/:operator_manufacturer_id/operator_types/:id(.:format)
  // function(operator_manufacturer_id, id, options)
  operator_manufacturer_operator_type_path: Utils.route([["operator_manufacturer_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"operator_manufacturers",false],[2,[7,"/",false],[2,[3,"operator_manufacturer_id",false],[2,[7,"/",false],[2,[6,"operator_types",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// operator_manufacturer_operator_types => /operator_manufacturers/:operator_manufacturer_id/operator_types(.:format)
  // function(operator_manufacturer_id, options)
  operator_manufacturer_operator_types_path: Utils.route([["operator_manufacturer_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"operator_manufacturers",false],[2,[7,"/",false],[2,[3,"operator_manufacturer_id",false],[2,[7,"/",false],[2,[6,"operator_types",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// operator_manufacturers => /operator_manufacturers(.:format)
  // function(options)
  operator_manufacturers_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"operator_manufacturers",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// outgoing_payment => /outgoing_payments/:id(.:format)
  // function(id, options)
  outgoing_payment_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"outgoing_payments",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// outgoing_payments => /outgoing_payments(.:format)
  // function(options)
  outgoing_payments_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"outgoing_payments",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// paid_absence_period => /paid_absence_periods/:id(.:format)
  // function(id, options)
  paid_absence_period_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"paid_absence_periods",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// paid_absence_periods => /paid_absence_periods(.:format)
  // function(options)
  paid_absence_periods_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"paid_absence_periods",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// partner_billing_address => /partner_billing_addresses/:id(.:format)
  // function(id, options)
  partner_billing_address_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"partner_billing_addresses",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// partner_billing_addresses => /partner_billing_addresses(.:format)
  // function(options)
  partner_billing_addresses_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"partner_billing_addresses",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// partner_shipping_address => /partner_shipping_addresses/:id(.:format)
  // function(id, options)
  partner_shipping_address_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"partner_shipping_addresses",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// partner_shipping_addresses => /partner_shipping_addresses(.:format)
  // function(options)
  partner_shipping_addresses_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"partner_shipping_addresses",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// payment => /payments/:id(.:format)
  // function(id, options)
  payment_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"payments",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// payment_business_partners_payment => /payments/:payment_id/business_partners_payments/:id(.:format)
  // function(payment_id, id, options)
  payment_business_partners_payment_path: Utils.route([["payment_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"payments",false],[2,[7,"/",false],[2,[3,"payment_id",false],[2,[7,"/",false],[2,[6,"business_partners_payments",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// payment_business_partners_payments => /payments/:payment_id/business_partners_payments(.:format)
  // function(payment_id, options)
  payment_business_partners_payments_path: Utils.route([["payment_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"payments",false],[2,[7,"/",false],[2,[3,"payment_id",false],[2,[7,"/",false],[2,[6,"business_partners_payments",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// payment_method => /payment_methods/:id(.:format)
  // function(id, options)
  payment_method_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"payment_methods",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// payment_methods => /payment_methods(.:format)
  // function(options)
  payment_methods_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"payment_methods",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// payment_payment_method => /payments/:payment_id/payment_methods/:id(.:format)
  // function(payment_id, id, options)
  payment_payment_method_path: Utils.route([["payment_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"payments",false],[2,[7,"/",false],[2,[3,"payment_id",false],[2,[7,"/",false],[2,[6,"payment_methods",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// payment_payment_methods => /payments/:payment_id/payment_methods(.:format)
  // function(payment_id, options)
  payment_payment_methods_path: Utils.route([["payment_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"payments",false],[2,[7,"/",false],[2,[3,"payment_id",false],[2,[7,"/",false],[2,[6,"payment_methods",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// payment_recommendation_report => /payments/:payment_id/recommendation_reports/:id(.:format)
  // function(payment_id, id, options)
  payment_recommendation_report_path: Utils.route([["payment_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"payments",false],[2,[7,"/",false],[2,[3,"payment_id",false],[2,[7,"/",false],[2,[6,"recommendation_reports",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// payment_recommendation_reports => /payments/:payment_id/recommendation_reports(.:format)
  // function(payment_id, options)
  payment_recommendation_reports_path: Utils.route([["payment_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"payments",false],[2,[7,"/",false],[2,[3,"payment_id",false],[2,[7,"/",false],[2,[6,"recommendation_reports",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// payment_wizard => /payments/:payment_id/wizard/:id(.:format)
  // function(payment_id, id, options)
  payment_wizard_path: Utils.route([["payment_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"payments",false],[2,[7,"/",false],[2,[3,"payment_id",false],[2,[7,"/",false],[2,[6,"wizard",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// payment_wizard_index => /payments/:payment_id/wizard(.:format)
  // function(payment_id, options)
  payment_wizard_index_path: Utils.route([["payment_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"payments",false],[2,[7,"/",false],[2,[3,"payment_id",false],[2,[7,"/",false],[2,[6,"wizard",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// payments => /payments(.:format)
  // function(options)
  payments_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"payments",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// payroll_defaults_api_web_v1_settings => /api/web/v1/settings/payroll_defaults(.:format)
  // function(options)
  payroll_defaults_api_web_v1_settings_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"web",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"payroll_defaults",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// payroll_defaults_settings => /settings/payroll_defaults(.:format)
  // function(options)
  payroll_defaults_settings_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"payroll_defaults",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// payroll_report => /payroll_reports/:id(.:format)
  // function(id, options)
  payroll_report_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"payroll_reports",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// payroll_report_payroll_report_states => /payroll_reports/:payroll_report_id/payroll_report_states(.:format)
  // function(payroll_report_id, options)
  payroll_report_payroll_report_states_path: Utils.route([["payroll_report_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"payroll_reports",false],[2,[7,"/",false],[2,[3,"payroll_report_id",false],[2,[7,"/",false],[2,[6,"payroll_report_states",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// payroll_reports => /payroll_reports(.:format)
  // function(options)
  payroll_reports_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"payroll_reports",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// payroll_salary_change => /payroll_salary_changes/:id(.:format)
  // function(id, options)
  payroll_salary_change_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"payroll_salary_changes",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// payroll_salary_change_reason => /payroll_salary_change_reasons/:id(.:format)
  // function(id, options)
  payroll_salary_change_reason_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"payroll_salary_change_reasons",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// payroll_salary_change_reasons => /payroll_salary_change_reasons(.:format)
  // function(options)
  payroll_salary_change_reasons_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"payroll_salary_change_reasons",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// payroll_salary_changes => /payroll_salary_changes(.:format)
  // function(options)
  payroll_salary_changes_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"payroll_salary_changes",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// payroll_setting => /payroll_settings/:id(.:format)
  // function(id, options)
  payroll_setting_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"payroll_settings",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// payroll_settings => /payroll_settings(.:format)
  // function(options)
  payroll_settings_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"payroll_settings",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// payroll_tax_setup_add_on => /payroll_tax_setup_add_ons/:id(.:format)
  // function(id, options)
  payroll_tax_setup_add_on_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"payroll_tax_setup_add_ons",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// payroll_tax_setup_add_ons => /payroll_tax_setup_add_ons(.:format)
  // function(options)
  payroll_tax_setup_add_ons_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"payroll_tax_setup_add_ons",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// permission => /permissions/:id(.:format)
  // function(id, options)
  permission_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"permissions",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// permissions => /permissions(.:format)
  // function(options)
  permissions_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"permissions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// pg_hero => /pghero
  // function(options)
  pg_hero_path: Utils.route([], {}, [2,[7,"/",false],[6,"pghero",false]]),
// pg_hero.space => /pghero(/:database)/space(.:format)
  // function(options)
  pg_hero_space_path: Utils.route([["database",false],["format",false]], {}, [2,[1,[2,[2,[2,[7,"/",false],[6,"pghero",false]],[7,"/",false]],[3,"database",false]],false],[2,[7,"/",false],[2,[6,"space",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
// pg_hero.relation_space => /pghero(/:database)/space/:relation(.:format)
  // function(relation, options)
  pg_hero_relation_space_path: Utils.route([["database",false],["relation",true],["format",false]], {}, [2,[1,[2,[2,[2,[7,"/",false],[6,"pghero",false]],[7,"/",false]],[3,"database",false]],false],[2,[7,"/",false],[2,[6,"space",false],[2,[7,"/",false],[2,[3,"relation",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// pg_hero.index_bloat => /pghero(/:database)/index_bloat(.:format)
  // function(options)
  pg_hero_index_bloat_path: Utils.route([["database",false],["format",false]], {}, [2,[1,[2,[2,[2,[7,"/",false],[6,"pghero",false]],[7,"/",false]],[3,"database",false]],false],[2,[7,"/",false],[2,[6,"index_bloat",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
// pg_hero.live_queries => /pghero(/:database)/live_queries(.:format)
  // function(options)
  pg_hero_live_queries_path: Utils.route([["database",false],["format",false]], {}, [2,[1,[2,[2,[2,[7,"/",false],[6,"pghero",false]],[7,"/",false]],[3,"database",false]],false],[2,[7,"/",false],[2,[6,"live_queries",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
// pg_hero.queries => /pghero(/:database)/queries(.:format)
  // function(options)
  pg_hero_queries_path: Utils.route([["database",false],["format",false]], {}, [2,[1,[2,[2,[2,[7,"/",false],[6,"pghero",false]],[7,"/",false]],[3,"database",false]],false],[2,[7,"/",false],[2,[6,"queries",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
// pg_hero.show_query => /pghero(/:database)/queries/:query_hash(.:format)
  // function(query_hash, options)
  pg_hero_show_query_path: Utils.route([["database",false],["query_hash",true],["format",false]], {}, [2,[1,[2,[2,[2,[7,"/",false],[6,"pghero",false]],[7,"/",false]],[3,"database",false]],false],[2,[7,"/",false],[2,[6,"queries",false],[2,[7,"/",false],[2,[3,"query_hash",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// pg_hero.system => /pghero(/:database)/system(.:format)
  // function(options)
  pg_hero_system_path: Utils.route([["database",false],["format",false]], {}, [2,[1,[2,[2,[2,[7,"/",false],[6,"pghero",false]],[7,"/",false]],[3,"database",false]],false],[2,[7,"/",false],[2,[6,"system",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
// pg_hero.cpu_usage => /pghero(/:database)/cpu_usage(.:format)
  // function(options)
  pg_hero_cpu_usage_path: Utils.route([["database",false],["format",false]], {}, [2,[1,[2,[2,[2,[7,"/",false],[6,"pghero",false]],[7,"/",false]],[3,"database",false]],false],[2,[7,"/",false],[2,[6,"cpu_usage",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
// pg_hero.connection_stats => /pghero(/:database)/connection_stats(.:format)
  // function(options)
  pg_hero_connection_stats_path: Utils.route([["database",false],["format",false]], {}, [2,[1,[2,[2,[2,[7,"/",false],[6,"pghero",false]],[7,"/",false]],[3,"database",false]],false],[2,[7,"/",false],[2,[6,"connection_stats",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
// pg_hero.replication_lag_stats => /pghero(/:database)/replication_lag_stats(.:format)
  // function(options)
  pg_hero_replication_lag_stats_path: Utils.route([["database",false],["format",false]], {}, [2,[1,[2,[2,[2,[7,"/",false],[6,"pghero",false]],[7,"/",false]],[3,"database",false]],false],[2,[7,"/",false],[2,[6,"replication_lag_stats",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
// pg_hero.load_stats => /pghero(/:database)/load_stats(.:format)
  // function(options)
  pg_hero_load_stats_path: Utils.route([["database",false],["format",false]], {}, [2,[1,[2,[2,[2,[7,"/",false],[6,"pghero",false]],[7,"/",false]],[3,"database",false]],false],[2,[7,"/",false],[2,[6,"load_stats",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
// pg_hero.free_space_stats => /pghero(/:database)/free_space_stats(.:format)
  // function(options)
  pg_hero_free_space_stats_path: Utils.route([["database",false],["format",false]], {}, [2,[1,[2,[2,[2,[7,"/",false],[6,"pghero",false]],[7,"/",false]],[3,"database",false]],false],[2,[7,"/",false],[2,[6,"free_space_stats",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
// pg_hero.explain => /pghero(/:database)/explain(.:format)
  // function(options)
  pg_hero_explain_path: Utils.route([["database",false],["format",false]], {}, [2,[1,[2,[2,[2,[7,"/",false],[6,"pghero",false]],[7,"/",false]],[3,"database",false]],false],[2,[7,"/",false],[2,[6,"explain",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
// pg_hero.tune => /pghero(/:database)/tune(.:format)
  // function(options)
  pg_hero_tune_path: Utils.route([["database",false],["format",false]], {}, [2,[1,[2,[2,[2,[7,"/",false],[6,"pghero",false]],[7,"/",false]],[3,"database",false]],false],[2,[7,"/",false],[2,[6,"tune",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
// pg_hero.connections => /pghero(/:database)/connections(.:format)
  // function(options)
  pg_hero_connections_path: Utils.route([["database",false],["format",false]], {}, [2,[1,[2,[2,[2,[7,"/",false],[6,"pghero",false]],[7,"/",false]],[3,"database",false]],false],[2,[7,"/",false],[2,[6,"connections",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
// pg_hero.maintenance => /pghero(/:database)/maintenance(.:format)
  // function(options)
  pg_hero_maintenance_path: Utils.route([["database",false],["format",false]], {}, [2,[1,[2,[2,[2,[7,"/",false],[6,"pghero",false]],[7,"/",false]],[3,"database",false]],false],[2,[7,"/",false],[2,[6,"maintenance",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
// pg_hero.kill => /pghero(/:database)/kill(.:format)
  // function(options)
  pg_hero_kill_path: Utils.route([["database",false],["format",false]], {}, [2,[1,[2,[2,[2,[7,"/",false],[6,"pghero",false]],[7,"/",false]],[3,"database",false]],false],[2,[7,"/",false],[2,[6,"kill",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
// pg_hero.kill_long_running_queries => /pghero(/:database)/kill_long_running_queries(.:format)
  // function(options)
  pg_hero_kill_long_running_queries_path: Utils.route([["database",false],["format",false]], {}, [2,[1,[2,[2,[2,[7,"/",false],[6,"pghero",false]],[7,"/",false]],[3,"database",false]],false],[2,[7,"/",false],[2,[6,"kill_long_running_queries",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
// pg_hero.kill_all => /pghero(/:database)/kill_all(.:format)
  // function(options)
  pg_hero_kill_all_path: Utils.route([["database",false],["format",false]], {}, [2,[1,[2,[2,[2,[7,"/",false],[6,"pghero",false]],[7,"/",false]],[3,"database",false]],false],[2,[7,"/",false],[2,[6,"kill_all",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
// pg_hero.enable_query_stats => /pghero(/:database)/enable_query_stats(.:format)
  // function(options)
  pg_hero_enable_query_stats_path: Utils.route([["database",false],["format",false]], {}, [2,[1,[2,[2,[2,[7,"/",false],[6,"pghero",false]],[7,"/",false]],[3,"database",false]],false],[2,[7,"/",false],[2,[6,"enable_query_stats",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
// pg_hero.reset_query_stats => /pghero(/:database)/reset_query_stats(.:format)
  // function(options)
  pg_hero_reset_query_stats_path: Utils.route([["database",false],["format",false]], {}, [2,[1,[2,[2,[2,[7,"/",false],[6,"pghero",false]],[7,"/",false]],[3,"database",false]],false],[2,[7,"/",false],[2,[6,"reset_query_stats",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
// pg_hero.system_stats => /pghero(/:database)/system_stats(.:format)
  // function(options)
  pg_hero_system_stats_path: Utils.route([["database",false],["format",false]], {}, [2,[1,[2,[2,[2,[7,"/",false],[6,"pghero",false]],[7,"/",false]],[3,"database",false]],false],[2,[7,"/",false],[2,[6,"system_stats",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
// pg_hero.query_stats => /pghero(/:database)/query_stats(.:format)
  // function(options)
  pg_hero_query_stats_path: Utils.route([["database",false],["format",false]], {}, [2,[1,[2,[2,[2,[7,"/",false],[6,"pghero",false]],[7,"/",false]],[3,"database",false]],false],[2,[7,"/",false],[2,[6,"query_stats",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
// pg_hero.root => /pghero/(:database)(.:format)
  // function(options)
  pg_hero_root_path: Utils.route([["database",false],["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"pghero",false]],[7,"/",false]],[2,[1,[3,"database",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// posting_period => /posting_periods/:id(.:format)
  // function(id, options)
  posting_period_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"posting_periods",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// posting_period_ap_credit_memos => /posting_periods/:posting_period_id/ap_credit_memos(.:format)
  // function(posting_period_id, options)
  posting_period_ap_credit_memos_path: Utils.route([["posting_period_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"posting_periods",false],[2,[7,"/",false],[2,[3,"posting_period_id",false],[2,[7,"/",false],[2,[6,"ap_credit_memos",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// posting_period_ap_down_payment_invoices => /posting_periods/:posting_period_id/ap_down_payment_invoices(.:format)
  // function(posting_period_id, options)
  posting_period_ap_down_payment_invoices_path: Utils.route([["posting_period_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"posting_periods",false],[2,[7,"/",false],[2,[3,"posting_period_id",false],[2,[7,"/",false],[2,[6,"ap_down_payment_invoices",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// posting_period_ap_invoices => /posting_periods/:posting_period_id/ap_invoices(.:format)
  // function(posting_period_id, options)
  posting_period_ap_invoices_path: Utils.route([["posting_period_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"posting_periods",false],[2,[7,"/",false],[2,[3,"posting_period_id",false],[2,[7,"/",false],[2,[6,"ap_invoices",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// posting_period_ar_credit_memos => /posting_periods/:posting_period_id/ar_credit_memos(.:format)
  // function(posting_period_id, options)
  posting_period_ar_credit_memos_path: Utils.route([["posting_period_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"posting_periods",false],[2,[7,"/",false],[2,[3,"posting_period_id",false],[2,[7,"/",false],[2,[6,"ar_credit_memos",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// posting_period_ar_down_payment_invoices => /posting_periods/:posting_period_id/ar_down_payment_invoices(.:format)
  // function(posting_period_id, options)
  posting_period_ar_down_payment_invoices_path: Utils.route([["posting_period_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"posting_periods",false],[2,[7,"/",false],[2,[3,"posting_period_id",false],[2,[7,"/",false],[2,[6,"ar_down_payment_invoices",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// posting_period_ar_invoices => /posting_periods/:posting_period_id/ar_invoices(.:format)
  // function(posting_period_id, options)
  posting_period_ar_invoices_path: Utils.route([["posting_period_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"posting_periods",false],[2,[7,"/",false],[2,[3,"posting_period_id",false],[2,[7,"/",false],[2,[6,"ar_invoices",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// posting_period_purchase_orders => /posting_periods/:posting_period_id/purchase_orders(.:format)
  // function(posting_period_id, options)
  posting_period_purchase_orders_path: Utils.route([["posting_period_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"posting_periods",false],[2,[7,"/",false],[2,[3,"posting_period_id",false],[2,[7,"/",false],[2,[6,"purchase_orders",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// posting_period_purchase_quotations => /posting_periods/:posting_period_id/purchase_quotations(.:format)
  // function(posting_period_id, options)
  posting_period_purchase_quotations_path: Utils.route([["posting_period_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"posting_periods",false],[2,[7,"/",false],[2,[3,"posting_period_id",false],[2,[7,"/",false],[2,[6,"purchase_quotations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// posting_period_sales_orders => /posting_periods/:posting_period_id/sales_orders(.:format)
  // function(posting_period_id, options)
  posting_period_sales_orders_path: Utils.route([["posting_period_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"posting_periods",false],[2,[7,"/",false],[2,[3,"posting_period_id",false],[2,[7,"/",false],[2,[6,"sales_orders",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// posting_period_sales_quotations => /posting_periods/:posting_period_id/sales_quotations(.:format)
  // function(posting_period_id, options)
  posting_period_sales_quotations_path: Utils.route([["posting_period_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"posting_periods",false],[2,[7,"/",false],[2,[3,"posting_period_id",false],[2,[7,"/",false],[2,[6,"sales_quotations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// posting_periods => /posting_periods(.:format)
  // function(options)
  posting_periods_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"posting_periods",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// production_order => /production_orders/:id(.:format)
  // function(id, options)
  production_order_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"production_orders",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// production_order_attachment => /production_orders/:production_order_id/attachments/:id(.:format)
  // function(production_order_id, id, options)
  production_order_attachment_path: Utils.route([["production_order_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"production_orders",false],[2,[7,"/",false],[2,[3,"production_order_id",false],[2,[7,"/",false],[2,[6,"attachments",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// production_order_attachments => /production_orders/:production_order_id/attachments(.:format)
  // function(production_order_id, options)
  production_order_attachments_path: Utils.route([["production_order_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"production_orders",false],[2,[7,"/",false],[2,[3,"production_order_id",false],[2,[7,"/",false],[2,[6,"attachments",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// production_order_line => /production_order_lines/:id(.:format)
  // function(id, options)
  production_order_line_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"production_order_lines",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// production_order_lines => /production_order_lines(.:format)
  // function(options)
  production_order_lines_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"production_order_lines",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// production_order_production_order_line => /production_orders/:production_order_id/production_order_lines/:id(.:format)
  // function(production_order_id, id, options)
  production_order_production_order_line_path: Utils.route([["production_order_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"production_orders",false],[2,[7,"/",false],[2,[3,"production_order_id",false],[2,[7,"/",false],[2,[6,"production_order_lines",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// production_order_production_order_lines => /production_orders/:production_order_id/production_order_lines(.:format)
  // function(production_order_id, options)
  production_order_production_order_lines_path: Utils.route([["production_order_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"production_orders",false],[2,[7,"/",false],[2,[3,"production_order_id",false],[2,[7,"/",false],[2,[6,"production_order_lines",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// production_orders => /production_orders(.:format)
  // function(options)
  production_orders_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"production_orders",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// profit_and_loss_statement => /profit_and_loss_statement(.:format)
  // function(options)
  profit_and_loss_statement_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"profit_and_loss_statement",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// project_payroll_setup => /project_payroll_setup/*path(.:format)
  // function(path, options)
  project_payroll_setup_path: Utils.route([["path",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"project_payroll_setup",false],[2,[7,"/",false],[2,[5,[3,"path",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// properties => /properties(.:format)
  // function(options)
  properties_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"properties",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// property => /properties/:id(.:format)
  // function(id, options)
  property_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"properties",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// punches_by_all_employees => /punches_by_all_employees(.:format)
  // function(options)
  punches_by_all_employees_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"punches_by_all_employees",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// purchase_order => /purchase_orders/:id(.:format)
  // function(id, options)
  purchase_order_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"purchase_orders",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// purchase_orders => /purchase_orders(.:format)
  // function(options)
  purchase_orders_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"purchase_orders",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// purchase_quotation => /purchase_quotations/:id(.:format)
  // function(id, options)
  purchase_quotation_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"purchase_quotations",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// purchase_quotations => /purchase_quotations(.:format)
  // function(options)
  purchase_quotations_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"purchase_quotations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// quantity_warehouse_item => /warehouses/:warehouse_id/items/:id/quantity(.:format)
  // function(warehouse_id, id, options)
  quantity_warehouse_item_path: Utils.route([["warehouse_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"warehouses",false],[2,[7,"/",false],[2,[3,"warehouse_id",false],[2,[7,"/",false],[2,[6,"items",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"quantity",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// rails_admin => /admin
  // function(options)
  rails_admin_path: Utils.route([], {}, [2,[7,"/",false],[6,"admin",false]]),
// rails_admin.dashboard => /admin/
  // function(options)
  rails_admin_dashboard_path: Utils.route([], {}, [2,[2,[7,"/",false],[6,"admin",false]],[7,"/",false]]),
// rails_admin.index => /admin/:model_name(.:format)
  // function(model_name, options)
  rails_admin_index_path: Utils.route([["model_name",true],["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"admin",false]],[7,"/",false]],[2,[3,"model_name",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// rails_admin.new => /admin/:model_name/new(.:format)
  // function(model_name, options)
  rails_admin_new_path: Utils.route([["model_name",true],["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"admin",false]],[7,"/",false]],[2,[3,"model_name",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// rails_admin.export => /admin/:model_name/export(.:format)
  // function(model_name, options)
  rails_admin_export_path: Utils.route([["model_name",true],["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"admin",false]],[7,"/",false]],[2,[3,"model_name",false],[2,[7,"/",false],[2,[6,"export",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// rails_admin.bulk_delete => /admin/:model_name/bulk_delete(.:format)
  // function(model_name, options)
  rails_admin_bulk_delete_path: Utils.route([["model_name",true],["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"admin",false]],[7,"/",false]],[2,[3,"model_name",false],[2,[7,"/",false],[2,[6,"bulk_delete",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// rails_admin.history_index => /admin/:model_name/history(.:format)
  // function(model_name, options)
  rails_admin_history_index_path: Utils.route([["model_name",true],["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"admin",false]],[7,"/",false]],[2,[3,"model_name",false],[2,[7,"/",false],[2,[6,"history",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// rails_admin.bulk_action => /admin/:model_name/bulk_action(.:format)
  // function(model_name, options)
  rails_admin_bulk_action_path: Utils.route([["model_name",true],["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"admin",false]],[7,"/",false]],[2,[3,"model_name",false],[2,[7,"/",false],[2,[6,"bulk_action",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// rails_admin.show => /admin/:model_name/:id(.:format)
  // function(model_name, id, options)
  rails_admin_show_path: Utils.route([["model_name",true],["id",true],["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"admin",false]],[7,"/",false]],[2,[3,"model_name",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// rails_admin.edit => /admin/:model_name/:id/edit(.:format)
  // function(model_name, id, options)
  rails_admin_edit_path: Utils.route([["model_name",true],["id",true],["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"admin",false]],[7,"/",false]],[2,[3,"model_name",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// rails_admin.delete => /admin/:model_name/:id/delete(.:format)
  // function(model_name, id, options)
  rails_admin_delete_path: Utils.route([["model_name",true],["id",true],["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"admin",false]],[7,"/",false]],[2,[3,"model_name",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"delete",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// rails_admin.show_in_app => /admin/:model_name/:id/show_in_app(.:format)
  // function(model_name, id, options)
  rails_admin_show_in_app_path: Utils.route([["model_name",true],["id",true],["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"admin",false]],[7,"/",false]],[2,[3,"model_name",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"show_in_app",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// rails_admin.history_show => /admin/:model_name/:id/history(.:format)
  // function(model_name, id, options)
  rails_admin_history_show_path: Utils.route([["model_name",true],["id",true],["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"admin",false]],[7,"/",false]],[2,[3,"model_name",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"history",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// rails_blob_representation => /rails/active_storage/representations/:signed_blob_id/:variation_key/*filename(.:format)
  // function(signed_blob_id, variation_key, filename, options)
  rails_blob_representation_path: Utils.route([["signed_blob_id",true],["variation_key",true],["filename",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"representations",false],[2,[7,"/",false],[2,[3,"signed_blob_id",false],[2,[7,"/",false],[2,[3,"variation_key",false],[2,[7,"/",false],[2,[5,[3,"filename",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// rails_conductor_inbound_email => /rails/conductor/action_mailbox/inbound_emails/:id(.:format)
  // function(id, options)
  rails_conductor_inbound_email_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// rails_conductor_inbound_email_reroute => /rails/conductor/action_mailbox/:inbound_email_id/reroute(.:format)
  // function(inbound_email_id, options)
  rails_conductor_inbound_email_reroute_path: Utils.route([["inbound_email_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[3,"inbound_email_id",false],[2,[7,"/",false],[2,[6,"reroute",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// rails_conductor_inbound_emails => /rails/conductor/action_mailbox/inbound_emails(.:format)
  // function(options)
  rails_conductor_inbound_emails_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// rails_direct_uploads => /rails/active_storage/direct_uploads(.:format)
  // function(options)
  rails_direct_uploads_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"direct_uploads",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// rails_disk_service => /rails/active_storage/disk/:encoded_key/*filename(.:format)
  // function(encoded_key, filename, options)
  rails_disk_service_path: Utils.route([["encoded_key",true],["filename",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"disk",false],[2,[7,"/",false],[2,[3,"encoded_key",false],[2,[7,"/",false],[2,[5,[3,"filename",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// rails_info => /rails/info(.:format)
  // function(options)
  rails_info_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"info",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// rails_info_properties => /rails/info/properties(.:format)
  // function(options)
  rails_info_properties_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"info",false],[2,[7,"/",false],[2,[6,"properties",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// rails_info_routes => /rails/info/routes(.:format)
  // function(options)
  rails_info_routes_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"info",false],[2,[7,"/",false],[2,[6,"routes",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// rails_mailers => /rails/mailers(.:format)
  // function(options)
  rails_mailers_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"mailers",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// rails_mailgun_inbound_emails => /rails/action_mailbox/mailgun/inbound_emails/mime(.:format)
  // function(options)
  rails_mailgun_inbound_emails_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"mailgun",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[2,[7,"/",false],[2,[6,"mime",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// rails_mandrill_inbound_emails => /rails/action_mailbox/mandrill/inbound_emails(.:format)
  // function(options)
  rails_mandrill_inbound_emails_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"mandrill",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// rails_postmark_inbound_emails => /rails/action_mailbox/postmark/inbound_emails(.:format)
  // function(options)
  rails_postmark_inbound_emails_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"postmark",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// rails_relay_inbound_emails => /rails/action_mailbox/relay/inbound_emails(.:format)
  // function(options)
  rails_relay_inbound_emails_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"relay",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// rails_sendgrid_inbound_emails => /rails/action_mailbox/sendgrid/inbound_emails(.:format)
  // function(options)
  rails_sendgrid_inbound_emails_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"sendgrid",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// rails_service_blob => /rails/active_storage/blobs/:signed_id/*filename(.:format)
  // function(signed_id, filename, options)
  rails_service_blob_path: Utils.route([["signed_id",true],["filename",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"blobs",false],[2,[7,"/",false],[2,[3,"signed_id",false],[2,[7,"/",false],[2,[5,[3,"filename",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// raw_payment_new_payment => /payments/new/raw_payment(.:format)
  // function(options)
  raw_payment_new_payment_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"payments",false],[2,[7,"/",false],[2,[6,"new",false],[2,[7,"/",false],[2,[6,"raw_payment",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// recalculate_salary_employee_work_period => /employee_work_periods/:id/recalculate_salary(.:format)
  // function(id, options)
  recalculate_salary_employee_work_period_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"employee_work_periods",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"recalculate_salary",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// receipt_production => /receipt_productions/:id(.:format)
  // function(id, options)
  receipt_production_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"receipt_productions",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// receipt_production_attachment => /receipt_productions/:receipt_production_id/attachments/:id(.:format)
  // function(receipt_production_id, id, options)
  receipt_production_attachment_path: Utils.route([["receipt_production_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"receipt_productions",false],[2,[7,"/",false],[2,[3,"receipt_production_id",false],[2,[7,"/",false],[2,[6,"attachments",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// receipt_production_attachments => /receipt_productions/:receipt_production_id/attachments(.:format)
  // function(receipt_production_id, options)
  receipt_production_attachments_path: Utils.route([["receipt_production_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"receipt_productions",false],[2,[7,"/",false],[2,[3,"receipt_production_id",false],[2,[7,"/",false],[2,[6,"attachments",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// receipt_productions => /receipt_productions(.:format)
  // function(options)
  receipt_productions_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"receipt_productions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// recommendation_report => /recommendation_reports/:id(.:format)
  // function(id, options)
  recommendation_report_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"recommendation_reports",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// recommendation_reports => /recommendation_reports(.:format)
  // function(options)
  recommendation_reports_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"recommendation_reports",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// redirect_to_work_period_api_web_v1_employee_work_day => /api/web/v1/employee_work_days/:id/redirect_to_work_period(.:format)
  // function(id, options)
  redirect_to_work_period_api_web_v1_employee_work_day_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"web",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"employee_work_days",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"redirect_to_work_period",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// remove_entire_list_payment => /payments/:id/remove_entire_list(.:format)
  // function(id, options)
  remove_entire_list_payment_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"payments",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"remove_entire_list",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// remove_from_list_payment => /payments/:id/remove_from_list(.:format)
  // function(id, options)
  remove_from_list_payment_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"payments",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"remove_from_list",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// resources_changer_payroll_report => /payroll_reports/:id/resources_changer(.:format)
  // function(id, options)
  resources_changer_payroll_report_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"payroll_reports",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"resources_changer",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// role => /roles/:id(.:format)
  // function(id, options)
  role_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"roles",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// role_permissions => /roles/:role_id/permissions(.:format)
  // function(role_id, options)
  role_permissions_path: Utils.route([["role_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"roles",false],[2,[7,"/",false],[2,[3,"role_id",false],[2,[7,"/",false],[2,[6,"permissions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// roles => /roles(.:format)
  // function(options)
  roles_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"roles",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// salaries => /salaries(.:format)
  // function(options)
  salaries_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"salaries",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// salary => /salaries/:id(.:format)
  // function(id, options)
  salary_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"salaries",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// sales_employee_api_web_v1_employees => /api/web/v1/employees/sales_employee(.:format)
  // function(options)
  sales_employee_api_web_v1_employees_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"web",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[6,"sales_employee",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// sales_order => /sales_orders/:id(.:format)
  // function(id, options)
  sales_order_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"sales_orders",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// sales_order_tech_sales_order => /sales_orders/:sales_order_id/tech_sales_orders/:id(.:format)
  // function(sales_order_id, id, options)
  sales_order_tech_sales_order_path: Utils.route([["sales_order_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"sales_orders",false],[2,[7,"/",false],[2,[3,"sales_order_id",false],[2,[7,"/",false],[2,[6,"tech_sales_orders",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// sales_order_tech_sales_orders => /sales_orders/:sales_order_id/tech_sales_orders(.:format)
  // function(sales_order_id, options)
  sales_order_tech_sales_orders_path: Utils.route([["sales_order_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"sales_orders",false],[2,[7,"/",false],[2,[3,"sales_order_id",false],[2,[7,"/",false],[2,[6,"tech_sales_orders",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// sales_orders => /sales_orders(.:format)
  // function(options)
  sales_orders_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"sales_orders",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// sales_quotation => /sales_quotations/:id(.:format)
  // function(id, options)
  sales_quotation_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"sales_quotations",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// sales_quotations => /sales_quotations(.:format)
  // function(options)
  sales_quotations_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"sales_quotations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// sales_tax_code => /sales_tax_codes/:id(.:format)
  // function(id, options)
  sales_tax_code_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"sales_tax_codes",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// sales_tax_codes => /sales_tax_codes(.:format)
  // function(options)
  sales_tax_codes_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"sales_tax_codes",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// service_call => /service_calls/:id(.:format)
  // function(id, options)
  service_call_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"service_calls",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// service_call_call_notification => /service_calls/:service_call_id/call_notifications/:id(.:format)
  // function(service_call_id, id, options)
  service_call_call_notification_path: Utils.route([["service_call_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"service_calls",false],[2,[7,"/",false],[2,[3,"service_call_id",false],[2,[7,"/",false],[2,[6,"call_notifications",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// service_call_call_notifications => /service_calls/:service_call_id/call_notifications(.:format)
  // function(service_call_id, options)
  service_call_call_notifications_path: Utils.route([["service_call_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"service_calls",false],[2,[7,"/",false],[2,[3,"service_call_id",false],[2,[7,"/",false],[2,[6,"call_notifications",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// service_call_customer_equipment_card => /service_calls/:service_call_id/customer_equipment_cards/:id(.:format)
  // function(service_call_id, id, options)
  service_call_customer_equipment_card_path: Utils.route([["service_call_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"service_calls",false],[2,[7,"/",false],[2,[3,"service_call_id",false],[2,[7,"/",false],[2,[6,"customer_equipment_cards",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// service_call_customer_equipment_cards => /service_calls/:service_call_id/customer_equipment_cards(.:format)
  // function(service_call_id, options)
  service_call_customer_equipment_cards_path: Utils.route([["service_call_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"service_calls",false],[2,[7,"/",false],[2,[3,"service_call_id",false],[2,[7,"/",false],[2,[6,"customer_equipment_cards",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// service_call_tech_sales_order => /service_calls/:service_call_id/tech_sales_orders/:id(.:format)
  // function(service_call_id, id, options)
  service_call_tech_sales_order_path: Utils.route([["service_call_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"service_calls",false],[2,[7,"/",false],[2,[3,"service_call_id",false],[2,[7,"/",false],[2,[6,"tech_sales_orders",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// service_call_tech_sales_orders => /service_calls/:service_call_id/tech_sales_orders(.:format)
  // function(service_call_id, options)
  service_call_tech_sales_orders_path: Utils.route([["service_call_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"service_calls",false],[2,[7,"/",false],[2,[3,"service_call_id",false],[2,[7,"/",false],[2,[6,"tech_sales_orders",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// service_calls => /service_calls(.:format)
  // function(options)
  service_calls_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"service_calls",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// service_contract => /service_contracts/:id(.:format)
  // function(id, options)
  service_contract_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"service_contracts",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// service_contract_attachment => /service_contracts/:service_contract_id/attachments/:id(.:format)
  // function(service_contract_id, id, options)
  service_contract_attachment_path: Utils.route([["service_contract_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"service_contracts",false],[2,[7,"/",false],[2,[3,"service_contract_id",false],[2,[7,"/",false],[2,[6,"attachments",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// service_contract_attachments => /service_contracts/:service_contract_id/attachments(.:format)
  // function(service_contract_id, options)
  service_contract_attachments_path: Utils.route([["service_contract_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"service_contracts",false],[2,[7,"/",false],[2,[3,"service_contract_id",false],[2,[7,"/",false],[2,[6,"attachments",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// service_contract_service_call => /service_contracts/:service_contract_id/service_calls/:id(.:format)
  // function(service_contract_id, id, options)
  service_contract_service_call_path: Utils.route([["service_contract_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"service_contracts",false],[2,[7,"/",false],[2,[3,"service_contract_id",false],[2,[7,"/",false],[2,[6,"service_calls",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// service_contract_service_calls => /service_contracts/:service_contract_id/service_calls(.:format)
  // function(service_contract_id, options)
  service_contract_service_calls_path: Utils.route([["service_contract_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"service_contracts",false],[2,[7,"/",false],[2,[3,"service_contract_id",false],[2,[7,"/",false],[2,[6,"service_calls",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// service_contract_service_maintenance => /service_contracts/:service_contract_id/service_maintenances/:id(.:format)
  // function(service_contract_id, id, options)
  service_contract_service_maintenance_path: Utils.route([["service_contract_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"service_contracts",false],[2,[7,"/",false],[2,[3,"service_contract_id",false],[2,[7,"/",false],[2,[6,"service_maintenances",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// service_contract_service_maintenances => /service_contracts/:service_contract_id/service_maintenances(.:format)
  // function(service_contract_id, options)
  service_contract_service_maintenances_path: Utils.route([["service_contract_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"service_contracts",false],[2,[7,"/",false],[2,[3,"service_contract_id",false],[2,[7,"/",false],[2,[6,"service_maintenances",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// service_contracts => /service_contracts(.:format)
  // function(options)
  service_contracts_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"service_contracts",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// service_maintenance => /service_maintenances/:id(.:format)
  // function(id, options)
  service_maintenance_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"service_maintenances",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// service_maintenances => /service_maintenances(.:format)
  // function(options)
  service_maintenances_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"service_maintenances",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// service_partner_type => /service_partner_types/:id(.:format)
  // function(id, options)
  service_partner_type_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"service_partner_types",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// service_partner_type_business_partner => /service_partner_types/:service_partner_type_id/business_partners/:id(.:format)
  // function(service_partner_type_id, id, options)
  service_partner_type_business_partner_path: Utils.route([["service_partner_type_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"service_partner_types",false],[2,[7,"/",false],[2,[3,"service_partner_type_id",false],[2,[7,"/",false],[2,[6,"business_partners",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// service_partner_type_business_partners => /service_partner_types/:service_partner_type_id/business_partners(.:format)
  // function(service_partner_type_id, options)
  service_partner_type_business_partners_path: Utils.route([["service_partner_type_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"service_partner_types",false],[2,[7,"/",false],[2,[3,"service_partner_type_id",false],[2,[7,"/",false],[2,[6,"business_partners",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// service_partner_types => /service_partner_types(.:format)
  // function(options)
  service_partner_types_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"service_partner_types",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// setting => /settings/:id(.:format)
  // function(id, options)
  setting_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// settings => /settings(.:format)
  // function(options)
  settings_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// sick_setting => /sick_settings/:id(.:format)
  // function(id, options)
  sick_setting_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"sick_settings",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// sick_settings => /sick_settings(.:format)
  // function(options)
  sick_settings_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"sick_settings",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// sidekiq_web => /sidekiq
  // function(options)
  sidekiq_web_path: Utils.route([], {}, [2,[7,"/",false],[6,"sidekiq",false]]),
// sort_general_ledger_account => /general_ledger_accounts/:id/sort(.:format)
  // function(id, options)
  sort_general_ledger_account_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"general_ledger_accounts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"sort",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// summary_check_employee_work_period => /employee_work_periods/:id/summary_check(.:format)
  // function(id, options)
  summary_check_employee_work_period_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"employee_work_periods",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"summary_check",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// task_manager_tech_sales_orders => /tech_sales_orders/task_manager(.:format)
  // function(options)
  task_manager_tech_sales_orders_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"tech_sales_orders",false],[2,[7,"/",false],[2,[6,"task_manager",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// tax_categories => /tax_categories(.:format)
  // function(options)
  tax_categories_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"tax_categories",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// tax_category => /tax_categories/:id(.:format)
  // function(id, options)
  tax_category_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"tax_categories",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// tax_category_tax_jurisdiction => /tax_categories/:tax_category_id/tax_jurisdictions/:id(.:format)
  // function(tax_category_id, id, options)
  tax_category_tax_jurisdiction_path: Utils.route([["tax_category_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"tax_categories",false],[2,[7,"/",false],[2,[3,"tax_category_id",false],[2,[7,"/",false],[2,[6,"tax_jurisdictions",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// tax_category_tax_jurisdictions => /tax_categories/:tax_category_id/tax_jurisdictions(.:format)
  // function(tax_category_id, options)
  tax_category_tax_jurisdictions_path: Utils.route([["tax_category_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"tax_categories",false],[2,[7,"/",false],[2,[3,"tax_category_id",false],[2,[7,"/",false],[2,[6,"tax_jurisdictions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// tax_code_determination => /tax_code_determinations/:id(.:format)
  // function(id, options)
  tax_code_determination_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"tax_code_determinations",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// tax_code_determinations => /tax_code_determinations(.:format)
  // function(options)
  tax_code_determinations_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"tax_code_determinations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// tax_jurisdiction => /tax_jurisdictions/:id(.:format)
  // function(id, options)
  tax_jurisdiction_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"tax_jurisdictions",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// tax_jurisdiction_tax_jurisdictions_rate => /tax_jurisdictions/:tax_jurisdiction_id/tax_jurisdictions_rates/:id(.:format)
  // function(tax_jurisdiction_id, id, options)
  tax_jurisdiction_tax_jurisdictions_rate_path: Utils.route([["tax_jurisdiction_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"tax_jurisdictions",false],[2,[7,"/",false],[2,[3,"tax_jurisdiction_id",false],[2,[7,"/",false],[2,[6,"tax_jurisdictions_rates",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// tax_jurisdiction_tax_jurisdictions_rates => /tax_jurisdictions/:tax_jurisdiction_id/tax_jurisdictions_rates(.:format)
  // function(tax_jurisdiction_id, options)
  tax_jurisdiction_tax_jurisdictions_rates_path: Utils.route([["tax_jurisdiction_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"tax_jurisdictions",false],[2,[7,"/",false],[2,[3,"tax_jurisdiction_id",false],[2,[7,"/",false],[2,[6,"tax_jurisdictions_rates",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// tax_jurisdictions => /tax_jurisdictions(.:format)
  // function(options)
  tax_jurisdictions_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"tax_jurisdictions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// tax_jurisdictions_rate => /tax_jurisdictions_rates/:id(.:format)
  // function(id, options)
  tax_jurisdictions_rate_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"tax_jurisdictions_rates",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// tax_jurisdictions_rates => /tax_jurisdictions_rates(.:format)
  // function(options)
  tax_jurisdictions_rates_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"tax_jurisdictions_rates",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// tax_report_sales_tax_codes => /sales_tax_codes/tax_report(.:format)
  // function(options)
  tax_report_sales_tax_codes_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"sales_tax_codes",false],[2,[7,"/",false],[2,[6,"tax_report",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// tech_sales_order => /tech_sales_orders/:id(.:format)
  // function(id, options)
  tech_sales_order_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"tech_sales_orders",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// tech_sales_order_attachment => /tech_sales_orders/:tech_sales_order_id/attachments/:id(.:format)
  // function(tech_sales_order_id, id, options)
  tech_sales_order_attachment_path: Utils.route([["tech_sales_order_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"tech_sales_orders",false],[2,[7,"/",false],[2,[3,"tech_sales_order_id",false],[2,[7,"/",false],[2,[6,"attachments",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// tech_sales_order_attachments => /tech_sales_orders/:tech_sales_order_id/attachments(.:format)
  // function(tech_sales_order_id, options)
  tech_sales_order_attachments_path: Utils.route([["tech_sales_order_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"tech_sales_orders",false],[2,[7,"/",false],[2,[3,"tech_sales_order_id",false],[2,[7,"/",false],[2,[6,"attachments",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// tech_sales_orders => /tech_sales_orders(.:format)
  // function(options)
  tech_sales_orders_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"tech_sales_orders",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// technicians_profit => /technicians_profit(.:format)
  // function(options)
  technicians_profit_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"technicians_profit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// template => /templates/:id(.:format)
  // function(id, options)
  template_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"templates",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// templates => /templates(.:format)
  // function(options)
  templates_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"templates",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// trial_balance => /trial_balance(.:format)
  // function(options)
  trial_balance_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"trial_balance",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// unauthenticated_root => /
  // function(options)
  unauthenticated_root_path: Utils.route([], {}, [7,"/",false]),
// update_rails_disk_service => /rails/active_storage/disk/:encoded_token(.:format)
  // function(encoded_token, options)
  update_rails_disk_service_path: Utils.route([["encoded_token",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"disk",false],[2,[7,"/",false],[2,[3,"encoded_token",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// update_security_user => /users/:id/update_security(.:format)
  // function(id, options)
  update_security_user_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"update_security",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// update_with_mark_as_deleted_employee_employee_punch => /employees/:employee_id/employee_punches/:id/update_with_mark_as_deleted(.:format)
  // function(employee_id, id, options)
  update_with_mark_as_deleted_employee_employee_punch_path: Utils.route([["employee_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[3,"employee_id",false],[2,[7,"/",false],[2,[6,"employee_punches",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"update_with_mark_as_deleted",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// user => /users/:id(.:format)
  // function(id, options)
  user_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// user_password => /users/password(.:format)
  // function(options)
  user_password_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"password",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// user_permission => /users/:user_id/permissions/:id(.:format)
  // function(user_id, id, options)
  user_permission_path: Utils.route([["user_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"permissions",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// user_permissions => /users/:user_id/permissions(.:format)
  // function(user_id, options)
  user_permissions_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"permissions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// user_registration => /users(.:format)
  // function(options)
  user_registration_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// user_session => /users/sign_in(.:format)
  // function(options)
  user_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"sign_in",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// user_unlock => /users/unlock(.:format)
  // function(options)
  user_unlock_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"unlock",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// users => /users(.:format)
  // function(options)
  users_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// vacation_setting => /vacation_settings/:id(.:format)
  // function(id, options)
  vacation_setting_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"vacation_settings",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// vacation_settings => /vacation_settings(.:format)
  // function(options)
  vacation_settings_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"vacation_settings",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// warehouse => /warehouses/:id(.:format)
  // function(id, options)
  warehouse_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"warehouses",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// warehouse_item => /warehouses/:warehouse_id/items/:id(.:format)
  // function(warehouse_id, id, options)
  warehouse_item_path: Utils.route([["warehouse_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"warehouses",false],[2,[7,"/",false],[2,[3,"warehouse_id",false],[2,[7,"/",false],[2,[6,"items",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// warehouse_items => /warehouses/:warehouse_id/items(.:format)
  // function(warehouse_id, options)
  warehouse_items_path: Utils.route([["warehouse_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"warehouses",false],[2,[7,"/",false],[2,[3,"warehouse_id",false],[2,[7,"/",false],[2,[6,"items",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// warehouses => /warehouses(.:format)
  // function(options)
  warehouses_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"warehouses",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// web_hooks_update_punches => /web_hooks/update_punches(.:format)
  // function(options)
  web_hooks_update_punches_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"web_hooks",false],[2,[7,"/",false],[2,[6,"update_punches",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// wizard => /wizard/:id(.:format)
  // function(id, options)
  wizard_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"wizard",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// wizard_index => /wizard(.:format)
  // function(options)
  wizard_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"wizard",false],[1,[2,[8,".",false],[3,"format",false]],false]]])}
;
      routes.configure = function(config) {
        return Utils.configure(config);
      };
      routes.config = function() {
        return Utils.config();
      };
      Object.defineProperty(routes, 'defaults', {
        get: function() {
          throw new Error("Routes" + ".defaults is removed. Use " + "Routes" + ".configure() instead.");
        },
        set: function(value) {}
      });
      routes.default_serializer = function(object, prefix) {
        return Utils.default_serializer(object, prefix);
      };
      return Utils.namespace(root, "Routes", routes);
    }
  };

  result = Utils.make();

  if (typeof define === "function" && define.amd) {
    define([], function() {
      return result;
    });
  }

  return result;

}).call(this);
