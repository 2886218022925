import Application from 'application-namespace';
import $ from 'jquery';
import 'jstree';

Application.Classes.GeneralLedgerAccountTree = class GeneralLedgerAccountTree {
  constructor($scope) {
    this.$scope = $scope;
    this.$scope.jstree({
      core: {
        check_callback: true,
      },
      plugins: [
        'dnd',
        'types',
        'contextmenu',
      ],
      types: {
        '#': {
          valid_children: [],
          icon: 'fas fa-home',
        },
        level_2: {
          valid_children: ['level_3'],
          icon: 'fas fa-folder',
        },
        level_3: {
          valid_children: ['level_4'],
          icon: 'fas fa-folder',
        },
        level_4: {
          valid_children: [],
          icon: 'fas fa-file',
        },
      },
      contextmenu: {
        items: (liDataAttributes) => {
          const glaOptions = {
            Create: {
              label: 'Create',
              icon: 'fas fa-plus',
              action: () => this.constructor.addGla(liDataAttributes),
            },
          };
          return liDataAttributes.type !== 'level_4' ? glaOptions : {};
        },
      },
    });

    this.$scope.jstree('open_all');

    this.$scope.on('move_node.jstree', (e, data) => {
      const parentId = $(`#${data.parent}`).data('id');
      const positions = [];
      const id = data.node.li_attr['data-id'];
      $('[data-id]').each((k, el) => positions.push([k, $(el).data('id')]));
      $.ajax({
        url: Routes.sort_general_ledger_account_path(id),
        type: 'POST',
        dataType: 'JSON',
        data: {
          positions,
          parent_id: parentId,
        },
      });
    });

    this.autoSelected();
  }

  static addGla(obj) {
    $.ajax({
      type: 'GET',
      url: obj.data.new,
      dataType: 'SCRIPT',
    });
  }

  autoSelected() {
    if (window.location.search.length) {
      window.location.search.substr(1).split('&').reverse().forEach((object) => {
        if (object.split('=')[0] === 'parent_id') {
          $('.parents').find(`a[href='/general_ledger_accounts/${object.split('=')[1]}']`).trigger('click');
          const newHref = window.location.href.replace(object, '');
          window.history.replaceState('', '', newHref);
        }
        if (object.split('=')[0] === 'account_id') {
          this.$scope.find(`a[href='/general_ledger_accounts/${object.split('=')[1]}/edit']`).trigger('click');
        }
      });
    }
  }
};
