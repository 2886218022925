import Application from 'application-namespace';
import $ from 'jquery';

Application.Classes.BillForm = class BillForm extends Application.Classes.CoreBase {
  constructor($scope) {
    super();
    const $item = $scope.find('[data-selected-position]');
    this.$lineQuantityInput = $scope.find('[data-quantity-position]');
    this.$descriptionInput = $scope.find('[data-selected-position-description]');

    $item.on('change', event => this.fetchPositionItem(event));
    this.$lineQuantityInput.on('change', element => this.changeTotalPosition(element));

    $scope.find('table').on('cocoon:after-insert', (e, insertedItem) => {
      this.initializePluginsAfterInsert(insertedItem);
      $($(insertedItem).find('.select-picker')[0]).on('change', event => this.fetchPositionItem(event));
      $($(insertedItem).find('[data-quantity-position]')[0]).on('change', element => this.changeTotalPosition(element));
      this.bindDoubleClick($(insertedItem).find('[data-selected-position-description]'));
    });

    this.bindDoubleClick(this.$descriptionInput);
  }

  fetchPositionItem(event) {
    const $item = $(event.currentTarget);
    $.ajax({
      url: Routes.item_path($item.val()),
      dataType: 'JSON',
    }).then((response) => {
      this.constructor.pasteItemOptions($item, response);
      $item.closest('tr').find('[data-quantity-position]').trigger('change');
    }).catch((error) => {
      console.error('Error: ', error);
    });
  }

  static pasteItemOptions(object, result) {
    const $parent = object.closest('.nested-fields');
    $parent.find('[data-selected-position-description]').text(result.description);
    $parent.find('[data-selected-position-price]').text(`$${result.purchasing_price_cents / 100}`);
  }

  bindDoubleClick(selectors) {
    return $(selectors).on('dblclick', (e) => {
      if ($(e.target).closest('.change_width_input').length) {
        $(e.target).removeClass('change_height_input').closest('td').removeClass('change_width_input');
      } else {
        this.$descriptionInput.removeClass('change_height_input').closest('td').removeClass('change_width_input');
        $(e.target).addClass('change_height_input').closest('td').addClass('change_width_input');
      }
    });
  }

  changeTotalPosition(element) {
    const line = $(element.target).closest('tr');
    const quantity = $(element.currentTarget).val();
    const price = line.find('[data-selected-position-price]').html().slice(1);
    const total = parseFloat(price * quantity).toFixed(2);
    this.importantPasteInDiv(line.find('[data-selected-position-total]'), `$${total || 0}`);
  }
};
