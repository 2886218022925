import Application from 'application-namespace';
import $ from 'jquery';
import _first from 'lodash/first';

Application.Classes.OutgoingPaymentsForm = class OutgoingPaymentsForm extends Application.Classes.CoreBase {
  constructor($scope) {
    super();
    this.$scope = $scope;
    this.$businessPartner = this.$scope.find('#outgoing_payment_business_partner_id');
    this.$servicePartnerType = this.$scope.find('#outgoing_payment_service_partner_type_id');
    this.$totalAmountDue = this.$scope.find('#total_amount_due');
    this.$paymentOnAccount = this.$scope.find('#outgoing_payment_payment_on_account');
    this.$paymentMeanTabs = this.$scope.find('[data-role="presentation"]');
    this.$currentTab = _first(this.$paymentMeanTabs);
    this.$warningTransaction = gon.warningTransaction;
    this.$submitBtn = this.$scope.find('[type="submit"]');
    this.$contactPeople = this.$scope.find('[data-contact-person]');

    this.$paymentMeanTabs.on('mouseup', (event) => {
      this.clearPaymentForm(event);
    });
    this.$paymentMeanTabs.on('click', (event) => {
      this.$paymentMeanForCheck = $(event.currentTarget);
      this.currentFormSerialize = this.$scope.find(`${$(event.currentTarget).find('a').attr('href')}`).find('input').serialize();
    });

    $(this.$currentTab).trigger('click');

    this.$submitBtn.on('click', (event) => {
      this.checkBalanceByAcoount(event);
    });

    this.$checkAccountInput = this.$scope.find('#outgoing_payment_payment_mean_check_attributes_check_account_id');
    this.$bankTransferInput = this.$scope.find('#outgoing_payment_payment_mean_bank_transfer_attributes_bank_transfer_account_id');
    this.$creditCardInput = this.$scope.find('#outgoing_payment_payment_mean_credit_card_attributes_credit_card_account_id');
    this.$cashAccount = this.$scope.find('#outgoing_payment_payment_mean_cash_attributes_cash_account_id');

    this.defaultCheckAccount = this.$checkAccountInput.val();
    this.defaultBankTransferAccount = this.$bankTransferInput.val();
    this.defaultCreditCardAccount = this.$creditCardInput.val();
    this.defaultCashAccount = this.$cashAccount.val();

    this.specificBusinessPartnerId = this.findGetParameter('business_partner_id');
    this.specificBusinessPartnerType = this.findGetParameter('business_partner_type');

    this.$checkAccountInput.on('select2:select', (event) => {
      if (this.defaultCheckAccount) {
        this.changePaymentAccount(event);
      }
      this.getBalanceByAccount(event);
    });
    this.$bankTransferInput.on('select2:select', (event) => {
      if (this.defaultBankTransferAccount) {
        this.changePaymentAccount(event);
      }
      this.getBalanceByAccount(event);
    });
    this.$creditCardInput.on('select2:select', (event) => {
      if (this.defaultCreditCardAccount) {
        this.changePaymentAccount(event);
      }
      this.getBalanceByAccount(event);
    });
    this.$cashAccount.on('select2:select', (event) => {
      if (this.defaultCashAccount) {
        this.changePaymentAccount(event);
      }
      this.getBalanceByAccount(event);
    });

    this.$paymentOnAccount.on('change', (event) => {
      this.amountDueWithPaymentOnAccount(event);
    });
    this.$paymentOnAccount.on('keydown', (event) => {
      if ((event.keyCode === 9) && ($(event.currentTarget).val() === '')) {
        $(event.currentTarget).val(parseFloat(0).toFixed(2));
      }
    });
    this.$servicePartnerType.on('change', () => {
      if (this.$servicePartnerType.val()) {
        return this.fetchServicePartnerType(this.$servicePartnerType);
      }
      this.$businessPartner.val('').trigger('change');
      return this.$businessPartner.find('option').remove();
    });

    this.$businessPartner.on('change', () => {
      $('.nested-fields').empty();
      if (this.$businessPartner.val()) {
        this.fetchBusinessPartner(this.$businessPartner);
      } else {
        $('#name, #bill_to, #contact_person, #open_balance').val('');
      }
    }).trigger('change');

    if (this.specificBusinessPartnerType) {
      this.$servicePartnerType.val(this.specificBusinessPartnerType).trigger('change');
    }

    this.$scope.on('cocoon:after-insert', (e, insertedItem) => {
      Application.app.initializeSelectPicker(insertedItem);
      Application.app.initializeMathInput(insertedItem);
      $(insertedItem).find('[type="checkbox"]').on('change', (event) => {
        this.calculationTotalPayment(event);
        this.calculationTotalAmountDue(event);
        this.amountDueWithPaymentOnAccount(event);
      });

      const $totalByLine = $(insertedItem).find('.document_total_payment input');
      $totalByLine.on('click', (event) => {
        if (parseFloat($(event.currentTarget).val()) === 0) {
          $(event.currentTarget).val('');
        }
      });
      $totalByLine.on('change', (event) => {
        this.changeTotalPayment(event);
      });
      $totalByLine.on('keydown', (event) => {
        if ((event.keyCode === 9) && ($(event.currentTarget).val() === '')) {
          $(event.currentTarget).val(parseFloat(0).toFixed(2));
        }
      });
      $('html').click((htmlEvent) => {
        if ($(htmlEvent.target)[0] !== $totalByLine[0]) {
          if (!$totalByLine.val()) {
            $totalByLine.val(parseFloat(0).toFixed(2));
            this.pasteInCheckbox($(insertedItem).find('[type="checkbox"]'), false);
          }
        }
      });
    });
  }

  clearPaymentForm(event) {
    if (this.$scope.find(`${$(this.$paymentMeanForCheck).find('a').attr('href')}`).find('input').serialize() === this.currentFormSerialize) {
      this.$currentTab = $(event.currentTarget);
    }
    return BootstrapDialog.show({
      type: BootstrapDialog.TYPE_WARNING,
      title: 'Warning',
      closeByBackdrop: false,
      closeByKeyboard: false,
      message: 'You have unsaved changes, which will be lost. Do you want to proceed?',
      cssClass: 'main-modal confirm-modal-window setting-confirm',
      buttons: [{
        label: 'NO',
        cssClass: 'btn-data-confirm-cancel btn-danger',
        action: (dialogRef) => {
          dialogRef.close();
          $(this.$currentTab).find('a').trigger('click');
          this.currentFormSerialize = this.$scope.find(`${$(event.currentTarget).find('a').attr('href')}`).find('input').serialize();
        },
      },
      {
        label: 'YES',
        cssClass: 'btn-data-confirm btn-success',
        action: (dialogRef) => {
          $('.tab-pane').each((k, v) => {
            if (!$(v).hasClass('active')) {
              $(v).find('input').val('');
              this.$currentTab = $(event.currentTarget);
            }
          });
          dialogRef.close();
        },
      },
      ],
    });
  }

  fetchServicePartnerType($servicePartnerType) {
    $.ajax({
      url: Routes.service_partner_type_path($servicePartnerType.val()),
      dataType: 'JSON',
    }).then((response) => {
      this.pasteSptOptions($servicePartnerType, response);
      if (this.specificBusinessPartnerId) {
        this.$businessPartner.val(this.specificBusinessPartnerId).trigger('change');
      }
    }).catch((error) => {
      console.error('Error: ', error);
    });
  }

  fetchBusinessPartner($businessPartner) {
    $.ajax({
      url: Routes.business_partner_path($businessPartner.val()),
      dataType: 'JSON',
      data: {
        type: 'outgoing_payment',
        definite_document_id: this.findGetParameter('document_id'),
        definite_document_class: this.findGetParameter('document_class'),
      },
    }).then((response) => {
      this.pasteBpOptions($businessPartner, response);
      this.pasteToList(this.$contactPeople, response.contact_people, 'contact_id');
    }).catch((error) => {
      console.error('Error: ', error);
    });
  }

  getBalanceByAccount(event) {
    if (!this.$warningTransaction) {
      $.ajax({
        url: Routes.balance_general_ledger_account_path($(event.currentTarget).val()),
        dataType: 'JSON',
      }).then((response) => {
        $(event.currentTarget).closest('.tab-pane').data('balance', response).find('.amount-input')
          .trigger('change');
      }).catch((error) => {
        console.error('Error: ', error);
      });
    } else {
      console.warn(`Application.Classes.OutgoingPaymentsForm#getBalanceByAccount !this.$warningTransaction - ${this.$warningTransaction}`);
    }
  }

  checkBalanceByAcoount(event) {
    if (!this.$warningTransaction) {
      return;
    }
    const amount = this.$scope.find('.tab-content').find('.active').find('.amount-input').val();
    const balance = this.$scope.find('.tab-content').find('.active').data('balance') / 100;
    if (parseFloat(balance) < parseFloat(amount)) {
      event.preventDefault();
      BootstrapDialog.show({
        type: BootstrapDialog.TYPE_WARNING,
        title: 'Low balance',
        closeByBackdrop: false,
        closeByKeyboard: false,
        message: `A low balance on your account (${balance}). Do you want to save?`,
        cssClass: 'main-modal confirm-modal-window setting-confirm',
        buttons: [{
          label: 'NO',
          cssClass: 'btn-data-confirm-cancel btn-danger',
          action: dialogRef => dialogRef.close(),
        },
        {
          label: 'YES',
          cssClass: 'btn-data-confirm btn-success',
          action: dialogRef => this.$scope.submit() && dialogRef.close(),
        },
        ],
      });
    }
  }

  changePaymentAccount(object) {
    return BootstrapDialog.show({
      type: BootstrapDialog.TYPE_WARNING,
      title: 'Warning',
      closeByBackdrop: false,
      closeByKeyboard: false,
      message: 'You selected other account. Do you want to save?',
      cssClass: 'main-modal confirm-modal-window setting-confirm',
      buttons: [{
        label: 'NO',
        cssClass: 'btn-data-confirm-cancel btn-danger',
        action: (dialogRef) => {
          if ($(object.currentTarget)[0] === this.$checkAccountInput[0]) {
            this.pasteInSelect(this.$checkAccountInput, this.defaultCheckAccount);
          }
          if ($(object.currentTarget)[0] === this.$bankTransferInput[0]) {
            this.pasteInSelect(this.$bankTransferInput, this.defaultBankTransferAccount);
          }
          if ($(object.currentTarget)[0] === this.$creditCardInput[0]) {
            this.pasteInSelect(this.$creditCardInput, this.defaultCreditCardAccount);
          }
          if ($(object.currentTarget)[0] === this.$cashAccount[0]) {
            this.pasteInSelect(this.$cashAccount, this.defaultCashAccount);
          }
          return dialogRef.close();
        },
      },
      {
        label: 'YES',
        cssClass: 'btn-data-confirm btn-success',
        action: (dialogRef) => {
          if ($(object.currentTarget)[0] === this.$checkAccountInput[0]) {
            this.defaultCheckAccount = $(object.currentTarget).val();
          }
          if ($(object.currentTarget)[0] === this.$bankTransferInput[0]) {
            this.defaultBankTransferAccount = $(object.currentTarget).val();
          }
          if ($(object.currentTarget)[0] === this.$creditCardInput[0]) {
            this.defaultCreditCardAccount = $(object.currentTarget).val();
          }
          if ($(object.currentTarget)[0] === this.$cashAccount[0]) {
            this.defaultCashAccount = $(object.currentTarget).val();
          }
          return dialogRef.close();
        },
      },
      ],
    });
  }

  pasteSptOptions(object, result) {
    return this.pasteToList(this.$businessPartner, result.business_partners, 'full_description');
  }

  pasteBpOptions(object, result) {
    const accountBalance = result.account_balance;
    const contactPerson = result.default_contact_person;
    const billingAddress = result.default_billing_address;

    this.pasteInInput('#name', result.name);
    if (accountBalance) {
      this.pasteInInput('#open_balance', accountBalance);
    } else {
      this.pasteInInput('#open_balance', ' ');
    }
    if (contactPerson) {
      this.pasteInInput('#contact_person', contactPerson);
    } else {
      this.pasteInInput('#contact_person', ' ');
    }
    if (billingAddress) {
      this.pasteInInput('#bill_to', billingAddress);
    } else {
      this.pasteInInput('#bill_to', ' ');
    }

    return result.all_payments.forEach((value) => {
      if (value) {
        $('.add_fields').trigger('click');
        const $allLines = $('.nested-fields');
        const line = $allLines[$($allLines).length - 1];
        if (value[0].type === 'JournalEntry') {
          $(line).addClass('warning');
        }
        this.pasteInInput($(line).find('.selected_position input'), value[0].id);
        $(line).find('.selected_link').html(`<a target='_blank' href=${value[0].path}>${value[0].number}</a>`);
        this.pasteInInput($(line).find('.document_type input'), value[0].type);
        this.importantPasteInDiv($(line).find('.document_data'), value[0].date);
        this.importantPasteInDiv($(line).find('.document_total'), value[0].total);
        this.importantPasteInDiv($(line).find('.document_due'), value[0].balance_due);
      }
    });
  }

  calculationTotalAmountDue() {
    let totalAmountDue = 0.00;
    $('.nested-fields').each((i, element) => {
      if ($(element).find('[type="checkbox"]').prop('checked') === true) {
        const lineTotal = $(element).find('.document_total_payment input').val();
        totalAmountDue += parseFloat(lineTotal);
      }
    });
    this.pasteInInput(this.$totalAmountDue, `$${totalAmountDue.toFixed(2)}`);
  }

  calculationTotalPayment(event) {
    const line = $(event.currentTarget).closest('.nested-fields');
    const lineBalanceDue = $(line).find('.document_due').html().slice(1)
      .replace(',', '');
    const $lineTotalPaymnet = $(line).find('.document_total_payment input');
    if ($(event.currentTarget).prop('checked') === true) {
      if ($($lineTotalPaymnet).val() === '') {
        this.pasteInCheckbox($(line).find('[type="checkbox"]'), false);
      } if ($($lineTotalPaymnet).val() === '0.00') {
        this.pasteInInput($($lineTotalPaymnet), parseFloat(lineBalanceDue).toFixed(2));
      }
    } else {
      this.pasteInInput($($lineTotalPaymnet), parseFloat(0).toFixed(2));
    }
  }

  changeTotalPayment(event) {
    const line = $(event.currentTarget).closest('.nested-fields');
    const balanceDue = $(event.currentTarget).closest('.nested-fields').find('.document_due').html()
      .slice(1)
      .replace(',', '');
    const currentTotalPayment = $(event.currentTarget).val();
    const $totalLine = $(line).find('.document_total_payment input');
    if ($totalLine !== parseFloat(0)) {
      this.pasteInCheckbox($(event.currentTarget).closest('.nested-fields').find('[type="checkbox"]'), true);
    }
    if (parseFloat(currentTotalPayment) === 0) {
      this.pasteInInput($totalLine, parseFloat(0).toFixed(2));
      this.pasteInCheckbox($(line).find('[type="checkbox"]'), false);
    }
    // #NOTE: Validations ->
    if (balanceDue > 0) {
      if (currentTotalPayment < 0) {
        this.pasteInInput($totalLine, '0');
        this.pasteInCheckbox($(line).find('[type="checkbox"]'), false);
      }
    } else if (parseFloat(currentTotalPayment) > parseFloat(0)) {
      this.pasteInInput($totalLine, parseFloat(0).toFixed(2));
      this.pasteInCheckbox($(line).find('[type="checkbox"]'), false);
    } else if (parseFloat(currentTotalPayment) < parseFloat(balanceDue)) {
      this.pasteInInput($totalLine, balanceDue);
    }
    // #NOTE: Validations <-
    this.amountDueWithPaymentOnAccount(event);
  }

  amountDueWithPaymentOnAccount(event) {
    this.calculationTotalAmountDue(event);
    const paymentOnAccountVal = parseFloat(this.$paymentOnAccount.val()) || 0;
    const totalAmountDue = parseFloat(this.$totalAmountDue.val().slice(1)) + paymentOnAccountVal;
    this.pasteInInput(this.$totalAmountDue, `$${totalAmountDue}`);
  }
};
