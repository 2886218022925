import React, { Component } from 'react';
import Form from 'react-bootstrap/Form';
import { camelCase, capitalize, lowerCase } from 'lodash';
import InputGroup from 'react-bootstrap/InputGroup';

class RenderField extends Component {

  render() {
    const {
      input: { name, value, onChange },
      label,
      prepend,
      type,
      as,
      disabled,
      meta: { error, touched }
    } = this.props;

    return (
      <>
        {label && <Form.Label>{label}</Form.Label>}
        {label === undefined && <Form.Label>{capitalize(lowerCase(name))}</Form.Label>}
        {prepend &&
        <InputGroup>
          <InputGroup.Prepend>
            <InputGroup.Text id={`inputGroup${camelCase(name)}`}>{prepend}</InputGroup.Text>
          </InputGroup.Prepend>
          <Form.Control
            as={as || 'input'}
            type={type || 'input'}
            value={value}
            disabled={disabled || false}
            aria-describedby={`inputGroup${camelCase(name)}`}
            onChange={onChange}
            className={error ? 'is-invalid' : null}
          />
        </InputGroup>
        }
        {!prepend &&
        <Form.Control
          as={as || 'input'}
          type={type || 'input'}
          value={value}
          disabled={disabled || false}
          onChange={onChange}
          className={error ? 'is-invalid' : null}
        />
        }
        <Form.Control.Feedback type="invalid">
          {error}
        </Form.Control.Feedback>
      </>
    );
  }

}

export default RenderField;
