import Application from 'application-namespace';
import $ from 'jquery';

Application.Concerns.NetworkObserver = superclass => class extends superclass {
  startObserver($scope) {
    if (window.localStorage.lostFormUrl === window.location.href) {
      this.showAlert($scope);
    }
    this.$instanceForm = $scope.serializeArray();

    Offline.on('down', () => {
      if (this.$scope.serializeArray() !== this.$instanceForm) {
        this.$instanceForm = $scope.serializeArray();
        window.localStorage.setItem('lostFormUrl', window.location.href);
        window.localStorage.setItem('documentFormValues', JSON.stringify(this.$instanceForm));
        Application.Classes.Toastr.warning('The changes have been saved in the browser cache.');
      }
      this.disableInputs($scope, true);
    });

    Offline.on('up', () => {
      window.localStorage.documentFormValues = null;
      window.localStorage.lostFormUrl = null;
      this.disableInputs($scope, false);
    });
  }

  showAlert($scope) {
    const pasteValuesInForm = () => {
      $.parseJSON(window.localStorage.documentFormValues).forEach((object) => {
        const input = $scope.find(`[name='${object.name}']`);
        input.val(object.value).trigger('change.select2');
        input.closest('.input').find('[data-type-item]').trigger('change');
        input.closest('.input').find('[data-document-business-partner]').trigger('change');
        input.closest('.input').find('[data-selected-position-price]').trigger('change');
        window.localStorage.documentFormValues = null;
        window.localStorage.lostFormUrl = null;
      });
    };

    BootstrapDialog.show({
      type: BootstrapDialog.TYPE_WARNING,
      title: 'You have saved values for current form',
      message: 'Do you wand to load saved values?',
      cssClass: 'main-modal confirm-modal-window setting-confirm',
      buttons: [{
        label: 'NO, DELETE CHANGES',
        cssClass: 'btn-data-confirm-cancel btn-danger',
        action: (dialogRef) => {
          window.localStorage.documentFormValues = null;
          window.localStorage.lostFormUrl = null;
          dialogRef.close();
        },
      },
      {
        label: 'YES',
        cssClass: 'btn-data-confirm btn-success',
        action: (dialogRef) => {
          pasteValuesInForm();
          dialogRef.close();
        },
      },
      ],
    });
  }

  disableInputs($scope, state) {
    $scope.find('input, textarea, .select_picker, .btn').prop('disabled', state);
    $scope.find('a').attr('disabled', state);
  }
};
