import React from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';

let AddressShippingNav = ({
    partnerShippingAddresses,
    fields
  }) => {

    const newNestedForm = (event) => {
      event.preventDefault();
      fields.push({});
    };

    return (
      <Row>
        <Col sm={12}>
          <hr/>
          <h5 className='text-center'>Shipping Addresses</h5>

          <Nav variant="pills" className="flex-column">
            {
              fields.map((partnerShippingAddress, index) =>
                <Nav.Link
                  eventKey={`shipping-${index}`}
                  key={`partnerShippingAddress-${index}`}
                >{partnerShippingAddresses[index].address_name || 'Define new'}</Nav.Link>
              )
            }
            <Nav.Item>
              <Nav.Link
                eventKey="first-shipping"
                onClick={newNestedForm}
              >Add</Nav.Link>
            </Nav.Item>
          </Nav>
        </Col>
      </Row>
    );
  }
;

AddressShippingNav = reduxForm({
  form: 'businessPartnerForm',
  enableReinitialize: true
})(AddressShippingNav);

AddressShippingNav = connect(state => {
  const { values: { partner_shipping_addresses_attributes: partnerShippingAddresses } } = state.form.businessPartnerForm;
  return {
    partnerShippingAddresses,
  };
})(AddressShippingNav);

export default AddressShippingNav;
