import Application from 'application-namespace';
import toastr from 'toastr';
import _get from 'lodash/get';

Application.Classes.Toastr = class Toastr {
  constructor() {
    this.$element = undefined;
    this.initClass();
  }

  static initClass() {
    this.prototype.container = '.flash-messages';
    this.prototype.defaults = {
      type: 'success',
      animate: {
        enter: 'animated fadeInRight',
        exit: 'animated fadeOutRight',
      },
    };
  }

  static error(message = '') {
    const options = { type: 'error', title: 'Warning!' };
    this.render(message, options);
    return this.$element;
  }

  static warning(message = '') {
    const options = { type: 'warning', title: 'Warning!' };
    this.render(message, options);
    return this.$element;
  }

  static success(message = '') {
    const options = { type: 'success', title: 'Success!' };
    this.render(message, options);
    return this.$element;
  }

  static info(message = '') {
    const options = { type: 'info', title: 'Info!' };
    this.render(message, options);
    return this.$element;
  }

  static backgroundJob(message = '') {
    const options = {
      type: 'info',
      title: 'Background Job',
      timeOut: false,
      extendedTimeOut: false,
    };
    this.render(message, options);
    return this.$element;
  }

  static render(message, options = {}) {
    const type = options.type === 'danger' ? 'error' : options.type;

    toastr.options = {
      closeButton: true,
      debug: false,
      newestOnTop: false,
      progressBar: true,
      positionClass: 'toast-bottom-left',
      preventDuplicates: false,
      onclick: null,
      showDuration: '10000',
      hideDuration: '4000',
      timeOut: _get(options, 'timeOut', '10000'),
      extendedTimeOut: _get(options, 'extendedTimeOut', '4000'),
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'slideDown',
      hideMethod: 'slideUp',
    };

    this.$element = toastr[type](message);
  }
};
