import React, { Component } from 'react';
import { Formik } from 'formik';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import LabelInputValue from '../../../decorators/LabelInputValue';

let StringForm = ({ setting, setSettingFormSubmit,  }) => {
  const settingInForm = {};
  const { id, var:settingVar } = setting;
  settingInForm[settingVar] = setting.value;

  return (
    <Formik
      initialValues={settingInForm}
      enableReinitialize={true}
      onSubmit={(values) => {
        const value = values[settingVar];
        const newSetting = {
          id,
          setting: { value }
        };
        setSettingFormSubmit(newSetting);
      }}
    >
      {({
        handleSubmit,
        handleChange,
        values,
        errors,
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <div className="d-flex bd-highlight mb-3 row" style={{ height: '60px' }}>
            <div className="p-2 bd-highlight align-self-end col-9 mb-0">
              <Form.Row>
                <Form.Group as={Col} md='12'>
                  <Form.Label>{LabelInputValue(settingVar)}</Form.Label>
                  <Form.Control
                    type="text"
                    value={values[settingVar]}
                    name={setting['var']}
                    onChange={handleChange}
                    isInvalid={!!errors[settingVar]}
                  />
                  {!!errors[settingVar] &&
                  <div style={{
                    color: 'red',
                    marginTop: '.5rem'
                  }}>{errors[settingVar]}</div>
                  }
                </Form.Group>
              </Form.Row>
            </div>
            <div className="align-self-center p-2 mt-2 bd-highlight col-3">
              <Button type='submit' className='btn btn-success btn-block'>Update</Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default StringForm;
