import axios from 'axios';

import { LOAD_PAYROLL_SALARY_CHANGES, LOAD_SALARY_CHANGE_REASONS, LOAD_ACTIVE_EMPLOYEES } from './actionTypes'

export const loadSalaryChangeReasons = () => {
  return (dispatch) => {
    return (
      axios ({ url: Routes.api_web_v1_payroll_salary_change_reasons_path() })
        .then(response => {
          dispatch({
            type: LOAD_SALARY_CHANGE_REASONS,
            payload: { response }
          })
        })
        .catch(error => {
          console.log(error);
        })
    )
  }
};

export const loadPayrollSalaryChanges = (params) => {
  return (dispatch) => {
    return (
      axios({ url: Routes.commissions_report_api_web_v1_payroll_salary_changes_path(params) })
        .then(response => {
          dispatch({
            type: LOAD_PAYROLL_SALARY_CHANGES,
            payload: { response }
          });
        })
        .catch(error => {
          console.log(error);
        })
    );
  };
};

export const loadActiveEmployees = () => {
  return (dispatch) => {
    return (
      axios ({ url: Routes.employees_active_api_web_v1_employees_path() })
        .then(response => {
          dispatch({
            type: LOAD_ACTIVE_EMPLOYEES,
            payload: { response }
          })
        })
        .catch(error => {
          console.log(error);
        })
    )
  }
};

