import Application from 'application-namespace';
import $ from 'jquery';

require('jquery-ui');
require('jquery-ui/ui/widgets/sortable');
require('jquery-ui/ui/disable-selection');
require('jquery-ui/ui/widgets/draggable');

Application.Classes.TaskManager = class TaskManager extends Application.Classes.CoreBase {
  constructor($scope) {
    super();
    this.$scope = $scope;
    this.$cardElement = this.$scope.find('.card-element');
    this.$copyElement = this.$scope.find('.glyphicon-option-vertical');
    this.$ticketContent = this.$scope.find('.ticket-content');
    window.localStorage.activeDraggable = false;

    this.determinationPriority();

    $('.draggable_section').sortable({ connectWith: '.draggable_section' });
    this.$cardElement.draggable({
      connectToSortable: '.draggable_section',
      containment: '.draggable_section',
      start: () => {
        window.localStorage.activeDraggable = true;
      },
      stop: (element) => {
        this.updateServiceCall(element);
        window.localStorage.activeDraggable = false;
      },
    });

    $('.ticket-content').disableSelection();

    this.$copyElement.on('mousedown', (el) => {
      const $currentTicket = $(el.currentTarget).closest('.card-element');
      if (!($currentTicket.closest('.employee-ticket').length > 0)) { return; }
      $currentTicket.clone().appendTo($currentTicket.closest('.draggable_section')).draggable({
        connectToSortable: '.draggable_section',
        containment: '.draggable_section',
        start: () => {
          window.localStorage.activeDraggable = true;
        },
        stop: (element) => {
          this.updateServiceCall(element);
          window.localStorage.activeDraggable = false;
        },
      });
    });
  }

  updateServiceCall(el) {
    let businessPartnerIds = [];
    const tsoId = $(el.target).data('tsoId');
    if ($(el.target).closest('.employee-ticket').data('employeeId')) {
      this.$scope.find(`[data-tso-id='${tsoId}']`).each((i, element) => {
        const businessPartner = $(element).closest('.employee-ticket');
        $(businessPartner).find(`[data-tso-id='${tsoId}']`).each((key, partnerElement) => {
          if (key > 0) { $(partnerElement).remove(); }
        });
        businessPartnerIds.push($(businessPartner).data('employeeId'));
      });
    } else {
      businessPartnerIds = [null];
      this.$scope.find('.employee-ticket').find(`[data-tso-id='${tsoId}']`).remove();
    }

    $.ajax({
      url: Routes.tech_sales_order_path({ id: tsoId }),
      dataType: 'JSON',
      type: 'PUT',
      data: { tech_sales_order: { employee_ids: businessPartnerIds } },
    }).then(() => {
      Application.Classes.Toastr.success('Service Call was updated!');
    }).catch((error) => {
      console.error('Error: ', error);
    });
  }

  determinationPriority() {
    this.$ticketContent.each((i, element) => {
      const ticketColor = $(element).data('ticketColor');
      switch (ticketColor) {
        case 'yellow': $(element).css('color', 'rgb(255, 228, 0)'); break;
        case 'green': $(element).css('color', 'green'); break;
        case 'red': $(element).css('color', 'red'); break;
        default: console.warn('missed case in determinationPriority', ticketColor);
      }
    });
  }
};
