import React, { Component } from 'react';
import { connect } from 'react-redux';
import { change } from 'redux-form';
import AsyncSelect from 'react-select/async/dist/react-select.esm';
import axios from 'axios';
import { split, get, map, isArray, isInteger, size } from 'lodash';
import Form from 'react-bootstrap/Form';
import Select2Collection from '../../decorators/Select2Collection';
import { capitalize, lowerCase } from 'lodash';

class Autocomplete extends Component {

  state = {
    defaultValue: undefined,
    defaultOptions: [],
    currentCollection: []
  };

  componentDidMount() {
    const { input, formValues } = this.props;
    if (formValues && formValues.values) {
      this.loadDefaultValue(get(formValues.values, input.name));
    }
  }

  loadDefaultValue(value) {
    const { fetchPath, responseKeyArray, handleChange } = this.props;

    if (isInteger(value) || (isArray(value) && size(value) > 0)) {
      return axios({
        url: `${split(fetchPath, '?', 1)}`,
        params: { ids: value }
      })
        .then(response => {
          const options = response.data[responseKeyArray];
          this.setState({
            defaultValue: Select2Collection(options, { labelKey: 'full_name' } ),
            currentCollection: options
          });
          if (handleChange) {
            handleChange(option);
          }
        })
        .catch(error => {
          console.log(error);
        });
    } else {
      this.setState({ defaultValue: null });
    }
  };

  loadOptions = (searchTerm) => {
    const { fetchPath, responseKeyArray } = this.props;

    return axios({
      method: 'GET',
      url: fetchPath,
      params: {
        search_term: searchTerm,
        per: 5
      }
    })
      .then(response => {
        const options = response.data[responseKeyArray];
        this.setState({ currentCollection: options });
        return Select2Collection(options, { labelKey: 'full_name' });
      })
      .catch(error => {
        console.log(error);
      });
  };

  loadDefaultOptions = () => {
    this.loadOptions()
      .then(result => this.setState({ defaultOptions: result }));
  };

  onChangeField = (value) => {
    const { changeFieldValue, formName, input, isMulti } = this.props;
    let inputValue = value.value;

    if (isMulti) {
      inputValue = map(value, (option) => {
        return option.value;
      });
    }

    changeFieldValue(formName, input.name, inputValue);
    this.setState({ defaultValue: value });
  };

  render() {
    const { label, disabled, input, meta: { error }, isMulti } = this.props;
    const { defaultOptions, defaultValue } = this.state;

    return (
      <div>
        {label && <label>{label}</label>}
        {label === undefined && <label>{capitalize(lowerCase(input.name))}</label>}
        <div>
          <div style={{ minWidth: '150px' }}>
            <AsyncSelect
              isDisabled={disabled}
              cacheOptions
              isMulti={isMulti}
              defaultOptions={defaultOptions}
              value={defaultValue}
              loadOptions={this.loadOptions}
              onChange={this.onChangeField}
              onMenuOpen={this.loadDefaultOptions}
              className={error ? 'is-invalid' : null}
            />
            <Form.Control.Feedback type="invalid">
              {error}
            </Form.Control.Feedback>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  changeFieldValue: (formName, fieldName, value) => {
    dispatch(change(formName, fieldName, value));
  },
});

const mapStateToProps = (state, ownProps) => {
  return {
    formValues: get(state.form, ownProps.formName)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Autocomplete);
