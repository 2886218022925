import React, { Component } from 'react';
import { Field } from 'redux-form';
import Button from 'react-bootstrap/Button';
import FileInput from '../../../../components/ReduxForm/FileInput';

class DocumentAttachmentFields extends Component {

  render() {
    const { attachment, index, fields } = this.props;
    const attachmentLine = fields.get(index);

    return (
      <tr key={index}>
        <td className="d-none">
          <Field
            name={`${attachment}.id`}
            type="text"
            component="input"
          />
        </td>
        {!attachmentLine.id &&
        <td>
          <Field
            name={`${attachment}.attach`}
            type="file"
            component={FileInput}
          />
        </td>
        }
        {attachmentLine.id &&
        <td>
          <Button
            variant="info"
            target="_blank"
            size="sm"
            href={Routes.download_attachment_path(attachmentLine.id)}
          >{`Download ${attachmentLine.filename}`}</Button>
        </td>
        }
        <td>
          <Button variant="danger" className="w-100" size="sm" onClick={() => fields.remove(index)}>
            Remove
          </Button>
        </td>
      </tr>
    );
  }

}

export default DocumentAttachmentFields;

