import Application from 'application-namespace';

import 'timezone-picker';
import _uniqueId from 'lodash/uniqueId';

Application.Classes.TimeZonePicker = class TimeZonePicker {
  constructor($scope) {
    $scope.addClass('hidden');
    this.mapId = _uniqueId(`map_${$scope.attr('id')}`);
    $scope.after(`<br><div class="map" id=${this.mapId}></div>`);
    this.$timeZoneMap = $(`#${this.mapId}`);

    this.$timeZoneMap.timezonePicker({
      quickLink: [{ 'NEW YORK': 'America/New_York', EST: 'EST' }],
    });

    this.$timeZoneMap.find('select').on('change', (event) => {
      $scope.val($(event.currentTarget).val());
    });

    const defaultValue = $scope.val().length < 1 ? 'America/New_York' : $scope.val();
    this.$timeZoneMap.data('timezonePicker').setValue(defaultValue);
  }
};
