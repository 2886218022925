import Application from 'application-namespace';
import $ from 'jquery';

Application.Classes.EmployeeForm = class EmployeeForm {
  constructor($scope) {
    this.$scope = $scope;
    this.$scope.find('.show-federal_tax_setups').click(() => this.$scope.find('#federal_tax_setups_wrapper').toggle());
    this.$scope.find('.show-new_york_tax_setups').click(() => this.$scope.find('#new_york_tax_setups_wrapper').toggle());
    this.$scope.find('.show-connecticut_tax_setups').click(() => this.$scope.find('#connecticut_tax_setups_wrapper').toggle());
    this.$scope.find('.show-history-salaries').click(() => this.$scope.find('#salaries_wrapper').toggle());
    this.$scope.find('.show-history-bonuses').click(() => this.$scope.find('#bonuses_wrapper').toggle());
    this.$scope.find('.show-history-salary-changes').click(() => this.$scope.find('#payroll_salary_changes_wrapper').toggle());
    this.$scope.find('.show-history-loan').click(() => this.$scope.find('#loans_wrapper').toggle());
    this.$scope.find('.show-history-award').click(() => this.$scope.find('#awards_wrapper').toggle());
    this.$scope.find('.show-table-educations').click(() => this.$scope.find('#educations_wrapper').toggle());
    this.$scope.find('.show-table-reviews').click(() => this.$scope.find('#reviews_wrapper').toggle());
    this.$scope.find('.show-table-previous_employments').click(() => this.$scope.find('#previous_employments_wrapper').toggle());
    this.$scope.find('.show-history-vacation').click(() => this.$scope.find('#vacations_wrapper').toggle());
    this.$scope.find('.show-history-sick').click(() => this.$scope.find('#sicks_wrapper').toggle());
    this.$scope.find('.show-paid_absence_period_sick').click(() => this.$scope.find('#paid_absence_period_sick_days_wrapper').toggle());
    this.$scope.find('.show-paid_absence_period_vacation').click(() => this.$scope.find('#paid_absence_period_vacation_days_wrapper').toggle());
    this.$scope.find('[data-toggle="tab"]').on('click', (event) => {
      window.localStorage.employeeActiveTab = $(event.currentTarget).attr('href');
    });
    this.$usePeriodWorkStates = this.$scope.find('[name="employee[use_period_work_states]"]');

    this.initCurrentTab();

    this.$usePeriodWorkStates.on('change', (event) => {
      if ($(event.currentTarget).prop('checked')) {
        this.$scope.find('.work-states-form').find('.remove_fields').trigger('click');
        this.$scope.find('.work-states-form').addClass('d-none');
        this.$scope.find('[data-association-insertion-node="tbody.work_states.positions"]').trigger('click');
        // Note: it's not right but Matt want it.
        if ($(event.currentTarget).closest('.input').hasClass('field_with_errors')) {
          $(event.currentTarget).closest('.input').find('span.error').remove();
          $(event.currentTarget).closest('.input').removeClass('field_with_errors');
        }
      } else {
        this.$scope.find('.work-states-form').removeClass('d-none');
      }
    });

    this.$scope.find('#payroll-tab').on('cocoon:after-insert', (e, insertedItem) => {
      Application.app.initializeSelectPicker(insertedItem);
      Application.app.initializeMathInput(insertedItem);
    });

    if (document.location.toString().match('#')) {
      this.$scope.find(`.nav-tabs a[href="#${document.location.toString().split('#')[1]}"]`).tab('show');
    }

    this.$scope.find('[name="employee[get_paid_for_holidays]"]').on('change', (event) => {
      if ($(event.currentTarget).prop('checked')) {
        this.$scope.find('.only-for-get-paid-for-holidays').removeClass('d-none');
      } else {
        this.$scope.find('.only-for-get-paid-for-holidays').addClass('d-none');
      }
    });

    this.$scope.find('#employee_status').trigger('change');
    this.$scope.find('[name="employee[salary_type]"]').trigger('change');
    this.$usePeriodWorkStates.trigger('change');
    this.$scope.find('[name="employee[get_paid_for_holidays]"]').trigger('change');
  }

  initCurrentTab() {
    if (window.localStorage.employeeActiveTab) { this.$scope.find(`a[href='${window.localStorage.employeeActiveTab}']`).trigger('click'); }
  }
};
