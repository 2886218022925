import Application from 'application-namespace';
import $ from 'jquery';

Application.Concerns.CreditMemoSpecifically = superclass => class extends superclass {
  specificallyCreditMemo($scope) {
    this.$write_in_stock_toggle = $scope.find('[data-write-in-stock-toggle]');
    if (this.$write_in_stock_toggle.length) {
      this.$submit_btn.on('click', event => $('.warehouse-dropdown').each((i, object) => {
        event.preventDefault();
        if (!object.disabled) {
          event.preventDefault();
          BootstrapDialog.show({
            type: BootstrapDialog.TYPE_WARNING,
            title: 'Warning',
            message: `Write in stock set as ${this.$write_in_stock_toggle.is(':checked')}. Do you want to save?`,
            cssClass: 'main-modal confirm-modal-window setting-confirm',
            buttons: [{
              label: 'NO',
              cssClass: 'btn-data-confirm-cancel',
              action: dialogRef => dialogRef.close(),
            },
            {
              label: 'YES',
              cssClass: 'btn-data-confirm',
              action: (dialogRef) => { $scope.submit(); return dialogRef.close(); },
            },
            ],
          });
        }
      }));
    }
  }
};
