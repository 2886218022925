import React, { useCallback } from 'react';
import MaterialTable from 'material-table';
import axios from 'axios';
import reduce from 'lodash/reduce';

const MaterialDataTable = ({
  title,
  path,
  tableColumns,
  onEdit = () => {
  },
  onRemove = () => {
  },
  onAdd = () => {
  }
}) => {

  const columnParams = useCallback((query) => {
    return reduce(tableColumns, (result, tableColumn, index) => {
      if (tableColumn.field) {
        result[`columns[${index}][data]`] = tableColumn.field;
        result[`columns[${index}][search][value]`] = query.search || '';
        result[`columns[${index}][searchable]`] = tableColumns.searchable || false;
        result[`columns[${index}][orderable]`] = tableColumns.sorting || false;
        if (query.orderBy && query.orderBy.field === tableColumn.field) {
          result[`order[${index}][column]`] = index;
          result[`order[${index}][dir]`] = query.orderDirection;
        }
      }
      result[`start`] = query.page * query.pageSize;
      result[`length`] = query.pageSize;
      return result;
    }, {});
  }, [tableColumns]);

  return (
    <>
      <MaterialTable
        title={title}
        columns={tableColumns}
        options={{
          actionsColumnIndex: -1,
          pageSize: 10
        }}
        editable={
          {
            onRowDelete: (oldData) => {
              return (onRemove(oldData));
            }
          }
        }
        actions={[
          {
            icon: 'add',
            tooltip: 'Add',
            isFreeAction: true,
            iconProps: {color: 'primary'},
            onClick: (event) => onAdd()
          },
          rowData => ({
            icon: 'edit',
            tooltip: 'Edit',
            iconProps: {color: 'primary'},
            onClick: (event, rowData) => onEdit(rowData)
          })
        ]}
        data={(query) => {
          let params = columnParams(query);
          return axios({
            method: 'GET',
            url: path,
            params: params
          })
            .then(response => {
              console.log('MaterialTable response >>>', response);
              console.log('MaterialTable response.recordsTotal >>>', response.data.recordsTotal);
              return ({
                data: response.data.data,
                page: query.page,
                totalCount: response.data.recordsTotal || 0
              });
            })
            .catch(error => {
              console.log(error);
            });
        }}
      />
    </>
  );
};

export default MaterialDataTable;


