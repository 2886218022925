import React, { Fragment } from 'react';
import GlobalRetirementBenefitSettings from './GlobalRetirementBenefitSettings';
import { Switch, Route } from 'react-router-dom';
import FormStepper from './FormStepper';
import CompanyRetirementSettingsList from './CompanyRetirementSettingsList';
import { Redirect} from 'react-router-dom';
import { useSelector } from 'react-redux';
import find from 'lodash/find'

const CompanyRetirementSettings = () => {

  const currentSetupStepData = useSelector(state => state.projectPayrollSetupStore.notConfiguredSettings);

  return (
    <Fragment>
      {
        currentSetupStepData && find(currentSetupStepData, { path: 'company_retirement_settings' }) &&
        <Redirect from="/company_retirement_settings" to="/company_retirement_settings/new" />
      }
      <h1>RETIREMENT SETTING</h1>
      <GlobalRetirementBenefitSettings/>
      <Switch>
        <Route path="/company_retirement_settings/new" component={FormStepper}/>
        <Route path="/company_retirement_settings/:companyRetirementSettingId/edit" component={FormStepper}/>
        <Route path="/company_retirement_settings/:companyRetirementSettingId/company_retirement_setting_lines" component={FormStepper}/>
        <Route path="/company_retirement_settings/:companyRetirementSettingId/company_retirement_setting_lines/new" component={FormStepper}/>
        <Route path="/company_retirement_settings/:companyRetirementSettingId/company_retirement_setting_lines/:companyRetirementSettingLineId/edit" component={FormStepper}/>
        <Route path="/company_retirement_settings" component={CompanyRetirementSettingsList}/>
      </Switch>
    </Fragment>
  );
};

export default CompanyRetirementSettings;
