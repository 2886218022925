import Application from 'application-namespace';
import mix from 'mix';
import $ from 'jquery';
import _get from 'lodash/get';
import _differenceWith from 'lodash/differenceWith';
import _isEqual from 'lodash/isEqual';
import Turbolinks from 'turbolinks';
import 'select2';

// Application.Classes.DocumentForm = class DocumentForm extends Application.Classes.CoreBase {
Application.Classes.DocumentForm = class DocumentForm extends mix(Application.Classes.CoreBase).with(
  Application.Concerns.NetworkObserver,
  Application.Concerns.Grammarly,
  Application.Concerns.TechSalesOrderSpecifically,
  Application.Concerns.CreditMemoSpecifically,
) {
  constructor($scope) {
    super();
    this.$scope = $scope;
    // # Determine objects
    this.$business_partner = this.$scope.find('[data-document-business-partner]');
    this.$discountPrice = this.$scope.find('[data-position-discount-price]');
    this.$discountPercent = this.$scope.find('[data-position-discount-percent]');
    this.$position_freight_price = this.$scope.find('[data-position-freight-price]');
    this.$totalTaxPrice = this.$scope.find('#total_tax_price');
    this.$totalTaxPercent = this.$scope.find('#total_tax_percent');
    this.$document_date = this.$scope.find('[data-document-date]');
    this.$tax_dropdown = this.$scope.find('.tax-dropdown');
    this.$description_input = this.$scope.find('[data-selected-position-description]');
    this.$details_input = this.$scope.find('[data-selected-position-details]');
    const $item = this.$scope.find('[data-selected-position]');
    this.$parent = $item.prevObject;
    const $quantityInput = this.$parent.find('[data-selected-position-quantity]');
    const $itemPriceInput = this.$parent.find('[data-selected-position-price]');
    this.$sales_form = this.$scope.find('.sales-form-flag');
    this.$contact_person = this.$scope.find('[data-contact-person]');
    this.$customer_ref_number = this.$scope.find('[data-customer-ref-number]');
    this.$customer_equpment_card = this.$scope.find('[data-customer-equipment-card-selection]');
    this.$charge_tax_on_freight = this.$scope.find('[data-charge-tax-on-freight]');
    // # For logistics tab
    this.$billing_address_dropdown = this.$scope.find('.billing-address-dropdown');
    this.$shipping_address_dropdown = this.$scope.find('.shipping-address-dropdown');
    this.$description_billing_address = this.$scope.find('#description_billing_address');
    this.$description_shipping_address = this.$scope.find('#description_shipping_address');
    this.$address_name = this.$scope.find('[data-address-address-name]');
    this.$address_line_1 = this.$scope.find('[data-address-address-line-1]');
    this.$address_line_2 = this.$scope.find('[data-address-address-line-2]');
    this.$address_city = this.$scope.find('[data-address-city]');
    this.$address_zip_code = this.$scope.find('[data-address-zip-code]');
    this.$address_state_name = this.$scope.find('[data-address-state-name]');
    // # For DPM
    this.$dpm_percent = this.$scope.find('[data-dpm-percent]');
    this.$dpm_amount = this.$scope.find('[data-dpm-amount]');
    // # Keep form values
    this.$origin_form_values = this.$scope.serializeArray();
    this.$submit_btn = this.$scope.find("[type='submit']");
    this.$copy_to_btn = this.$scope.find('#sales_copy');
    this.$back_btn = this.$scope.find('.btn-back');
    // # Tech Sales Order
    this.$warranty = this.$scope.find('.tech_sales_order_tech_sales_order_lines_warranty');
    this.$part = this.$scope.find('[data-selected-part]');

    this.$dpm_percent.on('change', el => this.calculationDpm(el));
    this.$dpm_amount.on('change', el => this.calculationDpm(el));
    this.$warranty.on('change', () => this.calculation_total_field());
    this.$part.on('change', () => this.calculation_total_field());
    this.$scope.find('[data-type-item]').on('change', el => this.determinationType(el));
    this.$charge_tax_on_freight.on('change', () => this.calculation_total_field());
    this.$document_date.on('dp.change', () => this.$scope.find('.tax-dropdown').val('').trigger('change'));

    this.$parent.on('cocoon:after-remove', () => {
      this.calculation_total_field();
      this.$discountPercent.trigger('change');
    });
    this.$scope.find('#contents-tab').on('cocoon:after-insert', (e, insertedItem) => {
      Application.app.initializeSelectPicker(insertedItem);
      Application.app.initializeAjaxSelectPicker(insertedItem);
      Application.app.initializeMathInput(insertedItem);
      this.bindDoubleClick($(insertedItem).find('[data-selected-position-description]'));
      this.bindDoubleClick($(insertedItem).find('[data-selected-position-details]'));
      $($(insertedItem).find('[data-selected-position]')).on('change', event => this.fetchPositionItem(event));
      $($(insertedItem).find('.tax-dropdown')).on('change', () => this.calculation_total_field());
      $($(insertedItem).find('[data-selected-position-quantity]')).on('change', () => this.calculation_total_field());
      $($(insertedItem).find('[data-selected-position-price]')).on('change', () => this.calculation_total_field());
      $($(insertedItem).find('.tech_sales_order_tech_sales_order_lines_warranty')).on('change', () => this.calculation_total_field());
      this.$scope.find('[data-type-item]').on('change', el => this.determinationType(el));
      this.$discountPercent.trigger('change');

      this.$scope.find('[data-selected-position]').on('select2:select', () => this.autoAddedLine());
      return this.$scope.find('[data-gl-account-position]').on('select2:select', () => this.autoAddedLine());
    });
    // # Check form changes
    this.$copy_to_btn.on('click', event => this.checkFormChanges(event));
    this.$back_btn.on('click', event => this.checkFormChanges(event));
    this.$submit_btn.on('click', () => {
      this.$origin_form_values = this.$scope.serializeArray();
    });

    $quantityInput.on('change', (el) => {
      if (!$(el.currentTarget).val()) {
        $(el.currentTarget).val('0');
      }
      this.calculation_total_field();
      this.$discountPercent.trigger('change');
    });
    $itemPriceInput.on('change', (el) => {
      if (!$(el.currentTarget).val()) {
        $(el.currentTarget).val('0');
      }
      this.calculation_total_field();
      this.$discountPercent.trigger('change');
    });

    this.$tax_dropdown.on('change', () => this.calculation_total_field());
    $item.on('change', event => this.fetchPositionItem(event));
    this.$business_partner.on('change', (event) => {
      this.fetchAdresses(event);
      this.fetchContactPeople(event);
      this.fetchCustomerEquipmentCards(event);
    });
    this.$billing_address_dropdown.on('change', () => this.setAddressDescription(this.$billing_address_dropdown, this.$description_billing_address));
    this.$shipping_address_dropdown.on('change', () => this.confirmChangedShippingAddress(this.$shipping_address_dropdown, this.$description_shipping_address));
    this.$discountPrice.on('change', () => this.changedDiscountPrice());
    this.$discountPercent.on('change', () => this.changedDiscountPercent());
    this.$position_freight_price.on('change', () => {
      if (this.$position_freight_price.val() === '') {
        this.$position_freight_price.val(0.00);
      }
      this.calculation_total_field();
    });

    this.$scope.find('[data-selected-position]').on('select2:select', () => this.autoAddedLine());
    this.$scope.find('[data-gl-account-position]').on('select2:select', (element) => {
      this.setDefaultSalesTaxCode(element.currentTarget);
      this.autoAddedLine();
    });

    this.bindDoubleClick(this.$description_input);
    this.bindDoubleClick(this.$details_input);
    this.calculation_total_field();
    this.startObserver(this.$scope);
    this.checkGrammary(this.$scope);
    this.specificallyDetermination(this.$scope);
    this.specificallyCreditMemo(this.$scope);
  }

  determinationType(el) {
    const line = $(el.currentTarget).closest('tr');
    line.find('input, textarea').val('');
    if ($(el.currentTarget).select2('data')[0].id === '1') {
      line.find('[data-gl-account-position]').val('').trigger('change').closest('td')
        .addClass('d-none');
      line.find('[data-selected-position]').val('').trigger('change').closest('td')
        .removeClass('d-none');
      line.find('.warehouse-dropdown').val('').prop('disabled', false).trigger('change');
      line.find('[data-selected-position-quantity]').prop('readonly', false).val(1);
    } else if ($(el.currentTarget).select2('data')[0].id === '2') {
      line.find('[data-gl-account-position]').val('').trigger('change').closest('td')
        .removeClass('d-none');
      line.find('[data-selected-position]').val('').trigger('change').closest('td')
        .addClass('d-none');
      line.find('.warehouse-dropdown').val('').prop('disabled', true).trigger('change');
      line.find('[data-selected-position-quantity]').prop('readonly', 'readonly').val(1);
    }
    return line.find('[data-selected-position-price]').val(parseFloat(0).toFixed(2));
  }

  bindDoubleClick(selectors) {
    return $(selectors).on('dblclick', (e) => {
      if ($(e.target).closest('.change_width_input').length) {
        return $(e.target).removeClass('change_height_input').closest('td').removeClass('change_width_input');
      }
      this.$description_input.removeClass('change_height_input').closest('td').removeClass('change_width_input');
      return $(e.target).addClass('change_height_input').closest('td').addClass('change_width_input');
    });
  }

  changedDiscountPrice() {
    const total_before_discount_val = this.$scope.find('#total_before_discount').val();
    if (parseFloat(this.$discountPrice.val()) > parseFloat(total_before_discount_val)) {
      this.$discountPrice.val(total_before_discount_val);
    }
    const discount_percent = ((this.$discountPrice.val() / total_before_discount_val) * 100) || 0;
    this.$discountPercent.val(parseFloat(discount_percent).toFixed(2));
    this.calculation_total_field();
  }

  changedDiscountPercent() {
    if (this.$discountPercent.val() > 100) {
      this.$discountPercent.val(100);
    }
    const total_before_discount_val = this.$scope.find('#total_before_discount').val();
    const discount_price = ((total_before_discount_val * this.$discountPercent.val()) / 100) || 0;
    this.$discountPrice.val(parseFloat(discount_price).toFixed(2));
    this.calculation_total_field();
  }

  calculation_total_field() {
    let total_rate_amount = 0;
    let total_percent = 0;
    let total_before_discount_val = 0;
    let index = 0;
    let warranty_status = false;
    this.$scope.find('#contents-tab').find('.nested-fields').each((i, element) => {
      const price = 0;
      let positionPrecent = $(element).find('[data-selected-position]').val();
      if (!positionPrecent) {
        positionPrecent = $(element).find('[data-gl-account-position]').val();
      }
      const currentPrice = $(element).find('[data-selected-position-price]').val();
      const currentQuantity = $(element).find('[data-selected-position-quantity]').val();
      // line_rate         = if $(element).find('.tax-dropdown').select2("data")?[0] then $(element).find('.tax-dropdown').select2("data")[0].element.dataset.rate else 0

      const line_rate = _get($(element).find('.tax-dropdown').select2('data')[0], 'element.dataset.rate', 0);
      let total = currentPrice * currentQuantity;
      // NOTE: for TSO
      const part_el = $(element).find('[data-selected-part]');
      if (part_el.length) {
        const part = $(part_el).val();
        total *= parseFloat(part);
      }
      const line_rate_amount = (total * line_rate) / 100;
      const positionNotHidden = element.style.display !== 'none';
      warranty_status = $(element).find('.boolean').find('input').is(':checked');
      if (positionPrecent && positionNotHidden && !warranty_status) {
        total_before_discount_val += total;
        if (line_rate) {
          index++;
          total_rate_amount += line_rate_amount;
          total_percent += parseFloat(line_rate);
        }
      }
      return $(element).find('[data-selected-position-total]').html(`$${parseFloat(total).toFixed(2)}`);
    });
    total_percent = (total_percent / index) || 0;
    $('#total_before_discount').val(parseFloat(total_before_discount_val).toFixed(2));
    const freight_price = parseFloat(this.$position_freight_price.val());
    const totalTaxPercentAfterDiscount = total_percent;
    const totalTaxPriceAfterDiscount = total_rate_amount * ((100 - this.$discountPercent.val()) / 100);
    if (this.$charge_tax_on_freight.length && !this.$charge_tax_on_freight.is(':checked')) {
      this.$totalTaxPrice.val(this.roundingToCents(parseFloat(totalTaxPriceAfterDiscount)));
    } else {
      let freight_percent_price = (freight_price * total_percent) / 100;
      freight_percent_price = !(freight_percent_price < Infinity) ? 0 : freight_percent_price;
      this.$totalTaxPrice.val(this.roundingToCents(parseFloat(totalTaxPriceAfterDiscount + freight_percent_price)));
    }
    this.$totalTaxPercent.val(parseFloat(totalTaxPercentAfterDiscount).toFixed(3));
    this.totalCalculations();
    if (this.$dpm_percent && this.$dpm_amount) {
      return this.$dpm_percent.trigger('change');
    }
  }

  totalCalculations() {
    const total_before_discount = $('#total_before_discount').val();
    const freight_price = this.$position_freight_price.val();
    let total_after_discount = total_before_discount - this.$discountPrice.val();
    const total_calculation = parseFloat(total_after_discount) + parseFloat(this.$totalTaxPrice.val()) + parseFloat(freight_price);
    if (this.$charge_tax_on_freight.length && this.$charge_tax_on_freight.is(':checked')) {
      total_after_discount += parseFloat(freight_price);
    }
    $('#total_after__discount').val(parseFloat(total_after_discount).toFixed(2));
    $('#total').val(parseFloat(total_calculation).toFixed(2));
    return $('[data-balance-due]').val(parseFloat(total_calculation - $('[data-applied-amount]').val()).toFixed(2));
  }

  fetchPositionItem(event) {
    const $item = $(event.currentTarget);

    if ($item.val()) {
      $.ajax({
        url: Routes.item_path($item.val()),
        dataType: 'JSON',
      }).then((response) => {
        this.pasteItemOptions($item, response);
      }).catch((error) => {
        console.error('Error: ', error);
      });
    } else {
      console.warn(`Application.Classes.DocumentForm#fetchPositionItem - Value: ${$item.val()}`);
    }
  }

  pasteItemOptions(object, result) {
    this.setDefaultSalesTaxCode(object);
    if (!result) {
      return;
    }
    this.$parent = object.closest('.nested-fields');
    this.$parent.find('[data-selected-position-price]').val((this.$sales_form.length ? result.sales_price_cents : result.purchasing_price_cents) / 100);
    this.$parent.find('[data-selected-position-details]').val(result.remarks);
    this.$parent.find('[data-selected-position-description]').val(result.description);
    const $warehouseDropdown = this.$parent.find('.warehouse-dropdown');
    if (!result.inventory_item) {
      $warehouseDropdown.val(null).trigger('change').prop('disabled', true);
    } else {
      $warehouseDropdown.prop('disabled', false);
      const $options = $warehouseDropdown.find('option');
      $options.each((i, element) => {
        const flag = element.attributes.default_flag ? element.attributes.default_flag.value : false;
        if (flag === 'true') {
          $warehouseDropdown.val(`${element.value}`).trigger('change');
        }
      });
    }
    this.calculation_total_field();
    return this.$discountPercent.trigger('change');
  }

  setDefaultSalesTaxCode(object) {
    let $sales_tax;
    const selected_shipping_address = this.$shipping_address_dropdown.select2('data');
    this.$parent = $(object).closest('.nested-fields');
    if (selected_shipping_address.length) {
      $sales_tax = selected_shipping_address[0].full_addresess ? selected_shipping_address[0].full_addresess.sales_tax_code : null;
      if (!$sales_tax) {
        $sales_tax = selected_shipping_address[0].element.attributes.sales_tax ? selected_shipping_address[0].element.attributes.sales_tax.value : null;
      }
    }
    if ($sales_tax && $(object).val()) {
      const $newOption = $(`<option data-rate=${$sales_tax.tax_rate}></option>`).val($sales_tax.id).text($sales_tax.name);
      this.$parent.find('.tax-dropdown').append($newOption).val($sales_tax.id).trigger('change');
    }
    this.$parent.find('.tax-dropdown').val(null).trigger('change');
  }

  fetchAdresses(event) {
    const id = $(event.currentTarget).val();
    if (!id.length) {
      this.$billing_address_dropdown.empty().select2();
      this.$shipping_address_dropdown.empty().select2();
      this.clearFields(this.$description_billing_address);
      this.clearFields(this.$description_shipping_address);
      return;
    }

    $.ajax({
      url: Routes.business_partner_partner_billing_addresses_path(id),
      dataType: 'JSON',
    }).then((response) => {
      this.setAddresses(response, this.$billing_address_dropdown, this.$description_billing_address);
    }).catch((error) => {
      console.error('Error: ', error);
    });

    $.ajax({
      url: Routes.business_partner_partner_shipping_addresses_path(id),
      dataType: 'JSON',
    }).then((response) => {
      this.setAddresses(response, this.$shipping_address_dropdown, this.$description_shipping_address);
    }).catch((error) => {
      console.error('Error: ', error);
    });
  }

  fetchCustomerEquipmentCards(event) {
    const id = $(event.currentTarget).val();
    if (id && this.$customer_equpment_card.length) {
      $.ajax({
        url: Routes.business_partner_path(id),
        dataType: 'JSON',
      }).then((response) => {
        this.pasteToList(this.$customer_equpment_card, response.customer_equipment_cards, 'driveway_gate_name');
      }).catch((error) => {
        console.error('Error: ', error);
      });
    } else {
      console.warn(`Application.Classes.DocumentForm#fetchCustomerEquipmentCards id: ${id} || this.$customer_equpment_card.length ${this.$customer_equpment_card.length}`);
    }
  }

  fetchContactPeople(event) {
    const id = $(event.currentTarget).val();
    if (id) {
      $.ajax({
        url: Routes.business_partner_contact_people_path(id),
        dataType: 'JSON',
      }).then((response) => {
        this.pasteCpOptions(this.$contact_person, response);
        if (this.$customer_ref_number.length) {
          this.$customer_ref_number.val(response[0] ? response[0].mobile_phone : '');
        }
      }).catch((error) => {
        console.error('Error: ', error);
      });
    } else {
      console.warn(`Application.Classes.DocumentForm#fetchContactPeople id: ${id}`);
    }
  }

  pasteCpOptions($el, optionsJson) {
    $el.empty();
    $el.html('<option value="" selected="selected"></option>');

    for (let k = 0; k < optionsJson.length; k++) {
      const el = optionsJson[k];
      $el.append(`<option value="${el.id}">${`${el.contact_id}`}</option>`);
    }
    $el.trigger('change');
  }

  setAddresses(resources, selector, element) {
    // # Sorb by default_flag
    resources.sort((a, b) => +b.default_address - (+a.default_address));
    const billing_address = [];
    const shipping_address = [];
    resources.forEach((value, key) => {
      const fullAddress = {
        address_name: value.address_name,
        address_line_1: value.address_line_1,
        address_line_2: value.address_line_2,
        city: value.city,
        zip_code: value.zip_code,
        state_name: (value.state != null ? value.state.code.toUpperCase() : undefined),
        sales_tax_code: value.sales_tax_code,
      };
      return billing_address.push({ id: value.id, text: value.address_name, full_addresess: fullAddress });
    });
    selector.empty().select2();
    selector.select2({ data: billing_address });
    this.setAddressDescription(selector, element);
  }

  confirmChangedShippingAddress(selector, element) {
    if (this.$shipping_address_dropdown.val()) {
      return BootstrapDialog.show({
        type: BootstrapDialog.TYPE_DANGER,
        title: 'Select',
        message: 'If you choose YES and press button SAVE, in all current ITEMS will be auto updated Sales Tax Code. Press NO to return previous value.',
        cssClass: 'main-modal confirm-modal-window setting-confirm',
        buttons: [{
          label: 'NO',
          cssClass: 'btn-data-confirm-cancel',
          action: (dialogRef) => {
            this.setAddressDescription(selector, element);
            dialogRef.close();
          },
        },
        {
          label: 'YES',
          cssClass: 'btn-data-confirm',
          action: (dialogRef) => {
            this.setAddressDescription(selector, element);
            this.setTaxCodesByShippingAddress(selector);
            dialogRef.close();
          },
        },
        ],
      });
    }
    return this.clearFields(element);
  }

  setTaxCodesByShippingAddress(selector) {
    const selected_shipping_address = this.$shipping_address_dropdown.select2('data');
    const tax_code_value = selected_shipping_address[0].element.attributes.sales_tax
      ? selected_shipping_address[0].element.attributes.sales_tax.value
      : selected_shipping_address[0].full_addresess.sales_tax_code;
    $('.tax-dropdown').each((i, element) => {
      const item_present = $(element).closest('.nested-fields').find('[data-selected-position]').val();
      if (item_present) {
        return $(element).val(tax_code_value).trigger('change');
      }
    });
    this.calculation_total_field();
  }

  // TODO: Dmitry check fullAddresess
  setAddressDescription(selector, element) {
    this.clearFields(element);
    if (selector.val()) {
      const fullAddresess = selector.select2('data')[0].full_addresess;
      if (fullAddresess) {
        $(element).find(this.$address_name).html(fullAddresess.address_name);
        $(element).find(this.$address_line_1).html(fullAddresess.address_line_1);
        $(element).find(this.$address_line_2).html(fullAddresess.address_line_2);
        $(element).find(this.$address_city).html(fullAddresess.city);
        $(element).find(this.$address_state_name).html(`, ${fullAddresess.state_name}`);
        $(element).find(this.$address_zip_code).html(`, ${fullAddresess.zip_code}`);
      }
      const $data = selector.select2('data')[0].element.attributes;
      if ($data[0].value) {
        $(element).find(this.$address_name).html($data.address_name ? $data.address_name.value : null);
        $(element).find(this.$address_line_1).html($data.address_line_1 ? $data.address_line_1.value : null);
        $(element).find(this.$address_line_2).html($data.address_line_2 ? $data.address_line_2.value : null);
        $(element).find(this.$address_city).html($data.city ? $data.city.value : null);
        $(element).find(this.$address_state_name).html($data.state ? `, ${$data.state.value}` : null);
        $(element).find(this.$address_zip_code).html($data.zip_code ? `, ${$data.zip_code.value}` : null);
      }
    }
  }

  clearFields(element) {
    $(element).find(this.$address_name).empty();
    $(element).find(this.$address_line_1).empty();
    $(element).find(this.$address_line_2).empty();
    $(element).find(this.$address_city).empty();
    $(element).find(this.$address_zip_code).empty();
    $(element).find(this.$address_state_name).empty();
  }

  serializeArrayDiff() {
    const elementTable = this.$scope.find('.dataTables_wrapper').find('select').serializeArray();
    const defaultOriginFormValues = this.$origin_form_values;
    const defaultScopeSerializeArrays = this.$scope.serializeArray();
    const scopeSerializeArrays = _differenceWith(defaultScopeSerializeArrays, elementTable, _isEqual);
    const originFormValues = _differenceWith(defaultOriginFormValues, elementTable, _isEqual);
    const clearArray = _differenceWith(scopeSerializeArrays, originFormValues, _isEqual);
    return clearArray.length;
  }

  checkFormChanges(event) {
    if ((this.serializeArrayDiff() > 0) && (this.$copy_to_btn.closest('.dropdown').hasClass('open') || (this.$back_btn && this.$submit_btn.length))) {
      BootstrapDialog.show({
        type: BootstrapDialog.TYPE_WARNING,
        title: 'Warning',
        message: 'There are unsaved changes. Do you want to save?',
        cssClass: 'main-modal confirm-modal-window setting-confirm',
        buttons: [{
          label: 'NO',
          cssClass: 'btn-data-confirm-cancel',
          action: (dialogRef) => {
            if (event.target.text === 'Back') {
              Turbolinks.visit(event.target.href);
            }
            return dialogRef.close();
          },
        },
        {
          label: 'YES',
          cssClass: 'btn-data-confirm',
          action: (dialogRef) => {
            this.$submit_btn.click();
            this.$origin_form_values = this.$scope.serializeArray();
            dialogRef.close();
            if (event.target.text === 'Back') {
              return Turbolinks.visit(event.target.href);
            }
          },
        },
        ],
      },
      event.preventDefault());
    }
  }

  autoAddedLine() {
    const $item_selection = $('[data-selected-position]');
    const $gl_selection = $('[data-gl-account-position]');
    const all_count = $item_selection.length + $gl_selection.length;
    let used_lines = 0;
    $item_selection.each((i, element) => {
      if ($(element).val()) {
        return used_lines += 1;
      }
    });
    $gl_selection.each((i, element) => {
      if ($(element).val()) {
        return used_lines += 1;
      }
    });
    if (all_count === (used_lines * 2)) {
      return this.$scope.find('.add_fields').trigger('click');
    }
  }

  calculationDpm(element) {
    let total;
    let current_value = $(element.currentTarget).val();
    const total_after_discount = $('#total_after__discount').val();
    const tax_amount = this.$totalTaxPrice.val();
    if ($(element.currentTarget).attr('data-dpm-percent')) {
      if (current_value > 100) {
        this.$dpm_percent.val(100.00);
        return;
      }
      total = ((parseFloat(total_after_discount) + parseFloat(tax_amount) + parseFloat(this.$position_freight_price.val())) * current_value) / 100;
      this.$dpm_amount.val(total.toFixed(2));
    } else if ($(element.currentTarget).attr('data-dpm-amount')) {
      if (parseFloat(current_value) > parseFloat(total_after_discount)) {
        this.$dpm_amount.val(total_after_discount) && (current_value = total_after_discount);
      }
      const current_percent = (current_value / (parseFloat(total_after_discount) + parseFloat(tax_amount))) * 100;
      this.$dpm_percent.val(parseFloat(current_percent).toFixed(2));
      total = (parseFloat(total_after_discount) * current_percent) / 100;
    }
    $('#total').val(total.toFixed(2));
    return $('[data-balance-due]').val(total.toFixed(2));
  }
};
