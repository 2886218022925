import ReactOnRails from 'react-on-rails';
import * as axios from 'axios';

import SettingsForm from '../bundles/Settings/components/SettingsForm';
import DocumentFormContainer from '../bundles/containers/Documents/components/DocumentFormContainer';
import WorkDaysCalendarContainer
  from '../bundles/containers/EmployeeWorkDays/components/Calendar/WorkDaysCalendarContainer';
import MissedDaysReportContainer
  from '../bundles/containers/EmployeeWorkDays/components/MissedDaysReport/MissedDaysReportContainer';
import BusinessPartnerFormContainer
  from '../bundles/containers/BusinessPartners/components/BusinessPartnerFormContainer';
import CommissionsReportWrapper from '../bundles/containers/CommissionsReport/CommissionsReportWrapper';
import EmployeePayrollReport from '../bundles/containers/EmployeePayrollReport';
import ProjectPayrollSetupWrapper from '../bundles/containers/ProjectPayrollSetup/ProjectPayrollSetupWrapper';

ReactOnRails.register({
  DocumentFormContainer,
  SettingsForm,
  BusinessPartnerFormContainer,
  WorkDaysCalendarContainer,
  MissedDaysReportContainer,
  CommissionsReportWrapper,
  EmployeePayrollReport,
  // project setup >>>
  ProjectPayrollSetupWrapper,
  // project setup <<<
});

axios.defaults.headers = ReactOnRails.authenticityHeaders();
