import React from 'react';
import Button from 'react-bootstrap/Button';
import GeneralPartnerEmailFields from './GeneralPartnerEmailFields';

const defaultProps = {
  partnerEmailScheme: {
    id: null,
    email: null,
  }
};

let GeneralPartnerEmail = ({ fields, partnerEmailScheme }) => {

  return (
    <>
      <tbody>
      {fields.map((partner_emails_attributes, index) =>
        <GeneralPartnerEmailFields
          key={`partner_emails_attribute-${index}`}
          partner_emails_attributes={partner_emails_attributes}
          index={index}
          fields={fields}
        />
      )}
      <tr>
        <td colSpan="1" className="text-right">
        </td>
        <td className="text-right">
          <Button block variant="success" size="sm" onClick={() => fields.push(partnerEmailScheme)}>
            Add
          </Button>
        </td>
      </tr>
      </tbody>
    </>
  );

};

GeneralPartnerEmail.defaultProps = defaultProps;

export default GeneralPartnerEmail;
