import React from 'react';
import { Field } from 'redux-form';
import Button from 'react-bootstrap/Button';

let GeneralPartnerEmailFields = ({
  partner_emails_attributes,
  index,
  fields
}) => {

  return (
    <tr key={index}>
      <td className="d-none">
        <Field
          name={`${partner_emails_attributes}.id`}
          type="text"
          component="input"
        />
      </td>
      <td>
        <Field
          name={`${partner_emails_attributes}.email`}
          component="input"
          type="text"
        />
      </td>
      <td>
        <Button variant="danger" block size="sm" onClick={() => fields.remove(index)}>
          Remove
        </Button>
      </td>
    </tr>
  );
};


export default GeneralPartnerEmailFields;

