import React, { Component } from 'react';

class FileInput extends Component {

  render() {
    const { input: { value, name, onChange } } = this.props;

    return (<input
      type="file"
      onChange={(event) => {
        onChange(event.target.files[0])
      }}
    />);
  }
}

export default FileInput;
