// NOTE: http://justinfagnani.com/2015/12/21/real-mixins-with-javascript-classes/
const mix = superclass => new (class MixinBuilder {
  constructor (superclass) {
    this.superclass = superclass
  }

  with (...mixins) {
    return mixins.reduce((c, mixin) => mixin(c), this.superclass)
  }
})(superclass)

export { mix as default }
