import Application from 'application-namespace';
import $ from 'jquery';
import _get from 'lodash/get';

Application.Classes.HealthBenefitSettingLineFormula = class HealthBenefitSettingLineFormula {
  constructor($scope) {
    this.$scope = $scope;
    this.currentId = this.$scope.data('healthBenefitSettingId');
    this.$formulaInput = this.$scope.find('[name="health_benefit_setting_line[calculation_formula]"]');

    this.$formulaInput.on('change', (event) => {
      $.ajax({
        url: Routes.health_benefit_setting_line_path(this.currentId),
        data: { calculation_formula: $(event.currentTarget).val() },
        method: 'GET',
        dataType: 'JSON',
      })
        .then((response) => {
          if (_get(response, 'company_pays_per_week')) {
            $(event.currentTarget).closest('.input').find('.hint').html(`<b>Company pays per week:</b> $${response.company_pays_per_week} | <b>Employee pays per week:</b> $${response.employee_pays_per_week}</p>`);
          }
        })
        .catch((error) => {
          $(event.currentTarget).closest('.input').find('.hint').html(error.responseJSON.error);
          Application.Classes.Toastr.error(error.responseJSON.error);
        });
    });

    this.$formulaInput.trigger('change');
  }
};
