import Application from 'application-namespace';
import $ from 'jquery';

Application.Classes.CustomSpinner = class CustomSpinner {
  static show(div, text) {
    const renderText = text == null ? 'LOADING...' : text;
    $(div).addClass('position-relative').append(`<div class='custom-spinner'>${renderText}</div>`);
    $(document).ajaxComplete(() => this.hide(div));
  }

  static hide(div) { $(div).removeClass('position-relative').find('.custom-spinner').remove(); }
};
