import React, { Component } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { connect } from 'react-redux';
import { change, Field } from 'redux-form';
import Autocomplete from '../../../components/ReduxForm/Autocomplete';
import MoneyInput from '../../../components/ReduxForm/MoneyInput';
import { recalculateDocument } from '../../../redux/commonDocumentReducer/actions';
import RenderField from '../../../components/ReduxForm/RenderField';

class DocumentTotals extends Component {

  componentDidMount() {
    const { recalculateDocumentTotals } = this.props;
    recalculateDocumentTotals({discountBasedOn: 'discount_price_cents'})
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { document, recalculateDocumentTotals, changeFieldValue } = this.props;
    const currentPropsForm = document.values;
    const prevPropsForm = prevProps.document.values;

    if (prevPropsForm.discount_price_cents !== currentPropsForm.discount_price_cents) {
      recalculateDocumentTotals({discountBasedOn: 'discount_price_cents'});
    }

    if (prevPropsForm.discount_percent !== currentPropsForm.discount_percent) {
      if (currentPropsForm.discountBasedOn !== 'discount_price_cents') {
        recalculateDocumentTotals({discountBasedOn: 'discount_percent'});
      } else {
        changeFieldValue('discountBasedOn', null)
      }
    }

    if (prevPropsForm.freight_price_cents !== currentPropsForm.freight_price_cents) {
      recalculateDocumentTotals();
    }
  }

  render() {
    return (
      <>
        <Row>
          <Col md="6">
            <Field
              name="sales_employee_id"
              component={Autocomplete}
              responseKeyArray="employees"
              fetchPath={Routes.api_web_v1_employees_path()}
              label="Sales employee"
              formName="commonDocumentForm"
            />
            <Field
              name="employee_ids"
              component={Autocomplete}
              isMulti={true}
              responseKeyArray="employees"
              fetchPath={Routes.api_web_v1_employees_path()}
              label="Technicians"
              formName="commonDocumentForm"
            />
            <Field
              name="owner_id"
              component={Autocomplete}
              responseKeyArray="employees"
              fetchPath={Routes.api_web_v1_employees_path()}
              label="Owner"
              formName="commonDocumentForm"
            />
            <Field
              name="remarks"
              as="textarea"
              component={RenderField}
            />
          </Col>
          <Col md="6">
            <Row>
              <Col md="6">
                <MoneyInput
                  name="total_before_discount_cents"
                  prepend="$"
                  formName="commonDocumentForm"
                  disabled={true}
                  label="Total before discount"
                />
                <MoneyInput
                  name="total_after_discount_cents"
                  prepend="$"
                  formName="commonDocumentForm"
                  disabled={true}
                  label="Total after discount"
                />
              </Col>
              <Col md="6">
                <Row>
                  <Col md="6">
                    <MoneyInput
                      name="discount_price_cents"
                      formName="commonDocumentForm"
                      label="Discount price"
                    />
                  </Col>
                  <Col md="6">
                    <Field
                      name="discount_percent"
                      type="number"
                      component={RenderField}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row>
              <Col md="6">
                <Row>
                  <Col md="6">
                    <Field
                      name="total_tax_percent"
                      label="Total tax percent"
                      disabled={true}
                      prepend="%"
                      type="number"
                      component={RenderField}
                    />
                  </Col>
                  <Col md="6">
                    <MoneyInput
                      name="total_tax_price_cents"
                      prepend="$"
                      formName="commonDocumentForm"
                      disabled={true}
                      label="Total tax price"
                    />
                  </Col>
                </Row>
                <MoneyInput
                  name="total_cents"
                  prepend="$"
                  formName="commonDocumentForm"
                  disabled={true}
                  label="Total"
                />
              </Col>
              <Col md="6">
                <MoneyInput
                  name="freight_price_cents"
                  formName="commonDocumentForm"
                  label="Freight price"
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    )
  }

}

const mapDispatchToProps = (dispatch) => {
  return {
    recalculateDocumentTotals: (payload) => {
      dispatch(recalculateDocument(payload));
    },
    changeFieldValue: (fieldName, value) => {
      dispatch(change('commonDocumentForm', fieldName, value));
    },
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    document: state.form.commonDocumentForm,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentTotals);
