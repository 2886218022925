import React from 'react';
import { BrowserRouter as Router } from "react-router-dom";
import {Provider} from "react-redux";
import store from '../../redux/store';

const Index = ({}) => {

  return (
    <Router>
      <Provider store={store}>
        TESTS REACT COMPONENT
      </Provider>
    </Router>
  )

};

export default Index
