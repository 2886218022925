import { SubmissionError } from 'redux-form';
import { includes, keys, reduce } from 'lodash';

export default function (errors, formValues) {
  const errorObject = reduce(errors, (result, value, key) => {
    if (includes(keys(formValues), `${key}_id`)) {
      result[`${key}_id`] = value[0];
    } else {
      result[key] = value[0];
    }
    return result
  }, {});
  throw new SubmissionError(errorObject);
}



