import React from 'react';
import Table from 'react-bootstrap/Table';
import GeneralPartnerEmail from './AttachmentFile';
import { FieldArray } from 'redux-form';

const AttachmentsFilesContainer = () => {

  return (
    <div>
      <Table bordered responsive size="sm">
        <thead>
        <tr>
          <th>File</th>
          <th style={{ width: '78px' }}>Actions</th>
        </tr>
        </thead>
        <FieldArray
          name="attachments_attributes"
          component={GeneralPartnerEmail}
        />
      </Table>
    </div>
  );

};

export default AttachmentsFilesContainer;

