import React, { useEffect } from 'react';
import { Field } from 'redux-form';
import { get, map } from 'lodash';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { connect } from 'react-redux';
import SelectWrapper from '../../../components/ReduxForm/SelectWrapper';
import MoneyInput from '../../../components/ReduxForm/MoneyInput';
import RenderField from '../../../components/ReduxForm/RenderField';
import { fetchBusinessPartnerData } from '../../../redux/documentStoreReducer/actions';

let BusinessPartnersHeader = ({ groups, servicePartnerType, changeBusinessPartnerCollection }) => {

  useEffect(() => {
    if (groups.length === 0 && servicePartnerType.length === 0) {
      // changeBusinessPartnerCollection();
    }
  });

  const servicePartnerTypeCollections = map(servicePartnerType, (value, _key) => ({
    value: value.id,
    label: value.name
  }));

  const groupsCollections = map(groups, (value, _key) => ({
    value: value.id,
    label: value.name
  }));

  return (
    <Row>
      <Col md="12">
        <Row>
          <Col md="3">
            <Field
              name="code"
              component={RenderField}
            />
          </Col>
          <Col md="3">
            <Field
              name="service_partner_type_id"
              component={SelectWrapper}
              collection={servicePartnerTypeCollections}
              label="Service Partner Type"
              formName="businessPartnerForm"
            />
          </Col>
          <Col md="3">
            <MoneyInput
              name="account_balance"
              prepend="$"
              formName="businessPartnerForm"
              disabled={false}
              label="Account Balance"
            />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Field
              component={RenderField}
              name="name"
            />
          </Col>
          <Col md={3}>
            <MoneyInput
              name="orders"
              prepend="$"
              formName="businessPartnerForm"
              disabled={false}
              label="Orders"
            />
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <Field
              name="group_id"
              component={SelectWrapper}
              collection={groupsCollections}
              label="Group"
              formName="businessPartnerForm"
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

const mapDispatchToProps = dispatch => ({
  changeBusinessPartnerCollection: () => {
    dispatch(fetchBusinessPartnerData());
  },
});


const mapStateToProps = (state) => {
  const { form: { businessPartnerForm: businessPartner }, businessPartnerStore } = state;
  return {
    businessPartner,
    businessPartnerStore,
    groups:
      get(businessPartnerStore, 'groupsCollections', []),
    servicePartnerType:
      get(businessPartnerStore, 'servicePartnerTypeCollections', []),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BusinessPartnersHeader);
