import React from 'react';
import { Formik } from 'formik';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import LabelInputValue from '../../../decorators/LabelInputValue';
import ReactSelect from '../../../components/Formik/ReactSelect/ReactSelect';

let ForeignKeyForm = ({ setting, collection, setSettingFormSubmit, }) => {
  const settingInForm = {};
  const { id, var: settingVar } = setting;
  settingInForm[settingVar] = setting.value;
  return (
    <Formik
      initialValues={{[settingVar]:setting.value}}
      enableReinitialize={true}
      onSubmit={(values) => {
        const value = values[settingVar];
        setSettingFormSubmit({
          id,
          setting: { value }
        });
      }}
    >
      {({
        handleSubmit,
        values,
        errors,
        setFieldValue,
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <div className="d-flex bd-highlight mb-3 row" style={{ height: '60px' }}>
            <div className="p-2 bd-highlight align-self-end col-9 mb-0">
              <Form.Row>
                <Form.Group as={Col} md='12'>
                  <Form.Label>{LabelInputValue(settingVar)}</Form.Label>
                  <ReactSelect
                    onChange={setFieldValue}
                    options={collection}
                    value={values[settingVar]}
                    name={settingVar}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors[settingVar]}
                  </Form.Control.Feedback>
                  {!!errors[settingVar] &&
                  <div style={{
                    color: 'red',
                    marginTop: '.5rem'
                  }}>{errors[settingVar]}</div>
                  }
                </Form.Group>
              </Form.Row>
            </div>
            <div className="align-self-center p-2 mt-2 bd-highlight col-3">
              <Button type='submit' className='btn btn-success btn-block'>Update</Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ForeignKeyForm;
