import Application from 'application-namespace';
import $ from 'jquery';

Application.Classes.InventoryCountsAndPostsForm = class InventoryCountsAndPostsForm extends Application.Classes.CoreBase {
  constructor($scope) {
    super();
    this.$scope = $scope;
    this.$scope.find('table').on('cocoon:after-insert', (e, insertedItem) => {
      this.initializePluginsAfterInsert(insertedItem);
      this.determinationLineInputs();
    });
    this.determinationLineInputs();
  }

  determinationLineInputs() {
    this.$scope.find('[data-item-dropdown]').on('change', element => this.determinationPath($(element.currentTarget).closest('tr')));
    this.$scope.find('[data-warehouse-dropdown]').on('change', element => this.determinationPath($(element.currentTarget).closest('tr')));
  }

  determinationPath($line) {
    const $item = $line.find('[data-item-dropdown]');
    const $warehouse = $line.find('[data-warehouse-dropdown]');
    const $quantityInStock = $line.find('[data-count-in-whse]');
    if ($item.val() && $warehouse.val()) {
      this.fetchItem($item, $warehouse, $quantityInStock);
    } else {
      $quantityInStock.val(0, $quantityInStock);
    }
  }

  fetchItem($item, $warehouse, $quantityInStock) {
    $.ajax({
      url: Routes.quantity_warehouse_item_path($warehouse.val(), $item.val()),
      dataType: 'JSON',
    }).then((response) => {
      $quantityInStock.val(response.in_stock);
    }).catch((error) => {
      console.error('Error: ', error);
    });
  }
};
