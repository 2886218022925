import Application from 'application-namespace';
import $ from 'jquery';

Application.Classes.HealthBenefitSettingForm = class HealthBenefitSettingForm {
  constructor($scope) {
    this.$scope = $scope;
    this.$allNewLineButton = this.$scope.find('.add_fields');

    $('.nested-fields').each((i, element) => {
      this.initAutoCalculationForLine($(element));
      this.markAsTouched($(element));
    });

    this.$scope.on('cocoon:after-insert', (e, insertedItem) => {
      Application.app.initializeSelectPicker(insertedItem);
      this.initAutoCalculationForLine(insertedItem);
      this.markAsTouched(insertedItem);
    });
  }

  initAutoCalculationForLine($line) {
    const $monthCostInput = $line.find('[data-mouth-cost-input]');
    const $weekCostInput = $line.find('[data-week-cost-input]');
    $monthCostInput.on('change', event => $weekCostInput.val(($(event.currentTarget).val() * 12) / 52).toFixed(2));
    $weekCostInput.on('change', (event) => {
      if (!$(event.currentTarget).data('isAddedNewLine')) { this.$allNewLineButton.trigger('click'); }
      $(event.currentTarget).data('isAddedNewLine', true);
      const weekCost = (($(event.currentTarget).val() * 52) / 12).toFixed(2);
      $monthCostInput.val(weekCost);
    });
  }

  markAsTouched($line) {
    const $isTouchedInput = $line.find('.health_benefit_setting_health_benefit_setting_lines_is_touched').find('input');
    $line.find('input').on('change', () => $isTouchedInput.prop('checked', true));
    $line.find('select').on('change', () => $isTouchedInput.prop('checked', true));
  }
};
