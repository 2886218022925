import React, { Component } from 'react';
import Select from 'react-select';

export default class ReactSelect extends Component {
  handleChange = value => {
    const { name, onChange } = this.props;
    if (value === null) {
      onChange(name, value);
      return;
    }

    onChange(name, value.value);
  };

  render() {
    const {
      name, value, options, isClearable, isMulti
    } = this.props;
    return (
      <div style={{ minWidth: '100px' }}>
        <Select
          isMulti={isMulti}
          name={name}
          required
          isClearable={isClearable}
          isSearchable
          value={value}
          onChange={this.handleChange}
          options={options}
        />
        {!!this.props.error &&
        <div style={{
          color: 'red',
          marginTop: '.5rem'
        }}>{this.props.error}</div>
        }
      </div>
    );
  }
}
