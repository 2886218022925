import React, {useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {Field, reduxForm} from "redux-form";
import {compose} from "redux";
import chroma from 'chroma-js';
import SelectWrapper from '../../../../components/ReduxForm/SelectWrapper';
import Select2Collection from '../../../../decorators/Select2Collection';
import MultiSelectWrapper from '../../../../components/ReduxForm/MultiSelectWrapper';
import { parseActiveEmployees, parseEmployeeWorkDays } from '../../../../redux/employeeWorkDaysCalendarReducer/actions';

const defaultProps = {
  activeEmployees: [],
  dayTypes:
    [{value: 1, label: 'Standard', color: '#41AE98'}, {value: 2, label: 'Sick', color: '#DE2800'},
      {value: 3, label: 'Vacation', color: '#FFD700'}, {value: 4, label: 'Holiday', color: '#0071A5'},
      {value: 5, label: 'Other', color: '#008E41'}, {value: 6, label: 'Weekend', color: '#808080'}]
};

const CalendarFilters = ({dayTypes}) => {

  const dispatch = useDispatch();
  const employeeWorkDaysSearch = useSelector(state => state.form.employeeWorkDaysSearch);
  const employeeWorkDaysCalendarStore = useSelector(state => state.employeeWorkDaysCalendarStore);
  const activeEmployees = get(employeeWorkDaysCalendarStore, 'activeEmployees', []);

  useEffect(() => {
    dispatch(parseActiveEmployees())
  }, []);

  useEffect(() => {
    if (employeeWorkDaysSearch.values && employeeWorkDaysSearch.values.employee_id &&
      employeeWorkDaysSearch.initial !== employeeWorkDaysSearch.values) {
      dispatch(parseEmployeeWorkDays(employeeWorkDaysSearch.values))
    }
  }, [employeeWorkDaysSearch]);

  const colourStyles = {
    control: styles => ({ ...styles, backgroundColor: 'white' }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      const color = chroma(data.color);
      return {
        ...styles,
        backgroundColor: isDisabled
            ? null
            : isSelected
                ? data.color
                : isFocused
                    ? color.alpha(0.1).css()
                    : null,
        color: isDisabled
            ? '#ccc'
            : isSelected
                ? chroma.contrast(color, 'white') > 2
                    ? 'white'
                    : 'black'
                : data.color,
        cursor: isDisabled ? 'not-allowed' : 'default',

        ':active': {
          ...styles[':active'],
          backgroundColor: !isDisabled && (isSelected ? data.color : color.alpha(0.3).css()),
        },
      };
    },
    multiValue: (styles, { data }) => {
      const color = chroma(data.color);
      return {
        ...styles,
        backgroundColor: color.alpha(0.1).css(),
      };
    },
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: data.color,
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: data.color,
      ':hover': {
        backgroundColor: data.color,
        color: 'white',
      },
    }),
  };

  return (
    <>
      <Row className='mb-4'>
        <Col xs={12} md={6}>
          <Field
              name="employee_id"
              component={SelectWrapper}
              collection={Select2Collection(activeEmployees, { labelKey: 'full_name' })}
              label="Employee"
              formName="employeeWorkDaysSearch"
              isClearable={false}
          />
        </Col>
        <Col xs={12} md={6}>
          <Field
            name="day_types"
            component={MultiSelectWrapper}
            collection={dayTypes}
            label="Work day type"
            formName="employeeWorkDaysSearch"
            colourStyles={colourStyles}
          />
        </Col>
      </Row>
    </>
  )
};

export default compose(
    reduxForm({
      form: 'employeeWorkDaysSearch', enableReinitialize : true
    })
)(CalendarFilters);

CalendarFilters.defaultProps = defaultProps;
