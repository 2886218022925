import React from 'react';
import { connect } from 'react-redux';
import { get, map, join, upperFirst } from 'lodash';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Swal from 'sweetalert2';
import axios from 'axios';

const LinkedTSOContainer = ({ techSalesOrders, onRemove }) => {

  const showRemoveConfirmModal = (techSalesOrder) => {
    Swal.fire({
      icon: 'warning',
      title: 'Are you sure?',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
        removeTechSalesOrder(techSalesOrder)
           .then(r => { onRemove() } )
      }
    })
  };

  const removeTechSalesOrder = (techSalesOrder) => {
    return axios({
      method: 'DELETE',
      url: Routes.api_web_v1_tech_sales_order_path(techSalesOrder.id),
    }).then((response) => {
      Swal.fire({
        title: 'Deleted!',
        text: 'Tech sales order has been deleted.',
        icon: 'success',
        showConfirmButton: false,
        timer: 1500
      });
      return response
    });
  };

  const linkedTechSalesOrderLine = (techSalesOrder, index) => {
    return (
      <tr key={`linked-tech-sales-order-${index}`}>
        <td>{index + 1}</td>
        <td>{techSalesOrder.business_partner_name}</td>
        <td>{techSalesOrder.business_partner_code}</td>
        <td>{techSalesOrder.number}</td>
        <td>{techSalesOrder.total.cents / 100}</td>
        <td>
          {
            join([
              upperFirst(techSalesOrder.follow_up_required),
              techSalesOrder.percentage_completed
            ], '/')
          }
        </td>
        <td>{techSalesOrder.technicians}</td>
        <td>
          <Dropdown as={ButtonGroup} size="sm" className="w-100">
            <Button variant="success"
                    href={Routes.edit_tech_sales_order_path(techSalesOrder.id)}>
              Edit
            </Button>
            <Dropdown.Toggle split variant="success" id="dropdown-split-basic"/>
            <Dropdown.Menu>
              <Dropdown.Item
                className="text-danger"
                onClick={() => showRemoveConfirmModal(techSalesOrder)}
              >
                Remove
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </td>
      </tr>
    );
  };

  return (
    <>
      <Table striped bordered hover size="sm">
        <thead>
        <tr>
          <th>#</th>
          <th>Customer Name</th>
          <th>Customer Code</th>
          <th>Sales Order Number</th>
          <th>Total Price</th>
          <th>Follow Up / Percent Completed</th>
          <th>Technicians</th>
          <th>Actions</th>
        </tr>
        </thead>
        <tbody>
        {techSalesOrders &&
        map(techSalesOrders, (techSalesOrder, index) => {
          return linkedTechSalesOrderLine(techSalesOrder, index);
        })
        }
        {!techSalesOrders.length &&
        <tr>
          <td colSpan={8} className="text-center">No content</td>
        </tr>
        }
        </tbody>
      </Table>
    </>
  );

};

const mapStateToProps = (state) => {
  return {
    techSalesOrders: get(state.documentsStore, 'techSalesOrders', [])
  };
};

export default connect(mapStateToProps)(LinkedTSOContainer);
