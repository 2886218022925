import Application from 'application-namespace';
import $ from 'jquery';
import _parseInt from 'lodash/parseInt';

Application.Classes.GLAccountForm = class GLAccountForm extends Application.Classes.CoreBase {
  constructor($scope) {
    super();
    this.$scope = $scope;
    this.$firstSerialNumber = this.$scope.find('#general_ledger_account_first_serial_number');
    this.$secondSerialNumber = this.$scope.find('#general_ledger_account_second_serial_number');
    this.$thirdSerialNumber = this.$scope.find('#general_ledger_account_third_serial_number');
    this.$fourthSerialNumber = this.$scope.find('#general_ledger_account_fourth_serial_number');
    this.$serialNumberInputs = this.$scope.find('.serial_number');

    this.$firstSerialNumber.on('blur', event => this.constructor.autoCompleteForm(event));
    this.$secondSerialNumber.on('focus', event => this.constructor.autoCompleteForm(event));
    this.$thirdSerialNumber.on('focus', event => this.constructor.autoCompleteForm(event));
    this.$fourthSerialNumber.on('focus', event => this.constructor.autoCompleteForm(event));
    this.$serialNumberInputs.on('input', event => this.constructor.autoSwipeBox(event));
  }

  static autoSwipeBox(event) {
    const currentInput = $(event.currentTarget).find('input');
    const currentMaxLength = _parseInt(currentInput.attr('maxlength'));
    const currentLength = currentInput.val().length;

    if (currentLength === currentMaxLength) {
      $(event.currentTarget).closest('.serial_number').next('.serial_number').find('input')
        .focus()
        .select();
    }
  }

  static autoCompleteForm(event) {
    const currentInput = $(event.currentTarget);

    const currentMaxLength = _parseInt(currentInput.attr('maxlength'));
    let currentLength = currentInput.val().length;

    if (currentLength !== currentMaxLength) {
      while (currentLength < currentMaxLength) {
        currentLength += 1;
        $(currentInput).val($(currentInput).val() + 0);
      }

      $(currentInput).trigger('input');
    }
  }
};
