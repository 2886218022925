import React, { Component } from 'react';
import { Field } from 'redux-form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { connect } from 'react-redux';
import { get } from 'lodash';
import SchemesOfDocuments from './Services/SchemesOfDocuments';
import { defineBusinessPartnerData } from '../../../redux/commonDocumentReducer/actions';
import Autocomplete from '../../../components/ReduxForm/Autocomplete';
import SelectWrapper from '../../../components/ReduxForm/SelectWrapper';
import RenderField from '../../../components/ReduxForm/RenderField';
import InputDatePicker from '../../../components/ReduxForm/InputDatePicker';
import Select2Collection from '../../../decorators/Select2Collection';
import { fetchBusinessPartnerData } from '../../../redux/documentStoreReducer/actions';

class DocumentHeader extends Component {

  componentDidMount() {
    const { changeBusinessPartner, document } = this.props;
    changeBusinessPartner(document.values.business_partner_id, document);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      changeBusinessPartner,
      setDefaultPartnerData,
      contactPeople,
      partnerBillingAddresses,
      partnerShippingAddresses
    } = this.props;
    const prevDocumentState = prevProps.document.values;
    const currentDocumentState = this.props.document.values;

    if (prevProps.document) {
      if (prevDocumentState.business_partner_id !== currentDocumentState.business_partner_id) {
        changeBusinessPartner(currentDocumentState.business_partner_id, this.props.document);
      }
    }
    if (prevProps.contactPeople !== this.props.contactPeople ||
      prevProps.partnerBillingAddresses !== this.props.partnerBillingAddresses ||
      prevProps.partnerShippingAddresses !== this.props.partnerShippingAddresses) {
      setDefaultPartnerData(contactPeople, partnerBillingAddresses, partnerShippingAddresses);
    }
  }

  render() {
    const { contactPeople, customerEquipmentCards, document } = this.props;
    const documentScheme = SchemesOfDocuments(document.values.class_name);

    return (
      <>
        <Row>
          <Col md="6">
            <Field
              name="business_partner_id"
              component={Autocomplete}
              responseKeyArray="business_partners"
              fetchPath={Routes.api_web_v1_business_partners_path({ business_partner_type: 'customer' })}
              label="Customer"
              formName="commonDocumentForm"
            />
            {documentScheme.customer_equipment_card_id &&
            <Field
              name="customer_equipment_card_id"
              component={SelectWrapper}
              collection={Select2Collection(customerEquipmentCards, { labelKey: 'full_name' })}
              label="Customer equipment card"
              formName="commonDocumentForm"
            />
            }
            <Field
              name="contact_person_id"
              component={SelectWrapper}
              collection={Select2Collection(contactPeople, { labelKey: 'full_name' })}
              label="Contact Person"
              formName="commonDocumentForm"
            />
            <Field
              name="customer_ref_number"
              component={RenderField}
            />
          </Col>
          <Col md="6">
            <Field
              name="number"
              component={RenderField}
            />
            <Field
              name="status"
              component={RenderField}
              disabled={true}
            />
            <Field
              name="posting_date"
              component={InputDatePicker}
            />
            <Field
              name="valid_until"
              component={InputDatePicker}
            />
            <Field
              name="document_date"
              component={InputDatePicker}
            />
            {documentScheme.closed_on &&
            <Field
              name="closed_on"
              component={InputDatePicker}
              disabled={true}
            />
            }
            {documentScheme.completed_date &&
            <Field
              name="completed_date"
              component={InputDatePicker}
              disabled={true}
            />
            }
            <Field
              className="d-none"
              name="is_copy"
              component="input"
            />
          </Col>
        </Row>
      </>
    );
  }

}

const mapDispatchToProps = dispatch => ({
  changeBusinessPartner: (businessPartnerId, document) => {
    dispatch(fetchBusinessPartnerData(businessPartnerId, document));
  },
  setDefaultPartnerData: (contactPeople, partnerBillingAddresses, partnerShippingAddresses) => {
    dispatch(defineBusinessPartnerData(contactPeople, partnerBillingAddresses, partnerShippingAddresses));
  }
});

const mapStateToProps = (state, ownProps) => {
  return {
    document: state.form.commonDocumentForm,
    contactPeople:
      get(state.documentsStore, 'contactPeople', []),
    partnerBillingAddresses:
      get(state.documentsStore, 'partnerBillingAddresses', []),
    partnerShippingAddresses:
      get(state.documentsStore, 'partnerShippingAddresses', []),
    customerEquipmentCards:
      get(state.documentsStore, 'customerEquipmentCards', [])
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentHeader);
