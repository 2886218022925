import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import RenderField from '../../../../components/ReduxForm/RenderField';
import Button from 'react-bootstrap/Button';
import SelectWrapper from '../../../../components/ReduxForm/SelectWrapper';
import Select2Collection from '../../../../decorators/Select2Collection';
import InputDatePicker from '../../../../components/ReduxForm/InputDatePicker';
import MoneyInput from '../../../../components/ReduxForm/MoneyInput';
import ReduxFormValidationAlert from '../../../../components/ReduxFormValidationAlert'

const FormPayrollTaxSetupAddOn = ({ payrollTaxSetupAddOn, handleSubmit, stepButtons = [] }) => {

  const [states, setStates] = useState([]);

  const loadStates = () => {
    return axios({
      method: 'GET',
      url: Routes.api_web_v1_dictionaries_path({name: 'state'})
    });
  };

  useEffect(() => {
    loadStates()
      .then(response => {
        setStates(response.data);
      })
      .catch(error => {
        console.log('FormPayrollTaxSetupAddOn.loadStates', error.response);
      });
  }, []);

  return (
    <>
      <ReduxFormValidationAlert formName={'payrollTaxSetupAddOn'}/>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col md="12">
            <Field
              name="name"
              component={RenderField}
            />
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <Field
              name="state_id"
              component={SelectWrapper}
              collection={Select2Collection(states, { labelKey: 'name' })}
              formName="payrollTaxSetupAddOn"
              label="State"
            />
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <Field
              name="effective_date"
              component={InputDatePicker}
            />
          </Col>
          <Col md="6">
            <Field
              name="end_date"
              component={InputDatePicker}
            />
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <Field
              name="employee_tax_rate"
              component={RenderField}
            />
          </Col>
          <Col md="6">
            <Field
              name="employer_tax_rate"
              component={RenderField}
            />
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <Field
              name="limit_time"
              component={SelectWrapper}
              collection={payrollTaxSetupAddOn.enum_collections.limit_times}
              formName="payrollTaxSetupAddOn"
            />
          </Col>
          <Col md="6">
            <Field
              name="limit_type"
              component={SelectWrapper}
              collection={payrollTaxSetupAddOn.enum_collections.limit_types}
              formName="payrollTaxSetupAddOn"
            />
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <MoneyInput
              name="week_limit_amount_cents"
              formName="payrollTaxSetupAddOn"
              label="Week limit amount"
            />
          </Col>
          <Col md="6">
            <MoneyInput
              name="year_limit_amount_cents"
              formName="payrollTaxSetupAddOn"
              label="Year limit amount"
            />
          </Col>
        </Row>


        <div className="d-flex justify-content-start align-items-baseline">
          {stepButtons}
          <Button
            variant="success"
            type="submit"
            block
          >
            Next
          </Button>
        </div>
      </Form>
    </>
  );

};

export default compose(
  reduxForm({
    form: 'payrollTaxSetupAddOn',
    enableReinitialize: true
  })
)(FormPayrollTaxSetupAddOn);
