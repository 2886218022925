import React from 'react';
import { useSelector } from 'react-redux';
import { get, map, capitalize } from 'lodash';
import Table from 'react-bootstrap/Table';
import moment from 'moment';

const MissedDaysTable = () => {
  const missedDaysReportStore = useSelector(state => state.missedDaysReportStore);
  const missedEmployeeWorkDays = get(missedDaysReportStore, 'missedEmployeeWorkDays', []);

  const definePaidAbsenceType = (workDay) => {
    if (workDay.is_paid_for_absence_duration) {
      if (workDay.day_type === 'holiday') {
        return 'Holiday';
      } else {
        return (capitalize(workDay.payment_type));
      }
    } else {
      return ('Not paid');
    }
  };

  return (
    <>
      <Table striped bordered hover size="sm">
        <thead>
        <tr>
          <th>Employee Name</th>
          <th>Date</th>
          <th>Day of Week</th>
          <th>Absence reason</th>
          <th>Paid Absence type</th>
        </tr>
        </thead>
        <tbody>

        {missedEmployeeWorkDays.length > 0 &&
        (
          <>
            {map(missedEmployeeWorkDays, (workDay, index) => {
                return (
                  <tr key={index}>
                    <td>{workDay.employee_full_name}</td>
                    <td>{moment(workDay.work_day_at)
                      .calendar()}</td>
                    <td>{moment(workDay.work_day_at)
                      .format('dddd')}</td>
                    <td>{capitalize(workDay.day_type)}</td>
                    <td>{definePaidAbsenceType(workDay)}</td>
                  </tr>
                );
              }
            )}
            <tr>
              <td colspan={5}>
                Total days: {missedEmployeeWorkDays.length}
              </td>
            </tr>
          </>
        )

        }

        {missedEmployeeWorkDays.length === 0 &&
        <tr>
          <td colSpan='5'>No data</td>
        </tr>
        }
        </tbody>
      </Table>
    </>
  );
};

export default MissedDaysTable;
