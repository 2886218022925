import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useHistory, Link } from 'react-router-dom'
import delay from 'lodash/delay';
import isEmpty from 'lodash/isEmpty';
import FormVacationSetting from './form';
import ReduxFormSubmissionError from '../../../../components/ReduxFormSubmissionError';
import { useSnackbar } from 'notistack';

const FormVacationSettingWrapper = () => {
  let params = useParams();
  const history = useHistory();
  const {enqueueSnackbar} = useSnackbar();
  const [loadPath, setLoadPath] = useState(null);
  const [vacationSetting, setVacationSetting] = useState({});

  useEffect(() => {
    if (params.vacationSettingId) {
      setLoadPath(Routes.edit_api_web_v1_vacation_setting_path(params.vacationSettingId));
    } else {
      setLoadPath(Routes.new_api_web_v1_vacation_setting_path());
    }
  }, [params]);

  useEffect(() => {
    if (loadPath) {
      loadVacationSetting()
        .then(response => {
          setVacationSetting(response.data.vacation_setting);
        })
        .catch(error => {
          console.log('FormVacationSettingWrapper.loadVacationSetting', error.response);
        });
    }
  }, [loadPath]);

  const loadVacationSetting = () => {
    return axios({ url: loadPath });
  };

  const handleSubmit = (values) => {
    return axios({
      method: params.vacationSettingId ? 'PUT' : 'POST',
      url: params.vacationSettingId ?
        Routes.api_web_v1_vacation_setting_path(params.vacationSettingId) :
        Routes.api_web_v1_vacation_settings_path(),
      data: { vacation_setting: values }
    })
      .then(response => {
        delay(() => {
          history.push('/vacation_settings')
        }, 1000);
        enqueueSnackbar('Vacation setting was saved.', { variant: 'success' })
      })
      .catch(error => {
        ReduxFormSubmissionError(error.response.data.errors, values);
      });
  };

  return (
    <>
      {!isEmpty(vacationSetting) &&
      <FormVacationSetting
        vacationSetting={vacationSetting}
        initialValues={vacationSetting}
        onSubmit={handleSubmit}
        stepButtons={
          <>
            <Link
              to={'/vacation_settings'}
              className="btn btn-outline-info btn-block mr-2"
            >
              Back
            </Link>
          </>
        }
      />
      }
    </>
  );
};

export default FormVacationSettingWrapper;
