import React, { Component } from 'react';
import { change, Field } from 'redux-form';
import { capitalize, get, map } from 'lodash';
import Button from 'react-bootstrap/Button';
import { connect } from 'react-redux';
import axios from 'axios';
import SelectWrapper from '../../../../components/ReduxForm/SelectWrapper';
import Autocomplete from '../../../../components/ReduxForm/Autocomplete';
import MoneyInput from '../../../../components/ReduxForm/MoneyInput';
import {
  changePositionItemTaxRate,
  changePositionItemType,
  recalculateDocument, removePositionItem
} from '../../../../redux/commonDocumentReducer/actions';
import RenderField from '../../../../components/ReduxForm/RenderField';

class DocumentPositionFields extends Component {

  state = {
    activeTextarea: null,
    isInventoryItem: false
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { document, index, recalculateDocumentTotals, changePositionType } = this.props;

    const prevStatePosition = prevProps.document.values.positions[index];
    const currentStatePosition = document.values.positions[index];

    if (prevStatePosition.position_type !== currentStatePosition.position_type) {
      changePositionType(currentStatePosition, index);
    }

    if (prevStatePosition.quantity !== currentStatePosition.quantity ||
      prevStatePosition.item_price_cents !== currentStatePosition.item_price_cents) {
      recalculateDocumentTotals();
    }

    if (prevStatePosition.item_id !== undefined && currentStatePosition.item_id &&
      prevStatePosition.item_id !== currentStatePosition.item_id) {
      this.fetchItemData(currentStatePosition.item_id);
    }
  }

  fetchItemData = (itemId) => {
    return axios({
      method: 'GET',
      url: Routes.api_web_v1_item_path(itemId)
    })
      .then(response => {
        this.pasteItemData(response.data.item);
      })
      .catch(error => {
        console.log(error);
      });
  };

  pasteItemData = (item) => {
    const { position, changeFieldValue } = this.props;

    changeFieldValue(`${position}.quantity`, 1);
    changeFieldValue(`${position}.item_description`, item.description);
    changeFieldValue(`${position}.item_details`, null);
    changeFieldValue(`${position}.item_price_cents`, item.sales_price_cents / 100);

    this.setState({ isInventoryItem: item.inventory_item });

    if (!item.inventory_item) {
      changeFieldValue(`${position}.warehouse_id`, null);
    }
  };

  setTaxCode = (selectedObject) => {
    const { changePositionTaxRate, index } = this.props;
    changePositionTaxRate(index, selectedObject.rate);
  };

  itemTypeClass = (fieldValue, index) => {
    const { document } = this.props;
    return document.values.positions[index] && (document.values.positions[index].position_type === fieldValue) ? 'd-none' : '';
  };

  setActiveTextarea = (event) => {
    if (this.state.activeTextarea === $(event.currentTarget)
      .attr('name')) {
      this.setState({ activeTextarea: null });
    } else {
      this.setState({
        activeTextarea: $(event.currentTarget)
          .attr('name')
      });
    }
  };

  removePositionLine = (index) => {
    const { removePositionItemLine, recalculateDocumentTotals, fields } = this.props;

    if (fields.get(index).id) {
      removePositionItemLine(index);
    } else {
      fields.remove(index);
      recalculateDocumentTotals();
    }
  };

  render() {
    const { document, position, index, fields, positionType } = this.props;
    const { activeTextarea, isInventoryItem } = this.state;

    return (
      <tr
        key={index}
        className={get(fields.get(index), '_destroy', false) ? 'd-none' : ''}
      >
        <td className="d-none">
          <Field
            name={`${position}.id`}
            type="text"
            component="input"
          />
        </td>
        <td>
          <Field
            name={`${position}.position_type`}
            component={SelectWrapper}
            isClearable={false}
            collection={
              map(document.values.position_types, (key, value) => ({
                value: key,
                label: capitalize(value)
              }))
            }
            formName="commonDocumentForm"
          />
        </td>
        <td className={this.itemTypeClass(1, index)}>
          <Field
            name={`${position}.general_ledger_account_id`}
            component={Autocomplete}
            responseKeyArray="general_ledger_accounts"
            fetchPath={Routes.api_web_v1_general_ledger_accounts_path({depth: 3})}
            formName="commonDocumentForm"
          />
        </td>
        <td className={this.itemTypeClass(2, index)}>
          <Field
            name={`${position}.item_id`}
            component={Autocomplete}
            responseKeyArray="items"
            fetchPath={Routes.api_web_v1_items_path({ itemType: 'sales' })}
            formName="commonDocumentForm"
          />
        </td>
        <td>
          <Field
            name={`${position}.item_description`}
            component="textarea"
            onDoubleClick={this.setActiveTextarea}
            className={activeTextarea === `positions[${index}].item_description` ? 'change-width-input change-height-input' : 'small-area'}
          />
        </td>
        <td>
          <Field
            name={`${position}.item_details`}
            component="textarea"
            onDoubleClick={this.setActiveTextarea}
            className={activeTextarea === `positions[${index}].item_details` ? 'change-width-input change-height-input' : 'small-area'}
          />
        </td>
        <td>
          <Field
            name={`${position}.quantity`}
            label={false}
            type="number"
            component={RenderField}
            normalize={(value, previousValue, allValues) => (value > 0 ? value : previousValue)}
            disabled={positionType === 2}
          />
        </td>
        <td>
          <MoneyInput
            name={`${position}.item_price_cents`}
            label={false}
            formName="commonDocumentForm"
          />
        </td>
        <td>
          <Field
            name={`${position}.sales_tax_code_id`}
            component={Autocomplete}
            responseKeyArray="sales_tax_codes"
            fetchPath={Routes.api_web_v1_sales_tax_codes_path()}
            formName="commonDocumentForm"
            handleChange={this.setTaxCode}
          />
        </td>
        <td>
          <Field
            name={`${position}.warehouse_id`}
            component={Autocomplete}
            responseKeyArray="warehouses"
            fetchPath={Routes.api_web_v1_warehouses_path()}
            formName="commonDocumentForm"
            disabled={positionType === 2 || !isInventoryItem && !document.values.positions[index].warehouse_id}
          />
        </td>
        <td>
          ${document.values.positions[index].total_cents / 100}
        </td>
        <td>
          <Button variant="danger" size="sm" onClick={() => this.removePositionLine(index)}>
            Remove
          </Button>
        </td>
      </tr>
    );
  }

}

const mapDispatchToProps = dispatch => ({
  changeFieldValue: (fieldName, value) => {
    dispatch(change('commonDocumentForm', fieldName, value));
  },
  recalculateDocumentTotals: () => {
    dispatch(recalculateDocument());
  },
  changePositionType: (position, positionIndex) => {
    dispatch(changePositionItemType(position, positionIndex));
  },
  changePositionTaxRate: (positionIndex, taxRate) => {
    dispatch(changePositionItemTaxRate(positionIndex, taxRate));
    dispatch(recalculateDocument());
  },
  removePositionItemLine: (positionIndex) => {
    dispatch(removePositionItem(positionIndex));
    dispatch(recalculateDocument());
  }
});

const mapStateToProps = (state) => {
  return {
    document: state.form.commonDocumentForm,
    positionType: state.form.commonDocumentForm.position_type
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentPositionFields);

