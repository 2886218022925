import { produce } from 'immer';
import { get } from 'lodash';

import { LOAD_MISSED_EMPLOYEE_WORK_DAYS, LOAD_ACTIVE_EMPLOYEES } from './actionTypes'

const loadMissedEmployeeWorkDays = (draft, action) => {
  draft.missedEmployeeWorkDays = get(action, 'payload.response.data.employee_work_days', []);
};

const loadActiveEmployees = (draft, action) => {
  draft.activeEmployees = get(action, 'payload.response.data.employees', []);
};

const missedDaysReportReducer = produce((draft = {}, action, baseState) => {
  switch (action.type) {
    case LOAD_MISSED_EMPLOYEE_WORK_DAYS:
      return loadMissedEmployeeWorkDays(draft, action);
    case LOAD_ACTIVE_EMPLOYEES:
      return loadActiveEmployees(draft, action);
    default:
      return draft;
  }
});

export default missedDaysReportReducer;
