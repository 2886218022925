module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<p>' +
((__t = ( worker_name )) == null ? '' : __t) +
'</p>\n<div class="row"  data-jid="' +
((__t = ( jid )) == null ? '' : __t) +
'"  style="position: relative">\n</div>\n<p>' +
((__t = ( obj.event )) == null ? '' : __t) +
'</p>\n';

}
return __p
}