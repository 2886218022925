import React, { useEffect, useState } from 'react';
import ReportFilters from './ReportFilters';
import ReportTable from './ReportTable';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import merge from 'lodash/merge';

const CommissionsReport = ({}) => {

  const location = useLocation();
  const [initialValues, setInitialValues] = useState({});
  useEffect(() => {
    setInitialValues(merge(
      { employee_id: null, payroll_salary_change_reasons: [], year: new Date().getFullYear(), page: 1 },
      queryString.parse(location.search)));
  }, []);

  return (
    <>
        <ReportFilters initialValues={initialValues}/>
        <ReportTable/>
    </>
  );

};

export default CommissionsReport;
