import { produce } from 'immer';
import { get, filter, isEmpty, compact, flattenDeep, round, findIndex } from 'lodash';

import {
  LOAD_REQUIRED_SETTINGS,
  SET_CURRENT_SETUP_STEP_DATA,
  SET_FORM_ACTIVE_STATE
} from './actionTypes';

const defineNotConfiguredSettings = (draft, action) => {
  const allSettings = compact(flattenDeep(draft.requiredSettings.map((s) => {
    return s.data;
  })));
  const notConfiguredSettings = compact(flattenDeep(filter(allSettings, (o) => {
    return o.is_configured === false;
  })));
  draft.currentSettingsPercent = round(100 - (notConfiguredSettings.length * 100 / allSettings.length));

  if (isEmpty(notConfiguredSettings)) {
    return;
  }

  draft.allSettings = allSettings;
  draft.notConfiguredSettings = notConfiguredSettings;
};

const loadRequiredSettings = (draft, action) => {
  draft.requiredSettings = get(action, 'payload.response.data.required_settings', []);
  defineNotConfiguredSettings(draft, action);
};

const setCurrentSetupStepData = (draft, action) => {
  draft.currentSetupStepData = get(action, 'payload.currentStep', null)
};


const setFormActiveState = (draft, action) => {
  draft.formActiveState = get(action, 'payload.currentStep', 0)
};

const projectPayrollSetupReducer = produce((draft = {}, action, baseState) => {

  switch (action.type) {
    case LOAD_REQUIRED_SETTINGS:
      return loadRequiredSettings(draft, action);
    case SET_CURRENT_SETUP_STEP_DATA:
      return setCurrentSetupStepData(draft, action);
    case SET_FORM_ACTIVE_STATE:
      return setFormActiveState(draft, action);
    default:
      return draft;
  }
});

export default projectPayrollSetupReducer;
