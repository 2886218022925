import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useHistory, Link } from 'react-router-dom'
import delay from 'lodash/delay';
import isEmpty from 'lodash/isEmpty';
import ReduxFormSubmissionError from '../../../../components/ReduxFormSubmissionError';
import FormPayrollTaxSetupAddOn from './form';
import { useSnackbar } from 'notistack';

const FormPayrollTaxSetupAddOnWrapper = () => {
  let params = useParams();
  const history = useHistory();
  const {enqueueSnackbar} = useSnackbar();
  const [loadPath, setLoadPath] = useState(null);
  const [payrollTaxSetupAddOn, setPayrollTaxSetupAddOn] = useState({});

  useEffect(() => {
    if (params.payrollTaxSetupAddOnId) {
      setLoadPath(Routes.edit_api_web_v1_payroll_tax_setup_add_on_path(params.payrollTaxSetupAddOnId));
    } else {
      setLoadPath(Routes.new_api_web_v1_payroll_tax_setup_add_on_path());
    }
  }, [params]);

  useEffect(() => {
    if (loadPath) {
      loadPayrollTaxSetupAddOn()
        .then(response => {
          setPayrollTaxSetupAddOn(response.data.payroll_tax_setup_add_on);
        })
        .catch(error => {
          console.log('FormPayrollTaxSetupAddOnWrapper.loadSickSetting', error.response);
        });
    }
  }, [loadPath]);

  const loadPayrollTaxSetupAddOn = () => {
    return axios({ url: loadPath });
  };

  const handleSubmit = (values) => {
    return axios({
      method: params.payrollTaxSetupAddOnId ? 'PUT' : 'POST',
      url: params.payrollTaxSetupAddOnId ?
        Routes.api_web_v1_payroll_tax_setup_add_on_path(params.payrollTaxSetupAddOnId) :
        Routes.api_web_v1_payroll_tax_setup_add_ons_path(),
      data: { payroll_tax_setup_add_on: values }
    })
      .then(response => {
        delay(() => {
          history.push('/payroll_tax_setup_add_ons')
        }, 1000);
        enqueueSnackbar('Payroll tax setup setting was saved.', { variant: 'success' })
      })
      .catch(error => {
        ReduxFormSubmissionError(error.response.data.errors, values);
      });
  };

  return (
    <>
      {!isEmpty(payrollTaxSetupAddOn) &&
      <FormPayrollTaxSetupAddOn
        payrollTaxSetupAddOn={payrollTaxSetupAddOn}
        initialValues={payrollTaxSetupAddOn}
        onSubmit={handleSubmit}
        stepButtons={
          <>
            <Link
              to={'/payroll_tax_setup_add_ons'}
              className="btn btn-outline-info btn-block mr-2"
            >
              Back
            </Link>
          </>
        }
      />
      }
    </>
  );
};

export default FormPayrollTaxSetupAddOnWrapper;
