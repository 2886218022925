import React, { useEffect } from 'react';
import { Field } from 'redux-form';
import { connect } from 'react-redux';
import Button from 'react-bootstrap/Button';
import Tab from 'react-bootstrap/Tab';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import map from 'lodash/map';
import RenderField from '../../../../../components/ReduxForm/RenderField';
import SelectWrapper from '../../../../../components/ReduxForm/SelectWrapper';
import { fetchBusinessPartnerData } from '../../../../../redux/businessPartnerStoreReducer/actions';

let BillingAddressesFields = ({
  state,
  changeBusinessPartnerCollection,
  partnerBillingAddress,
  index,
  fields
}) => {

  // useEffect(() => {
  //   if (state.length === 0) {
  //     changeBusinessPartnerCollection();
  //   }
  // });

  const stateCollection = map(state, (value, _key) => ({
    value: value.id,
    label: value.name
  }));


  return (
    <Tab.Pane key={index} eventKey={`billing-${index}`}>
      <Row>
        <Col xs={12}>

          <Field
            name={`${partnerBillingAddress}.address_name`}
            component={RenderField}
            label="Address name"
          />

          {/*Address line 1*/}

          <Field
            name={`${partnerBillingAddress}.address_line_2`}
            component={RenderField}
            label={'Address line 2'}
          />

          <Field
            name={`${partnerBillingAddress}.city`}
            component={RenderField}
            label={'City'}
          />

          <Field
            label={'Person positions'}
            name={`${partnerBillingAddress}.state_id`}
            component={SelectWrapper}
            collection={stateCollection}
            formName="businessPartnerForm"
          />

          <Field
            name={`${partnerBillingAddress}.zip_code`}
            component={RenderField}
            label={'Zip code'}
          />
          <hr/>
          <Button variant="danger" size="sm" onClick={() => fields.remove(index)}>
            Remove
          </Button>
        </Col>
      </Row>
    </Tab.Pane>
  );

};

const mapDispatchToProps = dispatch => ({
  changeBusinessPartnerCollection: () => {
    dispatch(fetchBusinessPartnerData());
  },
});


const mapStateToProps = (state) => {

  const {
    form: {
      businessPartnerForm:
        businessPartner
    },
    businessPartnerStore: {
      statesCollection,
    }
  } = state;

  return {
    businessPartner,
    state: statesCollection,
  };

};

export default connect(mapStateToProps, mapDispatchToProps)(BillingAddressesFields);

