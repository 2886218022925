import Application from 'application-namespace';
import $ from 'jquery';
import _get from 'lodash/get';

Application.Concerns.TechSalesOrderSpecifically = superclass => class extends superclass {
  specificallyDetermination($scope) {
    this.$tech_sales_order_type = $scope.find('[data-tech-sales-orderable-type]');
    this.$tech_sales_order_id = $scope.find('[data-tech-sales-orderable-id]');
    this.$business_partner_select = $scope.find('#tech_sales_order_business_partner_id');
    this.$customer_equipment_card_select = $scope.find('#tech_sales_order_customer_equipment_card_id');
    this.$current_document_id = $scope.find('#tech_sales_order_tech_sales_orderable_id').val();
    this.$subject_input = $scope.find('#tech_sales_order_subject');
    this.$contract_info = $scope.find('.current-contract-info');

    this.$business_partner_select.on('change', () => {
      if (this.$business_partner_select.val()) {
        $.ajax({
          url: Routes.business_partner_path(this.$business_partner_select.val()),
          dataType: 'JSON',
        }).then((response) => {
          const byDefault = response.customer_equipment_cards.length <= 1;
          this.pasteToCecListSpecific(this.$customer_equipment_card_select, response.customer_equipment_cards, 'driveway_gate_name', byDefault);
          this.$customer_equipment_card_select.prop('disabled', !response.customer_equipment_cards.length);
        }).catch((error) => {
          console.error('Error: ', error);
        });
      } else {
        Application.Classes.CoreBase.pasteToListGlobal(this.$customer_equipment_card_select, []);
        this.$customer_equipment_card_select.prop('disabled', true);
      }
    });

    this.$customer_equipment_card_select.on('change', (event) => {
      // contractId = $(event.currentTarget).select2("data")[0].element.attributes.contract?.value
      // const contractId =  __guard__($(event.currentTarget).select2("data")[0].element.attributes.contract, x => x.value);
      const contractId = _get($(event.currentTarget).select2('data')[0], 'element.attributes.contract.value');
      if (contractId) {
        this.$contract_info.html(`<a class='btn-level' href='${Routes.edit_service_contract_path(contractId)}'>Yes</a>`);
      } else {
        this.$contract_info.html("<p class='color-warning'>No</p>");
      }

      if ($(event.currentTarget).val().length > 0) {
        $scope.find('[value="ServiceCall"]').prop('disabled', false);
        $scope.find('[value="ServiceMaintenance"]').prop('disabled', false);
        return this.$tech_sales_order_type.select2().trigger('change');
      }
      $scope.find('[value="ServiceCall"]').prop('disabled', true);
      $scope.find('[value="ServiceMaintenance"]').prop('disabled', true);
      return this.$tech_sales_order_type.select2().val('').trigger('change');
    });

    this.$tech_sales_order_type.on('change', (event) => {
      if (this.$tech_sales_order_type.val() && this.$business_partner_select.val()) {
        switch ($(event.currentTarget).val()) {
          case 'SalesOrder':
            this.getAndPasteDocumentable(Routes.business_partner_sales_orders_path(this.$business_partner_select.val()), 'number', this.$tech_sales_order_id, this.$current_document_id);
            break;
          case 'ServiceCall':
            this.getAndPasteDocumentable(Routes.customer_equipment_card_service_calls_path(this.$customer_equipment_card_select.val()), 'service_call_number', this.$tech_sales_order_id, this.$current_document_id);
            break;
          case 'ServiceMaintenance':
            this.getAndPasteDocumentable(Routes.customer_equipment_card_service_maintenances_path(this.$customer_equipment_card_select.val()), 'number', this.$tech_sales_order_id, this.$current_document_id);
            break;
          default:
            console.error('Unprocessed case in specificallyDetermination', $(event.currentTarget).val());
            break;
        }
      } else {
        this.$tech_sales_order_id.prop('disabled', true);
        Application.Classes.CoreBase.pasteToListGlobal(this.$tech_sales_order_id, []);
      }
    });

    this.$tech_sales_order_id.on('select2:select', (event) => {
      this.fetchSubject(event, this.$tech_sales_order_type, this.$subject_input);
    });
    this.$tech_sales_order_id.on('change', (event) => {
      this.swapLinkId(event, this.$tech_sales_order_type);
      this.fetchSoTable(event, this.$tech_sales_order_type);
    });
    this.$tech_sales_order_type.trigger('change');
    return this.$tech_sales_order_id.trigger('change');
  }

  getAndPasteDocumentable(path, mainAttribute, domElement, currentDocument) {
    $.ajax({
      url: path,
      dataType: 'JSON',
      data: { type: 'tech_sales_order' },
    }).then((response) => {
      Application.Classes.CoreBase.pasteToListGlobal(domElement, response, mainAttribute);
      $(domElement).val(currentDocument).trigger('change');
      domElement.prop('disabled', !response.length);
    }).catch((error) => {
      console.error('Error: ', error);
      this.$tech_sales_order_id.prop('disabled', true);
      Application.Classes.CoreBase.pasteToListGlobal(this.$tech_sales_order_id, []);
    });
  }

  fetchSoTable(event, docType) {
    const id = $(event.currentTarget).val();
    if (id && ($(docType).val() === 'SalesOrder')) {
      $.ajax({
        type: 'GET',
        url: Routes.new_tech_sales_order_path(),
        dataType: 'SCRIPT',
        data: { sales_order_id: id },
      });
    }
  }

  fetchSubject(event, docType, subject) {
    const id = $(event.currentTarget).val();
    if (id) {
      const path = (() => {
        switch ($(docType).val()) {
          case 'SalesOrder':
            return Routes.sales_order_path(id);
          case 'ServiceCall':
            return Routes.service_call_path(id);
          case 'ServiceMaintenance':
            return Routes.service_maintenance_path(id);
          default:
            console.error('Unprocessed case in fetchSubject', $(docType).val());
            return null;
        }
      })();
      $.ajax({
        url: path,
        dataType: 'JSON',
      }).then((response) => {
        if ($(docType).val() === 'SalesOrder') { $(subject).val(`Sales Order: ${response.number}`); }
        if ($(docType).val() === 'ServiceCall') { $(subject).val(response.subject); }
        if ($(docType).val() === 'ServiceMaintenance') { $(subject).val(`Service Maintenance: ${response.number}`); }
      }).catch((error) => {
        console.error('Error: ', error);
      });
    } else {
      console.warn(`Application.Concerns.TechSalesOrderSpecifically#fetchSubject id - ${id}`);
    }
  }

  swapLinkId(event, docType) {
    $('.sales-order-table').html('');
    const id = $(event.currentTarget).val();
    let path = 'javascript: void(0)';
    if (id) {
      path = (() => {
        switch ($(docType).val()) {
          case 'SalesOrder':
            return Routes.edit_sales_order_path(id);
          case 'ServiceCall':
            return Routes.edit_service_call_path(id);
          case 'ServiceMaintenance':
            return Routes.edit_service_maintenance_path(id);
          default:
            return 'javascript: void(0)';
        }
      })();
    }
    $('#link_on_base_document').find('a').attr('href', path);
  }

  pasteToCecListSpecific(selector, data, title, byDefault) {
    if (byDefault == null) { byDefault = false; }
    const $selector = $(selector);
    $selector.empty();
    $selector.html('<option value="" selected="selected"></option>');
    for (let k = 0; k < data.length; k++) {
      const object = data[k];
      const attributeTitle = eval(`object.${title}`);
      // contractId     = object?.current_service_contract?.id
      // const contractId     =  __guard__(object != null ? object.current_service_contract : undefined, x => x.id);
      const contractId = _get(object, 'current_service_contract.id');
      $selector.append(`<option value="${object.id}" contract="${contractId || ''}">${attributeTitle}</option>`);
    }
    $selector.trigger('change');
    if (byDefault === true) {
      $selector.find('option[value=1]').attr('selected', 'selected');
      $selector.trigger('change');
    }
  }
};
