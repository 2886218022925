import Application from 'application-namespace';
import $ from 'jquery';
import _each from 'lodash/each';

Application.Classes.EmployeeHealthBenefitForm = class EmployeeHealthBenefitForm extends Application.Classes.CoreBase {
  constructor($scope) {
    super();
    this.$scope = $scope;
    this.$health_benefit_setting_line_input = this.$scope.find('[data-health-benefit-setting-line-input]');
    this.$start_date_input = this.$scope.find('[data-start-date-input]');
    this.$employee_pays_input = this.$scope.find('[data-employee-pays-input]');
    this.$company_pays_input = this.$scope.find('[data-company-pays-input]');
    this.$end_date_input = this.$scope.find('[data-end-date-input]');
    this.$submit_btn = this.$scope.find('[type="submit"]');
    this.employee_id = this.$scope.data('employeeId');

    this.$health_benefit_setting_line_input.on('change', event => this.getCalculatePays(event));

    this.$start_date_input.on('dp.change', (event) => {
      if ($(event.currentTarget).val()) {
        $.ajax({
          url: Routes.health_benefit_settings_path(),
          dataType: 'JSON',
          data: { start_date: $(event.currentTarget).val() },
        }).then((response) => {
          this.pasteHealthBenefitSettingLines(response);
        }).catch((error) => {
          console.warn('Error: ', error);
        });
      }
      console.warn(`Application.Classes.EmployeeHealthBenefitForm#conscructor  this.$start_date_input.dp.change Value - ${$(event.currentTarget).val()}`);
    });
  }

  pasteHealthBenefitSettingLines(healthBenefitSetting) {
    this.$start_date_input.closest('.input').find('.text-danger').remove();

    // TODO check the Konstantin
    if (healthBenefitSetting != null ? healthBenefitSetting.health_benefit_setting_lines : undefined) {
      this.$end_date_input.prop('disabled', false).data('DateTimePicker').date(new Date(healthBenefitSetting.end_date));
      const settingLines = healthBenefitSetting.health_benefit_setting_lines.sort((a, b) => a.total_cost_per_week_cents - b.total_cost_per_week_cents);
      this.pasteToList(this.$health_benefit_setting_line_input, settingLines, 'plan_name');
      this.setDisableForInputs(false);
    } else {
      this.$end_date_input.prop('disabled', true).data('DateTimePicker').clear();
      this.$start_date_input.closest('.input').append('<p class="text-danger">Health benefit settings is blank for this date.</p>');
      this.pasteToList(this.$health_benefit_setting_line_input, [], 'plan_name');
      this.setDisableForInputs(true);
    }
  }

  getCalculatePays(event) {
    $.ajax({
      url: Routes.health_benefit_setting_line_path($(event.currentTarget).val()),
      dataType: 'JSON',
    }).then((response) => {
      this.pastePayValues(response);
    }).catch((error) => {
      console.warn('Error: ', error);
    });
  }

  pastePayValues(responce) {
    if (responce.calculate_pays) {
      this.$employee_pays_input.val(responce.calculate_pays.employee_pays_per_week);
      this.$company_pays_input.val(responce.calculate_pays.company_pays_per_week);
    } else {
      this.$employee_pays_input.val(0);
      this.$company_pays_input.val(0);
    }
  }

  setDisableForInputs(status) {
    _each([this.$health_benefit_setting_line_input, this.$submit_btn, this.$employee_pays_input, this.$company_pays_input], element => $(element).prop('disabled', status));
  }
};
