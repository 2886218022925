import Application from 'application-namespace';

Application.Classes.BreadcrumbHeader = class BreadcrumbHeader {
  constructor($scope) {
    this.$recalculateBtn = $scope.find('.recalculate-salary-btn');
    this.subscribeToChanel();
  }

  subscribeToChanel() {
    Application.Services.WebSocket.subscribe('BackgroundJobsChannel', (data) => {
      if (data.event === 'start_job') {
        this.$recalculateBtn.find('a').addClass('text-success');
        this.$recalculateBtn.find('i').remove();
        // this.$recalculateBtn.find('i').addClass('fa-spin text-success');
        this.$recalculateBtn.prepend('<i class="fa fa-redo fa-spin text-success"></i>');
      }
      if (data.event === 'job_is_finished') {
        window.location.reload();
      }
    });
  }
};
