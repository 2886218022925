import Application from 'application-namespace';
import $ from 'jquery';
import moment from 'moment';

Application.Classes.EmployeePunchForm = class EmployeePunchForm {
  constructor($scope) {
    this.$scope = $scope;
    this.$punchTimeAtInput = this.$scope.find('[data-origin-data]');
    this.$punchTimeOnlyInput = this.$scope.find('[data-time-only]');
    this.$fullDatetimeInput = this.$scope.find('#punch_date_and_time');
    this.recommended_date = this.$punchTimeAtInput.val();

    this.$punchTimeOnlyInput.on('dp.change', (event) => {
      const selectedTime = moment($(event.currentTarget).val(), ['h:m a']);
      const selectedHours = selectedTime.hour();
      const selectedMinutes = selectedTime.minute();
      const resultTime = moment.utc(this.recommended_date).hour(selectedHours).minute(selectedMinutes);

      this.$punchTimeAtInput.val(resultTime.format('MM/DD/YYYY h:mm A'));

      this.$fullDatetimeInput.val(this.$punchTimeAtInput.val());
    });
  }
};
