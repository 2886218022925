import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { camelCase, capitalize, lowerCase } from 'lodash';
import InputGroup from 'react-bootstrap/InputGroup';

let TouchSpinField = ({
  input: { name, value, onChange },
  label,
  prepend,
  type,
  as,
  disabled,
  meta: { error, touched }
}) => {

  return (
    <>
      {label && <Form.Label>{label}</Form.Label>}
      {label === undefined && <Form.Label>{capitalize(lowerCase(name))}</Form.Label>}
      <InputGroup>
        <InputGroup.Prepend>
          <Button
            className='btn btn-primary'
            onClick={() => {
              onChange(parseInt(value) - 1);
            }}>-</Button>
        </InputGroup.Prepend>
        <Form.Control
          as={as || 'input'}
          type={type || 'input'}
          value={value}
          disabled={disabled || false}
          aria-describedby={`inputGroup${camelCase(name)}`}
          onChange={onChange}
          className={error ? 'is-invalid' : null}
        />
        <InputGroup.Append>
          <Button
            // type='submit'
            className='btn btn-primary'
            onClick={() => {
              onChange(parseInt(value) + 1);
            }}
          >+</Button>
        </InputGroup.Append>
      </InputGroup>
      <Form.Control.Feedback type="invalid">
        {error}
      </Form.Control.Feedback>
    </>
  );
};
export default TouchSpinField;
