import Application from 'application-namespace';
import $ from 'jquery';
import _parseInt from 'lodash/parseInt';

Application.Classes.PermissionsTable = class PermissionsTable {
  constructor($scope) {
    this.$scope = $scope;
    this.$actionSelection = this.$scope.find('[data-action-selection]');
    this.$clearBtn = this.$scope.find('[data-started-value]');
    this.currentPath = this.$scope.find('[data-resource-path]').attr('data-resource-path');
    this.$lines = this.$scope.find('tr');
    this.$resourcesForPermission = this.$scope.find('[data-link-on-resource]');
    this.$saveButton = this.$scope.find('#save_permissions');

    this.$actionSelection.off('change').on('change', (event) => {
      this.changeChildren(event);
      this.constructor.changeDisabled(event);
    });
    this.$saveButton.off('click').on('click', event => this.saveChanges(event));
    this.$clearBtn.off('click').on('click', event => this.constructor.resetValue(event));

    if (!this.currentPath.length) { $(this.$resourcesForPermission[0]).trigger('click'); }
  }

  static resetValue(event) {
    if ($(event.currentTarget).prop('disabled')) { return; }
    const $line = $(event.currentTarget).closest('tr');
    const startedValue = $(event.currentTarget).data('startedValue');
    $line.find('select').val(startedValue).trigger('change');
  }

  static changeDisabled(event) {
    const $line = $(event.currentTarget).closest('tr');
    const currentValue = $(event.currentTarget).val();
    const startedValue = $line.find('[data-started-value]').data('startedValue');
    $line.find('[data-apply-changes-btn]').attr('disabled', _parseInt(currentValue) === _parseInt(startedValue));
    $line.find('[data-started-value]').attr('disabled', _parseInt(currentValue) === _parseInt(startedValue));
  }

  saveChanges() {
    const result = {};
    this.$actionSelection.each((i, object) => {
      const startedValue = $(object).closest('tr').find('[data-started-value]').data('startedValue');
      const value = $(object).val();
      const resource = $(object).data('resourceName');
      if (_parseInt(value) !== _parseInt(startedValue)) {
        result[resource] = value;
      }
    });
    this.sendChanges(result);
  }

  sendChanges(changes) {
    $.ajax({
      type: 'POST',
      url: this.currentPath,
      dataType: 'SCRIPT',
      data: { permissions: changes },
    });
  }

  changeChildren(event) {
    const $currentElement = $(event.currentTarget);
    const currentValue = $currentElement.val();
    const currentId = $currentElement.closest('tr').data('id');
    this.$lines.each((i, element) => {
      const parentIds = $(element).data('ttParentId');
      if (parentIds) {
        const parentIdsArray = String(parentIds).split('-');
        parentIdsArray.forEach((id) => {
          if (_parseInt(id) === _parseInt(currentId)) {
            $(element).find('select').val(currentValue).trigger('change');
          }
        });
      }
    });
  }
};
