import Application from 'application-namespace';
import $ from 'jquery';
import mix from 'mix';

Application.Classes.TaxReport = class TaxReport extends mix(Application.Classes.CoreBase).with(
  Application.Concerns.ReportsExtension,
) {
  constructor($scope) {
    super();
    this.$scope = $scope;
    this.determinationLevelForTreeTable(this.$scope);
    this.$accrual = this.$scope.find('#accrual_basis');

    this.$accrual.on('change', (event) => {
      const path = $(event.currentTarget).data('path');
      $.ajax({
        method: 'GET',
        url: path,
        dataType: 'script',
        data: { accrual: $(event.currentTarget).is(':checked') },
      });
    });
  }
};
