import Application from 'application-namespace';
import $ from 'jquery';
import moment from 'moment';

Application.Classes.HolidayForm = class HolidayForm {
  constructor($scope) {
    this.$scope = $scope;
    this.$holidayDays = this.$scope.find('[data-holiday]');
    this.$addBtn = this.$scope.find('[data-association-insertion-node]');

    this.$holidayDays.on('click', (event) => {
      this.addSelectedDay(event);
    });

    this.$scope.on('cocoon:after-insert', (e, insertedItem) => {
      Application.app.initializeDatePicker(insertedItem);
    });
  }

  addSelectedDay(event) {
    event.preventDefault();
    const $element = $(event.currentTarget);
    const data = $element.data('date');
    const name = $element.data('holidayName');

    this.$addBtn.click();
    const $dates = this.$scope.find('.nested-fields');
    $dates.each((index, element) => {
      const $startDate = $(element).find('[data-start-date]');
      const $endDate = $(element).find('[data-end-date]');
      const $remark = $(element).find('[data-remark]');

      if (!$startDate.val() || !$endDate.val() || !$remark.val()) {
        $startDate.val(moment(data, 'YYYY-MM-DD').format('MM/DD/YYYY'));
        $endDate.val(moment(data, 'YYYY-MM-DD').format('MM/DD/YYYY'));
        $remark.val(name);
        this.$scope.find(`[data-holiday-name="${name}"]`).closest('a').remove();
      }
    });
  }
};
