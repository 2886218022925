import React, { useEffect } from 'react';
import { Field } from 'redux-form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import map from 'lodash/map';
import get from 'lodash/get';
import { useSelector, useDispatch } from 'react-redux';
import SelectWrapper from '../../../../components/ReduxForm/SelectWrapper';
import RenderField from '../../../../components/ReduxForm/RenderField';

let AccountingForm = () => {
  const businessPartner = useSelector(state => state.form.businessPartnerForm);
  console.log('businessPartner', businessPartner);
  const { values: { tax_status_id: taxStatusId } } = businessPartner;
  const businessPartnerStore = useSelector(state => state.businessPartnerStore);
  const taxStatus = get(businessPartnerStore, 'taxStatusCollection', []);
  console.log('businessPartnerStore', businessPartnerStore);
  console.log('taxStatus start', taxStatus);
  const taxStatusCollection = map(taxStatus, (value, _key) => ({
    value: value.id,
    label: value.name
  }));
  const dispatch = useDispatch();

  useEffect(() => {
    // console.log('taxStatus', taxStatus);
    // console.log('taxStatusCollection', taxStatusCollection);
    //   dispatch(allActions.businessPartnerCollectionActions.fetchTaxStatusCollection());
  },[]);


  return (
    <Row>
      <Col md={12}>
        <Field
          name="payment_term_id"
          component={SelectWrapper}
          collection={taxStatusCollection}
          label="Payment term"
          formName="businessPartnerForm"
        />
        {
          taxStatusId === 1 &&
          <Field
            name='exempt_number'
            component={RenderField}
            label={'Exempt number'}
          />
        }
      </Col>
    </Row>
  );
};

//
// const mapDispatchToProps = dispatch => ({
//   changeBusinessPartnerCollection: () => {
//     dispatch(fetchBusinessPartnerData());
//   },
// });

//
//
// const mapStateToProps = (state) => {
//   const {
//     form: {
//       businessPartnerForm:
//         businessPartner
//     },
//   } = state;
//   return {
//     businessPartner,
//     taxStatus: get(state.businessPartnerStore, 'taxStatusCollection', []),
//   };
//
// };

// export default connect(mapStateToProps, mapDispatchToProps)(AccountingForm);
export default AccountingForm;


