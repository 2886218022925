import React from 'react';
import Navigation from './Navigation';
import { Switch, Route } from 'react-router-dom'
import PayrollSettings from './PayrollSettings';
import SickSettings from './SickSettings';
import VacationSettings from './VacationSettings';
import EmergencyPhoneSettings from './EmergencyPhoneSettings';
import HealthBenefitSettings from './HealthBenefitSettings';
import CompanyRetirementSettings from './CompanyRetirementSettings';
import GlobalRetirementSettings from './GlobalRetirementSettings';
import PayrollTaxSetupAddOns from './PayrollTaxSetupAddOns';
import SummaryPage from './SummaryPage';

const ProjectPayrollSetup = ({}) => {

  return (
    <>
      <div className='row flex-xl-nowrap'>

        <div className='col-12 col-xl-9'>
          <Switch>
            <Route path="/summary" component={SummaryPage}/>
            <Route path="/payroll_settings" component={PayrollSettings}/>
            <Route path="/sick_settings" component={SickSettings}/>
            <Route path="/vacation_settings" component={VacationSettings}/>
            <Route path="/emergency_phone_settings" component={EmergencyPhoneSettings}/>
            <Route path="/health_benefit_settings" component={HealthBenefitSettings}/>
            <Route path="/company_retirement_settings" component={CompanyRetirementSettings}/>

            <Route path="/global_retirement_settings" component={GlobalRetirementSettings}/>
            <Route path="/payroll_tax_setup_add_ons" component={PayrollTaxSetupAddOns}/>
          </Switch>
        </div>

        <div className='d-none d-xl-block col-xl-3'>
          <Navigation/>
        </div>
      </div>

    </>
  );

};

export default ProjectPayrollSetup;
