import React from 'react';
import Table from 'react-bootstrap/Table';
import { FieldArray } from 'redux-form';
import DocumentAttachment from './DocumentAttachment';

const DocumentAttachmentsContainer = (props) => {
  return (
    <div>
      <Table bordered size="sm">
        <thead>
        <tr>
          <th>File</th>
          <th>Actions</th>
        </tr>
        </thead>
        <FieldArray
          name="attachments"
          component={DocumentAttachment}
        />
      </Table>
    </div>
  );
};

export default DocumentAttachmentsContainer;

