import Application from 'application-namespace';
import $ from 'jquery';

Application.Classes.EmployeeListFilters = class EmployeeListFilters {
  constructor($scope) {
    this.$scope = $scope;
    this.$showOnlyActiveEmployee = $scope.find('[name="show-only-active"]');
    this.dataTable = this.$scope.find('[data-class-binder="DataTable"]');

    this.$showOnlyActiveEmployee.on('change', (event) => {
      const currentValue = $(event.currentTarget).prop('checked');
      this.dataTable.DataTable().ajax.url(`?show_only_active_employees=${currentValue}`).load();
    });
  }
};
