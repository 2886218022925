import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Field } from 'redux-form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { get, find } from 'lodash';
import PartnerAddressCardDecorator from './PartnerAddressCardDecorator';
import SelectWrapper from '../../../../components/ReduxForm/SelectWrapper';
import Select2Collection from '../../../../decorators/Select2Collection';
import RenderField from '../../../../components/ReduxForm/RenderField';

const DocumentLogisticForm = ({ partnerBillingAddresses, partnerShippingAddresses, document }) => {

  const [selectedBillingAddress, setSelectedBillingAddress] = useState(null);
  const [selectedShippingAddress, setSelectedShippingAddress] = useState(null);

  useEffect(() => setPartnerAddresses(),
    [
      document.values.partner_billing_address_id,
      document.values.partner_shipping_address_id,
      partnerBillingAddresses,
      partnerShippingAddresses,
    ]
  );

  const setPartnerAddresses = () => {
    const currentDocument = document.values;
    setSelectedBillingAddress(find(partnerBillingAddresses, { id: currentDocument.partner_billing_address_id }));
    setSelectedShippingAddress(find(partnerShippingAddresses, { id: currentDocument.partner_shipping_address_id }));
  };

  return (
    <>
      <Row>
        <Col md="3">
          <Field
            name="partner_billing_address_id"
            component={SelectWrapper}
            collection={Select2Collection(partnerBillingAddresses, { labelKey: 'address_name' })}
            label="Partner billing address"
            formName="commonDocumentForm"
          />
        </Col>
        <Col md="3">
          <Field
            name="partner_shipping_address_id"
            component={SelectWrapper}
            collection={Select2Collection(partnerShippingAddresses, { labelKey: 'address_name' })}
            label="Partner shipping address"
            formName="commonDocumentForm"
          />
        </Col>
        <Col md="6">
          <Field
            name="bp_channel_name"
            component={RenderField}
          />
        </Col>
      </Row>
      <Row>
        <PartnerAddressCardDecorator
          selectedBillingAddress={selectedBillingAddress}
          selectedShippingAddress={selectedShippingAddress}
        />
      </Row>
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    document: state.form.commonDocumentForm,
    partnerBillingAddresses:
      get(state.documentsStore, 'partnerBillingAddresses', []),
    partnerShippingAddresses:
      get(state.documentsStore, 'partnerShippingAddresses', [])
  };
};

export default connect(mapStateToProps)(DocumentLogisticForm);
