import React, { useEffect, useState } from 'react';
import get from 'lodash/get';
import map from 'lodash/map';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import findIndex from 'lodash/findIndex';
import { useDispatch, useSelector } from 'react-redux';
import {
  loadRequiredSettings,
  setCurrentSetupStepData
} from '../../../redux/projectPayrollSetupReducer/actions';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Button from 'react-bootstrap/Button';

const Navigation = () => {
  let history = useHistory();
  let location = useLocation();
  const dispatch = useDispatch();

  const projectPayrollSetupStore = useSelector(state => state.projectPayrollSetupStore);
  const requiredSettings = get(projectPayrollSetupStore, 'requiredSettings', []);
  const allSettings = get(projectPayrollSetupStore, 'allSettings', []);
  const notConfiguredSettings = get(projectPayrollSetupStore, 'notConfiguredSettings', null);
  const currentSettingsPercent = get(projectPayrollSetupStore, 'currentSettingsPercent', 0);

  const [currentStep, setCurrentStep] = useState(undefined);
  const [currentStepIndex, setCurrentStepIndex] = useState(undefined);

  useEffect(() => {
    defineCurrentStep();
  }, [notConfiguredSettings, location]);

  useEffect(() => {
    dispatch(loadRequiredSettings());
  }, []);

  const defineCurrentStep = () => {
    const firstStep = get(notConfiguredSettings, '[0]', undefined);
    const currentSetupStepData = find(allSettings, {path: location.pathname.substring(1)});

    dispatch(setCurrentSetupStepData(currentSetupStepData));

    setCurrentStep(get(firstStep, 'path', '/start'));
    setCurrentStepIndex(findIndex(allSettings, firstStep) + 1);
  };

  const defineIcon = (subMenu) => {
    if (subMenu.is_configured) {
      return <i className="fa fa-check-circle pr-2 text-success" aria-hidden="true"/>;
    } else {
      return <i className="fa fa-minus-circle pr-2 text-warning" aria-hidden="true"/>;
    }
  };

  const defineSubMenuLi = (subMenu, subMenuIndex) => {
    return (
      <li key={`dataObject-${subMenuIndex}`}>
        {defineIcon(subMenu)}
        <NavLink activeClassName='font-weight-bolder' className='text-muted' to={`/${subMenu.path}`}>
          {subMenuIndex + 1}. {subMenu.entity_name}
        </NavLink>
      </li>
    );
  };

  const goToCurrentStep = () => {
    history.push(`/${currentStep}`);
  };

  return (
    <>
      <div className='p-2 border-left'>
        <h5>Project setup</h5>
        <ProgressBar
          variant={isEmpty(notConfiguredSettings) ? 'success' : 'info'}
          now={currentSettingsPercent}
        />
        <p>{currentSettingsPercent}% complete</p>
        { !isEmpty(notConfiguredSettings) &&
          <Button variant="info" onClick={goToCurrentStep} block disabled={`/${currentStep}` === location.pathname}>Continue to step {currentStepIndex}</Button>
        }
        <hr/>
        <ul className='list-unstyled nav bd-sidenav'>
          {
            map(requiredSettings, (requiredSetting, positionIndex) => {
              return (
                <ul className="pl-2 w-100" key={positionIndex}>
                  <a className='text-muted' href='#'>{requiredSetting.group_name}</a>
                  <ul className='list-unstyled pl-2'>
                    {
                      map(requiredSetting.data, (dataObject, dataIndex) => {
                        return defineSubMenuLi(dataObject, dataIndex);
                      })
                    }
                  </ul>
                </ul>
              );
            })
          }
        </ul>
      </div>
    </>
  );

};

export default Navigation;

