import React, { Component } from 'react';
import { Provider } from 'react-redux';
import store from '../../../redux/store';
import axios from 'axios';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import BusinessPartnerForm from './BusinessPartnerForm';
import { produce } from 'immer';

class BusinessPartnerFormContainer extends Component {

  state = {
    businessPartner: null,
    isLoading: false
  };

  componentDidMount() {
    this.fetchBusinessPartnerObject();
  }

  fetchBusinessPartnerObject = () => {
    axios({
      method: 'GET',
      url: Routes.define_business_partner_api_web_v1_business_partners_path(),
    })
      .then(response => {
        const { business_partner: businessPartner } = response.data;
        const NewBusinessPartner = produce(businessPartner, draft => {
          draft.service_partner_type_id = 1;
        });
        this.setState({
          businessPartner: NewBusinessPartner,
          isLoading: false
        });
      })
      .catch(error => {
        console.log(error);
      });
  };

  handleSubmit(values) {
    console.log(values);
  }

  render() {
    const {
      isLoading,
      businessPartner,
    } = this.state;
    if (isLoading) {
      return (
        <Container>
          <Row className="justify-content-md-center">
            <Spinner animation="border" role="status"/>
          </Row>
        </Container>
      );
    }
    return (
      <Provider store={store}>
        <BusinessPartnerForm onSubmit={this.handleSubmit}
                             businessPartner={businessPartner}/>
      </Provider>
    );
  }
}

export default BusinessPartnerFormContainer;
