import axios from 'axios';
import { has } from 'lodash';

import {
  PARSE_BUSINESS_PARTNER_DATA,
  STORE_TECH_SALES_ORDERS
} from './actionTypes'

export const fetchBusinessPartnerData = (businessPartnerId, document) => {
  return (dispatch) => {
    if (businessPartnerId) {
      return axios.all([
        fetchPartnerContactPeople(businessPartnerId),
        fetchPartnerBillingAddresses(businessPartnerId),
        fetchPartnerShippingAddresses(businessPartnerId),
        fetchCustomerEquipmentCards(businessPartnerId, document)
      ])
        .then(axios.spread((...responses) => {
          dispatch({
            type: PARSE_BUSINESS_PARTNER_DATA,
            payload: { responses }
          });
        }))
        .catch(errors => {
          console.log('documentStoreError', errors);
        });
    }
  };
};

export const parseTechSalesOrders = (salesOrderId) => {
  return (dispatch) => {
    return (
      axios({ url: Routes.api_web_v1_sales_order_tech_sales_orders_path(salesOrderId) })
        .then(response => {
          dispatch({
            type: STORE_TECH_SALES_ORDERS,
            payload: { response }
          });
        })
        .catch(error => {
          console.log(error);
        })
    );
  };
};

export const storeTechSalesOrders = (techSalesOrderData) => ({
  type: STORE_TECH_SALES_ORDERS,
  payload: {
    techSalesOrderData
  }
});

export const parseBusinessPartnerData = (businessPartnerData) => ({
  type: PARSE_BUSINESS_PARTNER_DATA,
  payload: {
    businessPartnerData
  }
});

const fetchPartnerContactPeople = (businessPartnerId) => {
  return axios.get(Routes.api_web_v1_business_partner_contact_people_path(businessPartnerId));
};

const fetchPartnerBillingAddresses = (businessPartnerId) => {
  return axios.get(Routes.api_web_v1_business_partner_partner_billing_addresses_path(businessPartnerId));
};

const fetchPartnerShippingAddresses = (businessPartnerId) => {
  return axios.get(Routes.api_web_v1_business_partner_partner_shipping_addresses_path(businessPartnerId));
};

const fetchCustomerEquipmentCards = (businessPartnerId, document) => {
  if (has(document.initial, 'customer_equipment_card_id')) {
    return axios.get(Routes.api_web_v1_business_partner_customer_equipment_cards_path(businessPartnerId));
  } else {
    return [];
  }
};
