import React, { Component } from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import Form from 'react-bootstrap/Form';
import { capitalize, lowerCase } from 'lodash';
import moment from 'moment';
import { formatDate, parseDate } from 'react-day-picker/moment';
import InputGroup from 'react-bootstrap/InputGroup';

class InputDatePicker extends Component {

  render() {
    const {
      input,
      label,
      disabled,
      meta: { error }
    } = this.props;

    let { input: { value } } = this.props;
    const isDifferentFormat = moment(input.value, 'YYYY-MM-DD', 'it', true).isValid();

    if (isDifferentFormat) {
      value = formatDate(input.value, 'MM/DD/YYYY', 'it');
    }

    // if (!disabled && !value) {
    //   value = formatDate(new Date(), 'MM/DD/YYYY')
    // }

    return (
      <div className={`${error ? 'is-invalid' : null}`}>
        {label && <Form.Label>{label}</Form.Label>}
        {label === undefined && <Form.Label>{capitalize(lowerCase(input.name))}</Form.Label>}
        <InputGroup>
          <DayPickerInput
            inputProps={{
              ...input,
              className: `form-control w-100 ${error ? 'is-invalid' : null}`,
              placeholder: 'Select date',
              autoComplete: 'off',
              disabled: disabled || false
            }}
            parseDate={parseDate}
            onDayChange={input.onChange}
            value={value}
          />
        </InputGroup>
        <Form.Control.Feedback type="invalid" className={`${error ? 'd-block' : null}`}>
          {error}
        </Form.Control.Feedback>
      </div>
    );
  }
}

export default InputDatePicker;
