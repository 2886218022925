import React, { useState } from 'react';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form';
import ReduxFormValidationAlert from '../../../../components/ReduxFormValidationAlert';
import InputDatePicker from '../../../../components/ReduxForm/InputDatePicker';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

const FormCompanyRetirementSetting = ({stepButtons, handleSubmit}) => {

  return (
    <>
      <ReduxFormValidationAlert formName={'companyRetirementSettingForm'}/>
      <Form onSubmit={handleSubmit}>

        <Row>
          <Col md="12">
            <Field
              name="effective_date"
              component={InputDatePicker}
            />
          </Col>
        </Row>

        <div className="d-flex justify-content-start align-items-baseline">
          {stepButtons}
          <Button
            variant="success"
            type="submit"
            block
          >
            Next
          </Button>
        </div>

      </Form>
    </>
  );
};

export default compose(
  reduxForm({
    form: 'companyRetirementSettingForm',
    enableReinitialize: true
  })
)(FormCompanyRetirementSetting);

