import Application from 'application-namespace';
import $ from 'jquery';
import moment from 'moment';

Application.Classes.TreeTableSearchBetween = class TreeTableSearchBetween {
  constructor($scope) {
    this.$scope = $scope;
    this.initializeRangeDatePicker(this.$scope);
    this.$refreshBtn = this.$scope.find('[data-refresh-btn]');
    this.$filters = this.$scope.find('select, input');
    this.$rangePicker = this.$scope.find('.range-date-picker');

    this.$refreshBtn.on('click', event => this.getResults(event));
    this.$rangePicker.on('apply.daterangepicker', () => this.$scope.find('[data-refresh-btn]').trigger('click'));
  }

  getResults(event) {
    const path = $(event.currentTarget).data('path');
    const result = {};
    this.$filters.each((i, element) => {
      let key;
      let val;
      if ($(element).attr('type') === 'checkbox') {
        key = $(element).attr('name'); val = $(element).prop('checked');
      } else {
        key = $(element).attr('name');
        val = $(element).val();
      }
      result[key] = val;
    });

    if (result.date_range) {
      result.start_date = result.date_range.split('-')[0];
      result.end_date = result.date_range.split('-')[1];
      window.history.pushState('', '', `?start_date=${result.start_date}&end_date=${result.end_date}`);
    }

    $.ajax({
      type: 'GET',
      url: path,
      dataType: 'SCRIPT',
      data: result,
      beforeSend: () => {
        this.$scope.find('.range-date-picker').prop('disabled', true);
        this.$refreshBtn.prop('disabled', true);
      },
    }).then(() => {
      this.$scope.find('.range-date-picker').prop('disabled', false);
      this.$refreshBtn.prop('disabled', false);
    }).catch((error) => {
      console.error('Error: ', error);
    });
  }

  initializeRangeDatePicker($defaultScope) {
    const $scope = $defaultScope == null ? $('body') : $defaultScope;
    const payrollRuns = { weekly: 6, biweekly: 14, monthly: 31 };
    const $input = $scope.find('.range-date-picker');
    const res = {};
    if ($input.data('lastPeriods')) {
      $input.data('lastPeriods').forEach((element) => {
        const dates = []; const key = [];
        Object.values(element.dates).forEach((date, index) => {
          dates.push(index > 0 ? moment.utc(date) : moment.utc(date));
          key.push(index > 0 ? moment.utc(date).format('l') : moment.utc(date).format('l'));
        });
        // res[`<span class='pull-right label ${element.label_class}'>${element.status}</span>${key.join('-')}`] = dates;
        res[`<span class='text-muted small ${element.label_class}'>● ${element.status}</span><br>${key.join('-')}`] = dates;
      });
    }
    $input.daterangepicker({
      locale: { format: 'MM/DD/YYYY', separator: '-' },
      maxSpan: { days: payrollRuns[$input.data('payrollRun')] || 61 },
      ranges: res,
    });
  }
};
