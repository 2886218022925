import React, { Fragment } from 'react';
import { Switch, Route } from 'react-router-dom';
import { Redirect} from 'react-router-dom';
import { useSelector } from 'react-redux';
import find from 'lodash/find'
import PayrollTaxSetupAddOnsList from './PayrollTaxSetupAddOnsList';
import FormPayrollTaxSetupAddOnWrapper from './FormPayrollTaxSetupAddOn';

const PayrollTaxSetupAddOns = () => {

  const currentSetupStepData = useSelector(state => state.projectPayrollSetupStore.notConfiguredSettings);

  return (
    <Fragment>
      {
        currentSetupStepData && find(currentSetupStepData, { path: 'payroll_tax_setup_add_ons' }) &&
        <Redirect from="/payroll_tax_setup_add_ons" to="/payroll_tax_setup_add_ons/new" />
      }
      <h1>TAX SETUP ADD ON SETTING</h1>
      <Switch>
        <Route path="/payroll_tax_setup_add_ons/new" component={FormPayrollTaxSetupAddOnWrapper}/>
        <Route path="/payroll_tax_setup_add_ons/:payrollTaxSetupAddOnId/edit" component={FormPayrollTaxSetupAddOnWrapper}/>
        <Route path="/payroll_tax_setup_add_ons" component={PayrollTaxSetupAddOnsList}/>
      </Switch>
    </Fragment>
  );
};

export default PayrollTaxSetupAddOns;
