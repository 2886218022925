import React from 'react';
import { change, reduxForm } from 'redux-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Button from 'react-bootstrap/Button';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import BusinessPartnersHeader from './BusinessPartnersHeader';
import GeneralPartnerForm from './GeneralTab/GeneralPartnerForm';
import GeneralPartnerPhonesContainer from './GeneralTab/GeneralPartnerPhones/GeneralPartnerPhonesContainer';
import GeneralPartnerEmailsContainer from './GeneralTab/GeneralPartnerEmails/GeneralPartnerEmailsContainer';
import ContactPersonsContainer from './ContactPersonsTab/ContactPersonsContainer';
import AccountingForm from './AccountingTab/AccountingForm';
import RemarkForm from './RemarksTab/RemarkForm';
import AttachmentsFilesContainer from './AttachmentsTab/AttachmentsFilesContainer';
import AddressesTabContainer from './AddressesTab/AddressesTabContainer';
import PaymentTermsForm from './PaymentTermsTab/PaymentTermsForm';

let BusinessPartnerForm = (props) => {

  const { handleSubmit } = props;

  return (
    <>
      <form onSubmit={handleSubmit}>
        <BusinessPartnersHeader/>
        <div className="mt-5 mb-5">
          <Tabs id="businessPartner-tabs">
            <Tab eventKey="general" title="General">
              <Row>
                <Col xs={6}>
                  <GeneralPartnerPhonesContainer/>
                </Col>
                <Col xs={6}>
                  <GeneralPartnerEmailsContainer/>
                </Col>
                <Col xs={12}>
                  <GeneralPartnerForm/>
                </Col>
              </Row>
            </Tab>
            <Tab eventKey="contact_persons" title="Contact Persons">
              <ContactPersonsContainer/>
            </Tab>
            <Tab eventKey="addresses" title="Addresses">
              <AddressesTabContainer/>
            </Tab>
            <Tab eventKey="payment_terms" title="Payment Terms">
              <PaymentTermsForm/>
            </Tab>
            <Tab eventKey="accounting" title="Accounting">
              <AccountingForm/>
            </Tab>
            <Tab eventKey="properties" title="Properties">
              Properties
            </Tab>
            <Tab eventKey="remarks" title="Remarks">
              <RemarkForm/>
            </Tab>
            <Tab eventKey="attachments" title="Attachments">
              <AttachmentsFilesContainer/>
            </Tab>

          </Tabs>
        </div>
        <div className="mt-4">
          <Button variant="success" type="submit" block>Save</Button>
        </div>

      </form>
    </>
  );
};
//
const mapStateToProps = (state, ownProps) => {
  const { form: { businessPartnerForm: businessPartner } } = state;
  return {
    businessPartner,
    initialValues: Object.assign({}, ownProps.businessPartner)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    changeFieldValue: (formName, fieldName, value) => {
      dispatch(change(formName, fieldName, value));
    }
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'businessPartnerForm',
    enableReinitialize: true
  })
)(BusinessPartnerForm);
