import React, {PureComponent} from 'react';
import {get, join, map, upperCase, isEmpty} from "lodash";
import Col from "react-bootstrap/Col";
import Label from "reactstrap/es/Label";
import ListGroup from "react-bootstrap/ListGroup";

class PartnerAddressCardDecorator extends PureComponent {


  render() {
    const {selectedBillingAddress, selectedShippingAddress} = this.props;

    return (
      <>
        {
          map([selectedBillingAddress, selectedShippingAddress], (address, key) => {
            const addressName = get(address, 'address_name', null);
            const addressLine1 = get(address, 'address_line_1', null);
            const addressLine2 = get(address, 'address_line_2', null);
            const fullAddress = join([
              get(address, 'city'),
              upperCase(get(address, 'state.code')),
              get(address, 'zip_code'),
            ], ', ')

            return (
              <Col md="3" key={`address-card-${key}`}>
                <Label> Description {key === 0 ? 'Billing' : 'Shipping'} Address </Label>
                <ListGroup>
                  {
                    !address &&
                    <>
                      <ListGroup>
                        <ListGroup.Item variant="dark" className="pt-1 pb-1"> Select address </ListGroup.Item>
                      </ListGroup>
                    </>
                  }

                  {
                    address &&
                    map([addressName, addressLine1, addressLine2, fullAddress], (line, lineKey) => {
                      if (!isEmpty(line)) {
                        return (<ListGroup.Item className="pt-1 pb-1" key={`address-line-${lineKey}`}
                                                variant="dark">{line}</ListGroup.Item>)
                      }
                    })
                  }
                </ListGroup>
              </Col>
            )
          })
        }
      </>
    );
  }

}


export default PartnerAddressCardDecorator;
