import Application from 'application-namespace';
import $ from 'jquery';

Application.Classes.AllowDayForm = class AllowDayForm {
  constructor($scope) {
    this.$scope = $scope;
    this.$yearSelection = this.$scope.find('[name="paid_absence_period[year]"]');
    this.$daysInput = this.$scope.find('[name="paid_absence_period[duration]"]');
    this.$durationTypeSelection = this.$scope.find('[name="paid_absence_period[duration_type]"]');
    this.$dayType = this.$scope.find('[name="paid_absence_period[period_type]"]');
    this.$isCountOvertime = this.$scope.find('[name="paid_absence_period[is_count_time_towards_overtime]"]');

    this.$yearSelection.on('change', (event) => {
      const currentValue = $(event.currentTarget).val();

      $('span.error').remove();
      if (this.$dayType.val() === 'sick') {
        $.ajax({
          url: Routes.sick_settings_path(),
          method: 'GET',
          dataType: 'JSON',
          data: { find_by_year: currentValue },
        }).then((response) => {
          this.$isCountOvertime.prop('checked', response.is_count_time_towards_overtime);
          this.$daysInput.val(response ? response.default_number_of_sick : 0);
          this.$durationTypeSelection.val(response ? response.calculate_sick_time_as : '').trigger('change');
        }).catch((error) => {
          console.warn('error', error);
          $(event.currentTarget).closest('.input').append('<span class="error text-warning">Sick settings not configured for this year.</span>');
        });
      } else {
        $.ajax({
          method: 'GET',
          url: Routes.vacation_settings_path(),
          dataType: 'JSON',
          data: { find_by_year: currentValue },
        }).then((response) => {
          this.$isCountOvertime.prop('checked', response.is_count_time_towards_overtime);
          this.$daysInput.val(response ? response.default_number_of_vacation : 0);
          this.$durationTypeSelection.val(response ? response.calculate_vacation_time_as : '').trigger('change');
        }).catch((error) => {
          console.warn('Error: ', error);
          $(event.currentTarget).closest('.input').append('<span class="error text-warning">Vacation settings not configured for this year.</span>');
        });
      }
    });
  }
};
