import ActionCable from 'actioncable';
import Application from 'application-namespace';
import _filter from 'lodash/filter';
import _each from 'lodash/each';
import _includes from 'lodash/includes';

Application.Services.WebSocket = class WebSocket {
  static cable;

  static subscribe = (channel, received) => {
    this.instance().subscriptions.create({ channel }, { received });
  };

  static unsubscribe  = (room) => {
    const subscriptions = this.instance().subscriptions.subscriptions;
    const unsubscribeSubscriptions = _filter(subscriptions, (subscription) => { return _includes(subscription.identifier, room) });
    if(unsubscribeSubscriptions.length) { _each(unsubscribeSubscriptions, (subscription) => this.instance().subscriptions.remove(subscription)) }
  };

  static publish = (room, data) => { };

  static instance = () => {
    if(this.cable === undefined) {
      this.cable = ActionCable.createConsumer();
    }
    return this.cable;
  }
};
