import React from 'react';
import { BrowserRouter as Router } from "react-router-dom";
import CommissionsReport from './CommissionsReport';
import {Provider} from "react-redux";
import store from '../../redux/store';

const CommissionsReportWrapper = ({}) => {

    return (
        <Router>
            <Provider store={store}>
                <CommissionsReport/>
            </Provider>
        </Router>
    )

};

export default CommissionsReportWrapper
