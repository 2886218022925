import axios from 'axios';
import { BUSINESS_PARTNER_SELECT_COLLECTION } from './actionTypes';

export const fetchBusinessPartnerData = () => {
  return (dispatch) => {
    return axios.all([
      fetchGroups(),
      fetchServicePartnerTypes(),
      fetchBusinessPartnerType(),
      fetchEmployees(),
      fetchPersonPositions(),
      fetchStates(),
      // fetchTaxStatus(),
      fetchSalesTaxCodes(),
    ])
      .then(axios.spread((...responses) => {
        dispatch({
          type: BUSINESS_PARTNER_SELECT_COLLECTION,
          payload: { responses }
        });
      }))
      .catch(errors => {
        console.log('businessPartnersStoreError', errors);
      });
  };
};


const fetchGroups = () => {
  return axios.get(Routes.api_web_v1_groups_path());
};

const fetchServicePartnerTypes = () => {
  return axios.get(Routes.api_web_v1_service_partner_types_path());
};

const fetchBusinessPartnerType = () => {
  return axios.get(Routes.business_partner_types_api_web_v1_business_partners_path());
};

const fetchEmployees = () => {
  return axios.get(Routes.sales_employee_api_web_v1_employees_path());
};

const fetchPersonPositions = () => {
  return axios.get(Routes.api_web_v1_person_positions_path());
};

const fetchStates = () => {
  return axios.get(Routes.api_web_v1_states_path());
};

const fetchTaxStatus = () => {
  return axios.get(Routes.api_web_v1_tax_statuses_path());
};

const fetchSalesTaxCodes = () => {
  return axios.get(Routes.api_web_v1_sales_tax_codes_path());
};
