import React from 'react';
import {Provider} from "react-redux";
import WorkDaysCalendar from "./WorkDaysCalendar";
import CalendarFilters from './CalendarFilters';
import store from '../../../../redux/store';

const WorkDaysCalendarContainer = ({}) => {

    const initialValues = { employee_id: null, day_types: [2, 3, 4, 5] };

    return (
        <>
            <Provider store={store}>
                <CalendarFilters initialValues={initialValues}/>
                <WorkDaysCalendar/>
            </Provider>
        </>
    )

};

export default WorkDaysCalendarContainer
