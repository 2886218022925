import PropTypes from 'prop-types';

export default function (className) {

  let baseSchema =
    {
      number: PropTypes.string.isRequired,
      posting_date: PropTypes.string,
      valid_until: PropTypes.string,
      document_date: PropTypes.string,
      remarks: PropTypes.string,
      business_partner_id: PropTypes.number.isRequired,
      contact_person_id: PropTypes.number,
      customer_ref_number: PropTypes.string,
      partner_billing_address_id: PropTypes.number,
      partner_shipping_address_id: PropTypes.number,
      shipping_type: PropTypes.number,
      bp_channel_name: PropTypes.string,
      sales_employee_id: PropTypes.number,
      owner_id: PropTypes.number,
      discount_price_cents: PropTypes.number,
      freight_price_cents: PropTypes.number,
      is_copy: PropTypes.bool,
      positions_key: 'sales_quotation_lines',
      positions:
        {
          id: PropTypes.number,
          warehouse_id: PropTypes.number,
          general_ledger_account_id: PropTypes.number,
          item_id: PropTypes.number,
          positions_type: PropTypes.number,
          sales_tax_code_id: PropTypes.number,
          quantity: PropTypes.number,
          item_price_cents: PropTypes.number,
          item_description: PropTypes.string,
          item_details: PropTypes.string,
          _destroy: PropTypes.bool
        },
      attachments_key: 'attachments_attributes',
      attachments: {
        id: PropTypes.number,
        attach: PropTypes.string,
        _destroy: PropTypes.bool
      }
    };

  switch (className) {
    case 'SalesQuotation':
      return baseSchema;
    case 'SalesOrder':
      baseSchema['customer_equipment_card_id'] = PropTypes.number.isRequired;
      baseSchema['positions_key'] = 'sales_order_lines';
      baseSchema['closed_on'] = PropTypes.string;
      baseSchema['completed_date'] = PropTypes.string;
      baseSchema['employee_ids'] = PropTypes.array;
      return baseSchema;
    default: {
    }
  }
}
