import Application from 'application-namespace';
import $ from 'jquery';


Application.Classes.SalaryForm = class SalaryForm extends Application.Classes.CoreBase {
  constructor($scope) {
    super();
    this.$scope = $scope;
    this.$salaryTypeSelection = this.$scope.find('[name="salary[salary_type]"]');
    this.$salaryBlock = this.$scope.find('.only-for-salary');
    this.$hourlyBlock = this.$scope.find('.only-for-hour');
    this.$requirePunchesInput = this.$scope.find('[name="salary[require_punches]"]');

    this.$salaryTypeSelection.on('change', event => this.showOrHideInputs(event));
    this.$salaryTypeSelection.trigger('change');
  }

  showOrHideInputs(event) {
    const currentValue = $(event.currentTarget).val();

    if (currentValue === 'salary') {
      this.$salaryBlock.removeClass('d-none');
      this.$salaryBlock.find('input').prop('disabled', false);
      this.$hourlyBlock.addClass('d-none');
      this.$hourlyBlock.find('input').prop('disabled', true);
      this.$requirePunchesInput.prop('checked', false).change();
    } else {
      this.$salaryBlock.addClass('d-none');
      this.$salaryBlock.find('input').prop('disabled', true);
      this.$hourlyBlock.removeClass('d-none');
      this.$hourlyBlock.find('input').prop('disabled', false);
      this.$requirePunchesInput.prop('checked', true).change();
    }
  }
};
