import React from 'react';
import Table from 'react-bootstrap/Table';
import GeneralPartnerPhone from './GeneralPartnerPhone';
import { FieldArray } from 'redux-form';

let GeneralPartnerPhonesContainer = () => {
  return (
    <div>
      <Table bordered responsive size="sm">
        <thead>
        <tr>
          <th>Phone</th>
          <th>Phone description</th>
          <th style={{ width: '78px' }}>Actions</th>
        </tr>
        </thead>
        <FieldArray
          name="partner_phones_attributes"
          component={GeneralPartnerPhone}
        />
      </Table>
    </div>
  );
};

export default GeneralPartnerPhonesContainer;

