import React from 'react';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import InputDatePicker from '../../../../components/ReduxForm/InputDatePicker';
import Form from 'react-bootstrap/Form';
import ReduxFormValidationAlert from '../../../../components/ReduxFormValidationAlert';
import RenderField from '../../../../components/ReduxForm/RenderField';
import Button from 'react-bootstrap/Button';
import MoneyInput from '../../../../components/ReduxForm/MoneyInput';

const FormGlobalRetirementSetting = ({ handleSubmit, stepButtons = [] }) => {

  return (
    <>
      <ReduxFormValidationAlert formName={'globalRetirementSettingForm'}/>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col md="6">
            <Field
              name="effective_date"
              component={InputDatePicker}
            />
          </Col>
          <Col md="6">
            <MoneyInput
              name="employee_max_cents"
              formName="globalRetirementSettingForm"
              label="Employee max"
            />
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <Field
              name="minimum_age_for_catch_up"
              component={RenderField}
            />
          </Col>
          <Col md="6">
            <MoneyInput
              name="additional_catch_up_amount_cents"
              formName="globalRetirementSettingForm"
              label="Additional catch up amount"
            />
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <MoneyInput
              name="overall_limit_cents"
              formName="globalRetirementSettingForm"
              label="Overall limit"
            />
          </Col>
          <Col md="6">
            <MoneyInput
              name="compensation_limit_cents"
              formName="globalRetirementSettingForm"
              label="Compensation limit"
            />
          </Col>
        </Row>
        <div className="d-flex justify-content-start align-items-baseline">
          {stepButtons}
          <Button
            variant="success"
            type="submit"
            block
          >
            Next
          </Button>
        </div>
      </Form>
    </>
  );

};

export default compose(
  reduxForm({
    form: 'globalRetirementSettingForm',
    enableReinitialize: true
  })
)(FormGlobalRetirementSetting);
