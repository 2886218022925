import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useHistory, Link } from 'react-router-dom'
import delay from 'lodash/delay';
import isEmpty from 'lodash/isEmpty';
import FormGlobalRetirementSetting from './form';
import ReduxFormSubmissionError from '../../../../components/ReduxFormSubmissionError';
import { useSnackbar } from 'notistack';

const FormGlobalRetirementSettingWrapper = () => {
  let params = useParams();
  const history = useHistory();
  const {enqueueSnackbar} = useSnackbar();
  const [loadPath, setLoadPath] = useState(null);
  const [globalRetirementSetting, setGlobalRetirementSetting] = useState({});

  useEffect(() => {
    if (params.globalRetirementSettingId) {
      setLoadPath(Routes.edit_api_web_v1_global_retirement_setting_path(params.globalRetirementSettingId));
    } else {
      setLoadPath(Routes.new_api_web_v1_global_retirement_setting_path());
    }
  }, [params]);

  useEffect(() => {
    if (loadPath) {
      loadGlobalRetirementSetting()
        .then(response => {
          setGlobalRetirementSetting(response.data.global_retirement_setting);
        })
        .catch(error => {
          console.log('FormGlobalRetirementSettingWrapper.loadGlobalRetirementSetting', error.response);
        });
    }
  }, [loadPath]);

  const loadGlobalRetirementSetting = () => {
    return axios({ url: loadPath });
  };

  const handleSubmit = (values) => {
    return axios({
      method: params.globalRetirementSettingId ? 'PUT' : 'POST',
      url: params.globalRetirementSettingId ?
        Routes.api_web_v1_global_retirement_setting_path(params.globalRetirementSettingId) :
        Routes.api_web_v1_global_retirement_settings_path(),
      data: { global_retirement_setting: values }
    })
      .then(response => {
        delay(() => {
          history.push('/global_retirement_settings')
        }, 1000);
        enqueueSnackbar('Global retirement setting was saved.', { variant: 'success' })
      })
      .catch(error => {
        ReduxFormSubmissionError(error.response.data.errors, values);
      });
  };

  return (
    <>
      {!isEmpty(globalRetirementSetting) &&
      <FormGlobalRetirementSetting
        initialValues={globalRetirementSetting}
        onSubmit={handleSubmit}
        stepButtons={
          <>
            <Link
              to={'/global_retirement_settings'}
              className="btn btn-outline-info btn-block mr-2"
            >
              Back
            </Link>
          </>
        }
      />
      }
    </>
  );
};

export default FormGlobalRetirementSettingWrapper;
