import Application from 'application-namespace';
import $ from 'jquery';
import _parseInt from 'lodash/parseInt';

Application.Classes.CustomerEquipmentCardForm = class CustomerEquipmentCardForm extends Application.Classes.CoreBase {
  constructor($scope) {
    super();
    this.$scope = $scope;
    this.$businessPartner = this.$scope.find('#customer_equipment_card_business_partner_id');
    this.$previousSn = this.$scope.find('#customer_equipment_card_previous_sn_id');
    this.$operatorManufacturer = this.$scope.find('#customer_equipment_card_operator_manufacturer_id');
    this.$operatorType = this.$scope.find('#customer_equipment_card_operator_type_id');
    this.$communicationManufacturer = this.$scope.find('#customer_equipment_card_communication_manufacturer_id');
    this.$communicationType = this.$scope.find('#customer_equipment_card_communication_type_id');
    this.$contactPerson = this.$scope.find('#customer_equipment_card_contact_person_id');
    this.$telephone = this.$scope.find('#customer_equipment_card_telephone');
    this.$btnSuccess = this.$scope.find('[type="submit"]');
    this.$statusSelection = this.$scope.find('[data-status-selection]');
    this.$newSnSelection = this.$scope.find('[data-new-sn-selection]');

    this.$businessPartner.on('change', () => {
      if (this.$businessPartner.val()) {
        this.fetchBusinessPartner(this.$businessPartner);
      } else {
        this.$contactPerson.val('').trigger('change');
        this.$telephone.val('');
      }
    });

    this.$previousSn.on('change', () => this.fetchCustomerEquipmentCard(this.$previousSn)).trigger('change');

    this.$operatorManufacturer.on('change', (event) => {
      const value = _parseInt($(event.currentTarget).find('option:selected').val());
      if (value) { this.fetchOperatorType(value); }
      this.$operatorType.empty();
    });

    this.$communicationManufacturer.on('change', (event) => {
      const value = _parseInt($(event.currentTarget).find('option:selected').val());
      if (value) { this.fetchCommunicationType(value); } else { this.$communicationType.empty(); }
    });

    this.$btnSuccess.on('click', event => this.checkStatus(event));
  }

  checkStatus(event) {
    if ((this.$statusSelection.val() !== 'active') && !this.$newSnSelection.val()) {
      event.preventDefault();
      BootstrapDialog.show({
        type: BootstrapDialog.TYPE_DANGER,
        title: 'Warning',
        message: 'You have not set a new serial number.Click Yes to continue or No to go back.',
        cssClass: 'main-modal confirm-modal-window setting-confirm',
        buttons: [{
          label: 'NO',
          cssClass: 'btn-data-confirm-cancel',
          action: dialogRef => dialogRef.close(),
        },
        {
          label: 'YES',
          cssClass: 'btn-data-confirm',
          action: (dialogRef) => {
            this.$scope.submit();
            dialogRef.close();
          },
        },
        ],
      });
    }
  }

  // TODO Route atribute
  fetchOperatorType(operatorManufacturerId) {
    $.ajax({
      url: Routes.operator_manufacturer_operator_types_path({ operator_manufacturer_id: operatorManufacturerId }),
      dataType: 'JSON',
    }).then((response) => {
      this.pasteToList(this.$operatorType, response, 'name');
    }).catch((error) => {
      console.error('Error: ', error);
    });
  }

  fetchCommunicationType(communicationManufacturerId) {
    $.ajax({
      url: Routes.communication_manufacturer_communication_types_path({ communication_manufacturer_id: communicationManufacturerId }),
      dataType: 'JSON',
    }).then((response) => {
      this.pasteToList(this.$communicationType, response, 'name');
    }).catch((error) => {
      console.error('Error: ', error);
    });
  }

  fetchBusinessPartner($businessPartner) {
    $.ajax({
      url: Routes.business_partner_path($businessPartner.val()),
      dataType: 'JSON',
    }).then((response) => {
      this.pasteBpOptions($businessPartner, response);
      this.fetchContactPeople($businessPartner.val());
      if (response.default_shipping_address_id) {
        this.fetchPartnerShippingAddress(response.default_shipping_address_id);
      } else {
        this.pastePsaOptions();
      }
    }).catch((error) => {
      console.error('Error: ', error);
    });
  }

  fetchContactPeople(businessPartner) {
    $.ajax({
      url: Routes.business_partner_contact_people_path({ business_partner: businessPartner }),
      dataType: 'JSON',
    }).then((response) => {
      this.pasteToList(this.$contactPerson, response, 'contact_id');
    }).catch((error) => {
      console.error('Error: ', error);
    });
  }

  fetchPartnerShippingAddress(partnerShippingAddress) {
    $.ajax({
      url: Routes.partner_shipping_address_path({ partner_shipping_address: partnerShippingAddress }),
      dataType: 'JSON',
    }).then((response) => {
      this.pastePsaOptions(partnerShippingAddress, response);
    }).catch((error) => {
      console.error('Error: ', error);
    });
  }

  fetchCustomerEquipmentCard($previousSn) {
    this.$previousSn = $previousSn;
    $.ajax({
      url: Routes.customer_equipment_card_path(this.$previousSn.val()),
      dataType: 'JSON',
    }).then((response) => {
      this.pastePsnOptions(this.$previousSn, response);
    }).catch((error) => {
      console.error('Error: ', error);
    });
  }

  pasteBpOptions(object, result) {
    this.pasteInInput('#customer_equipment_card_telephone', result.mobile_phone);
    this.pasteInSelect('#customer_equipment_card_contact_person_id', result.default_contact_person_id);
  }

  pastePsaOptions(object, result) {
    if (!$('#address-tab').length) { return; }
    this.pasteInInput('#customer_equipment_card_city', result ? result.city : ' ');
    this.pasteInInput('#customer_equipment_card_zip_code', result ? result.zip_code : ' ');
    this.pasteInInput('#customer_equipment_card_address_name', result ? result.address_name : ' ');
    this.pasteInInput('#customer_equipment_card_address_line_1', result ? result.address_line_1 : ' ');
    this.pasteInInput('#customer_equipment_card_address_line_2', result ? result.address_line_2 : ' ');
    this.pasteInSelect('#customer_equipment_card_state_id', result ? result.state_id : undefined);
  }

  pastePsnOptions(object, result) {
    this.pasteInInput('#customer_equipment_card_driveway_gate_name', result.driveway_gate_name);
    this.pasteInInput('#customer_equipment_card_territory', result.territory);
    this.pasteInInput('#customer_equipment_card_telephone', result.telephone);
    this.pasteInSelect('#customer_equipment_card_contact_person_id', result.contact_person_id);
  }
};
