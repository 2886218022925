import React, { Fragment } from 'react';
import { Switch, Route } from 'react-router-dom';
import HealthBenefitSettingsList from './HealthBenefitSettingsList';
import { Redirect} from 'react-router-dom';
import { useSelector } from 'react-redux';
import find from 'lodash/find'
import FormStepper from './FormStepper';

const HealthBenefitSettings = () => {

  const currentSetupStepData = useSelector(state => state.projectPayrollSetupStore.notConfiguredSettings);

  return (
    <Fragment>
      {
        currentSetupStepData && find(currentSetupStepData, { path: 'health_benefit_settings' }) &&
        <Redirect from="/health_benefit_settings" to="/health_benefit_settings/new" />
      }
      <h1>HEALTH BENEFIT SETTING</h1>
      <Switch>
        <Route path="/health_benefit_settings/new" component={FormStepper}/>
        <Route path="/health_benefit_settings/:healthBenefitSettingId/edit" component={FormStepper}/>
        <Route path="/health_benefit_settings/:healthBenefitSettingId/health_benefit_setting_lines" component={FormStepper}/>
        <Route path="/health_benefit_settings/:healthBenefitSettingId/health_benefit_setting_lines/new" component={FormStepper}/>
        <Route path="/health_benefit_settings/:healthBenefitSettingId/health_benefit_setting_lines/:healthBenefitSettingLineId/edit" component={FormStepper}/>
        <Route path="/health_benefit_settings" component={HealthBenefitSettingsList}/>
      </Switch>
    </Fragment>
  );
};

export default HealthBenefitSettings;
