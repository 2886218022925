import React from 'react';
import Table from 'react-bootstrap/Table';
import DocumentPosition from './DocumentPosition';
import { FieldArray } from 'redux-form';

const DocumentPositionsContainer = (props) => {
  return (
    <div>
      <Table bordered size="sm">
        <thead>
        <tr>
          <th>Type</th>
          <th>Item</th>
          <th>Item Description</th>
          <th>Item Details</th>
          <th>Quantity</th>
          <th>Item price</th>
          <th>Tax code</th>
          <th>Warehouse</th>
          <th>Total</th>
          <th>Actions</th>
        </tr>
        </thead>
        <FieldArray
          name="positions"
          component={DocumentPosition}
        />
      </Table>
    </div>
  );
};

export default DocumentPositionsContainer;

