import React, { Fragment } from 'react';
import { Switch, Route } from 'react-router-dom';
import { Redirect} from 'react-router-dom';
import { useSelector } from 'react-redux';
import find from 'lodash/find'
import VacationSettingsList from './VacationSettingsList';
import FormVacationSettingWrapper from './FormVacationSetting';

const VacationSettings = () => {

  const currentSetupStepData = useSelector(state => state.projectPayrollSetupStore.notConfiguredSettings);

  return (
    <Fragment>
      {
        currentSetupStepData && find(currentSetupStepData, { path: 'vacation_settings' }) &&
        <Redirect from="/vacation_settings" to="/vacation_settings/new" />
      }
      <h1>VACATION SETTING</h1>
      <Switch>
        <Route path="/vacation_settings/new" component={FormVacationSettingWrapper}/>
        <Route path="/vacation_settings/:vacationSettingId/edit" component={FormVacationSettingWrapper}/>
        <Route path="/vacation_settings" component={VacationSettingsList}/>
      </Switch>
    </Fragment>
  );
};

export default VacationSettings;
