import Application from 'application-namespace';
import $ from 'jquery';
import _get from 'lodash/get';
import _find from 'lodash/find';
import _remove from 'lodash/remove';
import _delay from 'lodash/delay';
import moment from 'moment';
import Turbolinks from 'turbolinks';
import toastr from 'toastr';
import * as Ladda from 'ladda';

Application.Core = class Core {
  start() {
    // Select2 Default Options
    $.fn.select2.defaults.set('placeholder', 'Please select option');
    $.fn.select2.defaults.set('theme', 'bootstrap4');
    $.fn.select2.defaults.set('width', '100%');
    $.fn.select2.defaults.set('minimumResultsForSearch', 0);
    $(document).on('turbolinks:load', () => this.afterRenderPage());
  }

  afterRenderPage() {
    Turbolinks.clearCache();
    this.initializePlugins();
    this.bindClasses();
    this.afterRenderPageAlertInit();
    this.initializeUserBackgroundJobsChanel();
  }

  afterRenderPageAlertInit() {
    $('.bootstrap-flash').each((i, el) => Application.Classes.Toastr.render($(el).text(), { type: $(el).data('type'), title: '' }));
  }

  bindClasses($defaultScope) {
    const $scope = $defaultScope == null ? $('body') : $defaultScope;
    $scope.find('[data-class-binder]').each((k, el) => $.each($(el).data('class-binder').split(' '), (index, className) => new (Application.Classes[className])($(el))));
  }

  initializePlugins($defaultScope) {
    const $scope = $defaultScope == null ? $('body') : $defaultScope;
    this.initializeTouchSpin($scope);
    this.initializePopover($scope);
    this.initializeZipCoder($scope);
    this.initializeSelectPicker($scope);
    this.initializeAjaxSelectPicker($scope);
    this.initializeTimePicker($scope);
    this.initializeDateTimePicker($scope);
    this.initializeDatePicker($scope);
    this.initializePhonePicker($scope);
    this.initializeLaddaSpiner($scope);
    this.initializeTreeTable($scope);
    this.initializeReportTree($scope);
    this.initializeMathInput($scope);
    this.initLazyModals($scope);
    this.initializeFocusedOnField($scope);
    this.initializeTooltip($scope);
  }

  initializeTouchSpin($defaultScope) {
    const $scope = $defaultScope == null ? $('body') : $defaultScope;
    $scope.find('.number-picker').TouchSpin({
      forcestepdivisibility: 'none',
    });
  }

  initializePopover($defaultScope) {
    const $scope = $defaultScope == null ? $('body') : $defaultScope;
    $scope.find('[data-toggle="popover"]').popover({
      html: true,
      container: 'body',
    });
  }

  initializeZipCoder($defaultScope) {
    const $scope = $defaultScope == null ? $('body') : $defaultScope;
    $scope.find('.zipcode-geocoder').AddressAutocomplete();
  }

  initializeSelectPicker($defaultScope) {
    const $scope = $defaultScope == null ? $('body') : $defaultScope;
    $scope.find('.select-picker').select2();
    this.changeSelect2Behavior($scope.find('.select-picker'));
  }

  initializeAjaxSelectPicker($defaultScope) {
    const $scope = $defaultScope == null ? $('body') : $defaultScope;
    const $el = $scope.find('.ajax-select-picker');
    $el.select2({
      templateSelection(data) {
        const $element = $scope.find('.ajax_select_picker').find(`option:contains('${data.text}')`);
        if (!$element.attr('data-rate') && (data.rate !== undefined)) {
          $element.attr('data-rate', data.rate);
        }
        return data.text;
      },
      ajax: {
        //        url: JSON.parse($(this).dataset.ajax).url
        dataType: 'json',
        delay: 250,
        data(params) {
          const options = $(this).data('ajax');
          const documentDate = () => {
            if (options.document_date_input) {
              const $documentDateInput = $(options.document_date_input);
              if ($documentDateInput.length) {
                $(options.document_date_input).val();
              }
              console.warn('$documentDateInput', $documentDateInput.length);
            }
          };
          return {
            q: params.term,
            term: params.term,
            _type: params._type,
            field_for_search: options.field_for_search,
            document_name: options.document_name,
            search_method: options.search_method,
            text_method: options.text_method,
            document_date: documentDate(),
          };
        },
        processResults: (data) => {
          data.results.forEach((v) => {
            if (v.rate !== undefined) {
              $scope.find('.ajax_select_picker').find(`option:contains('${v.text}')`).attr('data-rate', v.rate);
            }
          });
          return { results: data.results };
        },
      },
    });
    this.changeSelect2Behavior($scope.find('.ajax-select-picker'));
  }

  changeSelect2Behavior(element) {
    $(element).on('select2:close', (evt) => {
      const context = $(evt.target);
      $(document).on('keydown.select2', (e) => {
        if (e.which === 9) {
          const highlighted = context.data('select2').$dropdown.find('.select2-results__option--highlighted');
          if (highlighted) {
            const id = _get(highlighted.data('data'), 'id');
            context.val(id).trigger('change');
          }
          $(context).closest('.input').next('.input').find('input')
            .focus();
          $(context).closest('.input').next('.input').find('select')
            .select2('open');
        }
      });
      setTimeout((() => {
        $(document).off('keydown.select2');
      }), 1);
    });
  }

  dateTimePickerIcons() {
    return {
      time: 'fa fa-clock',
      date: 'fa fa-calendar',
      up: 'fa fa-arrow-up',
      down: 'fa fa-arrow-down',
      previous: 'fa fa-angle-double-left',
      next: 'fa fa-angle-double-right',
      today: 'fa fa-arrow-down',
      clear: 'fa fa-arrow-down',
      close: 'fa fa-arrow-down',
    };
  }

  initializeTimePicker($defaultScope) {
    const $scope = $defaultScope == null ? $('body') : $defaultScope;
    $scope.find('.time-picker').pickatime();
  }

  initializeDateTimePicker($defaultScope) {
    const $scope = $defaultScope == null ? $('body') : $defaultScope;
    $scope.find('.datetimepicker').datetimepicker(
      { icons: this.dateTimePickerIcons() },
    );
  }

  initializeDatePicker($defaultScope) {
    const $scope = $defaultScope == null ? $('body') : $defaultScope;
    $scope.find('.date-picker').datetimepicker(
      { icons: this.dateTimePickerIcons(), format: 'MM/DD/YYYY', useCurrent: false },
    );
  }

  initializeRangeDatePicker($defaultScope) {
    const $scope = $defaultScope == null ? $('body') : $defaultScope;
    const payrollRuns = { weekly: 6, biweekly: 14, monthly: 31 };
    const $input = $scope.find('.range-date-picker');
    const res = {};
    if ($input.data('lastPeriods')) {
      $input.data('lastPeriods').forEach((element) => {
        const dates = [];
        const key = [];
        Object.values(element.dates).forEach((date, index) => {
          dates.push(index > 0 ? moment.utc(date) : moment.utc(date));
          key.push(index > 0 ? moment.utc(date).format('l') : moment.utc(date).format('l'));
        });
        res[`<span class='pull-right label ${element.label_class}'>${element.status}</span>${key.join('-')}`] = dates;
      });
    }
    $input.daterangepicker({
      locale: { format: 'MM/DD/YYYY', separator: '-' },
      maxSpan: { days: payrollRuns[$input.data('payrollRun')] || 61 },
      ranges: res,
    });
  }

  initializePhonePicker($defaultScope) {
    const $scope = $defaultScope == null ? $('body') : $defaultScope;
    $scope.find('input[type=tel]').mask('(000) 000-0000');
  }

  initializeLaddaSpiner() {
    Ladda.bind('button[type=submit]', { timeout: 2000 });
  }

  initializeTreeTable($defaultScope) {
    const $scope = $defaultScope == null ? $('body') : $defaultScope;
    $scope.find('#tree_table').treetable({ expandable: true });
  }

  initializeReportTree($defaultScope) {
    const $scope = $defaultScope == null ? $('body') : $defaultScope;
    $scope.find('#report_tree').ReportsTree();
  }

  initializeMathInput($defaultScope) {
    const $scope = $defaultScope == null ? $('body') : $defaultScope;
    $scope.find('.math-picker').mathInput();
  }

  initLazyModals($defaultScope) {
    const $parent = $defaultScope == null ? $('body') : $defaultScope;
    $parent.find('[data-lazy-modal]').each((k, el) => {
      const $el = $(el);
      $el.off('click').on('click', (event) => {
        event.preventDefault();
        BootstrapDialog.show({
          size: BootstrapDialog.SIZE_EXTRAWIDE,
          type: BootstrapDialog.TYPE_INFO,
          title: $el.data('lazy-modal-title'),
          cssClass: $el.data('lazy-modal-css-class'),
          draggable: true,
          onshown: (dialog) => {
            dialog.$modal.removeAttr('tabindex');
            const $dialog = dialog.getModalBody();
            $dialog.load($(event.currentTarget).attr('href'), () => {
              this.initializePlugins($dialog);
              this.bindClasses($dialog);
            });
          },
        });
        return false;
      });
    });
  }

  initializeFocusedOnField($defaultScope) {
    const $scope = $defaultScope == null ? $('body') : $defaultScope;
    const $firstInput = $($scope.find('form').find('.input')[0]);
    if ($firstInput.hasClass('select_picker')) {
      if (!$firstInput.find('select').val()) {
        $firstInput.find('select').select2('open');
      }
    } else if (!$firstInput.find('input').val()) {
      $firstInput.find('input').focus().select();
    }
  }

  initializeTooltip($defaultScope) {
    const $parent = $defaultScope == null ? $('body') : $defaultScope;
    $parent.find('[data-toggle="tooltip"]').tooltip();
  }

  initializeUserBackgroundJobsChanel() {
    this.notificationQueues = [];
    Application.Services.WebSocket.subscribe('BackgroundJobsChannel', (data) => {
      this.toastrObject = _find(this.notificationQueues, { jid: data.jid });

      if (this.toastrObject === undefined) {
        this.toastrObject = data;
        this.toastrObject.toastr = Application.Classes.Toastr.backgroundJob(data);
        this.notificationQueues.push(this.toastrObject);
        // eslint-disable-next-line global-require
        $(this.toastrObject.toastr).html(require('./../../../templates/toastr_background_job.ejs')(data));
      } else {
        // eslint-disable-next-line global-require
        $(this.toastrObject.toastr).html(require('./../../../templates/toastr_background_job.ejs')(data));
        if (data.event === 'job_is_finished') {
          _delay(() => {
            toastr.clear(this.toastrObject.toastr);
            _remove(this.notificationQueues, { jid: this.toastrObject.jid });
          }, 2000);
        }
      }
    });
  }
};
