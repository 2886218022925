import React, { Component } from 'react';
import { connect } from 'react-redux';
import { change } from 'redux-form';
import Select from 'react-select';
import { find, map, parseInt } from 'lodash';

class MultiSelectWrapper extends Component {

  onChangeField = (value) => {
    const { changeFieldValue, formName, input } = this.props;
      const multiSelect = map(value, (option, _key) => (option.value));
      changeFieldValue(formName, input.name, multiSelect);
  };

  render() {
    const { label, collection, input, colourStyles } = this.props;
    return (
      <>
        {label && <label>{label}</label>}
        <div style={{ minWidth: '100px' }}>
          <Select
            isMulti={true}
            options={collection}
            onChange={this.onChangeField}
            value={map(input.value, (value, _key) => (find(collection, { value: parseInt(value) })))}
            styles={colourStyles}
          />
        </div>
      </>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  changeFieldValue: (formName, fieldName, value) => {
    dispatch(change(formName, fieldName, value));
  },
});

export default connect(null, mapDispatchToProps)(MultiSelectWrapper);
