import Application from 'application-namespace';
import $ from 'jquery';
import moment from 'moment';


Application.Classes.BusinessTimeForm = class BusinessTimeForm extends Application.Classes.CoreBase {
  constructor($scope) {
    super();
    this.$scope = $scope;
    this.$days = this.$scope.find('.days-selection');
    this.diabled_days = {
      mon: { '': '' }, tue: { '': '' }, wed: { '': '' }, thu: { '': '' }, fri: { '': '' }, sat: { '': '' }, sun: { '': '' },
    };
    this.work_days = this.$scope.find('[data-work-days]').data('workDays').configuration.raw.hours;
    this.$timeInputs = this.$scope.find('.time-input');
    this.$workDayInput = this.$scope.find('[data-work-days-input]');

    this.$timeInputs.on('change', event => this.changeTime(event));
    this.$days.on('change', event => this.toggleLine(event));
  }

  changeTime(event) {
    let endTime;
    let startTime;
    const day = $(event.currentTarget).data('day');
    if (this.$scope.find(`[data='${day}-start']`).val()) { startTime = moment(`${this.$scope.find(`[data='${day}-start']`).val()}`, ['h:mm A']).format('HH:mm'); }
    if (this.$scope.find(`[data='${day}-end']`).val()) { endTime = moment(`${this.$scope.find(`[data='${day}-end']`).val()}`, ['h:mm A']).format('HH:mm'); }
    this.work_days[day] = { [startTime]: endTime };
    this.$workDayInput.val(JSON.stringify(this.work_days));
  }

  toggleLine(event) {
    const day = $(event.currentTarget).data('day');
    if ($(event.currentTarget).is(':checked')) {
      this.work_days[day] = this.diabled_days[day];
      this.$scope.find(`[data='${day}-start']`).prop('disabled', false);
      this.$scope.find(`[data='${day}-end']`).prop('disabled', false);
    } else {
      this.diabled_days[day] = this.work_days[day];
      this.work_days[day] = {};
      this.$scope.find(`[data='${day}-start']`).prop('disabled', true);
      this.$scope.find(`[data='${day}-end']`).prop('disabled', true);
    }

    this.$workDayInput.val(JSON.stringify(this.work_days));
  }
};
