import Application from 'application-namespace';
import $ from 'jquery';


Application.Classes.ItemForm = class ItemForm extends Application.Classes.CoreBase {
  constructor($scope) {
    super();
    this.$scope = $scope;
    this.$itemType = this.$scope.find('#item_item_type_id');
    this.$itemType.on('change', event => this.disabledCheckBox(event));
    this.$itemType.trigger('change');
  }

  disabledCheckBox(event) {
    if ($(event.currentTarget).select2('data')[0].id === '1') {
      this.$scope.find('.item_inventory_item').find('input').attr('disabled', false);
      this.$scope.find('.item_purchase_item').find('input').attr('disabled', false);
    } else {
      this.$scope.find('.item_inventory_item').find('input').attr('disabled', 'disabled').prop('checked', false);
      this.$scope.find('.item_purchase_item').find('input').attr('disabled', 'disabled').prop('checked', false);
    }
  }
};
