import Application from 'application-namespace';
import $ from 'jquery';

Application.Classes.TabsRemoteContent = class TabsRemoteContent {
  constructor($scope) {
    if ($scope.find('a.active').length === 0) {
      $scope.find('a.nav-link').first().trigger('click').tab('show');
    } else {
      $scope.find('a.active').trigger('click').tab('show');
    }

    $scope.find('li')
      .on('click', (event) => {
        $scope.find('a.active').removeClass('active');
        $(event.currentTarget).find('a').tab('show');
      });
  }
};
