import React, { useMemo } from 'react';
import MaterialDataTable from '../../../../components/MaterialDataTable';
import moment from 'moment';
import axios from 'axios';
import { useHistory } from "react-router-dom";
import { useSnackbar } from 'notistack';

const SickSettingsList = () => {

  const {enqueueSnackbar} = useSnackbar();
  const history = useHistory();
  const tableColumns = useMemo(() => ([
    {
      title: 'Effective date',
      field: 'effective_date',
      searchable: true,
      sorting: true,
      type: 'date',
      render: (rowData) => (moment(rowData.effective_date).format('L'))
    }
  ]), []);

  const onRemove = (sickSetting) => {
    return axios({
      method: 'DELETE',
      url: Routes.api_web_v1_sick_setting_path(sickSetting.id),
    })
      .then((response) => {
        enqueueSnackbar('Sick setting was removed.', { variant: 'success' });
        return response;
      });
  };

  const onAdd = () => history.push(`/sick_settings/new`);

  const onEdit = (sickSetting) => (
    history.push(`/sick_settings/${sickSetting.id}/edit`)
  );

  return (
    <div className="pt-4">
      <MaterialDataTable
        title="Sick settings"
        tableColumns={tableColumns}
        path={Routes.api_web_v1_sick_settings_path()}
        onRemove={onRemove}
        onAdd={onAdd}
        onEdit={onEdit}
      />
    </div>
  );
};

export default SickSettingsList;
