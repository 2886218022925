import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import moment from 'moment';

const GlobalRetirementBenefitSettings = () => {

  const [globalRule, setGlobalRule] = useState({});

  useEffect(() => {
    loadGlobalSettings()
      .then(response => {
        setGlobalRule(response.data.global_retirement_setting);
      })
      .catch(error => {
        console.log('GlobalRetirementBenefitSettings.loadGlobalSettings', error.response);
      });
  }, []);

  const loadGlobalSettings = () => {
    return axios({ url: Routes.actual_api_web_v1_global_retirement_settings_path() });
  };

  return (
    <>
      {globalRule &&
      <TableContainer component={Paper}>
        <Table size="small" aria-label="a dense table">
          <TableBody>
            <TableRow>
              <TableCell> Effective date: {moment(globalRule.effective_date)
                .format('L')} </TableCell>
              <TableCell> Employee max: ${globalRule.employee_max_cents / 100} </TableCell>
            </TableRow>
            <TableRow>
              <TableCell> Minimum age for catch up: {globalRule.minimum_age_for_catch_up} </TableCell>
              <TableCell> Additional catch up: ${globalRule.additional_catch_up_amount_cents / 100} </TableCell>
            </TableRow>
            <TableRow>
              <TableCell> Overall limit: ${globalRule.overall_limit_cents / 100} </TableCell>
              <TableCell> Compensation limit: ${globalRule.compensation_limit_cents / 100} </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      }
    </>
  );

};

export default GlobalRetirementBenefitSettings;
