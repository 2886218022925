import React from 'react';
import ContactPeopleBlock from './ContactPeopleBlock';
import Tab from 'react-bootstrap/Tab';
import { FieldArray } from 'redux-form';

const ContactPeopleContainerAttributes = () => {
  return (
    <Tab.Container id="partner_contact_persons" defaultActiveKey="first">
      <FieldArray
        name="partner_contact_persons"
        component={ContactPeopleBlock}
      />
    </Tab.Container>
  );
};


export default ContactPeopleContainerAttributes;