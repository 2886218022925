import Application from 'application-namespace';

Application.Classes.DepositByBusinessPartner = class DepositByBusinessPartner {
  constructor($scope) {
    this.$scope = $scope;
    this.$submitBtn = this.$scope.find("[type='submit']");
    this.$businessPartner = this.$scope.find('[data-document-business-partner]');

    this.$submitBtn.on('mousedown', event => this.appliedAmountPopUp(event));
  }

  appliedAmountPopUp(event) {
    event.preventDefault();
    const bpName = this.$businessPartner.select2('data')[0].text;
    const { balance } = this.$businessPartner.select2('data')[0].element.dataset;

    if (balance > 0) {
      BootstrapDialog.show({
        type: BootstrapDialog.TYPE_WARNING,
        title: 'WARNING',
        message: `<h5>${bpName} have deposit $${balance}. Do you want to apply the deposit after creating invoice?</h5>`,
        cssClass: 'main-modal confirm-modal-window setting-confirm',
        buttons: [{
          label: 'YES',
          cssClass: 'btn-data-confirm-cancel',
          action: (dialogRef) => {
            window.localStorage.applyBusinessPartnerDeposit = true;
            dialogRef.close();
            this.$submitBtn.click();
          },
        },
        {
          label: 'NO',
          cssClass: 'btn-data-confirm-cancel',
          action: (dialogRef) => {
            window.localStorage.applyBusinessPartnerDeposit = false;
            dialogRef.close();
            this.$submitBtn.click();
          },
        },
        ],
      });
    }
  }
};
