import Application from 'application-namespace';

import ClipboardJS from 'clipboard';

Application.Classes.ClipboardInput = class ClipboardInput {
  constructor($scope) {
    const $copyButton = $scope.find('.btn');
    const clipboard = new ClipboardJS('.btn', { container: $scope });
    clipboard.on('success', () => {
      $copyButton.tooltip('show');
      setTimeout(() => {
        $copyButton.tooltip('hide');
      }, 3000);
    });
  }
};
