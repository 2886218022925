import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';
import DocumentAttachmentFields from './DocumentAttachmentFields';

class DocumentAttachment extends Component {

  render() {
    const { fields } = this.props;

    return (
      <>
        <tbody>
        {fields.map((attachment, index) =>
          <DocumentAttachmentFields
            key={`attachment-${index}`}
            attachment={attachment}
            index={index}
            fields={fields}
          />
        )}
        <tr>
          <td colSpan="10" className="text-right">
            <Button variant="success" size="sm" onClick={() => fields.push({attach: undefined})}>
              Add attachment
            </Button>
          </td>
        </tr>
        </tbody>
      </>
    );

  }

}

export default DocumentAttachment;
