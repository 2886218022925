/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
($ => $.fn.AddressAutocomplete = function (options) {
  let autocomplete;
  this.conponentField = this;
  this.conponentField.css('border', '1px #58b853 solid');
  this.settings = {
    address_el: this.data('addressEl') || '[data-address-field]',
    zip_el: this.data('zipEl') || '[data-zip-field]',
    state_el: this.data('stateEl') || '[data-state-field]',
    city_el: this.data('cityEl') || '[data-city-field]',
    country_el: this.data('countryEl') || '[data-country-field]',
    wrapper_el: this.data('wrapperEl') || '.row',
  };

  const initAutocomplete = (el) => {
    this.current_object = $(el);
    autocomplete = new (google.maps.places.Autocomplete)(el, { types: ['geocode'] });
    return autocomplete.addListener('place_changed', fillInAddress);
  };

  var fillInAddress = (el) => {
    const results = autocomplete.getPlace();
    if (results) {
      const addr = {};
      let ii = 0;
      while (ii < results.address_components.length) {
        var city; var country; var formatted_address; var route; var state; var street; var
          zipcode;
        const street_number = (route = (street = (city = (state = (zipcode = (country = (formatted_address = '')))))));
        const types = results.address_components[ii].types.join(',');
        if (types === 'street_number') {
          addr.street_number = results.address_components[ii].long_name;
        }
        if ((types === 'route') || (types === 'point_of_interest,establishment')) {
          addr.route = results.address_components[ii].long_name;
        }
        if ((types === 'sublocality,political') || (types === 'locality,political') || (types === 'neighborhood,political')) {
          addr.city = (city === '') || (types === 'locality,political') ? results.address_components[ii].long_name : city;
        }
        if (types === 'administrative_area_level_1,political') {
          addr.state = results.address_components[ii].short_name;
        }
        if ((types === 'postal_code') || (types === 'postal_code_prefix,postal_code')) {
          addr.zipcode = results.address_components[ii].long_name;
        }
        if (types === 'country,political') {
          addr.country = results.address_components[ii].long_name;
        }
        ii++;
      }
      return _pasteResults(addr);
    }
  };

  var _pasteResults = (addr) => {
    $(this.current_object).closest(this.settings.wrapper_el).find(`${this.settings.zip_el}`).val('')
      .val(addr != null ? addr.zipcode : undefined)
      .trigger('change');
    $(this.current_object).closest(this.settings.wrapper_el).find(`${this.settings.city_el}`).val('')
      .val(addr != null ? addr.city : undefined)
      .trigger('change');
    $(this.current_object).closest(this.settings.wrapper_el).find(`${this.settings.state_el}`).val('')
      .val(addr != null ? addr.state : undefined)
      .trigger('change');
    $(this.current_object).closest(this.settings.wrapper_el).find(`${this.settings.country_el}`).val('')
      .val(addr != null ? addr.country : undefined)
      .trigger('change');
    const state_id = $(this).closest(this.settings.wrapper_el).find(`[iso_2=\"${(addr != null ? addr.state : undefined)}\"]`).val();
    $(this.current_object).closest(this.settings.wrapper_el).find(`${this.settings.state_el}`).val(state_id)
      .trigger('change');
    return $(this.current_object).closest(this.settings.wrapper_el).find(`${this.settings.address_el}`).val([(addr != null ? addr.street_number : undefined), (addr != null ? addr.route : undefined)].join(' '));
  };

  return this.conponentField.on('focus', event => initAutocomplete(event.currentTarget));
}

)(jQuery);
