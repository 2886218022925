import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import commonDocumentReducer from './commonDocumentReducer/reducer';
import documentStoreReducer from './documentStoreReducer/reducer';
import employeeWorkDaysCalendarReducer from './employeeWorkDaysCalendarReducer/reducer';
import missedDaysReportReducer from './missedDaysReportReducer/reducer';
import businessPartnerStoreReducer from './businessPartnerStoreReducer/reducer';
import commissionReportsStoreReducer from './commissionReportsStoreReducer/reducer';
import projectPayrollSetupReducer from './projectPayrollSetupReducer/reducer';

const rootReducer = combineReducers({
  form: formReducer.plugin({ commonDocumentForm: commonDocumentReducer }),
  documentsStore: documentStoreReducer,
  employeeWorkDaysCalendarStore: employeeWorkDaysCalendarReducer,
  missedDaysReportStore: missedDaysReportReducer,
  businessPartnerStore: businessPartnerStoreReducer,
  commissionReportsStore: commissionReportsStoreReducer,
  projectPayrollSetupStore: projectPayrollSetupReducer,
});

export default rootReducer;
