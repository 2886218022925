import Application from 'application-namespace';
import $ from 'jquery';

Application.Classes.UserForm = class UserForm extends Application.Classes.CoreBase {
  constructor($scope) {
    super();
    this.$scope = $scope;
    this.$employeeSelect = this.$scope.find('#user_employee_id');
    this.$activeToggle = this.$scope.find('#user_active_user');
    this.$userRoleToggle = this.$scope.find('#user_role');
    this.keepUserRole = this.$userRoleToggle.val();

    this.$userRoleToggle.on('change', (event) => {
      if ((this.keepUserRole === 'super_admin') && ($(event.currentTarget).val() !== this.keepUserRole)) {
        BootstrapDialog.show({
          type: BootstrapDialog.TYPE_WARNING,
          title: 'User role',
          message: 'Are you sure? You will need another super administrator to restore your super admin rights.',
          cssClass: 'main-modal confirm-modal-window setting-confirm',
          buttons: [{
            label: 'NO',
            cssClass: 'btn-data-confirm-cancel',
            action: (dialogRef) => { this.$userRoleToggle.val(this.keepUserRole).trigger('change'); return dialogRef.close(); },
          },
          {
            label: 'YES',
            cssClass: 'btn-data-confirm',
            action: (dialogRef) => { this.keepUserRole = $(event.currentTarget).val(); return dialogRef.close(); },
          },
          ],
        });
      }
    });

    this.$employeeSelect.on('change', () => {
      this.fetchEmployee();
    });
    this.$employeeSelect.trigger('change');

    this.$activeToggle.closest('[data-toggle="toggle"]').on('click', (event) => {
      if ($(event.currentTarget).find('#user_active_user').is(':checked')) {
        BootstrapDialog.show({
          type: BootstrapDialog.TYPE_WARNING,
          title: 'Active user flag',
          message: 'Are you sure you want to set this admin inactive? They will no longer be able to log in.',
          cssClass: 'main-modal confirm-modal-window setting-confirm',
          buttons: [{
            label: 'NO',
            cssClass: 'btn-data-confirm-cancel',
            action: (dialogRef) => {
              this.$activeToggle.bootstrapToggle('on');
              dialogRef.close();
            },
          },
          {
            label: 'YES',
            cssClass: 'btn-data-confirm',
            action: (dialogRef) => {
              this.keepUserRole = $(event.currentTarget).val();
              dialogRef.close();
            },
          },
          ],
        });
      }
    });
  }

  fetchEmployee() {
    const id = this.$employeeSelect.val();
    if (id) {
      $.ajax({
        url: Routes.employee_path({ id }),
        dataType: 'JSON',
      }).then(response => this.pasteOptions(response)).catch((error) => {
        console.error('Error: ', error);
      });
    } else {
      console.warn(`Application.Classes.UserForm#fetchEmployee  !this.$employeeSelect.val - ${id}`);
    }
  }

  pasteOptions(object) {
    this.pasteInInput('#user_first_name', object.first_name);
    this.pasteInInput('#user_last_name', object.last_name);
    this.pasteInInput('#user_email', object.work_email);
    this.pasteInInput('#user_mobile_phone', object.work_cell);
  }
};
