import Application from 'application-namespace';
import $ from 'jquery';
import _ from 'lodash';

Application.Classes.BusinessPartnerForm = class BusinessPartnerForm extends Application.Classes.CoreBase {
  constructor($scope) {
    super();
    this.$scope = $scope;
    this.$businessPartnerCode = this.$scope.find('#business_partner_code');
    this.$businessPartnerType = this.$scope.find('#business_partner_service_partner_type_id');

    this.$businessPartnerType.on('change', (event) => {
      const typeId = $(event.currentTarget).select2('data')[0].id;
      $.ajax({
        url: Routes.get_actuality_code_business_partners_path(),
        dataType: 'JSON',
        data: { service_partner_type_id: typeId },
      }).then((response) => {
        if (!this.$businessPartnerType.prop('disabled')) {
          this.$businessPartnerCode.val(response);
        }
      }).catch((error) => {
        console.error('Error: ', error);
      });
    });

    this.$businessPartnerType.trigger('change');

    if (window.localStorage.businessPartnerActiveTab) { this.$scope.find(`a[href='${window.localStorage.businessPartnerActiveTab}']`).trigger('click'); }
    window.localStorage.businessPartnerActiveTab = null;

    this.$scope.find('#business_partner_tax_status_id').on('change', (event) => {
      const value = _.parseInt($(event.currentTarget).find('option:selected').val());
      const $businessPartnerExemptNumber = $('.business_partner_exempt_number');
      switch (value) {
        case 1:
          return $businessPartnerExemptNumber.removeClass('d-none');
        case 2:
          return $businessPartnerExemptNumber.addClass('d-none');
        default:
          return console.log('Application.Classes.BusinessPartnerForm#constructor #business_partner_tax_status_id value -', value);
      }
    });

    this.$scope.find('#business_partner_tax_status_id').trigger('change');

    this.$scope.find('.select-all-properties').click(() => $('#properties-tab :checkbox').each(function () {
      this.checked = true;
    }));

    this.$scope.find('.clear-all-properties').click(() => $('#properties-tab :checkbox').removeAttr('checked'));

    this.$scope.find('.tabs_header').click((event) => {
      $('.tab-pane').addClass('d-none');
      return $($(event.currentTarget).find('a').attr('href')).removeClass('d-none');
    });

    this.$scope.find('.btn-success').on('click', () => {
      window.localStorage.businessPartnerActiveTab = this.$scope.find('.nav-tabs').find('.active').find('a')[0].getAttribute('href');
    });

    // NESTED FORMS
    this.$cpTabsContainer = this.$scope.find('.collection-contact-person');
    this.$bAdderessTabsContainer = this.$scope.find('.collection-billing-address');
    this.$sAdderessTabsContainer = this.$scope.find('.collection-shipping-address');

    this.$scope.find('#generales-tab').on('cocoon:after-insert', (e, insertedItem) => {
      this.initializePluginsAfterInsert(insertedItem);
      this.buildNestedLine();
    });

    this.$scope.find('#addresses-tab').find('.sidebar-list').on('click', (element) => {
      this.findAndShowForm(element, 'addresses-tab');
    });

    this.$scope.find('#contact_persons-tab').find('.sidebar-list').on('click', (element) => {
      this.findAndShowForm(element, 'contact_persons-tab');
    });

    this.$scope.find('#addresses-tab').on('cocoon:before-insert', () => this.$scope.find('.edit-address').addClass('d-none'));
    this.$scope.find('#addresses-tab').on('cocoon:after-remove', (e, insertedItem) => this.$scope.find(`#${insertedItem.data('lineId')}`).remove());
    this.$scope.find('#addresses-tab').on('cocoon:after-insert', (e, insertedItem) => {
      let $activeCollection;
      let formId;
      $(insertedItem).removeClass('PartnerShippingAddress-');
      const fullTime = Date.now();
      if (insertedItem.hasClass('shipping-address-fields')) {
        $(insertedItem).attr('data-line-id', `PartnerShippingAddress-${fullTime}`).removeClass('PartnerShippingAddress-');
        $activeCollection = this.$sAdderessTabsContainer;
        formId = `PartnerShippingAddress-${fullTime}`;
      } else {
        $(insertedItem).attr('data-line-id', `PartnerBillingAddress-${fullTime}`).removeClass('PartnerBillingAddress-');
        $activeCollection = this.$bAdderessTabsContainer;
        formId = `PartnerBillingAddress-${fullTime}`;
      }

      insertedItem.addClass(formId);
      const $addressName = this.$scope.find(`.${formId}`).find('[data-address-name]');
      $addressName.on('change', () => {
        this.renameContactTab($activeCollection.find('.active'), $addressName.val());
      });
      $activeCollection.prepend(`<a href="javascript:void(0)" class='nav-link sidebar-list' id='${formId}'>${$addressName.val() || 'Define new'}</a>`);
      this.$scope.find('.sidebar-list').on('click', (element) => {
        this.findAndShowForm(element, 'addresses-tab');
      });
      this.$scope.find(`#${formId}`).find('a').trigger('click');
      return this.initializePluginsAfterInsert(insertedItem);
    });

    this.$scope.find('#contact_persons-tab').on('cocoon:after-remove', (e, insertedItem) => {
      if ($(insertedItem).closest('.contact_person_phones').length) {
        this.$scope.find(`#${insertedItem.data('lineId')}`).remove();
      }
    });

    this.$scope.find('#contact_persons-tab').on('cocoon:after-insert', (e, insertedItem) => {
      this.initializePluginsAfterInsert(insertedItem);
      if (!$(insertedItem).closest('.contact_person_phones').length && !$(insertedItem).closest('.contact_person_emails').length) {
        const newClass = Date.now();
        $(insertedItem).find('.contact_person_phones').closest('table').find('.add_fields')
          .attr('data-association-insertion-node', `.cp-phone-${newClass}`);
        $(insertedItem).find('.contact_person_phones').addClass(`cp-phone-${newClass}`);
        $(insertedItem).find('.contact_person_emails').closest('table').find('.add_fields')
          .attr('data-association-insertion-node', `.cp-email-${newClass}`);
        $(insertedItem).find('.contact_person_emails').addClass(`cp-email-${newClass}`);
        $(insertedItem).find('.add_fields').trigger('click');

        $(insertedItem).removeClass('ContactPerson-');
        const formId = `ContactPerson-${Date.now()}`;
        $(insertedItem).attr('data-line-id', formId).removeClass('ContactPerson-').addClass(formId);
        const $contactName = $(insertedItem).find('[data-contact-contact-id]');
        $contactName.on('change', () => {
          this.renameContactTab(this.$cpTabsContainer.find('.active'), $contactName.val());
        });
        this.$cpTabsContainer.prepend(`<a href="javascript:void(0)" class='nav-link sidebar-list' id='${formId}'>Define new</a>`);
        this.$scope.find('.sidebar-list').on('click', (element) => {
          this.findAndShowForm(element, 'contact_persons-tab');
        });
        this.$scope.find(`#${formId}`).find('a').trigger('click');
      }
      this.buildNestedLine();
    });

    const shippingAddressesCount = this.$sAdderessTabsContainer.data('shippingAddressesCount');
    const billingAddressesCount = this.$bAdderessTabsContainer.data('billingAddressesCount');
    const cpCount = this.$cpTabsContainer.data('contactPeopleCount');

    if (shippingAddressesCount === 0) { this.$scope.find('[data-associations="partner_shipping_addresses"]').trigger('click'); } else { $(this.$scope.find('#addresses-tab').find('.sidebar-list')[0]).addClass('active'); }
    if (billingAddressesCount === 0) { this.$scope.find('[data-associations="partner_billing_addresses"]').trigger('click'); }
    if (cpCount === 0) { this.$scope.find('[data-associations="contact_people"]').trigger('click'); }
    if ((shippingAddressesCount === 0) && (billingAddressesCount > 0)) { this.$scope.find('[data-default-line="true"]').find('a').trigger('click'); }

    this.buindNewLine(this.$scope.find('#contact_persons-tab'));
  }

  buildNestedLine() {
    const $partnerPhones = this.$scope.find('#generales-tab').find('[data-partner-phone]');
    $partnerPhones.off('change').on('change', () => {
      this.addLine($partnerPhones, ' ');
    });

    const $partnerEmails = this.$scope.find('#generales-tab').find('[data-partner-email]');
    $partnerEmails.off('change').on('change', () => {
      this.addLine($partnerEmails, '');
    });

    $partnerEmails.off('keydown').on('keydown', (event) => {
      if (event.keyCode === 9) {
        this.addLine($partnerEmails, '');
      }
    });

    const $contactPersonEmails = this.$scope.find('#contact_persons-tab').find('[data-partner-email]');
    $contactPersonEmails.off('change').on('change', (event) => {
      this.addLine($(event.currentTarget).closest('table').find('[data-partner-email]'), '');
    });
    $contactPersonEmails.off('keydown').on('keydown', (event) => {
      if (event.keyCode === 9) {
        this.addLine($(event.currentTarget).closest('table').find('[data-partner-email]'), '');
      }
    });
    const $contactPersonLines = this.$scope.find('#contact_persons-tab').find('[data-partner-phone]');
    return $contactPersonLines.off('change').on('change', (event) => {
      this.addLine($(event.currentTarget).closest('table').find('[data-partner-phone]'), ' ');
    });
  }

  addLine($elements, valBlank) {
    const allCount = $elements.length;
    let usedLines = 0;
    $elements.each((i, element) => { if ($(element).val() !== valBlank) { usedLines += 1; } });
    if (allCount === usedLines) { $elements.closest('table').find('.add_fields').trigger('click'); }
  }

  findAndShowForm(event, areaId) {
    this.clearActiveTabs(areaId);
    $(event.currentTarget).addClass('active');
    this.$scope.find(`#${areaId}`).find('.nested-fields').addClass('d-none');
    const formId = $(event.currentTarget).closest('a').attr('id');
    const $form = this.$scope.find(`.${formId}`);
    $form.removeClass('d-none');
    $form.find('.nested-fields').removeClass('d-none');
  }

  renameContactTab(element, value) { element.html(`${value}`); }

  clearActiveTabs(areaId) { this.$scope.find(`#${areaId}`).find('.sidebar-list').removeClass('active'); }

  clearAddressesForms(areaId) { this.$scope.find(`#${areaId}`).find('.nested-fields').addClass('d-none'); }

  buindNewLine($scope) { $($scope.find('.sidebar-list')[0]).trigger('click'); }
};
