import React, {Component} from 'react';
import axios from 'axios';
import {
    map,
    groupBy,
    without,
} from 'lodash';
import ForeignKeyForm from './SettingTypeForm/ForeignKeyForm';
import BooleanForm from './SettingTypeForm/BooleanForm';
import StringForm from './SettingTypeForm/StringForm';
import TouchSpinForm from './SettingTypeForm/TouchSpinForm';
import Spinner from 'react-bootstrap/Spinner';
import lowerCase from 'lodash/lowerCase';
import upperFirst from 'lodash/upperFirst';
import FloatForm from './SettingTypeForm/FloatForm';
import {split} from 'lodash';

class SettingsForm extends Component {
    state = {
        setting: {},
        settings: [],
        general_ledger_accounts: [],
        loading: true
    };

    componentDidMount = () => {
        const typeOfSetting = split(window.location.pathname, '/', 3)[2];
        const settingsPath = typeOfSetting === 'mobile_defaults' ? Routes.mobile_defaults_api_web_v1_settings_path() : Routes.api_web_v1_settings_path()
        this.fetchSettingsData(settingsPath)
            .then(response => {
                const {settings} = response.data;
                let newSettingsArray = map(settings, (setting) => {
                    if (setting.group !== 'item_groups') {
                        return setting;
                    }
                });
                newSettingsArray = without(newSettingsArray, undefined);
                const settingsV2 = groupBy(newSettingsArray, function (setting) {
                    return setting.group;
                });

                this.setState({
                    settings: settingsV2,
                    loading: false
                });
            });
    };


    setSettingFormSubmit = (data) => {
        const {id, setting} = data;
        axios({
            url: Routes.setting_path({id}),
            method: 'PATCH',
            data: {setting}
        })
            .then(() => {
                Application.Classes.Toastr.success('Saved');
            })
            .catch(error => {
                console.log(error);
            });
    };

    fetchSettingsData = (settingsPath) => (
        axios({ url: settingsPath })
            .then(response => (response))
            .catch(error => {
                console.log(error);
            })
    );

    renderInput = (setting) => {
        const {setSettingFormSubmit} = this;
        let collection = [];
        let array = [];
        const {value_type: valueType} = setting;
        switch (valueType) {
            case 'foreign_key':
                array = setting.options;
                collection = map(array, option => ({
                    value: `${option.id}`,
                    label: `${option.serial_number ? option.serial_number + '/' : ''} ${option.name}`,
                }));
                return (
                    <ForeignKeyForm
                        setSettingFormSubmit={setSettingFormSubmit}
                        setting={setting} collection={collection}
                    />
                );
            case 'boolean':
                return (BooleanForm(setting, {setSettingFormSubmit}));
            case 'enumeration':
                array = setting.options;
                collection = map(array, (option, _key) => ({
                        value: option[1],
                        label: option[0],
                    })
                );
                return (<ForeignKeyForm
                    setSettingFormSubmit={setSettingFormSubmit}
                    setting={setting}
                    collection={collection}
                />);
            case 'string':
                return (<StringForm
                    setSettingFormSubmit={setSettingFormSubmit}
                    setting={setting}
                />);
            case 'integer':
                return (<TouchSpinForm
                    setSettingFormSubmit={setSettingFormSubmit}
                    setting={setting}
                    validate={false}
                />);
            case 'float':
                return (<FloatForm
                    setSettingFormSubmit={setSettingFormSubmit}
                    setting={setting}
                />);
            case 'day':
                return (<TouchSpinForm
                    setSettingFormSubmit={setSettingFormSubmit}
                    setting={setting}
                    validate={true}
                />);
            case 'month':
                array = without(setting.options, null);
                collection = map(array, (option, _key) => ({
                    value: option,
                    label: option
                }));
                return (<ForeignKeyForm
                    setting={setting}
                    collection={collection}
                    setSettingFormSubmit={setSettingFormSubmit}
                />);
            case 'day_of_week':
                array = setting.options;
                collection = map(array, (option, _key) => ({
                    value: option,
                    label: option
                }));
                return (<ForeignKeyForm
                    setSettingFormSubmit={setSettingFormSubmit}
                    setting={setting}
                    collection={collection}
                />);
            case 'percent':
                return (<FloatForm
                    setSettingFormSubmit={setSettingFormSubmit}
                    setting={setting}
                />);
            default:
                console.warn('defineInput none type');
        }
    };

    render() {
        const {settings, loading} = this.state;
        const {renderInput} = this;
        if (loading) {
            return (
                <div className="d-flex justify-content-center h-100">
                    <div className="align-content-center">
                        <div className='sweet-loading mt-auto mb-auto'>
                            <Spinner animation="border"/>
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div>
                {map(Object.keys(settings), (group, _key) => {
                    return (
                        <div key={`setting-form-${_key}`}>
                            <h2 className={'text-center'}>{upperFirst(lowerCase(group))} Defaults</h2>
                            {
                                map(settings[group], (setting, key) => {
                                    return (
                                        <div key={`setting-form-${key}`}>
                                            {renderInput(setting)}
                                        </div>
                                    );
                                })
                            }
                            <div className="mt-5">
                                <hr/>
                            </div>
                        </div>);
                })}
            </div>);
    }
};

export default SettingsForm;
