import Application from 'application-namespace';
import $ from 'jquery';
import _filter from 'lodash/filter';
import _each from 'lodash/each';
import 'select2';

Application.Classes.NavBarSearch = class NavBarSearch {
  constructor($scope) {
    this.searchInput = $scope.find('[name="search"]');
    this.sideBarSection = $('#main-side-bar');
    this.currentFilter = this.sideBarSection.find('.current-filter');

    this.currentFilter.on('click', () => {
      this.searchInput.val('').trigger('keyup');
    });

    this.searchInput.on('keyup', (event) => {

      this.currentFilter.text($(event.currentTarget).val());
      this.currentFilter.closest('.filter-content').removeClass('d-none');

      if ($(event.currentTarget).val()) {
        this.sideBarSection.find('li').hide();
        const results = _filter(this.sideBarSection.find('li'), (line) => {
          return $(line).text().toLowerCase().indexOf($(event.currentTarget).val().toLowerCase()) !== -1;
        });
        _each(results, (object) => {
          $(object).show();
          this.openTree(object);
        });
      } else {
        this.sideBarSection.find('li').show();
        this.sideBarSection.find('ul').collapse('hide');
        this.currentFilter.closest('.filter-content').addClass('d-none');
      }
    });
  }

  openTree(object) {
    const treeId = $(object).closest('ul').attr('id');

    if (treeId && $(object).find(`[data-target='#${treeId}]']`)) {
      this.sideBarSection.find(`#${treeId}`).collapse('show');
      this.sideBarSection.find(`[data-target="#${treeId}"]`).show();
      this.sideBarSection.find(`[data-target="#${treeId}"]`).collapse('show');
      this.openTree(this.sideBarSection.find(`[data-target="#${treeId}"]`));
    }
  }
};
