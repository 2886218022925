import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Calendar from 'rc-year-calendar';
import { get, reduce } from 'lodash';
import moment from 'moment';
import { change } from 'redux-form';

const WorkDaysCalendar = () => {
  const dispatch = useDispatch();
  const employeeWorkDaysCalendarStore = useSelector(state => state.employeeWorkDaysCalendarStore);
  const employeeWorkDays = get(employeeWorkDaysCalendarStore, 'employeeWorkDays', []);

  const [decoratedWorkDays, setDecoratedWorkDays] = useState([]);
  const [year, setYear] = useState(moment()
    .format('YYYY'));

  useEffect(() => {
      decorateEmployeeWorkDays();
    }, [employeeWorkDaysCalendarStore]
  );

  useEffect(() => {
    dispatch(change('employeeWorkDaysSearch', 'year', year));
  }, [year]);

  const decorateEmployeeWorkDays = () => {
    const calendarDataSource = reduce(employeeWorkDays, (array, workDay, _index) => {
      const date = moment(workDay.work_day_at)._d;
      const colors = {
        unknown: '#C458FF',
        standard: '#41AE98',
        sick: '#DE2800',
        vacation: '#FFD700',
        holiday: '#0071A5',
        other: '#008E41',
        weekend: '#808080'
      };
      array.push({
        id: workDay.id,
        name: workDay.day_type,
        startDate: date,
        endDate: date,
        color: colors[workDay.day_type]
      });
      return array;
    }, []);
    setDecoratedWorkDays(calendarDataSource);
  };

  const calendarYearChanged = (calendarData) => {
    setYear(calendarData.currentYear);
  };

  const onDayClick = (event) => {
    if (event.events[0]) {
      const workDayId = event.events[0].id;
      window.location.replace(Routes.redirect_to_work_period_api_web_v1_employee_work_day_path(workDayId))
    }
  };

  return (
    <>
      {decoratedWorkDays &&
      <Calendar
        style='background'
        dataSource={decoratedWorkDays}
        year={year}
        onYearChanged={calendarYearChanged}
        onDayClick={onDayClick}
      />
      }
    </>
  );
};

export default WorkDaysCalendar;
