import Application from 'application-namespace';
import $ from 'jquery';
import moment from 'moment';
import _each from 'lodash/each';

Application.Classes.ServiceContractForm = class ServiceContractForm extends Application.Classes.CoreBase {
  constructor($scope) {
    super();
    this.$scope = $scope;
    this.$businessPartnerSelect = this.$scope.find('#service_contract_business_partner_id');
    this.$templateSelect = this.$scope.find('#service_contract_template_id');
    this.$contactPerson = this.$scope.find('#service_contract_contact_person_id');
    this.days = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];
    this.$businessPartnerSelect.on('change', () => this.fetchBusinessPartner());
    this.$scope.find('table').on('cocoon:after-insert', (e, insertedItem) => {
      this.initializePluginsAfterInsert(insertedItem);
      this.initializeDropdowns();
      this.validateOnUniqueness();
    });

    this.$scope.find('table').on('cocoon:after-remove', () => this.validateOnUniqueness());

    this.$templateSelect.on('change', () => this.fetchTemplate());

    this.initializeDropdowns();
    this.$scope.find('[data-equipment-cards-dropdown]').trigger('change');
  }

  initializeDropdowns() {
    this.$scope.find('[data-equipment-cards-dropdown]').on('change', (event) => {
      this.pasteAttributes(event);
      this.validateOnUniqueness();
    });
  }

  validateOnUniqueness() {
    const selectedValues = [];
    const $lines = this.$scope.find('[data-equipment-cards-dropdown]');
    $lines.each((index, element) => selectedValues.push($(element)[0].value));
    $lines.each((index, element) => {
      // TODO check the Konstantin
      setTimeout(( function() {
        $(element).find('option').prop('disabled', false);
        _each(selectedValues, (v) => {
          if (!$(element).val()) {
            $(element).find(`[value='${v}']`).prop('disabled', true);
          }
        });
        $(element).select2('destroy').select2();
      }), 10);
    });
  }

  pasteAttributes(event) {
    const selectedVariable = $(event.currentTarget).select2('data');
    const line = $(event.currentTarget).closest('tr');
    // TODO check the Konstantin
    if (selectedVariable[0].id) {
      const startDate = (selectedVariable[0].element.attributes.start_date != null ? selectedVariable[0].element.attributes.start_date.value : undefined) || this.$scope.find('#service_contract_start_date').val();
      const andDate = (selectedVariable[0].element.attributes.end_date != null ? selectedVariable[0].element.attributes.end_date.value : undefined) || this.$scope.find('#service_contract_end_date').val();
      const terminationDate = (selectedVariable[0].element.attributes.termination_date != null ? selectedVariable[0].element.attributes.termination_date.value : undefined) || this.$scope.find('#service_contract_termination_date').val();
      this.importantPasteInDiv(line.find('[data-item-type]'), selectedVariable[0].element.attributes.cec_type != null ? selectedVariable[0].element.attributes.cec_type.value : undefined);
      this.importantPasteInDiv(line.find('[data-serial-number]'), selectedVariable[0].element.attributes.serial_number != null ? selectedVariable[0].element.attributes.serial_number.value : undefined);

      this.importantPasteInDiv(line.find('[data-start-date]'), startDate);
      this.importantPasteInDiv(line.find('[data-end-date]'), andDate);
      this.importantPasteInDiv(line.find('[data-terminate-date]'), terminationDate);
    } else {
      line.find('[data-item-type], [data-serial-number], [data-start-date], [data-end-date], [data-terminate-date]').html('');
    }
  }

  fetchBusinessPartner() {
    $.ajax({
      url: Routes.business_partner_path(this.$businessPartnerSelect.val()),
      dataType: 'JSON',
    }).then((response) => {
      this.fetchContactPeople(this.$businessPartnerSelect.val());
      this.pasteBpOptions(this.$businessPartnerSelect, response);
    }).catch(error => console.error('Error: ', error));
  }

  fetchContactPeople(businessPartnerId) {
    $.ajax({
      url: Routes.business_partner_contact_people_path({ business_partner_id: businessPartnerId }),
      dataType: 'JSON',
    }).then((response) => {
      this.pasteToList(this.$contactPerson, response, 'contact_id');
    }).catch(error => console.error('Error: ', error));
  }

  fetchTemplate() {
    $.ajax({
      url: Routes.template_path(this.$templateSelect.val()),
      dataType: 'JSON',
    }).then((response) => {
      this.pasteTemplateOptions(this.$templateSelect, response);
    }).catch(error => console.error('Error: ', error));
  }

  // TODO: rewrite on selection CP
  pasteBpOptions(object, result) {
    this.pasteInSelect('#service_contract_contact_person_id', result.default_contact_person_id);
    this.pasteInInput('#service_contract_telephone', result.mobile_phone);
    this.pasteToListSpecific('[data-equipment-cards-dropdown]', result.customer_equipment_cards, 'driveway_gate_name');
  }

  pasteTemplateOptions(object, result) {
    this.pasteInSelect('#service_contract_contract_type', result.contract_type);
    this.pasteInInput('#service_contract_response_time', result.response_time);
    this.pasteInInput('#service_contract_resolution_time', result.resolution_time);
    this.pasteInInput('#service_contract_remarks', result.description);
    this.pasteInInput('#service_contract_contract_template_remarks', result.contract_template_remarks);
    this.pasteInSelect('#service_contract_response_time_unit_id', result.response_date_time_id);
    this.pasteInSelect('#service_contract_resolution_time_unit_id', result.resolution_date_time_id);

    // Coverage pull
    this.$scope.find('[data-day]').val('');
    this.pasteInCheckbox(this.$scope.find('[data-day]'), false);
    if (result.work_days) {
      const workDays = result.work_days.configuration.raw.hours;
      this.days.forEach((v, i) => {
        if (workDays[v] && Object.keys(workDays[v]).length) {
          const day = v;
          const from = Object.keys(workDays[v])[0];
          const to = workDays[v][from];
          if (from) { this.$scope.find(`[data='${day}-start']`).val(moment(from, ['HH:mm']).format('h:mm A')); }
          if (to) { this.$scope.find(`[data='${day}-end']`).val(moment(to, ['HH:mm']).format('h:mm A')); }
          this.pasteInCheckbox(this.$scope.find(`[data-day='${v}']`), !!(day && to));
        }
      });
    }

    this.pasteInCheckbox('#service_contract_parts', result.parts);
    this.pasteInCheckbox('#service_contract_labor', result.labor);
    this.pasteInCheckbox('#service_contract_including_holidays', result.including_holidays);
  }

  pasteToListSpecific(selector, data, title) {
    const $selector = $(selector);
    $selector.empty();
    $selector.html('<option value="" selected="selected"></option>');
    for (let k = 0; k < data.length; k++) {
      const object = data[k];
      const attributeTitle = eval(`object.${title}`);
      $selector.append(`<option cec_type="${object.cec_type.name}" serial_number="${object.serial_number}" value="${object.id}">${attributeTitle}</option>`);
    }
    return $selector.trigger('change');
  }
};
