import Application from 'application-namespace';


Application.Classes.DisabledForm = class DisabledForm {
  constructor($scope) {
    this.$scope = $scope;
    this.$inputs = this.$scope.find('input, textarea');
    this.$dropdowns = this.$scope.find('.select_picker');
    this.$paymentMeanContainer = this.$scope.find('.payment-mean');

    this.disabledInputs();
    if (this.$paymentMeanContainer) { this.activePaymentMeanTab(); }
  }

  disabledInputs() {
    this.$inputs.attr('disabled', 'disabled');
    this.$dropdowns.prop('disabled', true);
  }

  activePaymentMeanTab() {
    this.$paymentMeanContainer.find('.tabs_header a').trigger('click');
  }
};
