import Application from 'application-namespace';
import $ from 'jquery';
import _keys from 'lodash/keys';
import _each from 'lodash/each';

// NOTE: Grammary API -> https://montanaflynn-spellcheck.p.mashape.com/check/
Application.Concerns.Grammarly = superclass => class extends superclass {
  checkGrammary($scope) {
    $scope.find('textarea').on('change', (event) => {
      this.currentInput = $(event.currentTarget);
      this.content = this.currentInput.val();
      this.$currentDiv = this.currentInput.closest('.input');
      this.$currentDiv.find('.errorValues').empty();

      $.ajax({
        url: 'https://montanaflynn-spellcheck.p.mashape.com/check/',
        type: 'GET',
        data: { text: this.content },
        headers: {
          'X-Mashape-Key': gon.global.grammarly_key,
        },
      }).then((response) => {
        _each(_keys(response.corrections), (object) => {
          const options = [];

          _each(response.corrections[object], (correction) => {
            options.push(`<a style='cursor: pointer;' class='correction-variable' onclick='javascript:Application.Concerns.Grammarly.prototype.swapValue(this);' origin_value='${object}'>${correction}</a>`);
          });

          this.$currentDiv.append(
            "<div class='errorValues'>"
            + `<a style='cursor: pointer;' tabindex='0' data-toggle='popover' data-trigger='focus' title='May be you mean:' tabindex='0'}>${object}</a>`
            + '</div>',
          );
          $scope.find('[data-toggle="popover"]').popover({ html: true });
          const content = `${options.join(' ')}<hr><a style='cursor:pointer;' onclick='javascript:Application.Concerns.Grammarly.prototype.swapValue(this);'>Ignore<a>`;
          $scope.find('[data-toggle="popover"]').on('shown.bs.popover', () => {
            $scope.find('.popover-content').html(content);
          });
        });
      }).catch((error) => {
        console.error('Error: ', error);
      });
    });
  }

  // static swapValue(element) {
  //   if ($(element)[0].attributes.origin_value) {
  //     const originValue = $(element)[0].attributes.origin_value.value;
  //     const currentInput = $(element).closest('.input').find('textarea');
  //     $(currentInput).val($(currentInput).val().replace(originValue, $(element).text()));
  //   }
  //   $(element).closest('.errorValues').empty();
  // }
};

Application.Concerns.Grammarly.prototype.swapValue = (element) => {
  if ($(element)[0].attributes.origin_value) {
    const originValue = $(element)[0].attributes.origin_value.value;
    const currentInput = $(element).closest('.input').find('textarea');
    $(currentInput).val($(currentInput).val().replace(originValue, $(element).text()));
  }
  $(element).closest('.errorValues').empty();
};
