import React from 'react';
import { Formik } from 'formik';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import parseInt from 'lodash/parseInt';
import * as Yup from 'yup';
import LabelInputValue from '../../../decorators/LabelInputValue';

let TouchSpinForm = ({ setting, validate, setSettingFormSubmit }) => {
  const settingInForm = {};
  const styleButtonForm = { marginBottom: '28px' };
  const { id, var: settingVar } = setting;
  settingInForm[setting['var']] = setting.value;
  let schema = Yup.object({
    [setting['var']]: Yup.number()
      .required('can`t be blank')
      .nullable()
      .max(100)
      .min(1),
  });
  if (validate) {
    schema = Yup.object({
      [setting['var']]: Yup.number()
        .required('can`t be blank')
        .nullable()
        .max(365)
        .min(1),
    });
  }


  return (
    <Formik
      initialValues={settingInForm}
      validationSchema={schema}
      enableReinitialize={true}
      onSubmit={(values) => {
        const value = values[settingVar];
        const setting = {
          id,
          setting: { value }
        };
        setSettingFormSubmit(setting);
      }}
    >
      {({
        handleSubmit,
        handleChange,
        values,
        errors,
        setFieldValue,
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <div className="d-flex bd-highlight mb-3 row" style={{ height: '60px' }}>
            <div className="p-2 bd-highlight align-self-end col-9 mb-0">
              <Form.Row>
                <Form.Group as={Col} md='12'>
                  <Form.Label>{LabelInputValue(setting['var'])}</Form.Label>
                  <InputGroup>
                    <div className="input-group-prepend">
                      <Button
                        type='submit'
                        className='btn btn-primary'
                        onClick={(event) => {
                          event.preventDefault();
                          setFieldValue(setting['var'], parseInt(values[setting['var']]) - 1);
                        }}
                      >-</Button>
                    </div>
                    <Form.Control
                      type="number"
                      value={values[setting['var']]}
                      name={setting['var']}
                      onChange={handleChange}
                      isInvalid={!!errors[setting['var']]}
                    />
                    <div className="input-group-append">
                      <Button type='submit'
                              className='btn btn-primary'
                              onClick={(event) => {
                                event.preventDefault();
                                setFieldValue(setting['var'], parseInt(values[setting['var']]) + 1);
                              }}
                      >+</Button>
                    </div>
                  </InputGroup>
                  <Form.Control.Feedback type="invalid">
                    {errors[setting['var']]}
                  </Form.Control.Feedback>
                  {
                    !!errors[setting['var']] &&
                    <div style={{
                      color: 'red',
                      marginTop: '.5rem'
                    }}>{errors[setting['var']]}</div>
                  }
                </Form.Group>
              </Form.Row>
            </div>
            <div
              className="align-self-center p-2 mt-2 bd-highlight col-3"
              style={!!errors[setting['var']] ? styleButtonForm : {}}
            >
              <Button type='submit' className='btn btn-success btn-block'>Update</Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>);
};

export default TouchSpinForm;
