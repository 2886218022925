import axios from 'axios';

import {
  LOAD_REQUIRED_SETTINGS,
  SET_CURRENT_SETUP_STEP_DATA,
  SET_FORM_ACTIVE_STATE
} from './actionTypes'

export const loadRequiredSettings = () => {
  return (dispatch) => {
    return (
      axios ({ url: Routes.api_web_v1_project_payroll_setup_path() })
        .then(response => {
          dispatch({
            type: LOAD_REQUIRED_SETTINGS,
            payload: { response }
          })
        })
        .catch(error => {
          console.log(error);
        })
    )
  }
};

export const setCurrentSetupStepData = (currentStep) => ({
  type: SET_CURRENT_SETUP_STEP_DATA,
  payload: {
    currentStep
  }
});

export const setFormActiveState = (currentStep = null) => ({
  type: SET_FORM_ACTIVE_STATE,
  payload: {
    currentStep
  }
});
