import Application from 'application-namespace';
import $ from 'jquery';

Application.Concerns.ReportsExtension = superclass => class extends superclass {
  determinationLevelForTreeTable($scope) {
    $scope.find('#depth_selection').on('change', (event) => {
      $scope.find('#tree_table').treetable('collapseAll');
      const $value = $(event.currentTarget).val();
      switch ($value) {
        case '2':
          $scope.find('[data-level="1"]').find('a').trigger('click'); break;
        case '3':
          $scope.find('[data-level="1"], [data-level="2"]').find('a').trigger('click'); break;
        case '4':
          $scope.find('#tree_table').treetable('expandAll'); break;
        default:
          console.error('Application.Concerns.ReportsExtension#determinationLevelForTreeTable unprocessed switch:', $value);
      }
    });
  }
};
