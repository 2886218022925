import React, {useState, useEffect} from 'react';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import InputDatePicker from '../../../../components/ReduxForm/InputDatePicker';
import SelectWrapper from '../../../../components/ReduxForm/SelectWrapper';
import Select2Collection from '../../../../decorators/Select2Collection';
import RenderField from '../../../../components/ReduxForm/RenderField';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import ReduxFormValidationAlert from '../../../../components/ReduxFormValidationAlert'

const FormPayrollSetting = ({ payrollSetting, handleSubmit, stepButtons = [] }) => {

  const [states, setStates] = useState([]);

  const loadStates = () => {
    return axios({
      method: 'GET',
      url: Routes.api_web_v1_dictionaries_path({name: 'state'})
    });
  };

  useEffect(() => {
    loadStates()
      .then(response => {
        setStates(response.data);
      })
      .catch(error => {
        console.log('PayrollSettingForm.response', error.response);
      });
  }, []);

  return (
    <>
      <ReduxFormValidationAlert formName={'payrollSettingsForm'}/>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col md="12">
            <Field
              name="effective_date"
              component={InputDatePicker}
            />
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <Field
              name="frequency_payroll_runs"
              component={SelectWrapper}
              collection={payrollSetting.enum_collections.frequency_payroll_runs}
              formName="payrollSettingsForm"
            />
          </Col>
          <Col md="6">
            <Field
              name="default_work_state_id"
              component={SelectWrapper}
              collection={Select2Collection(states, { labelKey: 'name' })}
              formName="payrollSettingsForm"
              label="Default state"
            />
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <Field
              name="work_week_begin"
              component={SelectWrapper}
              collection={payrollSetting.enum_collections.work_week_begins}
              formName="payrollSettingsForm"
            />
          </Col>
          <Col md="6">
            <Field
              name="paid_days_count_after_end_report"
              component={RenderField}
            />
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <Field
              name="overtime_period_type"
              component={SelectWrapper}
              collection={payrollSetting.enum_collections.overtime_period_types}
              formName="payrollSettingsForm"
            />
          </Col>
          <Col md="6">
            <Field
              name="max_work_time_offset_minutes"
              component={RenderField}
            />
          </Col>
        </Row>
        <Row>
          <Col md="4">
            <Field
              name="archiving_payroll_after_days"
              component={RenderField}
            />
          </Col>
          <Col md="4">
            <Field
              name="employee_paid_holidays_after_days"
              component={RenderField}
            />
          </Col>
          <Col md="4">
            <Field
              name="min_punches_interval_minutes"
              component={RenderField}
            />
          </Col>
        </Row>
        <div className="d-flex justify-content-start align-items-baseline">
          {stepButtons}
          <Button
            variant="success"
            type="submit"
            block
          >
            Next
          </Button>
        </div>
      </Form>
    </>
  );

};

export default compose(
  reduxForm({
    form: 'payrollSettingsForm',
    enableReinitialize: true
  })
)(FormPayrollSetting);
