(function ($) {
  $.fn.ReportsTree = function (options) {
    this.packadateOptions = {
      selectMonths: true, today: 'Today', clear: false, selectYears: 35, format: 'mm/dd/yyyy', onStart: new Date(),
    };

    this.settings = {
      path: this.data('path'),
      data: {
        report_type: (this.data('report_type') != null) ? this.data('report_type') : 'accrual',
        start_at: this.data('from'),
        end_at: this.data('to'),
        level: (this.data('level') != null) ? this.data('level') : 1,
      },
      searchBetween: (this.data('serch_between') != null) ? this.data('serch_between') : true,
      selectionLevel: (this.data('selection') != null) ? this.data('selection') : true,
      reportByType: (this.data('type_selection') != null) ? this.data('type_selection') : true,
      dowloadReport: (this.data('dowload_report') != null) ? this.data('dowload_report') : true,
      columns: this.data('columns'),
      tableClass: this.data('table_class') || 'table table-bordered table-hover',
      americanDateFormat: this.data('americanDateFormat') || true,
      americanDateFormat: (this.data('american_date_format') != null) ? this.data('american_date_format') : true,
      type: this.data('type'),
      spinnerClass: this.data('spinner_class') || 'custom-spinner',
    };

    this.searchBetweenHtml = this.settings.searchBetween
      ? '<div class="col-sm-7">'
                + '<div class="row">'
                + '<div class="col-sm-5">'
                + `<input type='date_picker' name='from' id='from' value='${this.settings.data.start_at}' class='date-picker form-control'>`
                + '</div>'
                + '<div class="col-sm-5">'
                + `<input type='date_picker' name='to'   id='to'   value='${this.settings.data.end_at}'   class='date-picker form-control'>`
                + '</div>'
                + '<div class="col-sm-2">'
                + '<input type="submit" name="commit" value="Refresh" class="btn btn-success"  data-refresh-btn="true" data-disable-with="Refresh">'
                + '</div>'
                + '</div>'
                + '</div>'
      : '';

    this.reportByTypeHtml = this.settings.reportByType
      ? "<div class='col-sm-7'>"
                + "<select name='type_selection' id='type_selection' class='select-picker'>"
                + "<option selected='selected' value='accrual_basis'>Accrual Basis</option>"
                + "<option value='cash_basis'>Cash Basis</option>"
                + '</select>'
                + '</div>'
      : '';

    this.selectionLevelHtml = this.settings.selectionLevel
      ? "<div class='col-sm-5'>"
                + "<select name='depth_selection' id='depth_selection' class='select-picker'>"
                + "<option selected='selected' value='1'>1</option>"
                + "<option value='2'>2</option>"
                + "<option value='3'>3</option>"
                + "<option value='4'>4</option>"
                + '</select>'
                + '</div>'
      : '';

    this.dowloadReportButtonHtml = this.settings.dowloadReport
      ? '<a class="btn btn-primary" data-download-report-btn="true" href="javascript:void(0)">Download Report</a>'
      : '';

    const _specificDisplayMoney = (value, html) => {
      let style;
      if (html == null) { html = true; }
      const int = (value != null ? value[0] : undefined) === '$'
        ? value.replace('$', '')
        : value;
      const result = (() => {
        if (parseInt(int) < 0) {
          style = 'color:red;';
          return `($${parseFloat(int.toString().replace('-', '').replace(',', '')).formatMoney(2, '.', ',')})`;
        }
        style = '';
        return `$${parseFloat(int.toString().replace(',', '')).formatMoney(2, '.', ',')}`;
      })();
      if (html) {
        return `<span style='${style}'>${result}</span>`;
      }
      return result;
    };

    const _percentDisplay = value => `${parseFloat(value).toFixed(2)}%`;

    const _convertDate = (usDate) => {
      if (!usDate) { return; }
      if (!this.settings.americanDateFormat) { return usDate; }
      const dateParts = usDate != null ? usDate.split(/(\d{1,2})\/(\d{1,2})\/(\d{4})/) : undefined;
      `${dateParts[2]}/${dateParts[1]}/${dateParts[3]}`;
      return usDate;
    };

    const _changeSettings = (event, request_format) => {
      this.settings.data.start_at = $('#reportSearchBetween').find('[name="from"]').val();
      this.settings.data.end_at = $('#reportSearchBetween').find('[name="to"]').val();
      this.settings.data.level = $('#reportSearchBetween').find('[name="depth_selection"]').val();
      this.settings.data.report_type = $('#reportSearchBetween').find('[name="type_selection"]').val();
      if (request_format) {
        return window.open(`${this.settings.path}.${request_format}?start_at=${this.settings.data.start_at}&end_at=${this.settings.data.end_at}&level=${this.settings.data.level}&report_type=${this.settings.data.report_type}`);
      }
      return _fetchData();
    };

    const _initializeEvents = () => {
      this.find('#reportSearchBetween').find('#depth_selection').on('change', event => _changeSettings(event));
      this.find('#reportSearchBetween').find('#type_selection').on('change', event => _changeSettings(event));
      this.find('#reportSearchBetween').find('[data-refresh-btn]').on('click', event => _changeSettings(event));
      return this.find('[data-download-report-btn]').on('click', event => _changeSettings(event, 'xlsx'));
    };

    const _buildMainTable = (responce) => {
      const table = `<hr><div class='${this.settings.spinnerClass} d-none'>Progress...</div><table class='mainReportTable ${this.settings.tableClass}'><thead><tr></tr></thead><tbody></tbody></table>`;
      this.find('#mainTable').html(table);
      if (responce) {
        _buildMainHeader();
        this.parents = {};
        _buindMainTableLine(responce);
        _buindMainTableTotalLine();
        return _buindCustomTotalLines();
      }
      return _showSpinner();
    };

    var _buildMainHeader = () => {
      const { columns } = this.settings;
      return columns.forEach((object, index) => {
        const head_column = `<th>${object}</th>`;
        return this.find('#mainTable').find('thead').find('tr').append(head_column);
      });
    };

    const _balanceSheetLineTemplate = (titles, object, content) => {
      let color; let
        padding;
      if (content == null) { content = ''; }
      if (this.settings.type !== 'general_ledger') {
        padding = (object.depth * 20) + 10;
        //        arr = ['red', 'blue', 'green', 'black']
        //        arr[object.depth]
        color = (() => {
          switch (object.depth) {
            case 0:
              return 'red';
            case 1:
              return 'blue';
            case 2:
              return 'green';
            case 3:
              return 'black';
            default:
              return '#448ccc';
          }
        })();
      }
      this.settings.columns.forEach((obj, i) => {
        let styles;
        if (i > 0) { styles = ''; } else { styles = `padding-left:${padding}px;color:${color};`; }
        const string = (i !== 0) && (this.settings.data.level > 1) && object.children ? '' : titles[i];
        return content += `<td style=${styles}> ${string} </td>`;
      });
      return `<tr data-parent-id=${object.parent_ids !== '' ? object.parent_ids : object.id}>${content}</tr>`;
    };

    const _totalLineBalanceSheetLine = (titles, object, content, hidden, destination) => {
      if (content == null) { content = ''; }
      if (hidden == null) { hidden = false; }
      if (destination == null) { destination = 'Total by'; }
      const padding = object.depth * 20;
      const color = (() => {
        switch (object.depth) {
          case 0:
            return 'red';
          case 1:
            return 'blue';
          case 2:
            return 'green';
          case 3:
            return 'black';
          default:
            return '#448ccc';
        }
      })();
      this.settings.columns.forEach((obj, i) => {
        let string;
        const p_styles = i === 0
          ? `color:${color};padding-left:${padding}px;`
          : object.parent_ids === ''
            ? 'border-bottom: 3px double blue;border-top: 1px dotted blue;color: blue;padding: 2px;'
            : 'border-bottom: 1px dashed blue;border-top: 1px dotted blue;color: blue;padding: 2px;';
        if (i === 0) { const nick = (string = (destination === 'Total by' ? `${destination} ${titles[i]}` : destination)); } else { string = titles[i]; }
        return content += `<td><div style='${p_styles}'> ${string} </div></td>`;
      });
      const hidden_class = hidden ? 'd-none' : undefined;
      return `<tr class=${hidden_class} data-parent-id='${object.parent_ids}'>${content}</tr>`;
    };

    const _buindGeneralLedgerLine = data => data.journal_entries.forEach((object, index) => {
      const line_html = _balanceSheetLineTemplate(
        [
          moment(object.posting_date).format('L'),
          moment(object.due_date).format('L'),
          `<a href='${object.document_path}'>${object.document_number}</a>`,
          'Trans. No.',
          (object.document_remarks != null) ? object.document_remarks : '',
          `<a href='${object.offset_acct_path}'>${object.offset_acct}</a>`,
          object.offset_acct_name,
          _specificDisplayMoney(object.debit_credit),
          _specificDisplayMoney(object.cumulative_balance),
        ], object,
      );
      return this.find('#mainTable').find('tbody').append(line_html);
    });

    var _buindMainTableLine = (data, parent) => data.forEach((object, index) => {
      // #KEEP PARENTS
      let additional_data;
      additional_data = {};
      const total_line_html = (() => {
        switch (this.settings.type) {
          case 'balance_sheet':
            additional_data = { [object.name]: { balance: object.amount_balance_by_date } };
            var hidden = (object.amount_balance_by_date === '$0.00') || (parseInt(this.settings.data.level) === 1);
            return _totalLineBalanceSheetLine([object.name, _specificDisplayMoney(object.amount_balance_by_date), _percentDisplay(object.relative_percent)], object, '', hidden);
          case 'profit_and_loss_statement':
            additional_data = { [object.name]: { balance: object.amount_balance_by_date, balance_by_year: object.year_to_date } };
            hidden = (object.amount_balance_by_date === '$0.00') || (parseInt(this.settings.data.level) === 1);
            return _totalLineBalanceSheetLine([object.name, _specificDisplayMoney(object.amount_balance_by_date), _specificDisplayMoney(object.year_to_date)], object, '', hidden);
          case 'trial_balance':
            additional_data = { [object.name]: { total_debit: object.amount_debit_by_date, total_credit: object.amount_credit_by_date } };
            return _totalLineBalanceSheetLine([object.name, _specificDisplayMoney(object.amount_debit_by_date), _specificDisplayMoney(object.amount_credit_by_date), _specificDisplayMoney(object.amount_balance_by_date)], object);
        }
      })();
      this.parents[object.id] = {
        id: object.id, html: total_line_html, parent_id: `${object.parent_ids !== '' ? `${object.parent_ids}-${object.id}` : object.id}`, depth: object.depth, name: object.name, additional_data,
      };

      // #ADD LINES
      if ((object.amount_balance_by_date !== '$0.00') || (object.parent_ids === '')) {
        const line_html = (() => {
          switch (this.settings.type) {
            case 'balance_sheet':
              return _balanceSheetLineTemplate([object.name, _specificDisplayMoney(object.amount_balance_by_date), _percentDisplay(object.relative_percent)], object);
            case 'profit_and_loss_statement':
              return _balanceSheetLineTemplate([object.name, _specificDisplayMoney(object.amount_balance_by_date), _specificDisplayMoney(object.year_to_date)], object);
            case 'trial_balance':
              return _balanceSheetLineTemplate([object.name, _specificDisplayMoney(object.amount_debit_by_date), _specificDisplayMoney(object.amount_credit_by_date), _specificDisplayMoney(object.amount_balance_by_date)], object);
            case 'general_ledger':
              if (object.journal_entries.length || (object.cumulative_balance !== '0.0')) { return _balanceSheetLineTemplate([object.root_account.name, `<a href='${object.account_path}'>${object.serial_number}</a>`, '', '', '', object.name, '', '', `<p style='color: grey;'>${_specificDisplayMoney(object.cumulative_balance, false)}</p>`], object); }
              break;
          }
        })();
        this.find('#mainTable').find('tbody').append(line_html);
      }

      // #RECURSION
      if ((this.settings.type === 'general_ledger') && object.journal_entries) { _buindGeneralLedgerLine(object); }
      if (object.children) { return _buindMainTableLine(object.children, object); }
    });

    var _buindMainTableTotalLine = () => {
      this.total_line = {};
      const obj = this.parents;
      const keys = Object.keys(obj);
      return keys.sort((a, b) => obj[a].depth - (obj[b].depth)).reverse().forEach((key) => {
        const { depth } = obj[key];
        const key_id = obj[key].parent_id.toString().split('-').slice(0, depth + 1).join('-');
        const lines = this.find(`[data-parent-id='${key_id}']`);
        $($(lines)[lines.length - 1]).after(obj[key].html);
        switch (this.settings.type) {
          case 'balance_sheet':
            return this.total_line[obj[key].name] = {
              balance: ((this.total_line[obj[key].name] != null ? this.total_line[obj[key].name].balance : undefined) || 0) + parseFloat(obj[key].additional_data[obj[key].name].balance.slice(1).replace(',', '')),
            };
          case 'profit_and_loss_statement':
            return this.total_line[obj[key].name] = {
              balance: ((this.total_line[obj[key].name] != null ? this.total_line[obj[key].name].balance : undefined) || 0) + parseFloat(obj[key].additional_data[obj[key].name].balance.slice(1).replace(',', '')),
              balance_by_year: ((this.total_line[obj[key].name] != null ? this.total_line[obj[key].name].balance_by_year : undefined) || 0) + parseFloat(obj[key].additional_data[obj[key].name].balance_by_year.slice(1).replace(',', '')),
            };
          case 'trial_balance':
            return this.total_line[obj[key].name] = {
              total_debit: ((this.total_line[obj[key].name] != null ? this.total_line[obj[key].name].total_debit : undefined) || 0) + parseFloat(obj[key].additional_data[obj[key].name].total_debit.slice(1).replace(',', '')),
              total_credit: ((this.total_line[obj[key].name] != null ? this.total_line[obj[key].name].total_credit : undefined) || 0) + parseFloat(obj[key].additional_data[obj[key].name].total_credit.slice(1).replace(',', '')),
            };
        }
      });
    };

    var _buindCustomTotalLines = () => {
      let profit_period;
      switch (this.settings.type) {
        case 'balance_sheet':
          var line_after_total_by_equity = (this.total_line.Equity.balance + this.total_line.Liabilities.balance + this.total_line.Assets.balance) - this.total_line.Liabilities.balance - this.total_line.Equity.balance;
          this.find("td:contains('Total by Equity')").closest('tr').after(_totalLineBalanceSheetLine(['', _specificDisplayMoney(line_after_total_by_equity), '100.00%'], '', '', false, ''));

          var recalculate_total_equity = (this.total_line.Equity.balance + this.total_line.Assets.balance) - this.total_line.Liabilities.balance - this.total_line.Equity.balance;
          this.find("td:contains('Total by Equity')").closest('tr').find('span').html(_specificDisplayMoney(recalculate_total_equity));

          if (this.settings.data.level > 1) {
            profit_period = this.total_line.Assets.balance - this.total_line.Liabilities.balance - this.total_line.Equity.balance;
            return this.find("td:contains('Total by Equity')").closest('tr').before(_totalLineBalanceSheetLine(['', _specificDisplayMoney(profit_period), '100.00%'], '', '', false, 'Profit Period'));
          }
          break;
        case 'profit_and_loss_statement':
          var gross_profit = this.total_line.Revenues.balance - this.total_line['Cost of Sales'].balance;
          var gross_profit_by_year = this.total_line.Revenues.balance_by_year - this.total_line['Cost of Sales'].balance_by_year;
          this.find("td:contains('Total by Cost of Sales')").closest('tr').after(_totalLineBalanceSheetLine(['', _specificDisplayMoney(gross_profit), _specificDisplayMoney(gross_profit_by_year)], '', '', false, 'Gross Profit'));

          var operating_profit = this.total_line.Revenues.balance - this.total_line['Cost of Sales'].balance - this.total_line.Expenses.balance;
          var operating_profit_by_year = this.total_line.Revenues.balance_by_year - this.total_line['Cost of Sales'].balance_by_year - this.total_line.Expenses.balance_by_year;
          this.find("td:contains('Total by Expenses')").closest('tr').after(_totalLineBalanceSheetLine(['', _specificDisplayMoney(operating_profit), _specificDisplayMoney(operating_profit_by_year)], '', '', false, 'Operating Profit'));

          var profit_after_financing_expenses = this.total_line.Revenues.balance - this.total_line['Cost of Sales'].balance - this.total_line.Expenses.balance - this.total_line.Financing.balance;
          var profit_after_financing_expenses_by_year = this.total_line.Revenues.balance_by_year - this.total_line['Cost of Sales'].balance_by_year - this.total_line.Expenses.balance_by_year - this.total_line.Financing.balance_by_year;
          this.find("td:contains('Total by Financing')").closest('tr').after(_totalLineBalanceSheetLine(['', _specificDisplayMoney(profit_after_financing_expenses), _specificDisplayMoney(profit_after_financing_expenses_by_year)], '', '', false, 'Profit After Financing Expenses'));

          profit_period = this.total_line.Revenues.balance - this.total_line['Cost of Sales'].balance - this.total_line.Expenses.balance - this.total_line.Financing.balance - this.total_line['Other Revenues'].balance - this.total_line['Other Expenses'].balance;
          var profit_period_by_year = this.total_line.Revenues.balance_by_year - this.total_line['Cost of Sales'].balance_by_year - this.total_line.Expenses.balance_by_year - this.total_line.Financing.balance_by_year - this.total_line['Other Revenues'].balance_by_year - this.total_line['Other Expenses'].balance_by_year;
          return this.find("td:contains('Total by Other Expenses')").closest('tr').after(_totalLineBalanceSheetLine(['', _specificDisplayMoney(profit_period), _specificDisplayMoney(profit_period_by_year)], '', '', false, 'Profit Period'));
        case 'trial_balance':
          var total_debit = this.total_line.Assets.total_debit + this.total_line.Liabilities.total_debit + this.total_line.Equity.total_debit + this.total_line.Revenues.total_debit + this.total_line['Cost of Sales'].total_debit + this.total_line.Expenses.total_debit + this.total_line.Financing.total_debit + this.total_line['Other Revenues'].total_debit + this.total_line['Other Expenses'].total_debit;
          var total_credit = this.total_line.Assets.total_credit + this.total_line.Liabilities.total_credit + this.total_line.Equity.total_credit + this.total_line.Revenues.total_credit + this.total_line['Cost of Sales'].total_credit + this.total_line.Expenses.total_credit + this.total_line.Financing.total_credit + this.total_line['Other Revenues'].total_credit + this.total_line['Other Expenses'].total_credit;
          return this.find("td:contains('Total by Other Expenses')").closest('tr').after(_totalLineBalanceSheetLine(['', _specificDisplayMoney(total_debit), _specificDisplayMoney(total_credit), ''], '', '', false, 'Total'));
      }
    };

    const _addHtmlFilters = () => {
      this.html(
        `<div class="row" id="reportSearchBetween">${
          this.searchBetweenHtml
        }<div class="col-sm-5 pull-right"><div class="row">${
          this.reportByTypeHtml
        }${this.selectionLevelHtml
        }</div></div>`
                + '</div>'
                + '<div id="mainTable">'
                + `</div>${
                  this.dowloadReportButtonHtml}`,
      );
      this.find('.date-picker').pickadate(this.packadateOptions);
      this.find('.select-picker').select2();
      return _initializeEvents();
    };

    var _showSpinner = () => this.find(`.${this.settings.spinnerClass}`).removeClass('d-none');

    var _fetchData = () => {
      this.settings.data.start_at = _convertDate(this.settings.data.start_at);
      this.settings.data.end_at = _convertDate(this.settings.data.end_at);
      if (this.settings.path && this.settings.data.end_at && this.settings.data.start_at) {
        _showSpinner();
        return $.ajax({
          url: this.settings.path,
          dataType: 'JSON',
          data: this.settings.data,
          success: responce => _buildMainTable(responce),
        });
      }
    };

    _addHtmlFilters();
    _fetchData();
    return _buildMainTable();
  };
}(window.jQuery));
