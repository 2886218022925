($ => $.fn.ZipCoder = function (options) {
  let _pasteResults;
  this.settings = {
    state_el: this.data('stateEl'),
    city_el: this.data('cityEl'),
    wrapper_el: this.data('wrapperEl') || '.nested-fields',
    error_text: 'ERROR',
    error_timeout: 1200,
  };

  this.on('change', event => _fetchDataFromGoogle(event));
  this.on('keypress', (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      _fetchDataFromGoogle(event);
    }
    const chr = String.fromCharCode(event.which);
    if ('0123456789-'.indexOf(chr) < 0) {
      this.blockCalculation = true;
      return false;
    }
  });

  var _fetchDataFromGoogle = (event) => {
    const current_value = $(event.currentTarget).val();
    if (current_value && (current_value.length >= 5)) {
      return $.ajax({
        url: '//maps.googleapis.com/maps/api/geocode/json',
        type: 'GET',
        data: { address: current_value, sensor: true, language: 'en' },
        success: response => _separateResults(response.results, event),
      });
    }
    return _pasteResults('', event);
  };

  var _separateResults = (results, event) => {
    if (results.length >= 1) {
      const addr = {};
      let ii = 0;
      while (ii < results[0].address_components.length) {
        var city; var country; var formatted_address; var route; var state; var street; var
          zipcode;
        const street_number = (route = (street = (city = (state = (zipcode = (country = (formatted_address = '')))))));
        const types = results[0].address_components[ii].types.join(',');
        if (types === 'street_number') {
          addr.street_number = results[0].address_components[ii].long_name;
        }
        if ((types === 'route') || (types === 'point_of_interest,establishment')) {
          addr.route = results[0].address_components[ii].long_name;
        }
        if ((types === 'sublocality,political') || (types === 'locality,political') || (types === 'neighborhood,political')) {
          addr.city = (city === '') || (types === 'locality,political') ? results[0].address_components[ii].long_name : city;
        }
        if (types === 'administrative_area_level_1,political') {
          addr.state = results[0].address_components[ii].short_name;
        }
        if ((types === 'postal_code') || (types === 'postal_code_prefix,postal_code')) {
          addr.zipcode = results[0].address_components[ii].long_name;
        }
        if (types === 'country,political') {
          addr.country = results[0].address_components[ii].long_name;
        }
        ii++;
      }
      return _pasteResults(addr, event);
    }
    $(event.currentTarget).addClass('math-input-error').val(this.settings.error_text);
    return setTimeout((() => {
      return $(event.currentTarget).val('').removeClass('math-input-error').trigger('change');
    }
    ), this.settings.error_timeout);
  };

  return _pasteResults = (addr, event) => {
    $(event.currentTarget).closest(this.settings.wrapper_el).find(`[${this.settings.city_el}]`).val(addr != null ? addr.city : undefined);
    const state_id = $(event.currentTarget).closest(this.settings.wrapper_el).find(`[iso_2=\"${(addr != null ? addr.state : undefined)}\"]`).val();
    return $(event.currentTarget).closest(this.settings.wrapper_el).find(`[${this.settings.state_el}]`).val(state_id)
      .trigger('change');
  };
}

)(window.jQuery);
