import Application from 'application-namespace';
// import _range from 'lodash/range';
// import _each from 'lodash/each';
import _extend from 'lodash/extend';

Application.Classes.DataTable = class DataTable {
  constructor($currentTable) {
    this.$currentTable = $currentTable;
    this.initTable();
  }

  // checkingColumnsData() {
  //   this.columns = [];
  //   _each(_range(0, (this.$currentTable.data('columns-data') - 1)), i => this.columns.push({ data: `${i}` }));
  // }

  initTable() {
    this.table = this.$currentTable.DataTable({
      destroy: true,
      pageLength: this.$currentTable.data('pageLength'),
      serverSide: true,
      processing: true,
      bPaginate: this.$currentTable.data('bpaginate'),
      aoColumnDefs: [{ bSortable: false, aTargets: this.$currentTable.data('targets') }],
      order: [[this.$currentTable.data('defaultSort'), this.$currentTable.data('defaultSortDirection')]],
      searching: this.$currentTable.data('searching'),
      columns: this.$currentTable.data('columns'),
      drawCallback: this.afterRenderingTable,
      ajax: {
        url: this.$currentTable.data('source'),
        data: (d) => {
          _extend(d, this.$currentTable.data);
          const dtParams = this.$currentTable.data('dt_params');
          if (dtParams) {
            _extend(d, dtParams);
          }
        },
      },
    });
  }

  afterRenderingTable() {
    Application.app.initializePlugins(this.$currentTable);
  }
};
