import React, { useState, useEffect } from 'react';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import { useParams, Link, useHistory, useRouteMatch } from 'react-router-dom';
import FormCompanyRetirementSettingWrapper from '../FormCompanyRetirementSetting';
import { Switch, Route } from 'react-router-dom';
import CompanyRetirementSettingLinesList from '../CompanyRetirementSettingLinesList';
import FormCompanyRetirementSettingLineWrapper from '../FormCompanyRetirementSettingLine';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import findIndex from 'lodash/findIndex';

const FormStepper = () => {
  const match = useRouteMatch();
  const history = useHistory();
  let params = useParams();

  const [activeStep, setActiveStep] = useState(0);
  const steps = [
    {
      label: params.companyRetirementSettingId ? 'Edit company settings' : 'New company settings',
      path: `/company_retirement_settings/${params.companyRetirementSettingId}/edit`
    },
    {
      label: 'Configure math',
      path: `/company_retirement_settings/${params.companyRetirementSettingId}/company_retirement_setting_lines`
    }
  ];

  useEffect(() => {
    setActiveStep(
      findIndex(steps, (step) => (step.path === match.url))
    )
  }, [match]);

  const getStepContent = () => {
    return (
      <Switch>
        <Route
          path="/company_retirement_settings/new"
        >
          <FormCompanyRetirementSettingWrapper
            stepButtons={stepRetirementSettingButtons}
            goNextStep={(companyRetirementSettingId) =>
              goToLinesForm(companyRetirementSettingId)
            }
          />
        </Route>
        <Route
          path="/company_retirement_settings/:companyRetirementSettingId/edit"
        >
          <FormCompanyRetirementSettingWrapper
            stepButtons={stepRetirementSettingButtons}
            goNextStep={(companyRetirementSettingId) =>
              goToLinesForm(companyRetirementSettingId)
            }
          />
        </Route>

        <Route
          path="/company_retirement_settings/:companyRetirementSettingId/company_retirement_setting_lines/:companyRetirementSettingLineId/edit">
          <FormCompanyRetirementSettingLineWrapper
            stepButtons={stepRetirementSettingLineButtons}
            goNextStep={() =>
              history.push(`/company_retirement_settings/${params.companyRetirementSettingId}/company_retirement_setting_lines`)
            }
          />
        </Route>
        <Route path="/company_retirement_settings/:companyRetirementSettingId/company_retirement_setting_lines/new">
          <FormCompanyRetirementSettingLineWrapper
            stepButtons={stepRetirementSettingLineButtons}
            goNextStep={() =>
              history.push(`/company_retirement_settings/${params.companyRetirementSettingId}/company_retirement_setting_lines`)
            }
          />
        </Route>

        <Route
          path="/company_retirement_settings/:companyRetirementSettingId/company_retirement_setting_lines"
          component={CompanyRetirementSettingLinesList}
        />
      </Switch>
    );
  };

  const goToLinesForm = (companyRetirementSettingId) => {
    history.push(`/company_retirement_settings/${companyRetirementSettingId}/company_retirement_setting_lines`);
  };

  const stepRetirementSettingButtons = [
    <>
      <Link
        to={'/company_retirement_settings'}
        className="btn btn-outline-info btn-block mr-2"
      >
        Go to list
      </Link>
    </>
  ];

  const stepRetirementSettingLineButtons = [
    <>
      <Link
        to={`/company_retirement_settings/${params.companyRetirementSettingId}/company_retirement_setting_lines`}
        className="btn btn-outline-info btn-block mr-2"
      >
        Back
      </Link>
    </>
  ];

  return (
    <>
      <Stepper nonLinear activeStep={activeStep}>
        {
          map(steps, (step, index) => {
            return (
              <Step key={step.label}>
                <StepButton
                  disabled={isEmpty(params.companyRetirementSettingId)}
                  onClick={() => history.push(step.path)}
                >
                  {step.label}
                </StepButton>
              </Step>
            );
          })
        }
      </Stepper>
      <div>
        {getStepContent()}
      </div>
    </>
  );
};

export default FormStepper;
