import React from 'react';
import { Field } from 'redux-form';
import Button from 'react-bootstrap/Button';
import { connect } from 'react-redux';

let GeneralPartnerPhoneFields = ({ partner_phones_attribute, index, fields }) => {

  return (
    <tr key={index}>
      <td className="d-none">
        <Field
          name={`${partner_phones_attribute}.id`}
          type="text"
          component="input"
        />
      </td>
      <td>
        <Field
          name={`${partner_phones_attribute}.phone_number`}
          component="input"
          type="text"
        />
      </td>
      <td>
        <Field
          name={`${partner_phones_attribute}.phone_description`}
          component="input"
          type="text"
        />
      </td>
      <td>
        <Button variant="danger" block size="sm" onClick={() => fields.remove(index)}>
          Remove
        </Button>
      </td>
    </tr>
  );
};

const mapStateToProps = (state) => {
  const { form: { businessPartnerForm: businessPartner } } = state;
  return {
    businessPartner
  };
};

export default connect(mapStateToProps)(GeneralPartnerPhoneFields);

