import Application from 'application-namespace';
import $ from 'jquery';


Application.Classes.RecipientProductionForm = class RecipientProductionForm extends Application.Classes.CoreBase {
  constructor($scope) {
    super();
    this.$scope = $scope;
    this.$scope.find('table').on('cocoon:after-insert', (e, insertedItem) => {
      this.initializePluginsAfterInsert(insertedItem);
      this.initializeVariables();
    });
    this.initializeVariables();
  }

  initializeVariables() {
    this.$scope.find('[data-production-order-dropdown]').on('change', el => this.constructor.pasteAttributes(el));
    this.$scope.find('[data-production-order-dropdown]').trigger('change');
  }

  static pasteAttributes(element) {
    const selectedVariable = $(element.currentTarget).select2('data');
    const line = $(element.currentTarget).closest('tr');
    if (selectedVariable[0].id) {
      line.find('[data-item-number]').html(selectedVariable[0].element.attributes.item_number.value);
      line.find('[data-item-description]').html(selectedVariable[0].element.attributes.item_description.value);
      line.find('[data-order-type]').html(selectedVariable[0].element.attributes.order_type.value);
      line.find('[data-planned-quantity]').html(selectedVariable[0].element.attributes.planned_quantity.value);
    } else {
      line.find('[data-item-number], [data-item-description], [data-order-type], [data-planned-quantity]').html(0);
    }
  }
};
