import Application from 'application-namespace';
import $ from 'jquery';
import _get from 'lodash/get';
import _each from 'lodash/each';
import _range from 'lodash/range';

Application.Classes.ServiceCallForm = class ServiceCallForm extends Application.Classes.CoreBase {
  constructor($scope) {
    super();
    this.$scope = $scope;
    this.$businessPartnerSelect = $('#service_call_business_partner_id');
    this.$customerEquipmentCardSelect = $('#service_call_customer_equipment_card_id');
    this.$contactPerson = this.$scope.find('[data-contact-person]');

    this.$expensesDropdowns = this.$scope.find('[data-item-expenses-dropdown]').on('change', element => this.constructor.pasteAttributes(element));

    this.$businessPartnerSelect.on('change', () => {
      if (this.$businessPartnerSelect.val()) {
        this.fetchBusinessPartner(this.$businessPartnerSelect);
      } else {
        $('#name, #telephone_no, #contact_person').val('');
      }
    });
    // @$contactPerson.on 'change', (event)  => @$scope.find('#telephone_no').val($(event.currentTarget).select2('data')[0].element.attributes?.number?.value || '')
    // this.$contactPerson.on('change', event  => this.$scope.find('#telephone_no').val( __guard__( __guard__($(event.currentTarget).select2('data')[0].element.attributes, x1 => x1.number), x => x.value) || ''));
    this.$contactPerson.on('change', event => this.$scope.find('#telephone_no').val(_get($(event.currentTarget).select2('data')[0], 'element.attributes.number.value', '')));
    this.$customerEquipmentCardSelect.on('change', () => this.fetchCustomerEquipmentCard(this.$customerEquipmentCardSelect));

    this.$scope.find('table').on('cocoon:after-insert', (e, insertedItem) => {
      this.initializePluginsAfterInsert(insertedItem);
      this.$expensesDropdowns = this.$scope.find('[data-item-expenses-dropdown]').on('change', element => this.constructor.pasteAttributes(element));
    });

    this.$expensesDropdowns.trigger('change');
  }

  static pasteAttributes(element) {
    const selectedVariable = $(element.currentTarget).select2('data');
    const line = $(element.currentTarget).closest('tr');
    if (selectedVariable[0].id) {
      line.find('[data-item-description]').html(selectedVariable[0].element.attributes.item_description.value);
    } else {
      line.find('[data-item-description]').html('');
    }
  }

  fetchBusinessPartner($businessPartnerSelect) {
    this.$businessPartnerSelect = $businessPartnerSelect;
    $.ajax({
      url: Routes.business_partner_path(this.$businessPartnerSelect.val()),
      dataType: 'JSON',
      data: { type: 'service_call' },
    }).then((response) => {
      this.pasteCecsOptions(this.$customerEquipmentCardSelect, response.customer_equipment_cards);
      this.pasteBpOptions(this.$businessPartnerSelect, response);
      this.fetchContactPerson(this.$businessPartnerSelect.val());
      this.$customerEquipmentCardSelect.trigger('change');
    }).catch((error) => {
      console.error('Error: ', error);
    });
  }

  fetchContactPerson(businessPartnerId) {
    if (businessPartnerId) {
      $.ajax({
        url: Routes.business_partner_contact_people_path({ business_partner_id: businessPartnerId }),
        dataType: 'JSON',
      }).then((response) => {
        this.pasteCpOptions(this.$contactPerson, response);
      }).catch((error) => {
        console.error('Error: ', error);
      });
    } else {
      console.warn(`Application.Classes.ServiceCallForm#fetchContactPerson bp_id - ${businessPartnerId}`);
    }
  }

  pasteCpOptions($el, optionsJson) {
    $el.empty();
    $el.html('<option value="" selected="selected"></option>');
    _each(_range(0, (optionsJson.length - 1)), (k) => {
      const el = optionsJson[k];
      $el.append(`<option value="${el.id}" number="${el.phone_1}">${`${el.contact_id}`}</option>`);
    });
    $el.trigger('change');
  }

  fetchCustomerEquipmentCard($cec) {
    if ($cec.val()) {
      $.ajax({
        url: Routes.customer_equipment_card_path($cec.val()),
        dataType: 'JSON',
      }).then((response) => {
        this.pasteCecOptions($cec.val(), response);
      }).catch((error) => {
        console.error('Error: ', error);
      });
    }
    this.clearScData();
  }

  pasteBpOptions(object, result) {
    return this.pasteInInput('#name', result.name);
  }

  pasteCecsOptions($el, optionsJson) {
    const activeArray = [];
    $el.empty();
    $el.html('<option value="" selected="selected"></option>');
    _each(_range(0, (optionsJson.length - 1)), (k) => {
      const el = optionsJson[k];
      if (el.status === 'active') {
        activeArray.push(el);
        $el.append(`<option value="${el.id}">${el.driveway_gate_name}</option>`);
      }
    });
    $el.trigger('change');
    if (activeArray.length === 1) {
      $el.val(activeArray[0].id);
    }
    $el.trigger('change');
  }

  pasteCecOptions(object, result) {
    this.pasteInInput('#serial_cec_number', result.serial_number);
    if (result.cec_type) {
      this.pasteInInput('#item_type', result.cec_type.name);
    }
    if (result.current_service_contract) {
      this.pasteInInput('#service_contract', result.current_service_contract.contract_number);
      this.pasteInInput('#end_date', result.current_service_contract.end_date);
      this.pasteInInput('#service_call_resolution_by', result.resolution_by);
      this.pasteInInput('#service_call_response_by', result.response_by);
    } else {
      this.clearScData();
    }
    if (result.current_annual_service_dates) {
      $('#service_call_annual_service_date_id').prop('disabled', false);
      return this.pasteToList('#service_call_annual_service_date_id', result.current_annual_service_dates, 'remarks');
    }
    $('#service_call_annual_service_date_id').prop('disabled', true);
    return this.pasteToList('#service_call_annual_service_date_id', [], '');
  }

  clearScData() {
    this.pasteInInput('#service_contract', 'No active contract');
    this.pasteInInput('#end_date', 'No active contract');
    this.pasteInInput('#service_call_response_by', 'No active contract');
    this.pasteInInput('#service_call_resolution_by', 'No active contract');
  }

  // pasteServiceContractOptions(object, result) {
  //   this.pasteInInput('#end_date', result.end_date);
  //   if ($('#response_by')) {
  //     if (result.template) {
  //       this.pasteInInput('#response_by', result.template.response_by);
  //       return this.pasteInInput('#resolution_by', result.template.resolution_by);
  //     }
  //   }
  // }
};
