import React from 'react';
import { Field } from 'redux-form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { connect } from 'react-redux';
import RenderField from '../../../../components/ReduxForm/RenderField';

let RemarkForm = () => {

  return (
    <Row>
      <Col md={12}>
        <Field
          name='remarks'
          as="textarea"
          component={RenderField}
          label={''}
        />
      </Col>
    </Row>
  );
};

const mapStateToProps = (state) => {
  const { form: { businessPartnerForm: businessPartner } } = state;
  return {
    businessPartner
  };
};

export default connect(mapStateToProps)(RemarkForm);
