import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useHistory, Link } from 'react-router-dom'
import delay from 'lodash/delay';
import isEmpty from 'lodash/isEmpty';
import FormEmergencyPhoneSetting from './form';
import ReduxFormSubmissionError from '../../../../components/ReduxFormSubmissionError';
import { useSnackbar } from 'notistack';

const FormEmergencyPhoneSettingWrapper = () => {
  let params = useParams();
  const history = useHistory();
  const {enqueueSnackbar} = useSnackbar();
  const [loadPath, setLoadPath] = useState(null);
  const [emergencyPhoneSetting, setEmergencyPhoneSetting] = useState({});

  useEffect(() => {
    if (params.emergencyPhoneSettingId) {
      setLoadPath(Routes.edit_api_web_v1_emergency_phone_setting_path(params.emergencyPhoneSettingId));
    } else {
      setLoadPath(Routes.new_api_web_v1_emergency_phone_setting_path());
    }
  }, [params]);

  useEffect(() => {
    if (loadPath) {
      loadEmergencyPhoneSetting()
        .then(response => {
          setEmergencyPhoneSetting(response.data.emergency_phone_setting);
        })
        .catch(error => {
          console.log('FormEmergencyPhoneSettingWrapper.loadEmergencyPhoneSetting', error.response);
        });
    }
  }, [loadPath]);

  const loadEmergencyPhoneSetting = () => {
    return axios({ url: loadPath });
  };

  const handleSubmit = (values) => {
    return axios({
      method: params.emergencyPhoneSettingId ? 'PUT' : 'POST',
      url: params.emergencyPhoneSettingId ?
        Routes.api_web_v1_emergency_phone_setting_path(params.emergencyPhoneSettingId) :
        Routes.api_web_v1_emergency_phone_settings_path(),
      data: { emergency_phone_setting: values }
    })
      .then(response => {
        delay(() => {
          history.push('/emergency_phone_settings')
        }, 1000);
        enqueueSnackbar('Emergency phone setting was saved.', { variant: 'success' })
      })
      .catch(error => {
        ReduxFormSubmissionError(error.response.data.errors, values);
      });
  };

  return (
    <>
      {!isEmpty(emergencyPhoneSetting) &&
      <FormEmergencyPhoneSetting
        emergencyPhoneSetting={emergencyPhoneSetting}
        initialValues={emergencyPhoneSetting}
        onSubmit={handleSubmit}
        stepButtons={
          <>
            <Link
              to={'/emergency_phone_settings'}
              className="btn btn-outline-info btn-block mr-2"
            >
              Back
            </Link>
          </>
        }
      />
      }
    </>
  );
};

export default FormEmergencyPhoneSettingWrapper;
