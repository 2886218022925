import Application from 'application-namespace';
import $ from 'jquery';

Application.Classes.EmployeeWorkDayForm = class EmployeeWorkDayForm {
  constructor($scope) {
    this.$scope = $scope;
    this.$dayTypeSelect = this.$scope.find('[data-day-type-select]');
    this.$paymentTypeSelect = this.$scope.find('[data-payment-type-select]');
    this.$dayIsPaidInput = this.$scope.find('[data-is-paid-input]');
    this.$workDayInput = this.$scope.find('[data-work-day-input]');
    this.path = this.$scope.data('currentUrl');
    this.$noteField = this.$scope.find('[name="employee_work_day[note]"]');
    this.$reportField = this.$scope.find('[name="employee_work_day[payroll_report_id]"]');

    this.$paymentTypeSelect.on('change', event => this.replaceForm(event));
    this.$dayTypeSelect.on('change', event => this.replaceForm(event));
    this.$dayIsPaidInput.on('change', event => this.replaceForm(event));
  }

  replaceForm(event) {
    const currentTargetName = $(event.currentTarget).attr('name').split('[').pop().split(']')[0];
    $.ajax({
      method: 'GET',
      url: this.path,
      dataType: 'SCRIPT',
      data: {
        payment_type: this.$paymentTypeSelect.val(),
        day_type: this.$dayTypeSelect.val(),
        work_day_at: this.$workDayInput.val(),
        is_paid_for_absence_duration: this.$dayIsPaidInput.prop('checked'),
        note: this.$noteField.val(),
        payroll_report_id: this.$reportField.val(),
        recommended_date: this.$workDayInput.val(),
        current_target: currentTargetName
      },
    });
  }
};
