import { produce } from 'immer';
import { get } from 'lodash';

import {
  PARSE_BUSINESS_PARTNER_DATA,
  STORE_TECH_SALES_ORDERS
} from './actionTypes'

const parseBusinessPartnerData = (draft, action) => {
  const { responses } = action.payload;

  draft.contactPeople = get(responses[0].data, 'contact_people', []);
  draft.partnerBillingAddresses = get(responses[1].data, 'partner_billing_addresses', []);
  draft.partnerShippingAddresses = get(responses[2].data, 'partner_shipping_addresses', []);
  draft.customerEquipmentCards = get(responses[3].data, 'customer_equipment_cards', []);
  return draft
};

const storeTechSalesOrders = (draft, action) => {
  draft.techSalesOrders = get(action.payload.response.data, 'tech_sales_orders', [])
};

const documentStoreReducer = produce((draft = {}, action, baseState) => {
  switch (action.type) {
    case PARSE_BUSINESS_PARTNER_DATA:
      return parseBusinessPartnerData(draft, action);
    case STORE_TECH_SALES_ORDERS:
      return storeTechSalesOrders(draft, action);
    default:
      return draft;
  }
});

export default documentStoreReducer;
