import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from '../../redux/store';
import ProjectPayrollSetup from './ProjectPayrollSetup';
import { SnackbarProvider } from 'notistack';

const ProjectPayrollSetupWrapper = ({}) => {

  return (
    <Provider store={store}>
      <BrowserRouter basename="/project_payroll_setup">
        <SnackbarProvider>
          <ProjectPayrollSetup/>
        </SnackbarProvider>
      </BrowserRouter>
    </Provider>
  );

};

export default ProjectPayrollSetupWrapper;
