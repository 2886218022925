import React, { Component } from 'react';
import { Field } from 'redux-form';
import { camelCase, capitalize, lowerCase } from 'lodash';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';

class MoneyInput extends Component {

  renderMoney = (field) => {
    const { type, disabled } = this.props;
    field.input.value = field.input.value || 0;
    return (
      <>
        <Form.Control
          {...field.input}
          type={type || 'number'}
          disabled={disabled || false}
          className={field.meta.error ? 'is-invalid' : null}
        />

        <Form.Control.Feedback type="invalid">
          {field.meta.error}
        </Form.Control.Feedback>
      </>
    );
  };

  render() {
    const {
      name, label, prepend, onChange
    } = this.props;

    return (
      <>
        {label && <label>{label}</label>}
        {label === undefined && <label>{capitalize(lowerCase(name))}</label>}
        {prepend &&
        <InputGroup>
          <InputGroup.Prepend>
            <InputGroup.Text id={`inputGroup${camelCase(name)}`}>{prepend}</InputGroup.Text>
          </InputGroup.Prepend>
          <Field
            name={name}
            component={this.renderMoney}
            format={(value, name) => {
              return value / 100;
            }}
            onChange={onChange}
            parse={(value, name) => {
              return value * 100;
            }}
            aria-describedby={`inputGroup${camelCase(name)}`}
          />
        </InputGroup>
        }
        {!prepend &&
        <Field
          name={name}
          component={this.renderMoney}
          format={(value, name) => {
            return value / 100;
          }}
          onChange={onChange}
          parse={(value, name) => {
            return value * 100;
          }}
        />
        }
      </>
    );
  }
}

export default MoneyInput;

