import React, { Component } from 'react';
import CommonDocumentForm from './CommonDocumentForm';
import { Provider } from 'react-redux';
import axios from 'axios';
import DocumentRoutes from './Services/DocumentGetRoutes';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import { forEach, includes, keys, reduce, last, times } from 'lodash';
import { produce } from 'immer';
import { SubmissionError } from 'redux-form';
import DocumentSendRoutes from './Services/DocumentSendRoutes';
import SchemesOfDocuments from './Services/SchemesOfDocuments';
import store from '../../../redux/store';

class DocumentFormContainer extends Component {

  state = {
    document: null,
    isLoading: true
  };

  componentDidMount() {
    this.fetchDocumentObject();
  }

  submitDocument = (formValues) => {
    const permittedValues = this.permittedValues(formValues);
    const requestRoute = DocumentSendRoutes(formValues);

    return axios({
      method: requestRoute.method,
      url: requestRoute.path,
      data: {
        document: permittedValues,
        document_class: formValues.class_name,
        document_id: formValues.id
      }
    })
      .then(response => {
        this.setState({ document: this.decorateDocument(response.data.document) });
      })
      .catch(error => {
        console.log('DocumentError.response', error.response);
        this.showValidationErrors(error.response.data.errors, formValues);
      });
  };

  showValidationErrors = (errors, formValues) => {
    const documentScheme = SchemesOfDocuments(formValues.class_name);
    const errorObject = reduce(errors, (result, value, key) => {
      if (key === 'document_errors') {
        forEach(value, (documentError, errorKey) => {
          if (includes(keys(documentScheme), `${errorKey}_id`)) {
            result[`${errorKey}_id`] = documentError[0];
          } else {
            result[errorKey] = documentError[0];
          }
        });
      }
      if (key === 'position_errors') {
        result.positions = [];
        forEach(value, (positionLine, positionIndex) => {
          result.positions.push({});
          forEach(positionLine, (value, key) => {
            let positionField = key;
            if (includes(keys(documentScheme.positions), `${positionField}_id`)) {
              positionField = `${positionField}_id`;
            }
            return result.positions[positionIndex][positionField] = last(value);
          });
        });
      }
      result._error = 'Document is invalid.';
      return result;
    }, {});
    throw new SubmissionError(errorObject);
  };

  permittedValues = (formValues) => {
    const documentScheme = SchemesOfDocuments(formValues.class_name);
    const documentSchemeKeys = keys(documentScheme);
    const positionsKeys = keys(documentScheme.positions);

    return produce(formValues, draft => {
      forEach(draft, (documentValue, documentKey) => {
        if (documentKey === 'positions') {
          forEach(formValues.positions, (positionLine, positionIndex) => {
            forEach(positionLine, (positionValue, positionKey) => {
              if (!includes(positionsKeys, positionKey)) {
                delete (draft.positions[positionIndex][positionKey]);
              }
            });
          });
        } else {
          if (!includes(documentSchemeKeys, documentKey)) {
            delete (draft[documentKey]);
          }
        }
      });
      draft[`${documentScheme.positions_key}_attributes`] = draft.positions;
      delete (draft.positions);
      draft[documentScheme.attachments_key] = draft.attachments;
      delete (draft.attachments);
    });
  };

  fetchDocumentObject = () => {
    axios({
      method: 'GET',
      url: DocumentRoutes(),
    })
      .then(response => {
        this.setState({
          document: this.decorateDocument(response.data.document),
          isLoading: false
        });
      })
      .catch(error => {
        console.log(error);
      });
  };

  decorateDocument = (document) => {
    const positionCount = document.id ? 1 : 3;
    return produce(document, draft => {
      draft.position_types = {
        item: 1,
        service: 2
      };
      times(positionCount, () => {
        draft.positions.push({
          position_type: 1,
          quantity: 1,
          item_id: null,
          general_ledger_account_id: null,
          total_cents: 0
        });
      });
    });
  };

  render() {
    const { isLoading, document } = this.state;

    return (
      <>
        {
          isLoading &&
          <Container>
            <Row className="justify-content-md-center">
              <Spinner animation="border" role="status"/>
            </Row>
          </Container>
        }
        {
          !isLoading &&
          <Provider store={store}>
            <CommonDocumentForm initialValues={document} onSubmit={this.submitDocument}/>
          </Provider>
        }
      </>
    );
  }
}

export default DocumentFormContainer;
