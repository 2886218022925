import React from 'react';
import Button from 'react-bootstrap/Button';
import ContactPeopleEmailFields from './ContactPeopleEmailFields';
import map from 'lodash/map';

const ContactPeopleEmail = ({
  fields
}) => {

  const addField = () => fields.push({ id: null });

  return (
    <tbody>
    {map(fields, (contactPersonEmail, index) =>
      <ContactPeopleEmailFields
        key={`contact_person_emails_attributes-${index}`}
        contactPersonEmail={contactPersonEmail}
        index={index}
        fields={fields}
      />
    )}
    <tr>
      <td colSpan="3" className="text-right">
        <Button variant="success" size="sm" onClick={addField}>
          New
        </Button>
      </td>
    </tr>
    </tbody>
  );

};

export default ContactPeopleEmail;
