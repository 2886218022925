export default function (formValues) {
  let method = 'POST';
  let path = Routes.api_web_v1_common_documents_path();
  if (formValues.id) {
    method = 'PUT';
    path = Routes.api_web_v1_common_document_path(formValues.id);
  }

  return { path, method }
}
