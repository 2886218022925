import Application from 'application-namespace';
import $ from 'jquery';

Application.Classes.OpenInvoicesTable = class OpenInvoicesTable extends Application.Classes.CoreBase {
  constructor($scope) {
    super();
    this.$scope = $scope;
    this.$scope.find('.filter_checkbox').on('change', (el) => {
      if ($(el.currentTarget).is(':checked')) {
        this.$scope.find('.closed-document').closest('tr').addClass('d-none');
        this.$scope.find('.amount-result').closest('tr').addClass('d-none');
      } else {
        this.$scope.find('.closed-document').closest('tr').removeClass('d-none');
        this.$scope.find('.amount-result').closest('tr').removeClass('d-none');
      }
    });
  }
};
