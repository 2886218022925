import axios from 'axios';
import { PARSE_ACTIVE_EMPLOYEES, PARSE_EMPLOYEE_WORK_DAYS } from './actionTypes';

export const parseActiveEmployees = () => {
  return (dispatch) => {
    return (
      axios ({ url: Routes.employees_active_api_web_v1_employees_path() })
        .then(response => {
          dispatch({
            type: PARSE_ACTIVE_EMPLOYEES,
            payload: { response }
          })
        })
        .catch(error => {
          console.log(error);
        })
    )
  }
};

export const parseEmployeeWorkDays = (params) => {
  return (dispatch) => {
    return (
      axios({ url: Routes.calendar_api_web_v1_employee_work_days_path(params) })
        .then(response => {
          dispatch({
            type: PARSE_EMPLOYEE_WORK_DAYS,
            payload: { response }
          });
        })
        .catch(error => {
          console.log(error);
        })
    );
  };
};
