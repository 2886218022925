import Application from 'application-namespace';
import $ from 'jquery';

Application.Classes.Calendar = class Calendar {
  constructor() {
    $('#calendar').fullCalendar({
      editable: true,
      firstDay: 1,
      selectable: true,
      selectHelper: true,
      defaultView: 'year',
      allDaySlot: false,
      height: 600,
      eventBackgroundColor: '#d3d3d3',
      slotMinutes: 30,
      dragOpacity: '0.5',
      columnFormat: {
        month: 'dddd',
        week: 'dddd d',
        day: 'ddd',
      },
      header: {
        left: 'prev,next today',
        center: 'title',
        right: 'year,month,agendaWeek,agendaDay',
      },
      eventSources: [{
        url: '/events/calendar',
      }],

      eventDataTransform: (eventData) => {
        const resultData = eventData;
        const fullName = `${eventData.employee.first_name} ${eventData.employee.last_name}`;
        const eventType = eventData.event_type.name;
        resultData.title = `${fullName}/${eventType}`;
        resultData.start = eventData.start_at;
        resultData.end = eventData.end_at;
        return resultData;
      },

      select: (start, end) => {
        if (gon.currentUser.role === 'admin' || gon.currentUser.role === 'super_admin') {
          BootstrapDialog.show({
            title: 'Create event',
            cssClass: 'modal-calendar',
            draggable: false,
            onshown: (dialog) => {
              const $dialog = dialog.getModalBody();
              $dialog.load('/events/new', () => {
                $('#event_start_at').val(start.format('MM/DD/YYYY'));
                $('#event_end_at').val(end.format('MM/DD/YYYY'));
                Application.app.initializePlugins($('form'));
              });
            },
          },
          false);
        }
      },

      eventClick: (calEvent) => {
        if (gon.currentUser.role === 'admin' || gon.currentUser.role === 'super_admin') {
          BootstrapDialog.show({
            title: 'Update event',
            cssClass: 'modal-calendar',
            draggable: false,
            onshown: (dialog) => {
              const $dialog = dialog.getModalBody();
              $dialog.load(`/events/${calEvent.id}/edit`, () => Application.app.initializePlugins($('form')));
            },
          });
        }
      },

      eventAfterRender: (event, element) => {
        if (event.status === 'pending') {
          element.css('border', '1px solid #00FF00');
        } else if (event.status === 'inactive') {
          element.css('border', '1px solid #FF0000');
        } else {
          element.css('background-color', `#${event.event_type.color_event}`);
          element.css('border-color', `#${event.event_type.color_event}`);
        }
      },
    });
  }
};
