import { produce } from 'immer';
import { BUSINESS_PARTNER_SELECT_COLLECTION } from './actionTypes';

const parseBusinessPartnerData = (draft, action) => {
  const { responses } = action.payload;
  draft.groupsCollections = responses[0].data;
  draft.servicePartnerTypeCollections = responses[1].data;
  draft.businessPartnerTypeCollections = responses[2].data;
  draft.employeesCollections = responses[3].data.employees;
  draft.personPositionsCollection = responses[4].data.person_positions;
  draft.statesCollection = responses[5].data.states;
  // draft.taxStatusCollection = responses[6].data.tax_statuses;
  draft.salesTaxCodesCollection = responses[6].data.sales_tax_codes;
  return draft;
};

const businessPartnerStoreReducer = produce((draft = {}, action, baseState) => {
  switch (action.type) {
    case BUSINESS_PARTNER_SELECT_COLLECTION:
      return parseBusinessPartnerData(draft, action);
    default:
      return draft;
  }
});

export default businessPartnerStoreReducer;
