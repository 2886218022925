import Application from 'application-namespace';
import $ from 'jquery';

Application.Classes.PayrollSalaryChangeForm = class PayrollSalaryChangeForm {
  constructor($scope) {
    this.$scope = $scope;
    this.$totalInput = this.$scope.find('[data-total-field]');
    this.$addNewLineButton = this.$scope.find('.add_fields');
    this.empoyeeId = this.$scope.data('employeeId');

    this.$scope.on('cocoon:after-insert', (e, insertedItem) => {
      Application.app.initializeSelectPicker(insertedItem);
      Application.app.initializeMathInput(insertedItem);
      this.initRecalculateTotal(insertedItem);
      this.markAsTouched(insertedItem);
    });

    this.$scope.find('table').on('cocoon:after-remove', () => this.recalculateTotal());

    this.$scope.find('.nested-fields').each((i, el) => {
      this.initRecalculateTotal($(el));
      this.markAsTouched($(el));
    });
  }

  initRecalculateTotal($line) {
    $line.find('[data-allow-clear]').on('change', () => this.initFunctions($line));
    $line.find('[data-amount-input]').on('change', () => this.initFunctions($line));
    $line.find('[data-state-type-input]').on('change', event => this.constructor.behaviorForStates($(event.currentTarget), $line));
    $line.find('[data-math-type-input]').on('change', () => this.getTaxType($line));
    $line.find('[data-payroll-salary-change-reason-input]').on('change', () => this.getTaxType($line));
    this.constructor.behaviorForStates($line.find('[data-state-type-input]'), $line);
  }

  initFunctions($line) {
    this.recalculateTotal();
    if (!$line.find('.payroll_report_payroll_salary_changes_is_touched').find('input').prop('checked')) {
      this.$addNewLineButton.trigger('click');
    }
  }

  recalculateTotal() {
    let total = 0;
    this.$scope.find('[data-amount-input]').each((i, el) => {
      const mathType = $(el).closest('tr').find('[data-allow-clear]').val();
      if (mathType === 'deduction') {
        total -= parseFloat($(el).val());
      } else if (mathType === 'additional') {
        total += parseFloat($(el).val());
      }
    });
    this.$totalInput.val(total.toFixed(2));
  }

  getTaxType($line, defaultFormData) {
    const formData = defaultFormData == null ? {} : defaultFormData;
    $line.find('input').serializeArray().forEach((data) => {
      formData[data.name.replace(new RegExp(']', 'g'), '').split('[').slice(-1)[0]] = data.value;
    });
    $line.find('select').serializeArray().forEach((data) => {
      formData[data.name.replace(new RegExp(']', 'g'), '').split('[').slice(-1)[0]] = data.value;
    });
    $.ajax({
      url: Routes.determine_tax_type_employee_payroll_salary_changes_path({ employee_id: this.empoyeeId }),
      dataType: 'JSON',
      type: 'POST',
      data: { payroll_salary_change: formData },
    }).then((response) => {
      $line.find('[data-tax-type-text]').val(response.tax_type_text);
      $line.find('[data-tax-type-off-input]').val(response.tax_type).trigger('change');
    }).catch((error) => {
      console.error('Error: ', error);
    });
  }

  markAsTouched($line) {
    const $isTouchedInput = $line.find('.payroll_report_payroll_salary_changes_is_touched').find('input');
    $line.find('textarea').on('change', () => $isTouchedInput.prop('checked', true));
    $line.find('input').on('change', () => $isTouchedInput.prop('checked', true));
    return $line.find('select').on('change', () => $isTouchedInput.prop('checked', true));
  }

  static behaviorForStates($object, $line) {
    if ($object.val() === 'custom') {
      $line.find('[data-state-input]').prop('disabled', false);
    } else {
      $line.find('[data-state-input]').prop('disabled', true);
    }
  }
};
