import React, { useState, useEffect } from 'react';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import { useParams, Link, useHistory, useRouteMatch } from 'react-router-dom';
import { Switch, Route } from 'react-router-dom';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import findIndex from 'lodash/findIndex';
import HealthBenefitSettingsList from '../HealthBenefitSettingsList';
import FormHealthBenefitSettingWrapper from '../FormHealthBenefitSetting';
import FormHealthBenefitSettingLineWrapper from '../FormHealthBenefitSettingLine';
import HealthBenefitSettingLinesList from '../HealthBenefitSettingLinesList';

const FormStepper = () => {
  const match = useRouteMatch();
  const history = useHistory();
  let params = useParams();

  const [activeStep, setActiveStep] = useState(0);
  const steps = [
    {
      label: params.healthBenefitSettingId ? 'Edit health benefit settings' : 'New health benefit settings',
      path: `/health_benefit_settings/${params.healthBenefitSettingId}/edit`
    },
    {
      label: 'Configure math',
      path: `/health_benefit_settings/${params.healthBenefitSettingId}/health_benefit_setting_lines`
    }
  ];

  useEffect(() => {
    setActiveStep(
      findIndex(steps, (step) => (step.path === match.url))
    )
  }, [match]);

  const getStepContent = () => {
    return (
      <Switch>
        <Route
          path="/health_benefit_settings/new"
        >
          <FormHealthBenefitSettingWrapper
            stepButtons={stepHealthRetirementButtons}
            goNextStep={(healthBenefitSettingId) =>
              goToLinesForm(healthBenefitSettingId)
            }
          />
        </Route>
        <Route
          path="/health_benefit_settings/:healthBenefitSettingId/edit"
        >
          <FormHealthBenefitSettingWrapper
            stepButtons={stepHealthRetirementButtons}
            goNextStep={(healthBenefitSettingId) =>
              goToLinesForm(healthBenefitSettingId)
            }
          />
        </Route>

        <Route
          path="/health_benefit_settings/:healthBenefitSettingId/health_benefit_setting_lines/:healthBenefitSettingLineId/edit">
          <FormHealthBenefitSettingLineWrapper
            stepButtons={stepRetirementSettingLineButtons}
            goNextStep={() =>
              history.push(`/health_benefit_settings/${params.healthBenefitSettingId}/health_benefit_setting_lines`)
            }
          />
        </Route>
        <Route path="/health_benefit_settings/:healthBenefitSettingId/health_benefit_setting_lines/new">
          <FormHealthBenefitSettingLineWrapper
            stepButtons={stepRetirementSettingLineButtons}
            goNextStep={() =>
              history.push(`/health_benefit_settings/${params.healthBenefitSettingId}/health_benefit_setting_lines`)
            }
          />
        </Route>

        <Route
          path="/health_benefit_settings/:healthBenefitSettingId/health_benefit_setting_lines"
          component={HealthBenefitSettingLinesList}
        />
      </Switch>
    );
  };

  const goToLinesForm = (healthBenefitSettingId) => {
    history.push(`/health_benefit_settings/${healthBenefitSettingId}/health_benefit_setting_lines`);
  };

  const stepHealthRetirementButtons = [
    <>
      <Link
        to={'/health_benefit_settings'}
        className="btn btn-outline-info btn-block mr-2"
      >
        Go to list
      </Link>
    </>
  ];

  const stepRetirementSettingLineButtons = [
    <>
      <Link
        to={`/health_benefit_settings/${params.healthBenefitSettingId}/health_benefit_setting_lines`}
        className="btn btn-outline-info btn-block mr-2"
      >
        Back
      </Link>
    </>
  ];

  return (
    <>
      <Stepper nonLinear activeStep={activeStep}>
        {
          map(steps, (step, index) => {
            return (
              <Step key={step.label}>
                <StepButton
                  disabled={isEmpty(params.healthBenefitSettingId)}
                  onClick={() => history.push(step.path)}
                >
                  {step.label}
                </StepButton>
              </Step>
            );
          })
        }
      </Stepper>
      <div>
        {getStepContent()}
      </div>
    </>
  );
};

export default FormStepper;
