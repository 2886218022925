import React, { useState, useEffect } from 'react';
import FormCompanyRetirementSetting from './form';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import delay from 'lodash/delay';
import ReduxFormSubmissionError from '../../../../components/ReduxFormSubmissionError';

const FormHealthBenefitSettingWrapper = ({ stepButtons = [], goNextStep = () => {} }) => {

  let params = useParams();
  const [loadPath, setLoadPath] = useState(null);
  const [healthBenefitSetting, setHealthBenefitSetting] = useState({});

  const handleSubmit = (values) => {
    const submitPath = params.healthBenefitSettingId ?
      Routes.api_web_v1_health_benefit_setting_path(params.healthBenefitSettingId) :
      Routes.api_web_v1_health_benefit_settings_path();
    const submitMethod = params.healthBenefitSettingId ? 'PUT' : 'POST';

    return axios({
      method: submitMethod,
      url: submitPath,
      data: { health_benefit_setting: values }
    })
      .then(response => {
        delay(() => {
          goNextStep(response.data.health_benefit_setting.id);
        }, 2000);
      })
      .catch(error => {
        ReduxFormSubmissionError(error.response.data.errors, values);
      });
  };

  useEffect(() => {
    if (params.healthBenefitSettingId) {
      setLoadPath(Routes.edit_api_web_v1_health_benefit_setting_path(params.healthBenefitSettingId));
    } else {
      setLoadPath(Routes.new_api_web_v1_health_benefit_setting_path());
    }
  }, [params]);

  useEffect(() => {
    if (loadPath) {
      loadHealthRetirementSetting()
        .then(response => {
          setHealthBenefitSetting(response.data.health_benefit_setting);
        })
        .catch(error => {
          console.log('FormHealthBenefitSettingWrapper.loadHealthRetirementSetting', error.response);
        });
    }
  }, [loadPath]);

  const loadHealthRetirementSetting = () => {
    return axios({ url: loadPath });
  };

  return (
    <>
      {healthBenefitSetting &&
      <FormCompanyRetirementSetting
        stepButtons={stepButtons}
        onSubmit={handleSubmit}
        initialValues={healthBenefitSetting}
      />
      }
    </>
  );
};

export default FormHealthBenefitSettingWrapper;

