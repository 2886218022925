import React from 'react';
import Tab from 'react-bootstrap/Tab';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FieldArray } from 'redux-form';
import AddressBillingNav from './BillingAddresses/AddressBillingNav';
import AddressBillingTab from './BillingAddresses/AddressBillingTab';
import AddressShippingNav from './ShippingAddresses/AddressShippingNav';
import AddressShippingTab from './ShippingAddresses/AddressShippingTab';

const ContactPeopleContainerAttributes = () => {
  return (
    <Tab.Container id="addresses_attributes" defaultActiveKey="first">
      <Row>
        <Col xs={3}>
          <FieldArray
            name="partner_billing_addresses_attributes"
            component={AddressBillingNav}
          />
          <FieldArray
            name="partner_shipping_addresses_attributes"
            component={AddressShippingNav}
          />
        </Col>
        <Col xs={9}>
          <FieldArray
            name="partner_billing_addresses_attributes"
            component={AddressBillingTab}
          />
          <FieldArray
            name="partner_shipping_addresses_attributes"
            component={AddressShippingTab}
          />
        </Col>
      </Row>
    </Tab.Container>
  );
};

export default ContactPeopleContainerAttributes;
