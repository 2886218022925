import React from 'react';
import Button from 'react-bootstrap/Button';
import GeneralPartnerPhoneFields from './GeneralPartnerPhoneFields';


const defaultProps = {
  partnerPhoneScheme: {
    id: null,
    phone_description: null,
    phone_number: null
  }
};

let GeneralPartnerPhone = ({ fields, partnerPhoneScheme }) => {

  return (
    <>
      <tbody>
      {
        fields.map((partner_phones_attribute, index) =>
          <GeneralPartnerPhoneFields
            key={`partner_phones_attribute-${index}`}
            partner_phones_attribute={partner_phones_attribute}
            index={index}
            fields={fields}
          />
        )
      }
      <tr>
        <td colSpan="2" className="text-right">
        </td>
        <td className="text-right">
          <Button block variant="success" size="sm" onClick={() => fields.push(partnerPhoneScheme)}>
            Add
          </Button>
        </td>
      </tr>
      </tbody>
    </>
  );
};

GeneralPartnerPhone.defaultProps = defaultProps;

export default GeneralPartnerPhone;
