import Application from 'application-namespace';
import $ from 'jquery';

Application.Classes.TaskManagerObserver = class TaskManagerObserver {
  constructor($scope) {
    this.$scope = $scope;
    this.$filterByDate = this.$scope.find('#appointment_date');
    this.selectedDate = this.$filterByDate.val();

    this.$filterByDate.on('dp.change', (event) => {
      this.selectedDate = $(event.currentTarget).val();
      this.refreshContent();
    });

    Application.Services.WebSocket.subscribe('TaskManagerChannel', (data) => {
      this.refreshContent(data);
    });
  }

  markCurrentObject(data) {
    this.$scope.find(`[data-tso-id='${data.object.id}']`).addClass('marked-task');
  }

  refreshContent(data) {
    if (localStorage.activeDraggable === 'false') {
      $.ajax({
        url: Routes.task_manager_tech_sales_orders_path(),
        dataType: 'SCRIPT',
        type: 'GET',
        data: { filter_by_date: this.selectedDate },
        success: () => { if (data) { this.markCurrentObject(data); } },
      });
    }
  }
};
