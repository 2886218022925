import '../src/stylesheets/application';
import './react';
import 'jquery';

import 'jquery-ujs';
import Application from 'application-namespace';
import Turbolinks from 'turbolinks';

import 'jquery-ui';

import 'moment';
import 'bootstrap';
import 'bootstrap-touchspin';

import 'bootstrap4-datetimepicker';

import 'pickadate/lib/legacy';
import 'pickadate/lib/picker';
import 'pickadate/lib/picker.date';
import 'pickadate/lib/picker.time';
import 'imports-loader?define=>false,module=>false!toastr';

import 'select2';
import 'jstree';

// require jquery-datatable/js/jquery.dataTables
// require jquery-datatable/js/dataTables.bootstrap
// require jquery-datatable-editor/js/dataTables.editor

import 'datatables.net-bs4';

import 'cocoon-rails';
import 'offline-js';
import 'jquery-treetable';
import 'js-cookie';
import 'jstz';

import 'chart.js';
import 'bootstrap-daterangepicker';
import 'nprogress';
import 'jquery-mask-plugin';

window.BootstrapDialog = require('bootstrap4-dialog');
window.Routes = require('routes');
window.Ladda = require('ladda');
window.Tabulator = require('tabulator-tables');
window._ = require('lodash');
// window.moment = moment;

function importAll(r) { r.keys().forEach(r); }
// TODO : work is loader coffee js
importAll(require.context('../src/javascripts/plugins', true));
importAll(require.context('../src/javascripts/core', true));
importAll(require.context('../src/javascripts/abs_classes', true));
importAll(require.context('../src/javascripts/concerns', true));
importAll(require.context('../src/javascripts/classes', true));
importAll(require.context('../src/javascripts/services', true));

Application.app = new Application.Core();
Application.app.start();

Turbolinks.start({ cache: false });

console.log('Webpack start');
