import React from 'react';
import ReactPaginate from 'react-paginate';
import ceil from 'lodash/ceil';

const defaultProps = {
  handlePageClick: undefined,
  currentPage: 1,
  perRecords: 25,
  totalRecords: 0
};

const Bootstrap4Paginator = ( { handlePageClick, currentPage, perRecords, totalRecords } ) => {
  return (
    <>
      <nav aria-label="Page navigation example">
        <ReactPaginate
          containerClassName="pagination"
          breakClassName="page-item"
          breakLinkClassName="page-link"
          pageClassName="page-item"
          previousClassName="page-item"
          nextClassName="page-item"
          pageLinkClassName="page-link"
          previousLinkClassName="page-link"
          nextLinkClassName="page-link"
          activeClassName="active"
          onPageChange={handlePageClick}
          pageCount={ ceil(totalRecords / perRecords) }
          // marginPagesDisplayed={currentPage}
          // pageRangeDisplayed={currentPage}
        />
      </nav>
    </>
  );
};

Bootstrap4Paginator.defaultProps = defaultProps;

export default Bootstrap4Paginator;
