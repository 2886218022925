import React, { useEffect } from 'react';
import { Field } from 'redux-form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import map from 'lodash/map';
import get from 'lodash/get';
import { connect } from 'react-redux';
import SelectWrapper from '../../../../components/ReduxForm/SelectWrapper';
import TouchSpinField from '../../../../components/ReduxForm/TouchSpinField';
import InputDatePicker from '../../../../components/ReduxForm/InputDatePicker';
import { fetchBusinessPartnerData } from '../../../../redux/businessPartnerStoreReducer/actions';


let PaymentTermsForm = ({
  taxStatus,
  changeBusinessPartnerCollection,
  businessPartner:
    { values: { tax_status_id: taxStatusId } }
}) => {

  useEffect(() => {
    // if (taxStatus.length === 0) {
    //   changeBusinessPartnerCollection();
    // }
  });

  const taxStatusCollection = map(taxStatus, (value, _key) => ({
    value: value.id,
    label: value.name
  }));

  return (
    <Row>
      <Col md={6}>
        <Field
          name="payment_term_id"
          component={SelectWrapper}
          collection={[]}
          label="Payment term"
          formName="businessPartnerForm"
        />

        {/*Interest on arrears/*/}
        <Field
          name='interest_on_arrears'
          component={TouchSpinField}
          label={'Interest on arrears'}
        />

        <Field
          name="price_list_id"
          component={SelectWrapper}
          collection={[]}
          label="Price list"
          formName="businessPartnerForm"
        />

        <Field
          name='total_discount'
          component={TouchSpinField}
          label={'Total discount'}
        />

        <Field
          name="price_list_id"
          component={SelectWrapper}
          collection={[]}
          label="Dunning term"
          formName="businessPartnerForm"
        />

        <Field
          name="effective_discount_id"
          component={SelectWrapper}
          collection={[]}
          label="Effective discount"
          formName="businessPartnerForm"
        />
      </Col>

      <Col md={6}>

        <Field
          name="card_type"
          component={SelectWrapper}
          collection={[]}
          label="Card type"
          formName="businessPartnerForm"
        />

        <Field
          name='cvv'
          component={TouchSpinField}
          label={'Сvv'}
        />

        <Field
          name='Last four digits'
          component={TouchSpinField}
          label={'Last four digits'}
        />

        {/*?DATA*/}
        <Field
          name='last_four_digits'
          component={InputDatePicker}
          label={'Expiration date'}
        />
      </Col>

    </Row>
  );
};


const mapDispatchToProps = dispatch => ({
  changeBusinessPartnerCollection: () => {
    dispatch(fetchBusinessPartnerData());
  },
});


const mapStateToProps = (state) => {
  const {
    form: {
      businessPartnerForm:
        businessPartner
    },
  } = state;
  return {
    businessPartner,
    taxStatus: get(state.businessPartnerStore, 'taxStatusCollection', []),
  };

};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentTermsForm);


