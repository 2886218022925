import React from 'react';
import Button from 'react-bootstrap/Button';
import map from 'lodash/map';
import ContactPeoplePhoneFields from './ContactPeoplePhoneFields';

const ContactPeoplePhone = ({ fields }) => {
  return (
    <tbody>
    {map(fields, (contactPersonPhone, index) =>
      <ContactPeoplePhoneFields
        key={`contact_person_phones_attributes-${index}`}
        contactPersonPhone={contactPersonPhone}
        index={index}
        fields={fields}
      />
    )}
    <tr>
      <td colSpan="3" className="text-right">
        <Button variant="success" size="sm" onClick={() => fields.push({
          id: null,
        })}>
          New
        </Button>
      </td>
    </tr>
    </tbody>
  );
};

export default ContactPeoplePhone;
