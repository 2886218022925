import Application from 'application-namespace';

Application.Classes.AnnualServiceForm = class AnnualServiceForm extends Application.Classes.CoreBase {
  constructor($scope) {
    super();
    $scope.on('cocoon:after-insert', (e, insertedItem) => {
      Application.app.initializeDatePicker(insertedItem);
    });
  }
};
