import Application from 'application-namespace';
import mix from 'mix';

Application.Classes.TechniciansProfit = class TechniciansProfit extends mix(Application.Classes.CoreBase).with(
  Application.Concerns.ReportsExtension,
) {
  constructor($scope) {
    super();
    this.$scope = $scope;
    this.determinationLevelForTreeTable(this.$scope);
  }
};
