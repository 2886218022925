import React from 'react';
import { Field } from 'redux-form';
import Button from 'react-bootstrap/Button';
import FileInput from '../../../../components/ReduxForm/FileInput';

const AttachmentFileFields = ({
  attachments_attributes,
  index,
  fields
}) => {

  return (
    <tr key={index}>
      <td className="d-none">
        <Field
          name={`${attachments_attributes}.id`}
          type="text"
          component="input"
        />
      </td>
      <td>
        <Field
          name={`${attachments_attributes}.attach`}
          component={FileInput}
          type="file"
        />
      </td>
      <td>
        <Button variant="danger" block size="sm" onClick={() => fields.remove(index)}>
          Remove
        </Button>
      </td>
    </tr>
  );

};

export default AttachmentFileFields;

