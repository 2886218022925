import Application from 'application-namespace';
import $ from 'jquery';

Application.Classes.ConnecticutTaxSetupForm = class ConnecticutTaxSetupForm {
  constructor($scope) {
    this.$scope = $scope;
    this.$exemptInput = this.$scope.find('[name="connecticut_tax_setup[is_claiming_msrra_exemption]"]');
    this.$claimingMsrraExemptionLogicBlock = this.$scope.find('.claiming-msrra-exemption-logic-block');

    this.$exemptInput.on('change', (event) => {
      if ($(event.currentTarget).prop('checked')) {
        this.$claimingMsrraExemptionLogicBlock.removeClass('d-none');
      } else {
        this.$claimingMsrraExemptionLogicBlock.addClass('d-none').find('textarea').val('');
      }
    });
  }
};
