import Application from 'application-namespace';
import $ from 'jquery';

Application.Classes.ServiceMaintenanceForm = class ServiceMaintenanceForm {
  constructor($scope) {
    this.$cusomerEquipmentCard = $scope.find('.customer-equipment-card-dropdown');
    this.$operatorManufacturer = $scope.find('.operator_manufacturer');
    this.$operatorType = $scope.find('.operator_type');
    this.$voltage = $scope.find('.voltage');
    this.$operatorBoard = $scope.find('.operator_board');
    this.$communicationManufacturer = $scope.find('.communication_manufacturer');
    this.$communicationType = $scope.find('.communication_type');

    this.$tsoLink = $scope.find('.link-on-tso');
    this.$cecLink = $scope.find('.link-on-cec');
    this.$scLink = $scope.find('.link-on-sc');
    this.$employees = $scope.find('.sto-employees');

    this.$cusomerEquipmentCard.on('change', element => this.fetchCustomerEquipmentCard(element));

    this.$cusomerEquipmentCard.trigger('change');
  }

  fetchCustomerEquipmentCard(element) {
    const $data = this.$cusomerEquipmentCard.select2('data')[0].element.attributes;
    this.$operatorManufacturer.html($data.operator_manufacturer ? $data.operator_manufacturer.value : null);
    this.$operatorType.html($data.operator_type ? `, ${$data.operator_type.value}` : null);
    this.$voltage.html($data.voltage ? `, ${$data.voltage.value}` : null);
    this.$operatorBoard.html($data.operator_board ? `, ${$data.operator_board.value} Board` : null);
    this.$communicationManufacturer.html($data.communication_manufacturer ? $data.communication_manufacturer.value : null);
    this.$communicationType.html($data.communication_type ? `, ${$data.communication_type.value}` : null);

    if ($(element.currentTarget).val()) {
      this.$tsoLink.attr('href', Routes.edit_tech_sales_order_path($(element.currentTarget).val())).removeClass('disabled');
    } else {
      this.$tsoLink.addClass('disabled');
    }

    if ($data.cec_id) {
      this.$cecLink.attr('href', Routes.edit_customer_equipment_card_path($data.cec_id.value)).removeClass('disabled');
    } else {
      this.$cecLink.addClass('disabled');
    }

    if ($data.sc_id) {
      this.$scLink.attr('href', Routes.edit_service_call_path($data.sc_id.value)).removeClass('disabled');
    } else {
      this.$scLink.addClass('disabled');
    }

    if ($data.tso_employees) {
      this.$employees.html($data.tso_employees.value);
    } else {
      this.$employees.html('');
    }
  }
};
