import Application from 'application-namespace';
import $ from 'jquery';

Application.Classes.DepositForm = class DepositForm {
  constructor($scope) {
    this.$scope = $scope;
    this.$paymentTabs = this.$scope.find('[data-role="presentation"]');
    this.$gjAccount = this.$scope.find('#deposit_general_ledger_account_id');
    this.$selectionButtons = this.$scope.find('.deposit_deposit_lines_selected');
    this.$totalByTabs = this.$scope.find('[data-total-by-tab]');
    this.$totalByAll = this.$scope.find('#total_of_all_selected_payments');
    this.$selectAll = this.$scope.find('.select_all');

    this.$paymentTabs.on('click', (event) => {
      this.swapJlAccount(event);
    });

    this.$selectionButtons.on('change', (event) => {
      this.calculateAmountByTab(event);
    });

    this.$totalByTabs.on('change', (event) => {
      this.calculateAmountByAllTab(event);
    });

    this.$selectAll.on('change', (event) => {
      this.swapAllToggles(event);
    });

    this.$paymentTabs.trigger('click');
  }

  swapJlAccount(event) {
    const contentId = $(event.currentTarget).find('a').attr('href');
    const currentJlId = this.$scope.find(contentId).data('defaultAccount') || null;
    return $(this.$gjAccount).val(currentJlId).trigger('change');
  }

  calculateAmountByTab(event) {
    let result = 0;
    const $parentDiv = $(event.currentTarget).closest('.line_items');
    $parentDiv.find('[data-incoming-payment-line]').each((key, element) => {
      if ($(element).find('.deposit_deposit_lines_selected').find('[type="checkbox"]').is(':checked')) {
        result += parseFloat($(element).find('[data-amount-by-line]').html().slice(1)
          .replace(',', ''));
      }
    });
    return $parentDiv.closest('.tab-pane').find('[data-total-by-tab]').val(result.toFixed(2)).trigger('change');
  }

  calculateAmountByAllTab() {
    let result = 0;
    this.$totalByTabs.each((key, element) => {
      result += parseFloat($(element).val());
    });
    return this.$totalByAll.val(result.toFixed(2));
  }

  swapAllToggles(event) {
    const $toggles = $(event.currentTarget).closest('.tab-pane').find('.deposit_deposit_lines_selected').find('input');
    $toggles.each((i, element) => {
      if ($(event.currentTarget).is(':checked')) {
        $(element).prop('checked', true).change();
      } else {
        $(element).prop('checked', false).change();
      }
    });
  }
};
