import Application from 'application-namespace';
import $ from 'jquery';
import moment from 'moment';

Application.Classes.EmployeeWorkDayEmergencyServiceForm = class EmployeeWorkDayEmergencyServiceForm {
  constructor($scope) {
    this.$scope = $scope;
    this.$totalInput = this.$scope.find('[data-total-field]');
    this.$paidPhonePriceInput = this.$scope.find('[data-paid-phone-price-field]');
    this.total = this.$totalInput.val();
    this.$allNewLineButton = this.$scope.find('.add_fields');
    this.$emergencyServiceStateTypeInput = this.$scope.find('[data-emergency-service-state-type-input]');
    this.$emergencyServiceStateInput = this.$scope.find('[data-emergency-service-state-input]');

    this.$paidPhonePriceInput.on('change', () => this.recalculateTotal());
    this.$scope.find('[data-amount-input]').on('change', (event) => {
      this.recalculateTotal();
      $(event.currentTarget).data('isManualChanged', true);
    });

    this.$scope.find('.nested-fields').each((i, el) => {
      this.initRecalculateAmount($(el));
      this.markAsTouched($(el));
      this.initTimePicker($(el));
    });

    this.$scope.find('.nested-fields').each((i, el) => {
      this.initRecalculateAmount($(el));
      this.markAsTouched($(el));
      this.initTimePicker($(el));
    });

    this.$scope.find('.hold-phone-nested-line').each((i, el) => {
      const $isTouchedInput = $(el).find('.payroll_report_payroll_salary_changes_is_touched').find('input');

      $(el).find('[data-paid-phone-price-field]').on('change', (event) => {
        $(el).find('[data-destroy-line]').prop('checked', parseFloat($(event.currentTarget).val()) <= 0);
        $(el).find('.payroll_report_payroll_salary_changes_is_touched').find('input').prop('checked', parseFloat($(event.currentTarget).val()) > 0);
        this.recalculateTotal()
      });
      $(el).find('select').on('select2:select', () => {
        console.log($(el).find('[data-paid-phone-price-field]'))

        $isTouchedInput.prop('checked', parseFloat( $(el).find('[data-paid-phone-price-field]').val() ) > 0);
        this.recalculateTotal()
      });
    });

    this.$scope.on('cocoon:after-insert', (e, insertedItem) => {
      Application.app.initializeDateTimePicker(insertedItem);
      Application.app.initializeSelectPicker(insertedItem);
      Application.app.initializeMathInput(insertedItem);
      this.initTimePicker(insertedItem);
      this.initRecalculateAmount(insertedItem);
      this.initValidateAmount(insertedItem);
      this.markAsTouched(insertedItem);
    });

    this.$scope.find('table').on('cocoon:after-remove', () => this.recalculateTotal());

    this.initValidateAmount();
    this.behaviorForGlobalStates();
    this.recalculateTotal();
  }

  initTimePicker($line) {
    $line.find('.datetimepicker').each((i, el) => {
      this.constructor.correctTime($(el));
      $(el).closest('.datetimepicker-wrapper').removeClass('field_with_errors').find('.error')
        .remove();
      if ($(el).closest('td').find('.input').hasClass('field_with_errors')) {
        const message = $(el).closest('td').find('.error').html();
        $(el).closest('.datetimepicker-wrapper').addClass('field_with_errors').append(`<span class='error red-text'>${message}</span>`);
      }
    });

    return $line.find('.datetimepicker').on('dp.change', event => this.constructor.correctTime($(event.currentTarget)));
  }

  static correctTime($element) {
    const selectedTime = moment($element.val(), ['h:m a']);
    const recommendedDate = $element.closest('td').find('.datetime').val();
    const selectedHours = selectedTime.hour();
    const selectedMinutes = selectedTime.minute();
    const fixedDay = moment(recommendedDate).date();
    const resultTime = moment(recommendedDate).date(fixedDay).hour(selectedHours).minute(selectedMinutes);
    $element.closest('td').find('.input').find('input').val(resultTime.format('MM/DD/YYYY h:mm A'));
  }

  initRecalculateAmount($line) {
    $line.find('[data-state-type-input]').on('change', event => this.behaviorForLineStates($(event.currentTarget), $line));
    this.behaviorForLineStates($line.find('[data-state-type-input]'), $line);
    const $startAtInput = $line.find('[name="start_at_time_only"]');
    const $endAtInput = $line.find('[name="end_at_time_only"]');
    $startAtInput.on('dp.change', () => this.recalculatePaymentData($startAtInput, $endAtInput, $line));
    $startAtInput.on('dp.hide', event => this.constructor.limitTime($(event.currentTarget), $endAtInput));

    $endAtInput.on('dp.change', () => this.recalculatePaymentData($startAtInput, $endAtInput, $line));
    $endAtInput.on('dp.hide', event => this.constructor.limitTime($(event.currentTarget), $startAtInput, 'max'));

    // $startAtInput.trigger('dp.change');

    $line.find('[data-work-day-input]').on('change', (event) => {
      if (!$(event.currentTarget).data('isAddedNewLine')) { this.$allNewLineButton.trigger('click'); }
      $(event.currentTarget).data('isAddedNewLine', true);
      this.recalculatePaymentData($startAtInput, $endAtInput, $line);
    });
  }

  behaviorForGlobalStates() {
    this.$emergencyServiceStateTypeInput.on('change', (event) => {
      if ($(event.currentTarget).val() === 'custom') {
        this.$emergencyServiceStateInput.prop('disabled', false);
      } else {
        this.$emergencyServiceStateInput.prop('disabled', true).trigger('change');
      }
    });
    this.$emergencyServiceStateTypeInput.trigger('change');
  }

  behaviorForLineStates($object, $line) {
    if ($object.val() === 'custom') {
      $line.find('[data-state-input]').prop('disabled', false);
    } else {
      $line.find('[data-state-input]').prop('disabled', true).val('').trigger('change');
    }
  }

  recalculatePaymentData($startAtInput, $endAtInput, $line) {
    const startAt = moment($startAtInput.val(), 'h:mm A');
    const endAt = moment($endAtInput.val(), 'h:mm A');
    const intDuration = parseFloat(moment.duration(moment.duration(endAt.diff(startAt)).asMilliseconds()).asHours());
    const salary = $($line.closest('.nested-fields').find('[data-work-day-input]').select2('data')[0].element).attr('salary');
    const minPayment = $($line.closest('.nested-fields').find('[data-work-day-input]').select2('data')[0].element).attr('salary');
    const emergencyPercent = $($line.closest('.nested-fields').find('[data-work-day-input]').select2('data')[0].element).attr('emergency-percent');
    let duration = moment.utc(moment.duration(endAt.diff(startAt)).asMilliseconds()).format('H[h]:m[m]:s[s]');
    let amount = ((intDuration * (minPayment / 100)) * emergencyPercent).toFixed(2);
    const $amountInput = $line.find('[data-amount-input]');

    if (amount < 0) {
      amount = 'ERROR';
      duration = ['-', duration].join('');
      $line.find('.duration').addClass('text-danger');
      $amountInput.addClass('math-input-error');
    } else {
      $amountInput.removeClass('math-input-error');
      $line.find('.duration').removeClass('text-danger');
    }

    $amountInput.val(amount);
    $line.find('.day-info').remove();
    if (salary) {
      $line.find('[data-work-day-input]').closest('.input').after(`<p class="day-info text-muted">Salary: $${(salary / 100).toFixed(2)} per hour, emergency percent: ${emergencyPercent}</p>`);
    }
    if ($startAtInput.val() && $endAtInput.val()) { this.validateAmount($amountInput); }
    $line.find('.duration').html(duration);
    this.recalculateTotal();
  }

  recalculateTotal() {
    this.total = 0;
    this.$scope.find('.hold-phone-nested-line').each((i, el) => {
      if ($(el).find('.payroll_report_payroll_salary_changes_is_touched').find('[type="checkbox"]').is(':checked')) {
        this.total += parseFloat(($(el).find('[data-paid-phone-price-field]').val()))
      }
    });
    this.$scope.find('.nested-fields').find('[data-amount-input]').each((i, el) => {
      this.total += parseFloat($(el).val());
    });
    this.$totalInput.val(this.total.toFixed(2));
  }

  initValidateAmount($defaultScope) {
    const $scope = $defaultScope == null ? this.$scope : $defaultScope;
    $scope.find('[data-amount-input]').on('change', (event) => {
      this.validateAmount($(event.currentTarget));
    });
  }

  validateAmount($input) {
    const minPayment = $($input.closest('.nested-fields').find('[data-work-day-input]').select2('data')[0].element).attr('min-payment');

    if (!$input.data('isManualChanged') && (parseFloat($input.val()) < parseFloat(minPayment / 100))) {
      $input.val(minPayment / 100);
    }
  }

  static limitTime($from, $to, defaultType) {
    const type = defaultType == null ? 'min' : defaultType;
    if ($from.val()) {
      const selectedTime = moment($from.val(), 'h:mm A');
      if (type === 'min') {
        $to.data('DateTimePicker').minDate(moment({ h: moment(selectedTime).hour(), m: moment(selectedTime).minute() }));
      } else {
        $to.data('DateTimePicker').maxDate(moment({ h: moment(selectedTime).hour(), m: moment(selectedTime).minute() }));
      }
    }
  }

  markAsTouched($line) {
    const $isTouchedInput = $line.find('.payroll_report_emergency_services_is_touched').find('input');
    $line.find('.datetimepicker').on('dp.change', () => $isTouchedInput.prop('checked', true));
    $line.find('input').on('change', () => $isTouchedInput.prop('checked', true));
    $line.find('select').on('select2:select', () => $isTouchedInput.prop('checked', true));
  }
};
