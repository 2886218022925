import React, { useEffect } from 'react';
import { Field } from 'redux-form';
import Button from 'react-bootstrap/Button';
import Tab from 'react-bootstrap/Tab';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import map from 'lodash/map';
import get from 'lodash/get';
import { connect } from 'react-redux';
import RenderField from '../../../../../components/ReduxForm/RenderField';
import SelectWrapper from '../../../../../components/ReduxForm/SelectWrapper';
import { fetchBusinessPartnerData } from '../../../../../redux/businessPartnerStoreReducer/actions';

let ShippingAddressesFields = ({
  partnerShippingAddress,
  index,
  fields,
  salesTaxCodes,
  states,
  changeBusinessPartnerCollection
}) => {

  useEffect(() => {
    // if (states.length === 0) {
    //   changeBusinessPartnerCollection();
    // }
  });

  const salesTaxCodesCollection = map(salesTaxCodes, (value, _key) => ({
    value: value.id,
    label: value.full_name
  }));

  const statesCollection = map(states, (value, _key) => ({
    value: value.id,
    label: value.name
  }));

  return (
    <Tab.Pane key={index} eventKey={`shipping-${index}`}>
      <Row>
        <Col xs={12}>

          <Field
            name={`${partnerShippingAddress}.address_name`}
            component={RenderField}
            label="Address name"
          />

          {/*Address line 1*/}

          <Field
            name={`${partnerShippingAddress}.address_line_2`}
            component={RenderField}
            label={'Address line 2'}
          />

          <Field
            name={`${partnerShippingAddress}.city`}
            component={RenderField}
            label={'City'}
          />

          <Field
            label={'State'}
            name={`${partnerShippingAddress}.state_id`}
            component={SelectWrapper}
            collection={statesCollection}
            formName="businessPartnerForm"
          />

          <Field
            name={`${partnerShippingAddress}.zip_code`}
            component={RenderField}
            label={'Zip code'}
          />

          <Field
            label={'Sales tax code'}
            name={`${partnerShippingAddress}.sales_tax_code_id`}
            component={SelectWrapper}
            collection={salesTaxCodesCollection}
            formName="businessPartnerForm"
          />
          <hr/>
          <Button variant="danger" size="sm" onClick={() => fields.remove(index)}>
            Remove
          </Button>
        </Col>
      </Row>
    </Tab.Pane>
  );
};


const mapDispatchToProps = dispatch => ({
  changeBusinessPartnerCollection: () => {
    dispatch(fetchBusinessPartnerData());
  },
});


const mapStateToProps = (state) => {

  const {
    form: {
      businessPartnerForm:
        businessPartner
    },
  } = state;

  return {
    businessPartner,
    states: get(state.businessPartnerStore, 'statesCollection', []),
    salesTaxCodes: get(state.businessPartnerStore, 'salesTaxCodesCollection', [])
  };

};

export default connect(mapStateToProps, mapDispatchToProps)(ShippingAddressesFields);
