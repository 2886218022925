import React from 'react';
import { reduxForm } from 'redux-form';
import Tab from 'react-bootstrap/Tab';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import BillingAddressesFields from './BillingAddressesFields';

let AddressBillingTab = ({
  fields
}) => {

  return (
    <Row>
      <Col sm={12}>
        <Tab.Content>
          {
            fields.map((partnerBillingAddress, index) =>
              <BillingAddressesFields
                key={`partnerBillingAddress-${index}`}
                partnerBillingAddress={partnerBillingAddress}
                index={index}
                fields={fields}
              />
            )
          }
        </Tab.Content>
      </Col>
    </Row>
  );

};

AddressBillingTab = reduxForm({
  form: 'businessPartnerForm',
  enableReinitialize: true
})(AddressBillingTab);

export default AddressBillingTab;
