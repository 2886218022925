import React, { Fragment } from 'react';
import { Switch, Route, Redirect, BrowserRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import find from 'lodash/find'
import PayrollSettingsList from './PayrollSettingsList';
import FormPayrollSettingWrapper from './FormPayrollSetting';

const PayrollSettings = () => {

  const currentSetupStepData = useSelector(state => state.projectPayrollSetupStore.notConfiguredSettings);

  return (
    <Fragment>
      {
        currentSetupStepData && find(currentSetupStepData, { path: 'payroll_settings' }) &&
        <Redirect from="/payroll_settings" to="/payroll_settings/new" />
      }
      <h1>PAYROLL SETTING</h1>
      <BrowserRouter basename="/project_payroll_setup">

      <Switch>
        <Route path="/payroll_settings/new" component={FormPayrollSettingWrapper}/>
        <Route path="/payroll_settings/:payrollSettingId/edit" component={FormPayrollSettingWrapper}/>
        <Route path="/payroll_settings" component={PayrollSettingsList}/>
      </Switch>
      </BrowserRouter>
    </Fragment>
  );
};

export default PayrollSettings;
