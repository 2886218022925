import Application from 'application-namespace';
import $ from 'jquery';

Application.Classes.ItemGroupSetup = class ItemGroupSetup extends Application.Classes.CoreBase {
  constructor($scope) {
    super();
    this.$scope = $scope;
    this.$defaultItemGroups = this.$scope.find('[data-default-item-groups]');
    this.$accountSelections = this.$scope.find('[data-account-selection]');
    this.$itemLines = this.$scope.find('.item-line');
    this.$accountSelections.on('change', () => {
      this.generateJson();
    });
  }

  generateJson() {
    const results = {};

    this.$itemLines.each((i, element) => {
      const key = $(element).find('[data-account-name]').val();
      results[key] = $(element).find('[data-account-selection]').val();
    });

    this.$defaultItemGroups.val(JSON.stringify(results));
  }
};
