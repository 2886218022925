import Application from 'application-namespace';
import $ from 'jquery';

Application.Classes.SalesTaxCodeForm = class SalesTaxCodeForm extends Application.Classes.CoreBase {
  constructor($scope) {
    super();

    this.$scope = $scope;
    this.$tax_rate_input = this.$scope.find('#sales_tax_code_tax_rate');

    this.$scope.find('table').on('cocoon:after-insert', (e, insertedItem) => {
      this.initializePluginsAfterInsert(insertedItem);
      this.initializeVariables();
    });

    this.$scope.find('table').on('cocoon:after-remove', (e, insertedItem) => {
      insertedItem.find('[data-tj-rate]').html('0');
      this.calculationAmountTax();
    });

    this.initializeVariables();
  }

  initializeVariables() {
    this.$scope.find('[data-tax-dropdown]').on('change', (el) => {
      this.constructor.pasteAttributes(el);
      this.calculationAmountTax();
    });
    this.$scope.find('[data-tax-dropdown]').trigger('change');
  }

  static pasteAttributes(element) {
    const selectedVariable = $(element.currentTarget).select2('data');
    if (selectedVariable[0].id) {
      const line = $(element.currentTarget).closest('tr');
      line.find('[data-tj-type]').html(selectedVariable[0].element.attributes.data_type.value);
      line.find('[data-tj-code]').html(selectedVariable[0].element.attributes.data_code.value);
      line.find('[data-tj-name]').html(selectedVariable[0].element.attributes.data_name.value);
      line.find('[data-tj-rate]').html(selectedVariable[0].element.attributes.data_rate.value);
    }
  }

  calculationAmountTax() {
    let amount = 0;
    this.$scope.find('[data-tj-rate]').each((i, el) => {
      amount += $(el).html() ? parseFloat($(el).html()) : 0;
    });
    this.pasteInInput(this.$tax_rate_input, amount.toFixed(2));
  }
};
