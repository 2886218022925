import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {get, reverse} from 'lodash';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {Field, reduxForm} from "redux-form";
import {compose} from "redux";
import Select2Collection from '../../../../decorators/Select2Collection';
import SelectWrapper from '../../../../components/ReduxForm/SelectWrapper';
import MultiSelectWrapper from '../../../../components/ReduxForm/MultiSelectWrapper';
import { loadActiveEmployees, loadMissedEmployeeWorkDays } from '../../../../redux/missedDaysReportReducer/actions';

const years = (back) => {
    const year = new Date().getFullYear();
    return Array.from({length: back}, (v, i) => {
        const result = year - back + i + 1;
        return {value: result, label: result}
    });
};

const defaultProps = {
    activeEmployees: [], lastFiveYears: years(5),
    dayTypes:
      [{value: 2, label: 'Sick'}, {value: 3, label: 'Vacation'}, {value: 4, label: 'Holiday'}, {value: 5, label: 'Other'}]
};

const MissedDaysFilters = ({lastFiveYears, dayTypes}) => {

    const dispatch = useDispatch();
    const missedDaysReportFilters = useSelector(state => state.form.missedDaysReportFilters);
    const missedDaysReportStore = useSelector(state => state.missedDaysReportStore);
    const activeEmployees = get(missedDaysReportStore, 'activeEmployees', []);

    useEffect(() => {
        dispatch(loadActiveEmployees());
    }, []);

    useEffect(() => {
        if (missedDaysReportFilters.values && missedDaysReportFilters.values.employee_id &&
            missedDaysReportFilters.initial !== missedDaysReportFilters.values) {
            dispatch(loadMissedEmployeeWorkDays(missedDaysReportFilters.values))
        }
    }, [missedDaysReportFilters]);

    return (
        <>
            <Row className='mb-4'>
                <Col xs={12} md={4}>
                    <Field
                        name="employee_id"
                        component={SelectWrapper}
                        collection={Select2Collection(activeEmployees, {labelKey: 'full_name'})}
                        label="Employee"
                        formName="missedDaysReportFilters"
                        isClearable={false}
                    />
                </Col>
                <Col xs={12} md={4}>
                    <Field
                        name="day_types"
                        component={MultiSelectWrapper}
                        collection={dayTypes}
                        label="Work day type"
                        formName="missedDaysReportFilters"
                        isClearable={false}
                    />
                </Col>
                <Col xs={12} md={4}>
                    <Field
                        name="year"
                        component={SelectWrapper}
                        collection={reverse(lastFiveYears)}
                        label="Year"
                        formName="missedDaysReportFilters"
                        isClearable={false}
                    />
                </Col>
            </Row>
        </>
    )
};

MissedDaysFilters.defaultProps = defaultProps;

export default compose(
    reduxForm({
        form: 'missedDaysReportFilters', enableReinitialize: true
    })
)(MissedDaysFilters);
