import React from 'react';
import { connect } from 'react-redux';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

let SubmitFormSection = ({document}) => {

  return (
    <>
      <div className="mt-4">
        <Row>
          <Col>
            <Button variant="secondary" block>Back</Button>
          </Col>
          <Col>
            <Button variant="success" type="submit" disabled={document.submitting} block>
              {document.submitting &&
              <>
                <Spinner
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                Loading...
              </>
              }
              {!document.submitting &&
              'Save'
              }
            </Button>
          </Col>
        </Row>
      </div>
    </>
  )

};

const mapStateToProps = (state) => {
  return {
    document: state.form.commonDocumentForm,
  };
};

export default connect(mapStateToProps)(SubmitFormSection);
