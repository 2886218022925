import React, { useState, useEffect } from 'react';
import FormCompanyRetirementSetting from './form';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import delay from 'lodash/delay';
import ReduxFormSubmissionError from '../../../../components/ReduxFormSubmissionError';

const FormCompanyRetirementSettingWrapper = ({ stepButtons = [], goNextStep = () => {} }) => {

  let params = useParams();
  const [loadPath, setLoadPath] = useState(null);
  const [companyRetirementSetting, setCompanyRetirementSetting] = useState({});

  const handleSubmit = (values) => {
    const submitPath = params.companyRetirementSettingId ?
      Routes.api_web_v1_company_retirement_setting_path(params.companyRetirementSettingId) :
      Routes.api_web_v1_company_retirement_settings_path();
    const submitMethod = params.companyRetirementSettingId ? 'PUT' : 'POST';

    return axios({
      method: submitMethod,
      url: submitPath,
      data: { company_retirement_setting: values }
    })
      .then(response => {
        delay(() => {
          goNextStep(response.data.company_retirement_setting.id);
        }, 2000);
      })
      .catch(error => {
        ReduxFormSubmissionError(error.response.data.errors, values);
      });
  };

  useEffect(() => {
    if (params.companyRetirementSettingId) {
      setLoadPath(Routes.edit_api_web_v1_company_retirement_setting_path(params.companyRetirementSettingId));
    } else {
      setLoadPath(Routes.new_api_web_v1_company_retirement_setting_path());
    }
  }, [params]);

  useEffect(() => {
    if (loadPath) {
      loadCompanyRetirementSetting()
        .then(response => {
          setCompanyRetirementSetting(response.data.company_retirement_setting);
        })
        .catch(error => {
          console.log('FormCompanyRetirementSettingWrapper.loadCompanyRetirementSetting', error.response);
        });
    }
  }, [loadPath]);

  const loadCompanyRetirementSetting = () => {
    return axios({ url: loadPath });
  };

  return (
    <>
      {companyRetirementSetting &&
      <FormCompanyRetirementSetting
        stepButtons={stepButtons}
        onSubmit={handleSubmit}
        initialValues={companyRetirementSetting}
      />
      }
    </>
  );
};

export default FormCompanyRetirementSettingWrapper;

