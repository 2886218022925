import React from 'react';
import Alert from 'react-bootstrap/Alert';
import { useSelector } from 'react-redux';


export default function ({formName}) {
  const form = useSelector(state => state.form[formName]);

  return (
    <>
      {form.submitFailed &&
      <Alert variant="danger">
        <Alert.Heading>{form.error}</Alert.Heading>
        <p>
          Change form validation errors and that and try again.
        </p>
      </Alert>
      }
      {form.submitSucceeded &&
      <Alert variant="success">
        <Alert.Heading>Save is successfully!</Alert.Heading>
        <p>
          Now available copy to and pdf file.
        </p>
      </Alert>
      }
    </>
  )

}
