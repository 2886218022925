import React from 'react';
import { reduxForm } from 'redux-form';
import Tab from 'react-bootstrap/Tab';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ShippingAddressesFields from './ShippingAddressesFields';

let AddressShippingTab = ({
  fields
}) => {
  return (
    <Row>
      <Col sm={12}>
        <Tab.Content>
          {
            fields.map((partnerShippingAddress, index) =>
              <ShippingAddressesFields
                key={`partnerShippingAddress-${index}`}
                partnerShippingAddress={partnerShippingAddress}
                index={index}
                fields={fields}
              />
            )
          }
        </Tab.Content>
      </Col>
    </Row>
  );
};

AddressShippingTab = reduxForm({
  form: 'businessPartnerForm',
  enableReinitialize: true
})(AddressShippingTab);

export default AddressShippingTab;
