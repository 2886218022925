import axios from 'axios';

import { LOAD_MISSED_EMPLOYEE_WORK_DAYS, LOAD_ACTIVE_EMPLOYEES } from './actionTypes'

export const loadActiveEmployees = () => {
  return (dispatch) => {
    return (
      axios ({ url: Routes.employees_active_api_web_v1_employees_path() })
        .then(response => {
          dispatch({
            type: LOAD_ACTIVE_EMPLOYEES,
            payload: { response }
          })
        })
        .catch(error => {
          console.log(error);
        })
    )
  }
};

export const loadMissedEmployeeWorkDays = (params) => {
  return (dispatch) => {
    return (
      axios({ url: Routes.missed_days_api_web_v1_employee_work_days_path(params) })
        .then(response => {
          dispatch({
            type: LOAD_MISSED_EMPLOYEE_WORK_DAYS,
            payload: { response }
          });
        })
        .catch(error => {
          console.log(error);
        })
    );
  };
};

