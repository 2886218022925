import React from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import Tab from 'react-bootstrap/Tab';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import ContactFields from './ContactFields';
import map from 'lodash/map';

let ContactPeopleBlock = ({
  partnerContactPersons,
  fields
}) => {
  const newNestedForm = (event) => {
    event.preventDefault();
    fields.push({});
  };

  return (
    <Row>
      <Col sm={3}>
        <h5 className={'text-center'}>Contact People</h5>
        <Nav variant="pills" className="flex-column">
          {
            map(fields, (partnerContactPerson, index) => {
                return (
                  <Nav.Link
                    eventKey={index}
                    key={`partnerContactPerson-${index}`}
                  >{partnerContactPersons[index].contact_id || 'Define new'}</Nav.Link>
                );
              }
            )
          }
          <Nav.Item>
            <Nav.Link
              eventKey="first"
              onClick={newNestedForm}
            >Add</Nav.Link>
          </Nav.Item>
        </Nav>
      </Col>
      <Col sm={9}>
        <Tab.Content>
          {
            fields.map((partnerContactPerson, index) =>
              <ContactFields
                key={`partnerContactPerson-${index}`}
                partnerContactPerson={partnerContactPerson}
                index={index}
                fields={fields}
              />
            )
          }
        </Tab.Content>
      </Col>
    </Row>
  );
};

ContactPeopleBlock = reduxForm({
  form: 'businessPartnerForm',
  enableReinitialize: true
})(ContactPeopleBlock);

ContactPeopleBlock = connect(state => {
  const { form: { businessPartnerForm: { values: { partner_contact_persons: partnerContactPersons } } } } = state;
  return {
    partnerContactPersons,
  };
})(ContactPeopleBlock);

export default ContactPeopleBlock;
