import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import reverse from 'lodash/reverse';
import sortBy from 'lodash/sortBy';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Field, reduxForm } from 'redux-form';
import { compose } from 'redux';
import {
  loadActiveEmployees,
  loadPayrollSalaryChanges,
  loadSalaryChangeReasons
} from '../../../redux/commissionReportsStoreReducer/actions';
import SelectWrapper from '../../../components/ReduxForm/SelectWrapper';
import MultiSelectWrapper from '../../../components/ReduxForm/MultiSelectWrapper';
import Select2Collection from '../../../decorators/Select2Collection';
import queryString from 'query-string';
import { useHistory, useLocation } from "react-router-dom";

const years = (back) => {
  const year = new Date().getFullYear();
  return Array.from({length: back}, (v, i) => {
    const result = year - back + i + 1;
    return {value: result, label: result}
  });
};

const defaultProps = {
  activeEmployees: [],
  salaryChangeReasons: [],
  lastFiveYears: years(5)
};

const ReportFilters = ( { lastFiveYears } ) => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const commissionReportsFilters = useSelector(state => state.form.commissionReportsFilters);
  const commissionReportsStore = useSelector(state => state.commissionReportsStore);
  const activeEmployees = get(commissionReportsStore, 'activeEmployees', []);
  const salaryChangeReasons = get(commissionReportsStore, 'payrollSalaryChangeReasons', []);

  useEffect(() => {
    dispatch(loadSalaryChangeReasons());
    dispatch(loadActiveEmployees());
  }, []);

  useEffect(() => {
    if (commissionReportsFilters.values && commissionReportsFilters.values.employee_id &&
      commissionReportsFilters.initial !== commissionReportsFilters.values) {
      fillLocationSearch(commissionReportsFilters.values);
    }
  }, [commissionReportsFilters]);

  useEffect(() => {
    dispatch(loadPayrollSalaryChanges(queryString.parse(location.search)));
  }, [location.search]);

  const fillLocationSearch = (filterValues) => {
    history.push( {search: queryString.stringify(filterValues) } )
  };

  return (
    <>
      <Row className='mb-4'>
        <Col xs={12} md={5}>
          <Field
            name="employee_id"
            component={SelectWrapper}
            collection={Select2Collection(activeEmployees, { labelKey: 'full_name' })}
            label="Employee"
            formName="commissionReportsFilters"
            isClearable={false}
          />
        </Col>
        <Col xs={12} md={5}>
          <Field
            name="payroll_salary_change_reasons"
            component={MultiSelectWrapper}
            collection={Select2Collection(salaryChangeReasons, { labelKey: 'name' })}
            label="Salary change reasons"
            formName="commissionReportsFilters"
          />
        </Col>
        <Col xs={12} md={2}>
          <Field
            name="year"
            component={SelectWrapper}
            collection={reverse(sortBy(lastFiveYears))}
            label="Year"
            formName="commissionReportsFilters"
            isClearable={false}
          />
        </Col>
      </Row>
    </>
  );
};

ReportFilters.defaultProps = defaultProps;

export default compose(
  reduxForm({
    form: 'commissionReportsFilters',
    enableReinitialize: true
  })
)(ReportFilters);
