import React, { Component } from 'react';
import { find } from 'lodash';
import ComponentSelect from '../ComponentSelect/ComponentSelect';

export default class ReactSelect extends Component {
  selectedValue = (mass, value) => {
    let element = null;
    if (value != null) {
      element = find(mass, { value });
    }
    return element;
  };

  render() {
    const {
      name, value, error, options, onChange, isClearable, isMulti
    } = this.props;
    const valueSelected = this.selectedValue(options, value);
    return (
      <ComponentSelect
        isMulti={isMulti}
        error={error}
        name={name}
        required
        value={valueSelected}
        onChange={onChange}
        options={options}
        isClearable={isClearable}
      />
    );
  }
}
