import React from 'react';
import Alert from 'react-bootstrap/Alert';
import { connect } from 'react-redux';


let ValidateAlertSection = (props) => {

  const { document } = props;

  return (
    <>
      {document.submitFailed &&
      <Alert variant="danger">
        <Alert.Heading>{document.error}</Alert.Heading>
        <p>
          Change form validation errors and that and try again.
        </p>
      </Alert>
      }
      {document.submitSucceeded &&
      <Alert variant="success">
        <Alert.Heading>Document saved!</Alert.Heading>
        <p>
          Now available copy to and pdf file.
        </p>
      </Alert>
      }
    </>
  )

};

const mapStateToProps = (state) => {
  return {
    document: state.form.commonDocumentForm,
  };
};

export default connect(mapStateToProps)(ValidateAlertSection);
