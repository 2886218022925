import React from 'react';
import Table from 'react-bootstrap/Table';
import GeneralPartnerEmail from './GeneralPartnerEmail';
import { FieldArray } from 'redux-form';

const GeneralPartnerEmailsContainer = () => {
  return (
    <div>
      <Table bordered responsive size="sm">
        <thead>
        <tr>
          <th>Email</th>
          <th style={{ width: '78px' }}>Actions</th>
        </tr>
        </thead>
        <FieldArray
          name="partner_emails_attributes"
          component={GeneralPartnerEmail}
        />
      </Table>
    </div>
  );
};

export default GeneralPartnerEmailsContainer;

