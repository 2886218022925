import $ from 'jquery';

$.rails.allowAction = function (link) {
  if (!link.is('[data-confirm]')) {
    return true;
  }
  BootstrapDialog.show({
    type: BootstrapDialog.TYPE_DANGER,
    title: link.attr('data-title') || 'Confirm',
    message: link.attr('data-confirm'),
    buttons: [
      {
        label: 'No',
        action(dialogRef) {
          dialogRef.close();
        },
      }, {
        label: 'Yes',
        cssClass: 'btn-primary',
        action(dialogRef) {
          link.removeAttr('data-confirm');
          link.trigger('click.rails');
          dialogRef.close();
        },
      },
    ],
  });
  return false;
};
