import Application from 'application-namespace';
import $ from 'jquery';
import _each from 'lodash/each';

Application.Classes.CoreBase = class CoreBase {
  static pasteToListGlobal(selector, data, title, byDefaultValue) {
    const byDefault = byDefaultValue == null ? false : byDefaultValue;
    const $selector = $(selector);
    $selector.empty();
    $selector.html('<option value="" selected="selected"></option>');
    _each(data, (object) => {
      const attributeTitle = eval(`object.${title}`);
      // TODO: check this version
      // const attributeTitle = object[title];
      $selector.append(`<option value="${object.id}">${attributeTitle}</option>`);
    });
    $selector.trigger('change');
    if (byDefault) {
      $selector.find('option[value=1]').attr('selected', 'selected');
      $selector.trigger('change');
    }
  }

  pasteInInput(selector, attribute) { if (attribute) { $(selector).val(attribute); } }

  importantPasteInDiv(selector, attribute) { if (attribute) { $(selector).html(attribute); } }

  pasteInCheckbox(selector, attribute) { $(selector).prop('checked', attribute).trigger('change'); }

  pasteInSelect(selector, attribute) {
    if (attribute) { $(selector).val(attribute).trigger('change'); }
  }

  pasteToList(selector, data, title, byDefault) {
    Application.Classes.CoreBase.pasteToListGlobal(selector, data, title, byDefault);
  }

  roundingToCents(number) { return Math.round(number * 100) / 100; }

  initializePluginsAfterInsert(insertedItem) {
    Application.app.initializeSelectPicker(insertedItem);
    Application.app.initializeTouchSpin(insertedItem);
    Application.app.initializeDatePicker(insertedItem);
    Application.app.initializePhonePicker(insertedItem);
    Application.app.initializeZipCoder(insertedItem);
  }

  findGetParameter(parameterName) {
    let result = null;
    let tmp = [];
    window.location.search.substr(1).split('&').forEach((item) => {
      tmp = item.split('=');
      if (tmp[0] === parameterName) {
        result = decodeURIComponent(tmp[1]);
      }
    });
    return result;
  }
};
