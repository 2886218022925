import React from 'react';
import { Formik } from 'formik';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import LabelInputValue from '../../../decorators/LabelInputValue';

const BooleanForm = (setting, props) => (
  <Formik
    initialValues={
      {
        [setting['var']]: setting.value === 1 || setting.value === 't'
      }
    }
    enableReinitialize={true}
    onSubmit={(values) => {
      const { setSettingFormSubmit } = props;
      const { id } = setting;
      const value = values[setting['var']];
      setSettingFormSubmit({
        id,
        setting: { value }
      });
    }}
  >
    {({
      handleSubmit,
      values,
      setFieldValue
    }) => (
      <Form noValidate onSubmit={handleSubmit}>
        <div className="d-flex bd-highlight mb-3 row" style={{ height: '30px' }}>
          <div className="p-2 bd-highlight align-self-end col-9 mb-0">
            <Form.Row>
              <Form.Group as={Col} md='12'>
                <Form.Check
                  type="switch"
                  inline
                  checked={values[setting.var]}
                  value={values[setting.var]}
                  name={setting['var']}
                  label={LabelInputValue(setting.var)}
                  id={setting.var}
                  onChange={() => {
                    setFieldValue(setting.var, !values[setting.var]);
                  }
                  }
                />
              </Form.Group>
            </Form.Row>
          </div>
          <div className="align-self-center p-2 mt-0 bd-highlight col-3">
            <Button type='submit' className='btn btn-success btn-block'>Update</Button>
          </div>
        </div>
      </Form>
    )}
  </Formik>
);

export default BooleanForm;
