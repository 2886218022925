import { split, toInteger } from 'lodash';

export default function () {
  const documentClassName = split(window.location.pathname, '/', 3)[1];
  let documentId = null;
  if (toInteger(split(window.location.pathname, '/', 3)[2]) > 0) {
    documentId = toInteger(split(window.location.pathname, '/', 3)[2]);
  }
  let copyTo = split(window.location.pathname, '/', 4)[3];
  copyTo = copyTo === 'edit' ? null : copyTo;

  return Routes.define_document_api_web_v1_common_documents_path(
    {
      document_class: documentClassName,
      document_id: documentId,
      copy_to: copyTo
    }
  );
}
