import React from 'react';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import InputDatePicker from '../../../../components/ReduxForm/InputDatePicker';
import SelectWrapper from '../../../../components/ReduxForm/SelectWrapper';
import Form from 'react-bootstrap/Form';
import ReduxFormValidationAlert from '../../../../components/ReduxFormValidationAlert';
import RenderField from '../../../../components/ReduxForm/RenderField';
import Button from 'react-bootstrap/Button';

const FormVacationSetting = ({ vacationSetting, handleSubmit, stepButtons = [] }) => {

  return (
    <>
      <ReduxFormValidationAlert formName={'vacationSettingsForm'}/>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col md="12">
            <Field
              name="effective_date"
              component={InputDatePicker}
            />
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <Field
              name="eligible_for_vacation_pay_after_period_size"
              component={RenderField}
            />
          </Col>
          <Col md="6">
            <Field
              name="eligible_for_vacation_pay_after_period_type"
              component={SelectWrapper}
              collection={vacationSetting.enum_collections.eligible_for_vacation_pay_after_period_types}
              formName="vacationSettingsForm"
            />
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <Field
              name="default_number_of_vacation"
              component={RenderField}
            />
          </Col>
          <Col md="6">
            <Field
              name="calculate_vacation_time_as"
              component={SelectWrapper}
              collection={vacationSetting.enum_collections.calculate_vacation_time_as}
              formName="vacationSettingsForm"
            />
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <Field name="is_count_time_towards_overtime" id="is_count_time_towards_overtime" component="input"
                   type="checkbox"/>
            <label htmlFor="is_count_time_towards_overtime" className="ml-2">Is count time towards overtime</label>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <Field
              name="vacation_time_accrued"
              component={SelectWrapper}
              collection={vacationSetting.enum_collections.vacation_time_accrueds}
              formName="vacationSettingsForm"
            />
          </Col>
        </Row>
        <div className="d-flex justify-content-start align-items-baseline">
          {stepButtons}
          <Button
            variant="success"
            type="submit"
            block
          >
            Next
          </Button>
        </div>
      </Form>
    </>
  );

};

export default compose(
  reduxForm({
    form: 'vacationSettingsForm',
    enableReinitialize: true
  })
)(FormVacationSetting);
