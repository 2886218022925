import React, { useMemo } from 'react';
import MaterialDataTable from '../../../../components/MaterialDataTable';
import axios from 'axios';
import { useHistory, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';

const CompanyRetirementSettingLinesList = () => {

  const {enqueueSnackbar} = useSnackbar();
  let params = useParams();
  const history = useHistory();
  const tableColumns = useMemo(() => ([
    {
      title: 'Min employee percent',
      field: 'min_employee_percent',
      searchable: true,
      sorting: true
    },
    {
      title: 'Max employee percent',
      field: 'max_employee_percent',
      searchable: true,
      sorting: true
    },
    {
      title: 'Company match percent',
      field: 'company_match_percent',
      searchable: true,
      sorting: true
    },
  ]), []);

  const onRemove = (companyRetirementSettingLine) => {
    return axios({
      method: 'DELETE',
      url:
        Routes.api_web_v1_company_retirement_setting_company_retirement_setting_line_path(
          params.companyRetirementSettingId,
          companyRetirementSettingLine.id
        )
    })
      .then((response) => {
        enqueueSnackbar('Company retirement setting line was removed.', { variant: 'success' });
        return response;
      });
  };

  const onAdd = () => (
    history.push(`/company_retirement_settings/${params.companyRetirementSettingId}/company_retirement_setting_lines/new`)
  );

  const onEdit = (companyRetirementSettingLine) => (
    history.push(`/company_retirement_settings/${params.companyRetirementSettingId}/company_retirement_setting_lines/${companyRetirementSettingLine.id}/edit`)
  );

  return (
    <div className="pt-4">
      <MaterialDataTable
        title="Company retirement setting lines"
        tableColumns={tableColumns}
        path={Routes.api_web_v1_company_retirement_setting_company_retirement_setting_lines_path(params.companyRetirementSettingId)}
        onRemove={onRemove}
        onAdd={onAdd}
        onEdit={onEdit}
      />
    </div>
  );
};

export default CompanyRetirementSettingLinesList;


