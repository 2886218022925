import { produce } from 'immer';
import { get } from 'lodash';
import { PARSE_EMPLOYEE_WORK_DAYS, PARSE_ACTIVE_EMPLOYEES } from './actionTypes';

const parseEmployeeWorkDays = (draft, action) => {
  draft.employeeWorkDays = get(action.payload.response.data, 'employee_work_days', []);
};

const parseActiveEmployees = (draft, action) => {
  draft.activeEmployees = get(action.payload.response.data, 'employees', []);
};

const employeeWorkDaysCalendarReducer = produce((draft = {}, action, baseState) => {
  switch (action.type) {
    case PARSE_EMPLOYEE_WORK_DAYS:
      return parseEmployeeWorkDays(draft, action);
    case PARSE_ACTIVE_EMPLOYEES:
      return parseActiveEmployees(draft, action);
    default:
      return draft;
  }
});

export default employeeWorkDaysCalendarReducer;
