import React, { Component } from 'react';
import { connect } from 'react-redux';
import { change } from 'redux-form';
import Select from 'react-select';
import { filter, get, parseInt } from 'lodash';
import Form from 'react-bootstrap/Form';
import { capitalize, lowerCase } from 'lodash';

class SelectWrapper extends Component {

  onChangeField = (value) => {
    const { changeFieldValue, formName, input } = this.props;
    changeFieldValue(formName, input.name, get(value, 'value', null));
  };

  render() {
    const { label, collection, input, meta: { error }, isClearable = true } = this.props;

    return (
      <>
        {label && <label>{label}</label>}
        {label === undefined && <label>{capitalize(lowerCase(input.name))}</label>}
        <div style={{ minWidth: '100px', zIndex: 99999 }}>
          <Select
            options={collection}
            onChange={this.onChangeField}
            value={filter(collection, { value: input.value })}
            isClearable={isClearable}
            className={error ? 'is-invalid' : null}
            style={{'zIndex': 99999}}
          />
          <Form.Control.Feedback type="invalid">
            {error}
          </Form.Control.Feedback>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  changeFieldValue: (formName, fieldName, value) => {
    dispatch(change(formName, fieldName, value));
  },
});

export default connect(null, mapDispatchToProps)(SelectWrapper);
