import React  from 'react';
import Button from 'react-bootstrap/Button';
import DocumentPositionFields from './DocumentPositionFields';

const defaultProps = {
  positionScheme: {
    position_type: 1,
    quantity: 1,
    item_id: null,
    general_ledger_account_id: null,
    total_cents: 0,
    _destroy: false
  }
};

const DocumentPosition = ({ fields, positionScheme }) => {
  return (
    <>
      <tbody>
      {fields.map((position, index) =>
        <DocumentPositionFields
          key={`position-${index}`}
          position={position}
          index={index}
          fields={fields}
        />
      )}
      <tr>
        <td colSpan="10" className="text-right">
          <Button variant="success" size="sm" onClick={() => fields.push(positionScheme)}>
            Add position
          </Button>
        </td>
      </tr>
      </tbody>
    </>
  );
};

DocumentPosition.defaultProps = defaultProps;

export default DocumentPosition;
