import { createStore, applyMiddleware, compose } from 'redux'
// import logger from 'redux-logger'
import rootReducer from './reducers';
import thunk from "redux-thunk";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
  // composeEnhancers(applyMiddleware(thunk, logger))
);

export default store
