import React, { useMemo } from 'react';
import MaterialDataTable from '../../../../components/MaterialDataTable';
import axios from 'axios';
import { useHistory, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';

const HealthBenefitSettingLinesList = () => {

  const {enqueueSnackbar} = useSnackbar();
  let params = useParams();
  const history = useHistory();
  const tableColumns = useMemo(() => ([
    {
      title: 'Pay type',
      field: 'plan_name',
      searchable: true,
      sorting: true
    },
    {
      title: 'Cost per month',
      field: 'total_cost_per_month',
      searchable: true,
      sorting: true,
      type: 'currency'
    },
    {
      title: 'Cost per week',
      field: 'total_cost_per_week',
      searchable: true,
      sorting: true,
      type: 'currency'
    },
    {
      title: 'Company pays',
      field: 'company_pays',
      searchable: true,
      sorting: true,
      type: 'currency'
    },
  ]), []);

  const onRemove = (healthBenefitSettingLine) => {
    return axios({
      method: 'DELETE',
      url:
        Routes.api_web_v1_health_benefit_setting_health_benefit_setting_line_path(
          params.healthBenefitSettingId,
          healthBenefitSettingLine.id
        )
    })
      .then((response) => {
        enqueueSnackbar('Health benefit setting line was removed.', { variant: 'success' });
        return response;
      });
  };

  const onAdd = () => (
    history.push(`/health_benefit_settings/${params.healthBenefitSettingId}/health_benefit_setting_lines/new`)
  );

  const onEdit = (healthBenefitSettingLine) => (
    history.push(`/health_benefit_settings/${params.healthBenefitSettingId}/health_benefit_setting_lines/${healthBenefitSettingLine.id}/edit`)
  );

  return (
    <div className="pt-4">
      <MaterialDataTable
        title="Health benefit setting lines"
        tableColumns={tableColumns}
        path={Routes.api_web_v1_health_benefit_setting_health_benefit_setting_lines_path(params.healthBenefitSettingId)}
        onRemove={onRemove}
        onAdd={onAdd}
        onEdit={onEdit}
      />
    </div>
  );
};

export default HealthBenefitSettingLinesList;


