import React, { useEffect } from 'react';
import { Field } from 'redux-form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import map from 'lodash/map';
import { connect } from 'react-redux';
import LabelInputValue from '../../../../decorators/LabelInputValue';
import SelectWrapper from '../../../../components/ReduxForm/SelectWrapper';
import RenderField from '../../../../components/ReduxForm/RenderField';
import { fetchBusinessPartnerData } from '../../../../redux/businessPartnerStoreReducer/actions';
import Select2Collection from '../../../../decorators/Select2Collection';

let GeneralPartnerForm = ({ employees, businessPartnerType, changeBusinessPartnerCollection }) => {

  // useEffect(() => {
  //   if (businessPartnerType.length === 0) {
  //     changeBusinessPartnerCollection();
  //   }
  // });

  const employeesCollection = Select2Collection(employees, { labelKey: 'full_name' });

  const businessPartnerTypeCollection = map(businessPartnerType, (value, _key) => ({
    value: value.key,
    label: LabelInputValue(value.key)
  }));

  return (
    <Row>
      <Col md={6}>
        <Field
          name="fax"
          component={RenderField}
        />
        <Field
          name="web_site"
          component={RenderField}
        />
        <Field
          name="password"
          component={RenderField}
        />
      </Col>
      <Col xs={6}>
        <Field
          name="contact_person"
          component={RenderField}
        />
        <Field
          name="businessPartner_type"
          component={SelectWrapper}
          collection={businessPartnerTypeCollection}
          label="Business partner type"
          formName="businessPartnerForm"
        />
        <Field
          name="remarks"
          disabled={true}
          component={RenderField}
        />

        <Field
          name="sales_employee_id"
          component={SelectWrapper}
          collection={employeesCollection}
          label="Sales employee"
          formName="businessPartnerForm"
        />
      </Col>
    </Row>
  );
};


const mapDispatchToProps = dispatch => ({
  changeBusinessPartnerCollection: () => {
    dispatch(fetchBusinessPartnerData());
  },
});


const mapStateToProps = (state) => {

  const {
    form: {
      businessPartnerForm:
        businessPartner
    },
    businessPartnerStore: {
      businessPartnerTypeCollections,
      employeesCollections
    }
  } = state;

  return {
    businessPartner,
    businessPartnerType: map(businessPartnerTypeCollections, (value, key) => ({
      value,
      key
    })),
    employees: employeesCollections,
  };

};

export default connect(mapStateToProps, mapDispatchToProps)(GeneralPartnerForm);
