import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import { change, Field, reduxForm } from 'redux-form';
import { useSelector, useDispatch } from 'react-redux';
import ReduxFormValidationAlert from '../../../../components/ReduxFormValidationAlert';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import RenderField from '../../../../components/ReduxForm/RenderField';
import SelectWrapper from '../../../../components/ReduxForm/SelectWrapper';
import Select2Collection from '../../../../decorators/Select2Collection';
import MoneyInput from '../../../../components/ReduxForm/MoneyInput';
import ReduxFormSubmissionError from '../../../../components/ReduxFormSubmissionError';
import axios from 'axios';
import round from 'lodash/round';
import get from 'lodash/get';
import find from 'lodash/find';
import map from 'lodash/map';
import reduce from 'lodash/reduce';
import isEmpty from 'lodash/isEmpty';
import { useParams } from 'react-router-dom';

const FormCompanyRetirementSettingLine = ({ stepButtons, handleSubmit, benefitSettingLines = [] }) => {

  const dispatch = useDispatch();
  let params = useParams();

  const [healthPlans, setHealthPlans] = useState([]);
  const [companyPays, setCompanyPays] = useState(0);
  const [employeePays, setEmployeePays] = useState(0);
  const [selectedPlanCode, setSelectedPlanCode] = useState('');

  const healthBenefitSettingLineForm = useSelector(state => state.form.healthBenefitSettingLineForm);
  const currentSetupStepData = useSelector(state => state.projectPayrollSetupStore.currentSetupStepData);
  const formValues = get(healthBenefitSettingLineForm, 'values', null);
  const selectedPlanId = get(formValues, 'health_plan_id', null);

  const benefitSettingLinesCodes = reduce(benefitSettingLines, (array, line, index) => {
    array.push(line.plan_code);
    return array;
  }, []);

  const loadHealthPlans = () => {
    return axios({ url: Routes.api_web_v1_dictionaries_path({ name: 'health_plan' }) });
  };

  const recalculateCompanyPays = () => {
    return axios({
      method: 'POST',
      url: Routes.math_calculation_formula_api_web_v1_health_benefit_setting_health_benefit_setting_lines_path(params.healthBenefitSettingId),
      data: { health_benefit_setting_line: formValues }
    });
  };

  useEffect(() => {
    recalculateCompanyPays()
      .then(response => {
        setCompanyPays(round(response.data.company_pays_per_week, 2));
        setEmployeePays(round(response.data.employee_pays_per_week, 2));
      })
      .catch(error => {
        console.log('HealthBenefitSettingLineForm.response', error.response);
        ReduxFormSubmissionError(error.response.data.errors, formValues);
      });

  }, [formValues]);

  useEffect(() => {
    const selectedPlan = find(healthPlans, { id: selectedPlanId });
    if (isEmpty(selectedPlan)) {
      setSelectedPlanCode(null);
    } else {
      setSelectedPlanCode(selectedPlan.code);
    }
  }, [selectedPlanId, healthPlans]);

  useEffect(() => {
    loadHealthPlans()
      .then(response => {
        setHealthPlans(response.data);
      })
      .catch(error => {
        console.log('HealthBenefitSettingLineForm.response', error.response);
      });
  }, []);

  const calculateCostPerWeek = (event, value) => {
    dispatch(change('healthBenefitSettingLineForm', 'total_cost_per_week_cents', round((value * 12) / 52, 2)));
  };

  const calculateCostPerMonth = (event, value) => {
    dispatch(change('healthBenefitSettingLineForm', 'total_cost_per_month_cents', round((value * 52) / 12, 2)));
  };

  return (
    <>
      <ReduxFormValidationAlert formName={'healthBenefitSettingLineForm'}/>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col md="4">
            <Field
              name="health_plan_id"
              component={SelectWrapper}
              collection={Select2Collection(healthPlans, { labelKey: 'name' })}
              label="Health plan"
              formName="healthBenefitSettingLineForm"
            />
          </Col>
          <Col md="4">
            <MoneyInput
              name="total_cost_per_month_cents"
              formName="healthBenefitSettingLineForm"
              label="Total cost per month"
              onChange={calculateCostPerWeek}
            />
          </Col>
          <Col md="4">
            <MoneyInput
              name="total_cost_per_week_cents"
              formName="healthBenefitSettingLineForm"
              label="Total cost per week"
              onChange={calculateCostPerMonth}
            />
          </Col>
        </Row>

        <Row>
          <Col md="12">
            <Field
              name="calculation_formula"
              component={RenderField}
              disabled={isEmpty(selectedPlanCode)}
            />
            {isEmpty(selectedPlanCode) &&
            <small className="text-danger">First select health plan.</small>
            }
            {!isEmpty(selectedPlanCode) &&
            <>
              <small>
                Variables which you can use:
                <ul>
                  {
                    map(benefitSettingLines, (benefitSettingLine, index) => {
                      return (
                        <>
                          <li>
                            <code>health_plan.{benefitSettingLine.plan_code}.total_cost_per_week</code>
                          </li>
                          <li>
                            <code>health_plan.{benefitSettingLine.plan_code}.company_pays</code>
                          </li>
                        </>);
                    })
                  }
                  <li>
                    <code>health_plan.{selectedPlanCode}.total_cost_per_week</code>
                  </li>
                  <li>
                    <code>health_plan.{selectedPlanCode}.company_pays</code>
                  </li>
                </ul>
              </small>
            </>
            }
          </Col>
        </Row>

        <hr/>

        <Row>
          <Col md="12" className="d-flex align-items-end flex-column">
            <div>Employee pays: ${employeePays}</div>
            <div>Company pays: ${companyPays}</div>
          </Col>
        </Row>

        <Row className="pt-3">
          <Col md="6">
            <Button variant="success" type="submit" block>Save</Button>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default compose(
  reduxForm({
    form: 'healthBenefitSettingLineForm',
    enableReinitialize: true
  })
)(FormCompanyRetirementSettingLine);

