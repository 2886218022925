import React, { useState, useEffect } from 'react';
import FormCompanyRetirementSettingLine from './form';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import delay from 'lodash/delay';
import ReduxFormSubmissionError from '../../../../components/ReduxFormSubmissionError';
import { useSnackbar } from 'notistack';

const FormHealthBenefitSettingLineWrapper = ({
  stepButtons = [], goNextStep = () => {
  }
}) => {

  let params = useParams();
  const {enqueueSnackbar} = useSnackbar();
  const [loadPath, setLoadPath] = useState(null);
  const [healthBenefitSettingLine, setHealthBenefitSettingLine] = useState({});

  useEffect(() => {
    if (params.healthBenefitSettingLineId) {
      if (params.healthBenefitSettingLineId) {
        setLoadPath(
          Routes.edit_api_web_v1_health_benefit_setting_health_benefit_setting_line_path(params.healthBenefitSettingId, params.healthBenefitSettingLineId)
        );
      } else {
        setLoadPath(
          Routes.new_api_web_v1_health_benefit_setting_health_benefit_setting_line_path(params.healthBenefitSettingId)
        );
      }
    }
  }, [params]);

  useEffect(() => {
    if (loadPath) {
      loadHealthBenefitSettingLine()
        .then(response => {
          setHealthBenefitSettingLine(response.data.health_benefit_setting_line);
        })
        .catch(error => {
          console.log('FormHealthBenefitSettingLineWrapper.loadHealthBenefitSettingLine', error.response);
        });
    }
  }, [loadPath]);

  const loadHealthBenefitSettingLine = () => {
    return axios({ url: loadPath });
  };

  const handleSubmit = (values) => {
    const submitPath = params.healthBenefitSettingLineId ?
      Routes.api_web_v1_health_benefit_setting_health_benefit_setting_line_path(
        params.healthBenefitSettingId,
        params.healthBenefitSettingLineId
      ) :
      Routes.api_web_v1_health_benefit_setting_health_benefit_setting_lines_path(
        params.healthBenefitSettingId
      );
    const submitMethod = params.healthBenefitSettingLineId ? 'PUT' : 'POST';

    return axios({
      method: submitMethod,
      url: submitPath,
      data: { health_benefit_setting_line: values }
    })
      .then(response => {
        delay(() => {
          goNextStep();
        }, 1000);
        enqueueSnackbar('Health benefit setting line was saved.', { variant: 'success' })
      })
      .catch(error => {
        ReduxFormSubmissionError(error.response.data.errors, values);
      });
  };

  return (
    <>
      <h4>{`${params.healthBenefitSettingLineId ? 'Edit' : 'Crate'} health benefit setting line`}</h4>
      {healthBenefitSettingLine &&
      <FormCompanyRetirementSettingLine
        stepButtons={stepButtons}
        onSubmit={handleSubmit}
        initialValues={healthBenefitSettingLine}
      />
      }
    </>
  );
};

export default FormHealthBenefitSettingLineWrapper;

