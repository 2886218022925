import Application from 'application-namespace';
import $ from 'jquery';

Application.Classes.EmployeeWorkPeriod = class EmployeeWorkPeriod extends Application.Classes.CoreBase {
  constructor($scope) {
    super();
    this.$scope = $scope;
    this.$editButton = this.$scope.find('[data-edit-button-enabled]');
    this.$tableElement = this.$scope.find('table');
    this.$tableOverlay = this.$scope.find('.table-loading-overlay');

    this.$scope.on('cocoon:after-insert', (e, insertedItem) => Application.app.initializeSelectPicker(insertedItem));

    if (this.$editButton) {
      this.$tableElement.addClass('position-relative');
      this.editIsEnablen = this.$editButton.data('editButtonEnabled');
      this.$editButton.on('click', () => this.disableBehavior());
      this.disableBehavior();
    }
  }

  disableBehavior() {
    // const html = "<div class='table-loading-overlay'></div>";
    if (this.editIsEnablen) {
      this.$tableOverlay.removeClass('d-none').addClass('d-flex');
      this.$editButton.html('Enable edit');
      this.editIsEnablen = false;
    } else {
      // this.$tableElement.find('.table-loading-overlay').remove();
      this.$tableOverlay.removeClass('d-flex').addClass('d-none');
      this.$editButton.html('Disable edit');
      this.editIsEnablen = true;
    }
  }
};
