import Application from 'application-namespace';
import $ from 'jquery';

Application.Classes.FederalTaxSetupForm = class FederalTaxSetupForm {
  constructor($scope) {
    this.$scope = $scope;
    this.$exemptInput = this.$scope.find('[name="federal_tax_setup[exempt]"]');

    this.$exemptInput.on('change', (event) => {
      if ($(event.currentTarget).prop('checked')) {
        if (!$(event.currentTarget).closest('.input').find('.exempt-warning').length) {
          $(event.currentTarget).closest('.input').append('<div class="exempt-warning text-danger">Are you sure you meet BOTH of the following criteria:'
              + '<li>Last year I had a right to a refund of all federal income tax withheld because I had no tax liability.</li>'
              + '<li>This year I expect a refund of all federal income tax withheld because I expect to have no tax liability.</li></div>');
        }
      } else {
        $(event.currentTarget).closest('.input').find('.exempt-warning').remove();
      }
    });
  }
};
